
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from "../../../../widgets/LangContext";
import { ordinalSuffix, timeAMandPMformat } from '../../../../widgets/NumberFormat';
import { CreatedDateFormat } from '../../../../widgets/DateFormat';

const WeeklyLessionPlanListView = () => {
    const { language } = useLanguage();
    const { teacher_weekly_lession_plan_list } = useSelector((state) => state.academics);
    return (
        <Fragment>
            <div className='bg-white rounded-md p-2 shadow-sm dark:bg-slate-800'>
                <div className="relative overflow-x-scroll">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray600 capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr className='bg-gray200'>

                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Lession To Be Taught" : "अवधि समय"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Objectives to the lession" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Teaching Materials" : "रोल नं"}
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Teaching Method" : "फोन"}
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Assignments Test or Project Works" : "DOB"}
                                </th>

                                <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Created At" : "लिङ्ग"}
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(teacher_weekly_lession_plan_list?.results) && teacher_weekly_lession_plan_list?.results?.length > 0 ? (
                                teacher_weekly_lession_plan_list?.results?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 py-1 border dark:border-slate600 border-gray300 font-medium">
                                            {cls?.lession_to_be_taught || "-"}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.objective_to_lession || "-"}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.teachining_materials || "-"}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.teachining_methods || "-"}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.assignment_test || "-"}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.created_at ? CreatedDateFormat(cls?.created_at) : ""} {timeAMandPMformat(cls?.created_at)}
                                        </td>
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    No Data Found
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>

        </Fragment >
    );
}

export default WeeklyLessionPlanListView;
