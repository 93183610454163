
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { CustomOptions, StdBatchOptions } from '../../../../widgets/Options';
import { door_side_list } from '../../../../widgets/json';

const HallRoomCreateView = ({ FetchData, onBatchList }) => {
    const { language } = useLanguage();
    const DoorSideOption = CustomOptions(door_side_list);
    const StdBatchOption = StdBatchOptions(onBatchList?.batch);
    const [selectValues, setOnSelectValues] = useState({
        door_side: "",
        batch_id: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };

    const initialValues = {
        door_side: "",
        batch_id: "",
        hall_name: "",
        desk_row: "",
        desk_col: "",
        seat_cap: "",
    };

    const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("door_side", selectValues.door_side.value);
                formData.append("batch", selectValues.batch_id.value);
                formData.append("hall_name", values.hall_name);
                formData.append("desk_row", values.desk_row);
                formData.append("desk_col", values.desk_col);
                formData.append("seat_cap", values.seat_cap);
                try {
                    await postDataToTheServer(AppURL.ExamURL + "exam-seat-plan-hall-room-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        action.resetForm();
                        FetchData();
                        setOnSelectValues({
                            door_side: "",
                            batch_id: "",
                        });
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add Hall Room/Class Room" : "हल कोठा/कक्षा कोठा थप्नु"} icon={IoMdAddCircle} />
                <Grid container spacing={1}>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <SelectOptionBox
                            label={language ? "Batch" : "ब्याच"}
                            placeholder="Select Batch"
                            value={selectValues.batch_id}
                            options={StdBatchOption}
                            name="batch_id"
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.batch_id}
                            errors={errors.batch_id}
                            required={true} />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="hall_name"
                            type="text"
                            placeholder="Enter Hall Name"
                            label={language ? "Hall Name" : "हलको नाम"}
                            value={values.hall_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.hall_name}
                            errors={errors.hall_name}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <SelectOptionBox label={language ? "Door Side" : "ढोका साइड"} placeholder="Door Side" value={selectValues.door_side} options={DoorSideOption} name="door_side" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.door_side} errors={errors.door_side} required={true} />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="desk_row"
                            type="number"
                            placeholder="Enter Row Line Desk Number"
                            label={language ? "Row Desk Number" : "परीक्षा पूर्ण अंक"}
                            value={values.desk_row}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.desk_row}
                            errors={errors.desk_row}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="desk_col"
                            type="number"
                            placeholder="Enter Column Line Desk Number"
                            label={language ? "Column Desk Number" : "परीक्षा पूर्ण अंक"}
                            value={values.desk_col}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.desk_col}
                            errors={errors.desk_col}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="seat_cap"
                            type="number"
                            placeholder="Enter Seat Capacity(Per Desk)"
                            label={language ? "Seat Capacity(Per Desk)" : "सिट क्षमता"}
                            value={values.seat_cap}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.seat_cap}
                            errors={errors.seat_cap}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default HallRoomCreateView