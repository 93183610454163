import React, { useState } from 'react'
export const LoginContext = React.createContext();
const AuthContext = ({ children }) => {
    const [authUser, setOnAuthUser] = useState([]);
    const [userID, setOnUserID] = useState(null);

    return (
        <div>
            <LoginContext.Provider value={{ authUser, setOnAuthUser, setOnUserID, userID }}>
                {children}
            </LoginContext.Provider>
        </div>
    )
}
export default AuthContext