import React, { useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { PdfExportBtn } from '../../../../widgets/AppBtn';
import AccountDuesReportListView from '../../../../components/account/report/dues_rpt/AccountDuesReportListView';

const StudentAccountDuesReportListView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(pageTitle({ "title": PageTitle }))
      document.title = `Nexapp - ${PageTitle}`;
      setIconsButton(<PdfExportBtn />);
    }, []);
  return (
    <Fragment>
        <AccountDuesReportListView />
    </Fragment>
  )
}

export default StudentAccountDuesReportListView