import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
import GalleryCateEditView from '../../../components/mobile_app/gallery_cate/GalleryCateEditView';
import GalleryCateCreateView from '../../../components/mobile_app/gallery_cate/GalleryCateCreateView';
import GalleryCateListView from '../../../components/mobile_app/gallery_cate/GalleryCateListView';

const GalleryCategoryCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
  const [onEditID, setOnEditID] = useState(false);
  const [onAcaListData, setOnAcaListData] = useState([]);
  const url = AppURL.MobileAppURL + "gallery-category-create-list-view/";
  const [onUpdateURL, setOnUpdateURL] = useState("");
  const fetchAcademicsListView = async (urls) => {
    setOnUpdateURL(urls);
    try {
      await fetchDataFromApi(urls).then((res) => {
        setOnAcaListData(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const FetchData = async () => {
    await fetchAcademicsListView(url);
  }
  useEffect(() => {
    FetchData();
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xl={4} lg={4} sm={12} xs={12} md={4}>
        {
          onEditID ? <GalleryCateEditView setOnEditID={setOnEditID} fetchAcademicsListView={fetchAcademicsListView} onUpdateURL={onUpdateURL} onEditID={onEditID} /> : <GalleryCateCreateView FetchData={FetchData} />
        }
      </Grid>
      <Grid item xl={8} lg={8} sm={12} xs={12} md={8}>
        <GalleryCateListView fetchAcademicsListView={fetchAcademicsListView} onAcaListData={onAcaListData} setOnEditID={setOnEditID} />
      </Grid>
    </Grid>)
}

export default GalleryCategoryCreateListView