import React from 'react'
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';
import ReportLabel from '../../../../widgets/ReportLabel';

const MarksLedgNumListPDFView = () => {
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    const { marks_ledger_number_list_view, marks_ledger_view } = useSelector((state) => state.exam);
    if (!marks_ledger_number_list_view || !marks_ledger_number_list_view.data || marks_ledger_number_list_view.data.length === 0) {
        return (
            <div className='bg-white p-1 rounded-md font-public-sans text-[12px] dark:bg-gray-800'>
                <p className='text-gray-500 dark:text-gray-400 text-center'>Data not found</p>
            </div>
        );
    }
    console.log(marks_ledger_view)
    // Group students by roll number
    const groupedStudents = {};
    marks_ledger_number_list_view.data.forEach((student) => {
        const rollNo = student.roll_no;
        if (!groupedStudents[rollNo]) {
            groupedStudents[rollNo] = {
                roll_no: rollNo,
                first_name: student.first_name,
                last_name: student.last_name,
                subjects: {},
            };
        }
        groupedStudents[rollNo].subjects[student.subj_name] = student.th;
    });

    // Get all unique subjects
    const subjects = Object.keys(
        marks_ledger_number_list_view.data.reduce((acc, student) => {
            acc[student.subj_name] = true;
            return acc;
        }, {})
    );
    return (
        <div className=''>
            <div className='bg-white h-screen print-portrait'>
            <style>
                    {`@media print {
                        @page {
                            size: A4 portrait;
                            margin: 0.5in; /* Set margin for all sides */
                        }
                    }`}
                </style>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">

                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>

                            </tr>
                            <tr >
                                <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>

                                <td className="w-[25%]">
                                </td>

                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                    Date: {DateNow}
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Mark Ledger Report
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className='font-public-sans overflow-x-auto mt-2 text-[11px] gap-2 flex flex-row'>
                    {marks_ledger_view?.class && <ReportLabel label="Class Name: " value={marks_ledger_view?.class} />}
                    {marks_ledger_view?.batch && <ReportLabel label="Batch Name" value={marks_ledger_view?.batch} />}
                    {marks_ledger_view?.exam_type && <ReportLabel label="Exam" value={marks_ledger_view?.exam_type} />}
                </div>
                <div className='overflow-x-auto'>
                    <table width="100%" border={1} className='whitespace-nowrap'>
                        <thead>
                            <tr className='bg-slate200 text-[12px] text-black dark:text-gray-400 opacity-80 dark:bg-gray-900'>
                                <th align='center' className='border border-gray-300 px-2 py-1 w-12'>Roll No</th>
                                <th className='border border-gray-300 px-2 py-1'>Student Name</th>
                                {subjects.map((subject) => (
                                    <th  className='border border-gray-300 px-2 py-1 w-14' key={subject}>{subject}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(groupedStudents).map((student, index) => (
                                <tr key={index} className='text-gray-800 text-[12px] dark:text-gray-600'>
                                    <td align='center' className='border px-1 py-1 border-gray-300'>{student.roll_no}</td>
                                    <td className='border px-1 py-1 border-gray-300'>{`${student.first_name?student.first_name: ""} ${student.middle_name?student.middle_name: ""} ${student.last_name?student.last_name: ""}`}</td>
                                    {subjects.map((subject) => (
                                        <td align='center' className='border px-1 py-1 border-gray-300' key={subject}>
                                            {student.subjects[subject] || '-'}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MarksLedgNumListPDFView