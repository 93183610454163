import React, { Fragment } from 'react'
import ComponentHeading from '../../../../widgets/ComponentHeading'
import { useLanguage } from '../../../../widgets/LangContext'
import { FaListUl } from 'react-icons/fa';
import { EditPenBtn, NextBtn, PrevBtn, ViewEyeBtn } from '../../../../widgets/AppBtn';
import { Link } from 'react-router-dom';

const HallRoomListView = ({ onAcaListData, setOnEditID, fetchHallRoomList }) => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Exam Hall List" : "परीक्षा हल सूची"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Batch Name" : "ब्याच नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Hall Name" : "हलको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Door Side" : "ढोका साइड"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Row Desk No" : "रो डेस्क नं"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Column Desk No" : "स्तम्भ डेस्क नं"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Seat Capacity Per Desk" : "सिट क्षमता प्रति डेस्क"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-20">
                                    {language ? "Action " : "कार्य"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onAcaListData?.results) &&
                                onAcaListData?.results?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 capitalize">
                                            {cls?.batch_name}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {cls?.hall_name}
                                        </td>
                                        <td className="px-2 border dark:border-slate600">
                                            {cls?.door_side === 1 ? "Left Side" : "Right Side"}
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            {cls?.desk_row}
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            {cls?.desk_col}
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            {cls?.seat_cap}
                                        </td>
                                        <td align='center' className="px-2 border dark:border-slate600 ">
                                            <EditPenBtn onClick={async () => await setOnEditID(cls?.sn)} /> <Link to={`/exam-std-hall-seat-details-view/${cls?.sn}`} ><ViewEyeBtn /></Link>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
                    if (onAcaListData?.previous) {
                        await fetchHallRoomList(onAcaListData?.previous)
                    }
                }} />
                <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
                    if (onAcaListData?.next) {
                        await fetchHallRoomList(onAcaListData?.next)
                    }
                }} />
            </div>
        </Fragment>
    )
}

export default HallRoomListView