import React, { Fragment, useEffect, useState } from 'react'
import { fetchDataFromApi, putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { useParams } from 'react-router-dom';
import door from "../../../../assets/img/icon/door.png";
import blackboard from "../../../../assets/img/icon/teachings.png";
import deskbench from "../../../../assets/img/icon/desk.png";
import ExamCheckBox from '../../../../widgets/ExamCheckBox';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { FaChair } from "react-icons/fa";

const HallRoomDetailsView = ({ studentSeats, fetchHallDeskListView }) => {
    const { id } = useParams();
    const InActiveBench = async (row, column) => {
        const formData = new FormData();
        formData.append("column_number", column);
        formData.append("row_number", row);
        try {
            await putDataToTheServer(AppURL.ExamURL + "exam-seat-plan-hall-room-retrieve-details-active-inactive-view/" + id, formData).then((res) => {
                ToastSuccess(res?.message);
                fetchHallDeskListView();
            });
        } catch (error) {
            console.log(error);
        }
    }
    const renderRows = () => {
        const columns = {};
        studentSeats?.desk_list?.forEach(seat => {
            const { row_number, column_number } = seat;
            const key = `${row_number}-${column_number}`;
            if (!columns[column_number]) {
                columns[column_number] = [];
            }
            columns[column_number].push(seat);
        });
        var desk_no = 1;
        return Object.keys(columns)?.map((columnNumber) => (
            <tr key={columnNumber}>
                {columns[columnNumber]?.map((seat, index) => (
                    <td key={`${seat?.row_number}-${seat?.column_number}-${index}`} className='px-3 py-1'>
                        <div className={`${seat?.status ? "bg-gray100 " : "bg-red-100"} rounded-md flex justify-center`}>
                            <div className='w-full'>
                                <div className='flex flex-row justify-center py-1.5'>
                                    <div className='flex flex-row px-2'>
                                        <img src={deskbench} className='my-auto w-7 h-76' alt='Door' />
                                        <h6 className='my-auto font-public-sans font-bold'>
                                            <ExamCheckBox onClick={() => InActiveBench(seat.row_number, seat.column_number)} value={seat.status} label={`Desk ${desk_no++}`} />
                                        </h6>

                                    </div>
                                </div>
                                <div className='flex flex-row justify-between w-full px-2 pb-0.5'>
                                    {
                                        seat?.seats?.map((seat, index) => (
                                            <div title={seat?.student_name} className={` ${seat?.roll_no ? "bg-customPrimary  rounded-sm" : "bg-green-500 rounded-full"} p-1 cursor-pointer text-white flex flex-row`}><div>
                                                {
                                                    seat?.roll_no ? "" : <FaChair size={10} />
                                                }
                                                {
                                                    seat?.roll_no ?
                                                        <Fragment>
                                                            <h6 className='text-[12px] font-bold font-public-sans text-center'>Class: {seat?.class_name} [{seat?.roll_no}]</h6>

                                                            <h6 className='text-[9px] font-public-sans -mt-1.5'>{`${seat?.roll_no ? seat?.roll_no + "." : ""} ${seat?.first_name ? seat?.first_name : ""} ${seat?.middle_name ? seat?.middle_name : ""} ${seat?.last_name ? seat?.last_name : ""}`}</h6>
                                                        </Fragment> : ""
                                                }

                                            </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                        </div>
                    </td>
                ))}
            </tr>
        ));
    };

    return (
        <div className='px-4 bg-white p-2 rounded-md'>
            <div className='bg-customPrimary px-1 rounded-sm'>
                <div className='flex justify-between py-1 px-2 my-auto'>
                    <div>
                        <div className='bg-white rounded-full h-10'>
                            {studentSeats?.hall_details?.door_side === 1 &&
                                <div className='flex flex-row px-2'>  <img src={door} className='p-1.5 my-auto w-10 h-10' alt='Door' />
                                    <h6 className=' my-auto font-public-sans font-bold'>Door Side</h6></div>}
                        </div>
                    </div>
                    <div className={`bg-white rounded-full h-10 ${studentSeats?.hall_details?.door_side === 1 ? "-ml-28" : "ml-28"}`}>
                        <div className='flex flex-row px-2'>  <img src={blackboard} className='p-1.5 my-auto w-10 h-10' alt='Door' /> <h6 className=' my-auto font-public-sans font-bold'>{studentSeats?.hall_details?.hall_name}</h6></div>
                    </div>
                    <div className='bg-white rounded-full h-10'>
                        {studentSeats?.hall_details?.door_side === 2 &&
                            <div className='flex flex-row px-2'>  <img src={door} className='p-1.5 my-auto w-10 h-10' alt='Door' />
                                <h6 className=' my-auto font-public-sans font-bold'>Door Side</h6></div>}
                    </div>
                </div>
            </div>
            <table className="text-sm text-left rtl:text-right overflow-y-scroll whitespace-nowrap scroll-x-auto rounded w-full">
                <tbody>
                    {renderRows()}
                </tbody>
            </table>
        </div>
    )
}

export default HallRoomDetailsView