import React, { useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SidebarReportClose } from '../../../store/softSlice';
import { useFormik } from 'formik';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ItemStockRptList, ItemStockType } from '../../../store/inventorySlice';
import ReportHeading from '../../../widgets/ReportHeading';
import DefaultSelectOptionBox from '../../../widgets/DefaultSelectBox';
import { StockReportOptions } from '../../../widgets/Options';
import { ClearBtn, SearchBtn } from '../../../widgets/AppBtn';



const StockRptForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }


    const [selectValues, setOnSelectValues] = useState({
        IssueSalesPurchase: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data.value,
        }));
    };



    const initialValues = {
        BranchID: "",
    }

    const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.InventoryURL + `inventory-product-sales-issue-available-rpt-list/?IssueSalesPurchase=${selectValues?.IssueSalesPurchase}`).then((res) => {
                        dispatch(ItemStockRptList(res));
                        dispatch(ItemStockType({ "RptType": selectValues?.IssueSalesPurchase }));
                        CloseDispatch();
                        navigate("/inventory-stock-report-page-list-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <div className=''>
            <ReportHeading heading="Product Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <DefaultSelectOptionBox name="IssueSalesPurchase" label="Product Report Type" options={StockReportOptions} value={selectValues.IssueSalesPurchase} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.IssueSalesPurchase} errors={errors.IssueSalesPurchase} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StockRptForm