import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';


const PDFClassWiseRoutineListView = () => {

    const { class_routine_list_view } = useSelector((state) => state.academics);
    const [groupedData, setGroupedData] = useState({});
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    useEffect(() => {
        if (class_routine_list_view?.routine) {
            const dataByDay = class_routine_list_view.routine.reduce((acc, curr) => {
                const day = curr.dayy;
                if (!acc[day]) {
                    acc[day] = [];
                }
                acc[day].push(curr);
                return acc;
            }, {});
            setGroupedData(dataByDay);
        }
    }, [class_routine_list_view?.routine]);

    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">

                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>
                            </tr>
                            <tr >
                                <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                    Date: {DateNow}
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Class Routine
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="relative overflow-x-scroll mt-3">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <tbody>
                            {Object.entries(groupedData).map(([day, dayData]) => (
                                <tr key={day} className={`text-[12px] text-gray600 capitalize  border border-gray300 dark:border-slate600 dark:text-gray-400 font-public-sans`}>
                                    <td className="px-2 bg-gray-200 font-semibold py-1 border border-gray300 dark:border-slate600">{day === '1' ? "Sunday" : day === '2' ? "Monday" : day === '3' ? 'Tuesday' : day === '4' ? "Wednesday" : day === '5' ? "Thursday" : day === '6' ? "Friday" : "Saturday"}</td>
                                    {dayData?.map((item, index) => (
                                        <td key={index} colSpan={1} className={`px-2 py-1 ${item.teacher_id === null ? "bg-red-200" : ""} border border-gray300 dark:border-slate600`}>
                                            {item.subject_id !== null ? (
                                                <Fragment>
                                                    <h4 className='font-medium text-[14px]'>{item.subject_name}</h4>
                                                    <h4 className='font-normal text-[12px]'>{item.start_time} - {item.end_time}</h4>
                                                    <h4 className='font-normal text-[12px] text-red-700'>[{`${item.first_name ? item.first_name : ""} ${item.middle_name ? item.middle_name : ""} ${item.last_name ? item.last_name : ""}`}]</h4>
                                                </Fragment>
                                            ) : <div>
                                                <h4>{item?.activities}</h4>
                                                <h4>{item?.start_time} - {item?.end_time}</h4></div>}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment >
    )
}

export default PDFClassWiseRoutineListView