import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { PdfExportBtn } from '../../../../widgets/AppBtn';
import MarksheetPrintPageView from '../../../../components/exam/report/marksheet/MarksheetPrintPageView';
import { useReactToPrint } from 'react-to-print';
import MarksheetStudentListView from '../../../../components/exam/report/marksheet/MarksheetStudentListView';

const ExamMarksheetStudentListView = ({ setIconsButton, PageTitle }) => {
    const dispatch = useDispatch();
    const { marks_ledger_view } = useSelector((state) => state.exam);
    const [students, setStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const componentListRef = useRef();
    const [onSelectedStudent, setOnSelectedStudent] = useState([]);
    useEffect(() => {
        dispatch(pageTitle({ title: PageTitle }));
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(<PdfExportBtn onClick={handlePrintCard} />);
    }, [dispatch, PageTitle, setIconsButton]);

    useEffect(() => {
        if (marks_ledger_view?.data) {
            const Ledgers = marks_ledger_view.data;
            setStudents(Ledgers);

            const uniqueSubjects = [...new Set(Ledgers.map(subject => subject.subj_name))];
            setSubjects(uniqueSubjects);
        }
    }, [marks_ledger_view]);

    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });

    const groupDetailsData = students.reduce((acc, student) => {
        if (!acc[student.member_sn_id]) {
            acc[student.member_sn_id] = {
                member_sn_id: student.member_sn_id,
                name: student.studname,
                roll_no: student.roll_no,
                class: student.class_name,
                sec: student.sec_id,
                father_name: student.father_name,
                district_name: student?.district_name,
                dobn: student?.dobn,

                marks: {},
                studtotalgrade: student.studtotalgrade,
                studtotalgpa: student.studtotalgpa,
            };
        }
        acc[student.member_sn_id].marks[student.subj_name] = {
            total: student.total,
            grade: student.totalgrade,
            thgrade: student.thgrade,
            prgrade: student.prgrade,
            totalgpa: student.totalgpa
        };
        return acc;
    }, {});

    const studentList = Object.values(groupDetailsData);

    return (
        <React.Fragment>
            <MarksheetStudentListView setOnSelectedStudent={setOnSelectedStudent} onSelectedStudent={onSelectedStudent} />
            <div className='hidden'>
                <div ref={componentListRef}>
                    {studentList.map((student, index) => (
                        <MarksheetPrintPageView key={index} student={student} subjects={subjects} />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ExamMarksheetStudentListView;
