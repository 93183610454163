import React, { Fragment } from 'react'
import ComponentHeading from '../../../../../widgets/ComponentHeading';
import { FaUserGraduate } from 'react-icons/fa';
import { useLanguage } from '../../../../../widgets/LangContext';

const StaffPersonalDetails = ({ onEmployeeDetails }) => {
  const { language } = useLanguage();
  return (
    <Fragment>
      <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
        <ComponentHeading label={'Personal Details'} icon={FaUserGraduate} />
        <div className="relative overflow-x-auto">
          <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
            <thead className="text-[12px] text-gray600  capitalize border-b  dark:text-gray-400 font-public-sans">
            </thead>
            <tbody className='text-slate-800  font-medium'>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Gender
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.gender === 1 ? "Female" : onEmployeeDetails?.gender === 2 ? "Male" : "Other"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  DOB [AD]
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.dobe ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  DOB [BS]
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.dobn ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Birth Certificate No.
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.citz_no ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  E-mail
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.email ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Blood Group
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.blood_group ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Religion
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.religion ||"-"}
                </td>
              </tr>


              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Nationality
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.nationality ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Registration Date
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.reg_date_n ||"-"}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Qualification
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {onEmployeeDetails?.qualification ||"-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default StaffPersonalDetails