import React, { Fragment } from 'react';
import MarksEvaluationRptFormView from '../../../../components/exam/report/marks_evl_rpt/MarksEvaluationRptFormView';

const MarksEvaluationReportFormView = () => {
  return (
    <Fragment>
        <MarksEvaluationRptFormView />
    </Fragment>
  )
}

export default MarksEvaluationReportFormView