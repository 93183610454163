import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { Grid } from '@mui/material';
import SearchStudentListView from '../../../../components/academics/create/upgrade/SearchStudentListView';
import UpgradeStudentListView from '../../../../components/academics/create/upgrade/UpgradeStudentListView';
import { GrTransaction } from "react-icons/gr";
import ListUpgradeStudentView from '../../../../components/academics/create/upgrade/ListUpgradeStudentView';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';

const AcaStudentUpgradeCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }));
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
  const fetchStudentFilterOptionListView = async () => {
      try {
          await fetchDataFromApi(AppURL.ExamURL + "exam-student-filter-options-list-view/").then((res) => {
              setOnStudentOptionList(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
      fetchStudentFilterOptionListView();
  }, []);
  return (
    <Fragment>
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xl={5.5} md={5.5} lg={5.5} xs={12} sm={12}>
          <SearchStudentListView onStudentOptionList={onStudentOptionList} />
        </Grid>
        <Grid item xl={1} md={1} lg={1} xs={12} sm={12} container justifyContent="center" alignItems="center">
          <span className='p-4 bg-customPrimary text-white rounded-full' >
            <GrTransaction /></span>
        </Grid>
        <Grid item xl={5.5} md={5.5} lg={5.5} xs={12} sm={12}>
          <UpgradeStudentListView  selectedStudents={selectedStudents} onStudentOptionList={onStudentOptionList}/>
        </Grid>
        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
          <ListUpgradeStudentView  setSelectedStudents={setSelectedStudents} selectedStudents={selectedStudents}  />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AcaStudentUpgradeCreateListView;
