import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { colorSwitch } from '../../../../../widgets/Options';
import { postDataToTheServer } from '../../../../../widgets/api';
import AppURL from '../../../../../widgets/AppURL';
import { BSToADFormat } from '../../../../../widgets/DateFormat';
import { ToastSuccess } from '../../../../../widgets/ToastMessage';
import { SaveBtn } from '../../../../../widgets/AppBtn';

const SearchStudentListView = () => {
    const { attend_student_lists } = useSelector((state) => state?.attendance);
    const [students, setStudents] = useState([]);
    const date_en = localStorage.getItem('attend_date')
    useEffect(() => {
        if (attend_student_lists?.data) {
            const initialData = attend_student_lists.data.map(student => ({
                ...student,
                status: 'Present', // default value
                remarks: '',
                date_en: BSToADFormat(date_en),
                date_ne: date_en
            }));
            setStudents(initialData);
        }
    }, [attend_student_lists]);

    const handleStatusChange = (member_sn, newStatus) => {
        const status = newStatus === 'Present' ? 'P' : newStatus === 'Late' ? 'L' : newStatus;
        setStudents(prevStudents =>
            prevStudents.map(student =>
                student.member_sn === member_sn ? { ...student, status } : student
            )
        );
    };
    const handleRemarkChange = (member_sn, newRemark) => {
        setStudents(prevStudents =>
            prevStudents.map(student =>
                student.member_sn === member_sn ? { ...student, remarks: newRemark } : student
            )
        );
    };
    const saveAllData = async () => {
        const formData = new FormData();
        formData.append('students', JSON.stringify(students));
        try {
            await postDataToTheServer(AppURL.AttendanceURL + "student-attendace-student-create-list-view/", formData).then((res) => {
                // ToastSuccess(res?.message);
                console.log(res);
                // ToastSuccess(res?.message);
            })
        } catch (error) {
            console.error('Error saving data', error);
        }
    };

    return (
        <Fragment>
            <div className="relative overflow-x-auto max-h-64 rounded-md bg-white p-2">
                <table className="w-full text-sm text-left rtl:text-right whitespace-nowrap font-public-sans text-gray500 dark:text-gray300">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                        <tr className="sticky top-0 bg-slate100 font-normal text-[12px] text-gray700 capitalize dark:text-gray300">
                            <th scope="col" align='center' className="px-2 py-1.5 border border-gray-300 w-10">S.N.</th>
                            <th scope="col" className="px-2 py-1.5 border border-gray-300 w-[20%]">Student Name</th>
                            <th scope="col" align='center' className="px-2 py-1.5 border border-gray-300 w-16">Roll No</th>
                            <th scope="col" align='center' className="px-2 py-1.5 border border-gray-300 w-[35%]">Status</th>
                            <th scope="col" align='center' className="px-2 py-1.5 border border-gray-300">Remarks</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-800">
                        {students.map((student, index) => (
                            <tr key={student?.member_sn} className="bg-white border-b text-[12px] dark:bg-gray800 dark:border-gray700">
                                <td align='center' className="px-2 py-1 text-gray900 whitespace-nowrap border border-gray300">{index + 1}</td>
                                <td className="px-2 py-1 border border-gray300">
                                    {`${student?.first_name || ''} ${student?.middle_name || ''} ${student?.last_name || ''}`}
                                </td>
                                <td className="px-2 py-1 border border-gray300" align='center'>{student?.roll_no}</td>
                                <td className="px-2 py-1 border border-gray-300" align='center'>
                                    <div className='gap-5 flex justify-center'>
                                        {['Present', 'Absent', 'Leave',].map(status => (
                                            <button
                                                key={status}
                                                className={`px-3 py-1 rounded-sm text-xs ${student?.status === status
                                                    ? colorSwitch(status) + " text-white"
                                                    : 'border border-customPrimary text-gray-700'
                                                    }`}
                                                onClick={() => handleStatusChange(student?.member_sn, status)}
                                            >
                                                {status}
                                            </button>
                                        ))}
                                    </div>
                                </td>

                                <td className="px-2 py-1 border border-gray300" align='center'>
                                    <input
                                        type="text"
                                        className='border border-gray-300 w-full px-2 py-0.5 text-[11px]'
                                        value={student.remarks}
                                        onChange={(e) => handleRemarkChange(student.member_sn, e.target.value)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            <div className="flex justify-end mt-4">
                <SaveBtn type={'button'} onClick={saveAllData} />
            </div>
        </Fragment>
    );
}

export default SearchStudentListView;
