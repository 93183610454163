import React, {Fragment, useEffect, useState} from 'react';
import WeeklyLessionPlanFormView from '../../../../components/academics/report/weekly_lession_plan/WeeklyLessionPlanFormView';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';

const WeeklyLessionPlanReportFormView = () => {
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
  const fetchStudentFilterOptionListView = async () => {
      try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-teacher-daily-log-book-options-list-view/").then((res) => {
              setOnStudentOptionList(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
      fetchStudentFilterOptionListView();
  }, []);
  return (
    <Fragment>
      <WeeklyLessionPlanFormView onStudentOptionList={onStudentOptionList} />
    </Fragment>
  )
}

export default WeeklyLessionPlanReportFormView