import React, { Fragment, useState } from 'react'
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../../widgets/LangContext';
import { useFormik } from 'formik';
import { SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { MediumOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { useDispatch } from "react-redux";
import ReportHeading from '../../../../widgets/ReportHeading';
import { useNavigate } from 'react-router-dom';
import { FeeMappingReportListView, FeeMappingReportOptionsList } from '../../../../store/accountSlice';
import { SidebarReportClose } from '../../../../store/softSlice';

const FeeMappingRptFormView = ({ onStudentOptionList }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const lang = lanugage_medium;
  const StdClassOption = StdClassOptions(onStudentOptionList?.class);
  const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
  const MediumOption = MediumOptions(lang)
  const navigate = useNavigate();
  const [selectValues, setOnSelectValues] = useState({
    class_id: "",
    section: "",
    medium: "",
  });
  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ "action": false }));
}
  const optionHandleSelect = (fieldName, data) => {
    handleChange(fieldName)(data.value)
    setOnSelectValues((prevValues) => ({
      ...prevValues,
      [fieldName]: data,
    }));
  };
  const initialValues = {
    class_id: "",
    section: "",
    medium: "",
  };

  const { errors, touched, handleBlur, isSubmitting, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("class_id", selectValues.class_id.value);
        formData.append("section", selectValues.section?.value);
        formData.append("medium", selectValues.medium?.value);
        try {
          await fetchDataFromApi(AppURL.AccountsURL + "account-fee-mapping-report-create-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&medium=" + selectValues?.medium?.value, formData).then((res) => {
            dispatch(FeeMappingReportListView(res));
            dispatch(FeeMappingReportOptionsList({
              class: selectValues.class_id.label,
              section: selectValues.section.label,
              medium: selectValues.medium.label,
            }));
            CloseDispatch();
            navigate("/fee-mapping-report-page-list-view");
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  return (
    <Fragment>
      <div className='mb-4 bg-white rounded-md dark:bg-slate800'>
      <ReportHeading heading="Fee Mapping Report" />
        <form onSubmit={handleSubmit} className='mt-3'>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.class_id} errors={errors.class_id} required={true} />
            </Grid>
            <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.section} errors={errors.section} required={true} />
            </Grid>
            <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.medium} errors={errors.medium} required={true} />
            </Grid>
            <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <div className='flex justify-end mt-5'>
                <SearchBtn type="submit" disabled={isSubmitting} />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Fragment>
  )
}

export default FeeMappingRptFormView