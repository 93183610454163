import React, { Fragment, useEffect, useState } from 'react';
import { useLanguage } from '../../../../widgets/LangContext';
import { useFormik } from 'formik';
import { fetchDataFromApi, putDataToTheServer } from '../../../../widgets/api';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { ClearBtn, UpdateBtn } from '../../../../widgets/AppBtn';
import AppURL from '../../../../widgets/AppURL';
import { MdOutlineModeEdit } from 'react-icons/md';

const GapGradeEditFormView = ({ setOnEditID, onUpdateURL, fetchGpaDivisionList, onEditID }) => {
    const [onEditItemDetails, setOnEditItemDetails] = useState({});

    const FetchEditFeeTermsData = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL + "exam-gap-grade-division-retrieve-update-view/" + onEditID).then((res) => {
                setOnEditItemDetails(res?.data);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        FetchEditFeeTermsData();
    }, [onEditID])
    const { language } = useLanguage();
    const initialValues = {
        division_name: onEditItemDetails?.division_name || "",
        perc_from: onEditItemDetails?.perc_from || 0,
        perc_to: onEditItemDetails?.perc_to || 0,
        grade: onEditItemDetails?.grade || "",
        gpa: onEditItemDetails?.gpa || 0,
        grade_des: onEditItemDetails?.grade_des || "",
    };

    const { values, handleSubmit, handleBlur, handleChange, errors, touched } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("division_name", values.division_name);
                formData.append("perc_from", values.perc_from);
                formData.append("perc_to", values.perc_to);
                formData.append("grade", values.grade);
                formData.append("gpa", values.gpa);
                formData.append("grade_des", values.grade_des);
                try {
                    await putDataToTheServer(AppURL.ExamURL + "exam-gap-grade-division-retrieve-update-view/" + onEditID, formData).then((res) => {
                        ToastSuccess(res?.message);
                        fetchGpaDivisionList(onUpdateURL);
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        });
    return (
        <Fragment>
            <div className='bg-white p-4 shadow rounded'>
                <ComponentHeading label="Edit GPA/Grade/Division" icon={MdOutlineModeEdit} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                            <InputTextBox
                                name="division_name"
                                type="text"
                                label={language ? "Division Name" : "डिभिजन नाम"}
                                value={values.division_name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.division_name}
                                errors={errors.division_name}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                            <InputTextBox
                                name="perc_from"
                                type="number"
                                label={language ? "Percentage From" : "बाट प्रतिशत"}
                                value={values.perc_from}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.perc_from}
                                errors={errors.perc_from}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                            <InputTextBox
                                name="perc_to"
                                type="number"
                                label={language ? "Percentage to" : "को प्रतिशत"}
                                value={values.perc_to}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.perc_to}
                                errors={errors.perc_to}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                            <InputTextBox
                                name="grade"
                                type="text"
                                label={language ? "Grade" : "ग्रेड"}
                                value={values.grade}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.grade}
                                errors={errors.grade}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                            <InputTextBox
                                name="gpa"
                                type="number"
                                label={language ? "GPA" : "GPA"}
                                value={values.gpa}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.gpa}
                                errors={errors.gpa}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                            <InputTextBox
                                name="grade_des"
                                type="text"
                                label={language ? "Grade Des" : "ग्रेड विवरण"}
                                value={values.grade_des}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.grade_des}
                                errors={errors.grade_des}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                    <div className='flex justify-end mt-3 gap-3'>
                        <ClearBtn type="button" onClick={async () => await setOnEditID(false)} /> <UpdateBtn type="submit" />
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default GapGradeEditFormView