import { ADToBS, BSToAD } from 'bikram-sambat-js';

// 2024-01-22 - 2080/10/08
export const ADToBSConvert = (date) => {
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(date ? date : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY/MM/DD');
}
// 2080/10/08 - 2080.10.08
export const NepaliACDateFormat = (date) => {
    const NepaliDate = require('nepali-date');
    let NPDate = new NepaliDate(date);
    return NPDate.format('YYYY.MM.DD');
}
// 2024-02-15 - Current Date
export const EngNowDate = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// 2024/02/15 - Current Date
export const EngNowDateFormat = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

//2080-02-22 - 2080/02/22
export const FormatDate = (onENSelectDate) => {
    const ENDate = new Date(onENSelectDate ? onENSelectDate : "");
    const year = ENDate.getFullYear();
    const month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(ENDate.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
}

//2080/02/22 - 2080-02-22
export const FormatRevDate = (onENSelectDate) => {
    const ENDate = new Date(onENSelectDate ? onENSelectDate : "");
    const year = ENDate.getFullYear();
    const month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(ENDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


// 2080/11/03 - Now Nepali Date
export const NepNowDateFormat = () => {
    const date = new Date()
    const NepaliDate = require('nepali-date');
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const EnglishDate = `${year}-${month}-${day}`;
    const convertedNepaliDate = ADToBS(EnglishDate ? EnglishDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY/MM/DD');
}

// 2080/11/03 - 2024-10-20
export const BSToADFormat = (dates) => {
    const NepaliDate = require('nepali-date');
    const ENDate = new Date(dates ? dates : "");
    const year = ENDate.getFullYear();
    const month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(ENDate.getDate()).padStart(2, '0');
    const NPBSToAD = `${year}-${month}-${day}`;
    const convertedNepaliDate = BSToAD(NPBSToAD ? NPBSToAD : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY-MM-DD');
}

// 2080/11/03 - 2024/10/20
export const BSToADUSFormat = (dates) => {
    const NepaliDate = require('nepali-date');
    const NepaliMonth = new NepaliDate(dates ? dates : "");
    const year = NepaliMonth.format("YYYY");
    const day = NepaliMonth.format("DD");
    const month = NepaliMonth.format("MM");
    const NPBSToAD = `${year}-${month}-${day}`
    const convertedNepaliDate = BSToAD(NPBSToAD ? NPBSToAD : "");
    const ENDate = new Date(convertedNepaliDate ? convertedNepaliDate : "");
    const en_year = ENDate.getFullYear();
    const en_month = String(ENDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const en_day = String(ENDate.getDate()).padStart(2, '0');
    return `${en_year}/${en_month}/${en_day}`;
}
// 2080-11-03 - Now Nepali Date
export const NepNowDateFormatRev = () => {
    const date = new Date()
    const NepaliDate = require('nepali-date');
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const EnglishDate = `${year}-${month}-${day}`;
    const convertedNepaliDate = ADToBS(EnglishDate ? EnglishDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY-MM-DD');
}
// 2080/10/20 - 2024/02/30
export const BSToADConvert = (date) => {
    const DateFormat = FormatRevDate(date ? date : "");
    const ADDated = BSToAD(DateFormat);
    const FormatedDate = FormatDate(ADDated);
    return FormatedDate;
}


// Month Name Number Return 2080/08/24 = 08
export const GetMonthNumber = (date) => {
    const NepaliDate = require('nepali-date');
    const NepaliMonth = new NepaliDate(date || "2080/08/24");
    return NepaliMonth.format("MM");
}

// Month Name Number Return 2080/08/24 = 2080
export const GetYearNumber = (date) => {
    const NepaliDate = require('nepali-date');
    const NepaliMonth = new NepaliDate(date || "2080/08/24");
    return NepaliMonth.format("YYYY");
}


// Default Year Name Nepali
export const NowNepaliYearName = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const DefaultADDate = `${year}-${month}-${day}`;
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(DefaultADDate ? DefaultADDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate)
    const NepaliMonth = new NepaliDate(NPDate || "2080/08/24");
    return NepaliMonth.format("YYYY");
}

// Default Month Name Nepali
export const NowNepaliMonthName = () => {
    const date = new Date()
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const DefaultADDate = `${year}-${month}-${day}`;
    const NepaliDate = require('nepali-date');
    const convertedNepaliDate = ADToBS(DefaultADDate ? DefaultADDate : "");
    let NPDate = new NepaliDate(convertedNepaliDate)
    const NepaliMonth = new NepaliDate(NPDate || "2080/08/24");
    return NepaliMonth.format("MM");
}


// Default Month Name Nepali
export const NPClearDateN = (convertedNepaliDate) => {
    const NepaliDate = require('nepali-date');
    let NPDate = new NepaliDate(convertedNepaliDate)
    const NepaliMonth = new NepaliDate(NPDate || "2080/08/24");
    const Month = NepaliMonth.format("MM");
    const Year = NepaliMonth.format("YYYY");
    return `${Year}/${Month}/01`

}


export const formatTime = (time) => {
    // Create a Date object
    const date = new Date(`${time}Z`);
    
    // Extract hours, minutes, and seconds
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    
    // Format time string without microseconds
    return `${hours}:${minutes}:${seconds}`;
  };


  export const CreatedDateFormat = (date) => {
    // Create a Date object
    const NepaliDate = require('nepali-date');
    const format_date = new Date(date);
   
    const date_format_get= format_date.toLocaleDateString();
    const convertedNepaliDate = ADToBS(date_format_get ? date_format_get : "");
    let NPDate = new NepaliDate(convertedNepaliDate);
    return NPDate.format('YYYY/MM/DD');
     
  };