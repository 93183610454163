import React from 'react'
import { TiEye } from "react-icons/ti";
import { BsFillSendFill, BsQrCode } from "react-icons/bs";
import { Button } from "@material-tailwind/react";
import { MdOutlineKeyboardReturn, MdSkipPrevious } from "react-icons/md";
import { BiSkipNext } from "react-icons/bi";
import { FaSave, FaSearch } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { IconButton } from "@material-tailwind/react";
import { MdLockPerson } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { FaFilePdf } from "react-icons/fa";
import { BsFiletypeXls } from "react-icons/bs";
import { IoMdCard } from "react-icons/io";
import { MdPrint } from "react-icons/md";
import { MdOutlineModeEdit } from "react-icons/md";
import { IoAddOutline } from 'react-icons/io5';
import { MdOutlineAutoMode } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";

export const ViewEyeBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-${wh ? wh : 7} h-${wh ? wh : 7} rounded-full`}>
            <TiEye />
        </IconButton>
    )
}

export const QRBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <BsQrCode />
        </IconButton>
    )
}
export const AddPlusBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <IoAddOutline />
        </IconButton>
    )
}
export const ViewPremissionBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <MdLockPerson />
        </IconButton>
    )
}
export const PdfExportBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-${wh ? wh : 7} h-${wh ? wh : 7} rounded-full`}>
            <FaFilePdf />
        </IconButton>
    )
}


export const ExportExcelBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <BsFiletypeXls />
        </IconButton>
    )
}


export const CardPrintBtn = ({ onClick, bg_color }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-7 h-7 rounded-full`}>
            <IoMdCard />
        </IconButton>
    )
}
export const EditPenBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-customPrimary" : "bg-gray-600"} w-${wh ? wh : 7} h-${wh ? wh : 7} rounded-full`}>
            <MdOutlineModeEdit className='dark:text-slate700' />
        </IconButton>

    )
}

export const DeleteBtn = ({ onClick, bg_color, wh }) => {
    return (
        <IconButton onClick={onClick} className={` ${bg_color === 1 ? "bg-red-600" : "bg-red-600"} w-${wh ? wh : 7} h-${wh ? wh : 7} rounded-full`}>
            <MdDeleteForever className='dark:text-slate700' />
        </IconButton>

    )
}
export const PrevBtn = ({ onClick, disabled }) => {
    return (
        <Button size="sm" onClick={onClick} color="white" className="flex text-white items-center capitalize gap-2 bg-gray-700 rounded-[3px] px-2 py-1" disabled={disabled}>
            <MdSkipPrevious className='text-[19px]' />
            <span>Prev</span>
        </Button>
    )
}

export const NextBtn = ({ onClick, disabled }) => {
    return (
        <Button size="sm" onClick={onClick} color="white" className="flex text-white items-center capitalize gap-2 bg-gray-700 rounded-[3px] px-2 py-1" disabled={disabled}>
            <BiSkipNext className='text-[19px]' />
            <span>Next</span>
        </Button>
    )
}


export const SaveBtn = ({ onClick, type, disabled, label }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={disabled || false}>
            <FaSave className='text-[19px]' />
            <span className='text-[13px]'>Save</span>
        </Button>
    )
}

export const SendBtn = ({ onClick, type, disabled, label }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={disabled || false}>
            <BsFillSendFill className='text-[19px]' />
            <span className='text-[13px]'>Send</span>
        </Button>
    )
}


export const PayBill = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <FaSave className='text-[19px]' />
            <span className='text-[13px]'>Update Bill</span>
        </Button>
    )
}

export const AutoClear = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-green-600 rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <MdOutlineAutoMode className='text-[19px]' />
            <span className='text-[13px]'>Auto Bill Extact </span>
        </Button>
    )
}
export const ReturnButton = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <MdOutlineKeyboardReturn className='text-[19px]' />
            <span className='text-[13px]'>Return</span>
        </Button>
    )
}
export const ViewBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <TiEye className='text-[19px]' />
            <span className='text-[13px]'>View</span>
        </Button>
    )
}
export const PrintBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-gray600 rounded-[3px] py-1.5 px-1.5" onClick={onClick} disabled={false}>
            <MdPrint className='text-[19px]' />
            <span className='text-[13px]'>Print</span>
        </Button>
    )
}
export const SearchBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            {disabled ? <svg aria-hidden="true" className="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg> : <FaSearch className='text-[19px]' />}
            <span className='text-[13px]'>Search</span>
        </Button>
    )
}


export const CustomBtn = ({ onClick, type, disabled, label, icon }) => {
    const Icon = icon
    return (
        <Button type={type} size="sm" onClick={onClick} color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] py-1.5 px-1.5" disabled={false}>
            <Icon className='text-[19px]' />
            <span className='text-[13px]'>{label}</span>
        </Button>
    )
}


export const ClearBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type || "button"} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-gray-700 rounded-[3px] px-1.5 py-1.5" disabled={disabled || false}>
            <MdClear className='text-[19px]' />
            <span className='text-[13px] my-auto'>Clear</span>
        </Button>
    )
}

export const AddBtn = ({ onClick, type, disabled, label }) => {
    return (
        <Button type={type || "button"} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-customPrimary rounded-[3px] px-1.5 py-1.5" disabled={disabled || false}>
            <IoAddOutline className='text-[19px]' />
            <span className='text-[13px] my-auto'>{label}</span>
        </Button>
    )
}


export const UpdateBtn = ({ onClick, type, disabled }) => {
    return (
        <Button type={type} onClick={onClick} size="sm" color="white" className="flex text-white items-center capitalize gap-2 bg-green-500 rounded-[3px] py-1.5 px-1.5" disabled={disabled || false}>
            <RxUpdate className='text-[19px]' />
            <span className='text-[13px]'>Update</span>
        </Button>
    )
}
