import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HomeworkCreateView from '../../../components/mobile_app/homework/HomeworkCreateView';
import SubjectBoxCreateView from '../../../components/mobile_app/homework/SubjectBoxCreateView';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';

const MobileAppHomeWorkCreateListView = ({ setIconsButton, PageTitle }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, [PageTitle]);
  const [subList, setOnSubList] = useState([]);

  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
  const fetchStudentFilterOptionListView = async () => {
    try {
      await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-filter-options-list-view/").then((res) => {
        setOnStudentOptionList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchStudentFilterOptionListView();
  }, []);

 

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
        <HomeworkCreateView setOnSubList={setOnSubList} onStudentOptionList={onStudentOptionList} />
      </Grid>
      <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
        <Grid container spacing={2}>
          {
            subList?.subjects?.map((items, index) => (
              <Grid key={index} item lg={1.2} sm={12} md={1.2} xl={1.2} xs={12}>
                <SubjectBoxCreateView subject_name={items?.subj_name} sn={items?.sn}  />
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MobileAppHomeWorkCreateListView