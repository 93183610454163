import React, { Fragment } from 'react'
import { useLanguage } from '../../../widgets/LangContext'
import { ViewEyeBtn } from '../../../widgets/AppBtn';
import { Link } from 'react-router-dom';

const TeacherNotificationListView = ({ onAcaListData }) => {
    const { language } = useLanguage();

    return (
        <Fragment>
            <div className='bg-white rounded-md p-2 shadow-sm dark:bg-slate800'>
                {/* <ComponentHeading icon={FaListUl} label={language ? "Book Category List" : "कक्षाको सूची"} /> */}
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-[30%]">
                                    {language ? "Message Title" : "सन्देश शीर्षक"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Sent By" : "द्वारा पठाइयो"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Teacher/All" : "शिक्षक/सबै"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "Action" : "भाषा"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {
                                Array.isArray(onAcaListData?.results) && onAcaListData?.results?.map((notification, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 py-1 border dark:border-slate600">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 py-1 border dark:border-slate600 capitalize">
                                            {notification?.title}
                                        </td>
                                        <td align='left' className="px-2 py-1 border capitalize dark:border-slate600 ">
                                            {notification?.first_name} {notification?.last_name}
                                        </td>
                                        <td align='center' className="px-2 py-1 border capitalize dark:border-slate600 ">
                                            {
                                                notification?.teacher_id === null ? "All" : ""
                                            }
                                        </td>
                                        <td align='center' className="px-2 py-1 border capitalize dark:border-slate600 ">
                                            <Link to={`/teacher-notification-details-view/${notification?.sn}`}><ViewEyeBtn wh={6} bg_color={1} /></Link>

                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                {/* <PrevBtn disabled={onBookList?.previous === null ? true : false} onClick={async () => {
            if (onBookList?.previous) {
                await BookListView(onBookList?.previous)
            }
        }} />
        <NextBtn disabled={onBookList?.next === null ? true : false} onClick={async () => {
            if (onBookList?.next) {
                await BookListView(onBookList?.next)
            }
        }} /> */}
            </div>
        </Fragment>
    )
}

export default TeacherNotificationListView