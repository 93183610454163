import React, { Fragment, useEffect, useState } from 'react';
import ContentHeading from '../../../../widgets/ContentHeading';
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../../widgets/LangContext';
import { useFormik } from 'formik';
import { SearchBtn } from '../../../../widgets/AppBtn';
import { FaSearch } from "react-icons/fa";
import { MediumOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { useDispatch } from "react-redux";
import { Class_Wise_Subject_List, ClassRoutineSelectField } from '../../../../store/academicsSlice';

const SearchStudentListView = ({ onStudentOptionList, FetchDataStdList }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const lang = lanugage_medium;
  const StdClassOption = StdClassOptions(onStudentOptionList?.class);
  const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
  const MediumOption = MediumOptions(lang)
  const [selectValues, setOnSelectValues] = useState({
    class_id: "",
    section: "",
    medium: "",
    day: ""
  });
  const optionHandleSelect = (fieldName, data) => {
    if (data === null || data.value === "") {
      // If data is null or empty, clear the input value
      handleChange(fieldName)("");
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
    } else {
      // Otherwise, update the input value as usual
      handleChange(fieldName)(data.value);
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: data,
      }));
    }
    if (fieldName === 'class_id') {
      dispatch(Class_Wise_Subject_List([]));
    }
  };
  const initialValues = {
    class_id: "",
    section: "",
    medium: "",
    day: ""
  };

  const { errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("class_id", selectValues.class_id?.value);
        formData.append("section", selectValues.section?.value);
        formData.append("medium", selectValues.medium?.value);
        formData.append("day", selectValues.day?.value);
        FetchDataStdList(selectValues.class_id?.value, selectValues.section?.value, selectValues.medium?.value);
      },
    });
  useEffect(() => {
    dispatch(ClassRoutineSelectField(selectValues))
  }, [selectValues])

  return (
    <Fragment>
      <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
        <ContentHeading label="Search Student" icon={FaSearch} margin={0} />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.class_id} errors={errors.class_id} required={true} />
            </Grid>
            <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.section} errors={errors.section} required={true} />
            </Grid>
            <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.medium} errors={errors.medium} required={true} />
            </Grid>
            <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
              <div className='flex justify-end mt-5'>
                <SearchBtn type="submit" />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Fragment>
  )
}

export default SearchStudentListView