import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { ClearBtn, SaveBtn, UpdateBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { CustomOptions } from '../../../../widgets/Options';
import { fetchDataFromApi, putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";
import DefaultSelectOptionBox from "../../../../widgets/DefaultSelectBox";
import { vehicle_type } from '../../../../widgets/json';
import InputNumber from '../../../../widgets/InputNumber';

const VechileEditView = ({ onEditID, setOnEditID, fetchAcademicsListView, onUpdateURL }) => {
  const { language } = useLanguage();
  const [orgStrDetails, setOnClassStrDetails] = useState({});
  const VehicleTypeOptions = CustomOptions(vehicle_type);
  const [selectValues, setOnSelectValues] = useState({
    vehicle_type: "",
  });
  useEffect(() => {
    const FetchData = async () => {
      try {
        if (onEditID) {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-school-vehicle-retrieve-update-view/" + onEditID).then((res) => {
            setOnClassStrDetails(res?.data);
            setOnSelectValues({
              vehicle_type: res?.data?.vehicle_type || ""
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    FetchData();
  }, [onEditID])

  const optionHandleSelect = (fieldName, data) => {
    handleChange(fieldName)(data.value)
    setOnSelectValues((prevValues) => ({
      ...prevValues,
      [fieldName]: data.value,
    }));
  };

  const initialValues = {
    order: 1,
    vehicle_type: "",
    vehicle_number: orgStrDetails?.vehicle_number || "",
    blue_book_upto: orgStrDetails?.blue_book_upto,
    chassis_number: orgStrDetails?.chassis_number || "",
    insurance_upto: orgStrDetails?.insurance_upto,
    gps_emi: orgStrDetails?.gps_emi || "",
    driver_name: orgStrDetails?.driver_name || "",
    driver_address: orgStrDetails?.driver_address || "",
    driver_contact: orgStrDetails?.driver_contact || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("order", values.order);
        formData.append("vehicle_type", selectValues.vehicle_type);
        formData.append("vehicle_number", values.vehicle_number);
        formData.append("blue_book_upto", values.blue_book_upto);
        formData.append("chassis_number", values.chassis_number);
        formData.append("insurance_upto", values.insurance_upto);
        formData.append("gps_emi", values.gps_emi);
        formData.append("driver_name", values.driver_name);
        formData.append("driver_address", values.driver_address);
        formData.append("driver_contact", values.driver_contact);

        try {
          await putDataToTheServer(AppURL.AcademicsURL + "academics-school-vehicle-retrieve-update-view/" + onEditID, formData).then((res) => {
            ToastSuccess(res?.message);
            fetchAcademicsListView(onUpdateURL);
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
        <ComponentHeading label={language ? "Add Vehicle" : "सवारी साधन थप्नु"} icon={IoMdAddCircle} />
        <Grid container spacing={1.5}>

          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="order"
              type="number"
              placeholder="Enter Order"
              label={language ? "Order" : "नाम"}
              value={values.order}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.order}
              errors={errors.order}
              required={true}
            />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <DefaultSelectOptionBox label={language ? "Vehicle Type" : "सवारी साधनको प्रकार"} placeholder="Select Vehicle Type" value={selectValues.vehicle_type} name="vehicle_type" onFocus={true} options={VehicleTypeOptions} optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.vehicle_type} errors={errors.vehicle_type} required={true} />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="vehicle_number"
              type="text"
              placeholder="Enter Vehicle Number"
              label={language ? "Vehicle Number" : "नाम"}
              value={values.vehicle_number}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.vehicle_number}
              errors={errors.vehicle_number}
              required={true}
            />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="chassis_number"
              type="text"
              placeholder="Enter Chassis Number"
              label={language ? "Chassis Number" : "नाम"}
              value={values.chassis_number}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.chassis_number}
              errors={errors.chassis_number}
              required={true}
            />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputNumber name="blue_book_upto" value={values.blue_book_upto} handleChange={handleChange} placeholder={'Enter BlueBook UpTo Date'} mask="9999/99/99" required={true} label={language ? "BlueBook UpTo" : "जन्म मिति (BS)"} />

          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputNumber name="insurance_upto" value={values.insurance_upto} handleBlur={handleBlur}
              touched={touched.insurance_upto}
              errors={errors.insurance_upto} handleChange={handleChange} placeholder={'Enter Insurance UpTo Date'} mask="9999/99/99" required={true} label={language ? "Insurance UpTo" : "जन्म मिति (BS)"} />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="gps_emi"
              type="text"
              placeholder="Enter GPS IMEI Code"
              label={language ? "GPS IMEI" : "नाम"}
              value={values.gps_emi}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.gps_emi}
              errors={errors.gps_emi}
              required={true}
            />
          </Grid>

          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="driver_name"
              type="text"
              placeholder="Enter Driver Name"
              label={language ? "Driver Name" : "नाम"}
              value={values.driver_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.driver_name}
              errors={errors.driver_name}
              required={true}
            />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="driver_contact"
              type="text"
              placeholder="Enter Contact Number"
              label={language ? "Driver Contact" : "नाम"}
              value={values.driver_contact}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.driver_contact}
              errors={errors.driver_contact}
              required={true}
            />
          </Grid>
          <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
            <InputTextBox
              name="driver_address"
              type="text"
              placeholder="Enter Driver Address"
              label={language ? "Driver Address" : "नाम"}
              value={values.driver_address}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.driver_address}
              errors={errors.driver_address}
              required={true}
            />
          </Grid>
        </Grid>
        <div className="flex justify-end mt-5 gap-2">
         <ClearBtn onClick={()=>setOnEditID(null)} /> <UpdateBtn type="submit" />
        </div>
      </form>
    </Fragment>
  )
}

export default VechileEditView