import React, { Fragment, useEffect, useState } from 'react'
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { useDispatch } from 'react-redux';
import { GL_Ledger_List, Sub_Ledger_List } from '../../../../store/accountSlice';
import HeaderLedgerForm from '../../../../components/account/report/ac_ledger/HeaderLedgerForm';
import { pageTitle } from '../../../../store/softSlice';
import { Grid } from '@mui/material';
import SingleVoucherListView from '../../../../components/account/report/ac_ledger/SingleVoucherListView';

const AccountLedgerReportViewView = ({ setIconsButton, PageTitle }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    const [onGeneralLedgList, setOnGeneralLedgList] = useState([]);
    const TransactionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AccountsURL + "account-transaction-general-ledg-list-view/").then((res) => {
                setOnGeneralLedgList(res);
                dispatch(GL_Ledger_List(res));
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        TransactionListView();
    }, []);
    dispatch(Sub_Ledger_List([]));
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <HeaderLedgerForm onGeneralLedgList={onGeneralLedgList} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                    <SingleVoucherListView />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default AccountLedgerReportViewView