import { createSlice } from '@reduxjs/toolkit';

export const attendanceSlice = createSlice({
    name: 'attendance',
    initialState: {
        attend_student_lists: {},
        student_attendance_rpt_list:{}
      
    },
    reducers: {
        Attendance_Student_List_Search: (state, action) => {
            state.attend_student_lists = action.payload;
        },
        Clear_Attendance_Student_List_Search: (state, action) => {
            state.attend_student_lists = [];
        },
        StudentAttendanceReportListView: (state, action) => {
            state.student_attendance_rpt_list = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { Attendance_Student_List_Search, StudentAttendanceReportListView, Clear_Attendance_Student_List_Search} = attendanceSlice.actions
export default attendanceSlice.reducer