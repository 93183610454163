import React, { Fragment } from 'react'
import { BiSolidBus } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { useLanguage } from '../../../../widgets/LangContext';

const VehicleRoutineRptListView = () => {
    const { vehicle_routine_rpt } = useSelector((state) => state.academics);
    const { language } = useLanguage();
    return (
        <Fragment>
            {
                vehicle_routine_rpt?.rpt_type === "2" && <div className='bg-white py-4 px-3 rounded-md dark:bg-slate800'>
                    <div className='font-public-sans w-[50%]'>
                        {
                            Array.isArray(vehicle_routine_rpt?.rotine) && vehicle_routine_rpt?.rotine?.length > 0 ? (vehicle_routine_rpt?.rotine?.map((vehicle, index) => (
                                <div className="flex gap-x-3" key={index}>
                                    <div className="w-24 text-end block">
                                        <span className="text-xs text-gray800 dark:text-neutral-400">{vehicle?.pick_up_time} AM</span>
                                    </div>
                                    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-400 dark:after:bg-slate700">
                                        <div className="relative z-10 size-7 flex justify-center items-center">
                                            <div className="size-2 rounded-full bg-gray-500 dark:bg-neutral-600"></div>
                                        </div>
                                    </div>
                                    <div className="grow pt-0.5 pb-4 w-[50%]">
                                        <div className='flex flex-row items-center gap-2'> {/* Adjusted here */}
                                            <div>
                                                <BiSolidBus className='w-6 h-6 px-1 my-auto bg-customPrimary text-white rounded-full' />
                                            </div>
                                            <div>
                                                <h3 className="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                                                    {vehicle?.bus_stop_name}
                                                </h3>
                                                <p className="text-[10px] text-gray-600 dark:text-neutral-400">
                                                    {vehicle?.notice}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grow pt-0.5 pb-4 w-[40%]">
                                        <div className='flex flex-row items-center gap-2'> {/* Adjusted here */}
                                            <div>
                                                <BiSolidBus className='w-6 h-6 px-1 my-auto bg-customPrimary text-white rounded-full' />
                                            </div>
                                            <div>
                                                <h3 className="flex gap-x-1.5 text-[12px] font-semibold text-gray-800 dark:text-white">
                                                    Drop: <span className='font-medium'>{vehicle?.drop_time} PM</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))) : <div className="flex gap-x-3">
                                <div className="w-24 text-end block">
                                    <span className="text-xs text-gray800 dark:text-neutral-400">No Data</span>
                                </div>
                                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-400 dark:after:bg-slate700">
                                    <div className="relative z-10 size-7 flex justify-center items-center">
                                        <div className="size-2 rounded-full bg-gray-500 dark:bg-neutral-600"></div>
                                    </div>
                                </div>
                                <div className="grow pt-0.5 pb-4 w-[50%]">
                                    <div className='flex flex-row items-center gap-2'> {/* Adjusted here */}
                                        <div>
                                            <BiSolidBus className='w-6 h-6 px-1 my-auto bg-customPrimary text-white rounded-full' />
                                        </div>
                                        <div>
                                            <h3 className="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                                                No Data
                                            </h3>
                                            <p className="text-[10px] text-gray-600 dark:text-neutral-400">
                                                No Data
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grow pt-0.5 pb-4 w-[40%]">
                                    <div className='flex flex-row items-center gap-2'> {/* Adjusted here */}
                                        <div>
                                            <BiSolidBus className='w-6 h-6 px-1 my-auto bg-customPrimary text-white rounded-full' />
                                        </div>
                                        <div>
                                            <h3 className="flex gap-x-1.5 text-[12px] font-semibold text-gray-800 dark:text-white">
                                                Drop: No Data
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            }
            {
                vehicle_routine_rpt?.rpt_type === "1" && 
                <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                    <div className="relative overflow-x-scroll">
                        <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                            <thead className="text-[12px] text-gray600  capitalize border-b bg-gray200 dark:text-gray-400 font-public-sans">
                                <tr>
                                    <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                        {language ? "S.N." : "क्र.सं."}
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                        {language ? "Bus Stop Name" : "पुरा नाम"}
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                        {language ? "Notice" : "पुरा नाम (नेपाली)"}
                                    </th>
                                    <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                        {language ? "Pickup Time" : "पुरा नाम (नेपाली)"}
                                    </th>
                                    <th scope="col" align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 w-12">
                                        {language ? "Drop Time" : "रोल नं"}
                                    </th>
                                    
                                </tr>
                            </thead>
                            <tbody className='text-slate-800'>
                                {Array.isArray(vehicle_routine_rpt?.rotine) && vehicle_routine_rpt?.rotine?.length > 0 ? (
                                    vehicle_routine_rpt?.rotine?.map((cls, index) => (
                                        <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                            <td align='center' className="px-2 py-1 border border-gray300 dark:border-slate600  ">
                                                {index + 1}.
                                            </td>
                                            <td align='left' className="px-2 py-1 font-semibold border border-gray300 dark:border-slate600 ">
                                                {cls?.bus_stop_name}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.notice}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.pick_up_time} AM
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.drop_time} PM
                                            </td>
                                        </tr>
                                    ))
                                ) : (<tr>
                                    <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                        Student not found.
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>}
        </Fragment>
    )
}

export default VehicleRoutineRptListView