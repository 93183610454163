import React, { Fragment } from 'react';
import ImageLazyLoading from '../../../../widgets/ImageLazyLoading';
import Logo from '../../../../assets/img/logo/logo.png';
const BrandLogo = () => {
  return (
    <Fragment>
        <div className='flex-col py-2'>
            <div className='flex justify-center'>
                <ImageLazyLoading className="px-3" width={80} src={Logo} />
            </div>
            <div className='flex items-center align-middle text-center w-full'>
                <h2 className='text-center w-full font-public-sans font-bold text-[16px] text-customPrimary'>NEXAPP</h2>
            </div>
        </div>
    </Fragment>
  )
}

export default BrandLogo