import React, { Fragment } from 'react'
import SubjectSearchListView from '../../../../components/exam/report/subject_list/SUbjectSearchListView'

const ExamSubjectListFormView = () => {
  return (
    <Fragment>
        <SubjectSearchListView />
    </Fragment>
  )
}

export default ExamSubjectListFormView