import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from "@mui/material";

const MarksEvaluationReportListView = () => {
    const { exam_marks_evaluation_rpt_list = {} } = useSelector((state) => state.exam);
    const [totalStd, setTotalStd] = useState({ totalStdCount: 0 });
    const [allGrades, setAllGrades] = useState([]);

    useEffect(() => {
        if (exam_marks_evaluation_rpt_list?.total_class_report) {
            const totalCountStd = exam_marks_evaluation_rpt_list.total_class_report.reduce((total, item) => total + item.grade_count, 0);
            setTotalStd({ totalStdCount: totalCountStd });
        }

        // Determine all unique grades available in the data
        const uniqueGrades = new Set();
        exam_marks_evaluation_rpt_list.subject_wise_reports.forEach(obj => {
            uniqueGrades.add(obj.thgrade);
        });
        setAllGrades(Array.from(uniqueGrades)); // Convert Set to Array
    }, [exam_marks_evaluation_rpt_list]);

    if (!exam_marks_evaluation_rpt_list || !exam_marks_evaluation_rpt_list.subject_wise_reports) {
        return <div className='text-red-500 font-public-sans'>Data Not Found...</div>;
    }

    // Group subjects by title and grades
    const groupedData = exam_marks_evaluation_rpt_list.subject_wise_reports.reduce((acc, obj) => {
        const key = `${obj.subj_title}-${obj.thgrade}`; // Unique key combining subject title and grade
        if (!acc[obj.subj_title]) {
            acc[obj.subj_title] = {
                subj_title: obj.subj_title,
                grades: {
                    [obj.thgrade]: obj.count
                }
            };
        } else {
            if (!acc[obj.subj_title].grades[obj.thgrade]) {
                acc[obj.subj_title].grades[obj.thgrade] = obj.count;
            } else {
                acc[obj.subj_title].grades[obj.thgrade] += obj.count;
            }
        }
        return acc;
    }, {});

    const renderRows = Object.values(groupedData).map((subject, index) => {
        return (
            <tr key={index} className='text-[12px]'>
                <td className="px-2 font-medium  py-1 border border-gray-400 dark:border-slate600 uppercase text-[12px]">
                    {subject.subj_title}
                </td>
                {allGrades.map((grade, gradeIndex) => (
                    <td key={gradeIndex} align='center' className="px-2 font-medium py-1 border border-gray-400 dark:border-slate600 uppercase text-[12px]">
                        {subject.grades[grade] || 0}
                    </td>
                ))}
            </tr>
        );
    });

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
                    <div className='bg-white rounded-md p-2 shadow dark:bg-slate800'>
                        <div className="relative overflow-x-auto">
                            <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                                <thead className="text-[13px] text-gray700 bg-gray300 capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                                    <tr>
                                        <th scope="col" colSpan={4} align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                            All Student Reports
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                            S.N.
                                        </th>
                                        {allGrades.map((grade, index) => (
                                            <th key={index} scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                                Grade {grade}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='text-slate-800'>
                                    {renderRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12} xl={8}>
                    <Fragment>
                        <h2 className='font-public-sans pb-2 font-semibold text-[14px] underline'>Grade Count Total Student</h2>
                        <table className="border-collapse border text-[12px] border-gray-200 font-public-sans">
                            <thead>
                                <tr className="bg-gray300">
                                    <th className="border border-gray-400 px-2 py-1">Subject Title</th>
                                    {allGrades.map((grade, index) => (
                                        <th key={index} className="border border-gray-400 px-2 py-1">{grade}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {renderRows}
                            </tbody>
                        </table>
                    </Fragment>
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default MarksEvaluationReportListView;
