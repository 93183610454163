import React from 'react'
import { HiOutlineArrowRight } from "react-icons/hi";

export const MenuLink = ({ label }) => {
    return (
        <div className='flex gap-2 mt-2.5 select-none hover:text-customPrimary cursor-pointer text-slate600 font-normal items-center'>
            <HiOutlineArrowRight className='text-[10px] font-public-sans' />
            <span className='text-[13.5px] font-public-sans'>{label}</span>
        </div>
    )
}

