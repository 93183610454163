import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { ToastError } from '../../widgets/ToastMessage';
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/img/logo/Image_logo.png'
import InputTextBox from '../../widgets/InputTextBox';
import { BranchOptions } from '../../widgets/Options';
import DefaultSelectOptionBox from '../../widgets/DefaultSelectBox';
import { useFormik } from "formik";
import AppURL from '../../widgets/AppURL';

const LoginView = () => {
    const navigate = useNavigate();
    const [selectValues, setOnSelectValues] = useState({
        branch_id: "1",
    });
    const [onLogin, setOnLogin]=useState('Login')
    const optionHandleSelect = (fieldName, data) => {
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data.value,
        }));
    };
    const initialValues = {
        username: "",
        password: "",
        branch_id: "1",
    }

    const { handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                if (values.username === "") {
                    ToastError("Enter Your Username")
                } else if (values.password === "") {
                    ToastError("Enter Your Password")
                } else if (selectValues?.branchid === "") {
                    ToastError("Select Your Branch")
                } else {
                    setOnLogin('Logged In...')
                    const formData = new FormData();
                    formData.append("username", values.username);
                    formData.append("password", values.password);
                    formData.append("branch_id", 1);
                    try {
                        await axios.post(AppURL.URL+"nexapp-school-app/app-user-login/", formData).then((res) => {
                            if (res?.data?.status === 200) {
                                action.resetForm();
                                setOnSelectValues({
                                    branch_id: "",
                                });
                                navigate("/default-dashboard");
                                localStorage.setItem('access_token', res?.data?.access_token);
                                localStorage.setItem('user_id', res?.data?.user_id);
                                localStorage.setItem('branch_id', res?.data?.branch_id);
                                localStorage.setItem('fullname', res?.data?.fullname);
                                localStorage.setItem('fullname', res?.data?.fullname);
                            } else if (res?.data?.status === 400) {
                                ToastError(res?.data?.message);
                                setOnLogin('Login');
                            }
                        });
                    } catch (error) {
                        console.error('Error:', error);
                        setOnLogin('Login');
                    }

                }
            },
        });

   

    return (
        <section className="bg-gray-100 dark:bg-gray-900 h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full dark:border md:mt-0 sm:max-w-[45rem] xl:p-0  dark:bg-gray-800 dark:border-gray-700">
                    <div className="space-y-3 md:space-y-5">
                        <div className='sm:flex block rounded-md bg-slate-100 sm:shadow-xl '>
                            <div className='flex-1 sm:p-5 sm:flex justify-center items-center sm:bg-gradient-to-br sm:from-gray-50 sm:to-gray-400 sm:bg-transparent rounded-l-md'>
                                <div className='text-center w-full'>
                                    <div className="flex justify-center">
                                        <img src={Logo} alt="" className='sm:w-48 w-36' />
                                    </div>

                                </div>
                            </div>
                            <div className='sm:bg-white bg-transparent flex-1 p-5 sm:py-14'>
                                <div className='block'>
                                    <h1 className='text-2xl font-public-sans font-bold select-none'>Login</h1>
                                </div>
                                <div>
                                    <p className='text-[10px] font-public-sans select-none font-medium mb-2 text-gray-800'>Please provide your valid login details to get access.                         </p>
                                </div>
                                <form>
                                    <div className='mt-8'>
                                        <div className='block'>
                                            <InputTextBox type="text" name="username"
                                                placeholder="Enter Username"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                touched={touched.username}
                                                errors={errors.username}
                                                disabled={false} />
                                        </div>
                                        <div className='mt-3'>
                                            <InputTextBox type="password" name="password"
                                                placeholder="Enter Password"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                touched={touched.password}
                                                errors={errors.password}
                                                disabled={false} />
                                        </div>
                                        <div className='mt-3'>
                                            <DefaultSelectOptionBox name="branch_id" disabled={true} value={selectValues?.branch_id} optionSelect={optionHandleSelect} options={BranchOptions} clear={true} placeholder="Select Branch" type="password" />
                                        </div>
                                    </div>
                                    <div className="text-[10px] mt-2 flex justify-end">
                                        <Link to="/renew-license">
                                            <span className="font-normal text-customPrimary transition-colors duration-300 delay-200 hover:text-customPrimary hover:underline hover:transition-all hover:delay-300 cursor-pointer">
                                                Renew License
                                            </span>
                                        </Link>
                                    </div>
                                    <div className='mt-1 flex justify-center text-center '>
                                        <button onClick={handleSubmit} type="submit" className="px-7 text-[12px] select-none mt-4 w-full justify-center py-2 group font-medium text-center inline-flex items-center text-white outline-none bg-customPrimary rounded-sm hover:customPrimary dark:hover:opacity-96 font-public-sans">
                                            {onLogin}
                                        </button>
                                    </div>
                                </form>
                                <div className='mt-6 text-center'>
                                    <div className='block'>
                                        <p className='text-[11px] font-public-sans font-normal'>Licensed to: <span className='text-customPrimary'>Preface Technology PVT LTD</span></p>
                                    </div>
                                    <div className='mt-1'>
                                        <p className='text-[11px] font-public-sans font-normal'>Copyright © 2023. <span className='text-customPrimary'>Preface Technology PVT LTD</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginView