import { Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from 'react';
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { useLanguage } from "../../../../../widgets/LangContext";
import { fetchDataFromApi } from "../../../../../widgets/api";
import AppURL from "../../../../../widgets/AppURL";
import ComponentHeading from "../../../../../widgets/ComponentHeading";
import { MoneyFormat } from "../../../../../widgets/NumberFormat";
import { ClearBtn, PrintBtn } from "../../../../../widgets/AppBtn";
import PrintSingleVoucher from "./print/PrintSingleVoucher";
import { useReactToPrint } from "react-to-print";

const SingleVoucherList = ({ onSingleVoucherPopup, setOnSingleVoucherPopup }) => {
    const { language } = useLanguage();
    const handleOpen = () => setOnSingleVoucherPopup({ open: !onSingleVoucherPopup?.open });
    const [ledgerList, setOnLedgerList] = useState([]);
    const fetchVoucherList = async () => {
        if (onSingleVoucherPopup?.tsn) {
            try {
                await fetchDataFromApi(AppURL.AccountsURL + "account-single-voucher-list-popup-view/?tsn=" + onSingleVoucherPopup?.tsn).then((res) => {
                    setOnLedgerList(res);
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        fetchVoucherList();
    }, [onSingleVoucherPopup?.tsn]);
    const componentListRef = useRef();
    const RenderListComponent = React.forwardRef(() => (
        <PrintSingleVoucher ledgerList={ledgerList} />
    ));

    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });
    return (
        <Dialog size="lg" className="ml-[120px]" open={onSingleVoucherPopup?.open} handler={handleOpen}>
            <DialogBody>
                <div className='bg-white rounded-md p-2 h-full dark:bg-slate800 overflow-y-auto'>
                    <ComponentHeading icon={BsFillTicketDetailedFill} label={'Single Voucher'} />
                    <div className="relative overflow-x-auto">
                        <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                            <thead className="text-[13px] text-gray600 capitalize border-b dark:bg-slate800 dark:text-gray-400 font-public-sans">
                                <tr className='bg-gray100'>
                                    <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600 w-10">
                                        {language ? "S.N." : "क्र.सं."}
                                    </th>
                                    <th scope="col" className="px-2 py-1 border dark:border-slate600">
                                        {language ? "Particular Name" : "बिबरण नाम"}
                                    </th>
                                    <th scope="col" className="px-2 py-1 border dark:border-slate600 w-20">
                                        {language ? "Cr" : "क्रे."}
                                    </th>
                                    <th scope="col" className="px-2 py-1 border dark:border-slate600 w-20">
                                        {language ? "Dr" : "डे."}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-slate-800'>
                                {
                                    Array.isArray(ledgerList?.data) && ledgerList?.data?.map((ledger, index) => (
                                        <tr key={index} className={`transition duration-150 select-none ease-in-out hover:bg-green-600 hover:text-white hover:shadow focus:bg-green-600 focus:shadow-sm focus:outline-none focus:ring-0 active:bg-green-400 active:shadow-sm motion-reduce:transition-none text-[11.5px] cursor-pointer text-gray700 dark:text-gray-400 border-b font-public-sans dark:border-gray-700`}>
                                            <td align='center' className="px-2 py-1.5  capitalize">
                                                {index + 1}.
                                            </td>
                                            <td align='left' className="px-2 capitalize">
                                                {ledger?.gl_name}
                                            </td>
                                            <td align='left' className="px-2 capitalize">
                                                {ledger?.debit_amt ? MoneyFormat(ledger?.debit_amt) : MoneyFormat(0)}
                                            </td>
                                            <td align='left' className="px-2 capitalize">
                                                {ledger?.credit_amt ? MoneyFormat(ledger?.credit_amt) : MoneyFormat(0)}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <div className="flex gap-3">
                    <PrintBtn onClick={handlePrintCard} /> <ClearBtn onClick={handleOpen} />
                </div>
            </DialogFooter>
            <div className='hidden'>
                <div ref={componentListRef}>
                    <RenderListComponent />
                </div>
            </div>
        </Dialog>
    )
}

export default SingleVoucherList