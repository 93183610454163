import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ActiveInactiveOptions, VehicleOptions } from '../../../../widgets/Options';
import { active_inactive_list } from '../../../../widgets/json';

const VehicleRoutineFormView = ({ FetchData, vehicleOptions }) => {
    const { language } = useLanguage();
    const [selectValues, setOnSelectValues] = useState({
        vehicle_name: "",
    });
    const VehicleOption = VehicleOptions(vehicleOptions?.results);
    const ActiveInactiveOption = ActiveInactiveOptions(active_inactive_list);
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        priority: 1,
        vehicle: "",
        bus_stop_name: "",
        pick_up_time: "",
        drop_time: "",
        status: "",
        notice: ""
    };
    const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("priority", values.priority);
                formData.append("vehicle", selectValues.vehicle.value);
                formData.append("bus_stop_name", values.bus_stop_name);
                formData.append("pick_up_time", values.pick_up_time);
                formData.append("drop_time", values.drop_time);
                formData.append("status", selectValues.status?.value==="1"?true:false);
                formData.append("notice", values.notice);
                try {
                    await postDataToTheServer(AppURL.AcademicsURL + "academics-school-vehicle-routine-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        action.resetForm();
                        setOnSelectValues({
                            vehicle: "",
                            status: ""
                        });
                        FetchData();
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add Vehicle Routine" : "सवारी साधन थप्नु"} icon={IoMdAddCircle} />
                <Grid container spacing={1.5}>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="priority"
                            type="number"
                            placeholder="Enter Priority"
                            label={language ? "Priority" : "नाम"}
                            value={values.priority}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.priority}
                            errors={errors.priority}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <SelectOptionBox label={language ? "Vehicle Name" : "सवारी साधनको नाम"} placeholder="Select Vehicle" value={selectValues.vehicle} name="vehicle" onFocus={true} options={VehicleOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.vehicle} errors={errors.vehicle} required={true} />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="bus_stop_name"
                            type="text"
                            placeholder="Enter Bus Stop Name"
                            label={language ? "Bus Stop Name" : "नाम"}
                            value={values.bus_stop_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.bus_stop_name}
                            errors={errors.bus_stop_name}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="notice"
                            type="text"
                            placeholder="Enter Notice"
                            label={language ? "Notice" : "नाम"}
                            value={values.notice}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.notice}
                            errors={errors.notice}
                            required={false}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="pick_up_time"
                            type="time"
                            placeholder="Enter Pickup Time"
                            label={language ? "Pickup Time" : "नाम"}
                            value={values.pick_up_time}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.pick_up_time}
                            errors={errors.pick_up_time}
                            required={true}
                        />                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="drop_time"
                            type="time"
                            placeholder="Enter Drop Time"
                            label={language ? "Drop Time" : "नाम"}
                            value={values.drop_time}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.drop_time}
                            errors={errors.drop_time}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <SelectOptionBox label={language ? "Active/Inactive" : "सवारी साधनको नाम"} placeholder="Select Status" value={selectValues.status} name="status" onFocus={true} options={ActiveInactiveOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.status} errors={errors.status} required={true} />
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-5 gap-2">
                    <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default VehicleRoutineFormView