
import React, { Fragment } from 'react'
import { Checkbox } from "@material-tailwind/react";

const ExamCheckBox = ({ label, handleChange, name, value, onClick }) => {
    return (
        <Fragment>
            <div className='flex font-public-sans'>
                <Checkbox size={5}
                    onClick={onClick}
                    id={name}
                    color="green" defaultChecked={value}
                    onChange={handleChange} name={name}
                    className="h-4 w-4 -my-2 pl-0 rounded-full dark:border-slate400 border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                />
                {label && <label className='my-auto -ml-1 select-none cursor-pointer text-gray700 text-[12px] font-medium dark:text-gray400' htmlFor={name}>{label}</label>}
            </div>
        </Fragment>
    )
}

export default ExamCheckBox