import React from 'react'
import DefaultPhoto from '../../../../assets/img/card/EmptyImage.png'
const GuardianFeedbackCard = () => {
    return (
        <div className='bg-white text-center rounded-md'>
            <div className='bg-customPrimary py-1 rounded-t-md text-white cursor-none font-medium font-public-sans mb-2'>
                4 minutes ago
            </div>
            <div>
                <img src={DefaultPhoto} alt="" className='h-28 mx-auto w-28 border rounded-full' />
            </div>
            <div className='py-2'>
                <h6 className='font-public-sans font-semibold pt-2 text-customPrimary cursor-pointer'>Name: Sushil KC, Class: One</h6>
                <h6 className='font-public-sans font-semibold text-gray700 cursor-pointer'>Father: Suman KC</h6>
                <p className='text-gray600 text-[12px] py-1'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aut deserunt blanditiis laboriosam ratione exercitationem adipisci consectetur eos eius? Odio, placeat..... <span className='underline hover:text-customPrimary cursor-pointer'>Read More</span></p>

            </div>
        </div>
    )
}

export default GuardianFeedbackCard