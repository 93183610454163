import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import GpaGradeCreateFormView from '../../../components/exam/setup/gpa_grade/GpaGradeCreateFormView';
import ExamGapGradeListView from '../../../components/exam/setup/gpa_grade/ExamGapGradeListView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
import GapGradeEditFormView from '../../../components/exam/setup/gpa_grade/GapGradeEditFormView';

const ExamGradeGPACreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    const URLS = AppURL.ExamURL + "exam-gap-grade-division-create-list-view/";
    const [onAcaListData, setOnAcaListData] = useState([]);
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const [onEditID, setOnEditID] = useState(false);

    const fetchGpaDivisionList = async (urls) => {
        setOnUpdateURL(urls);
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnAcaListData(res);
            })
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchGpaDivisionList(URLS);
    }
    useEffect(() => {
        FetchData();
    }, []);
    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    {
                        onEditID ? <GapGradeEditFormView setOnEditID={setOnEditID} fetchGpaDivisionList={fetchGpaDivisionList} onUpdateURL={onUpdateURL} onEditID={onEditID} /> : <GpaGradeCreateFormView FetchData={FetchData} />
                    }
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                    <ExamGapGradeListView onAcaListData={onAcaListData} setOnEditID={setOnEditID} FetchData={FetchData} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default ExamGradeGPACreateListView