import React, { Fragment } from 'react'
import { useLanguage } from '../../../../widgets/LangContext'
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa';

const AdmissionRequestListView = () => {
    const {language} = useLanguage();
  return (
    <Fragment>
    <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
        <ComponentHeading icon={FaListUl} label={language ? "Admission Request List" : "टिमको सूची"} />
        <div className="relative overflow-x-scroll">
            <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                <thead className="text-[12px] text-gray600 bg-gray200 capitalize border-b  dark:text-gray-400 font-public-sans">
                    <tr>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                            {language ? "S.N." : "क्र.सं."}
                        </th>
                        <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                            {language ? "Full Name" : "पुरा नाम"}
                        </th>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                            {language ? "Gender" : "पुरा नाम (नेपाली)"}
                        </th>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                            {language ? "Dob" : "रोल नं"}
                        </th>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                            {language ? "Class" : "फोन"}
                        </th>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                           G-Name
                        </th>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                            G-Phone
                        </th>
                        <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                            Remarks
                        </th>
                    </tr>
                </thead>
                <tbody className='text-slate-800'>
                    <tr>
                        <td colSpan="13" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                            Student not found.
                        </td>
                    </tr>
                   
                </tbody>
            </table>

        </div>
    </div>
    <div className='flex gap-3 mt-3 justify-end'>

    </div>
</Fragment>
  )
}

export default AdmissionRequestListView