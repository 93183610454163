import React, { Fragment } from 'react'
import { Checkbox } from "@material-tailwind/react";

const InputCheckValue = ({ label, handleChange, name, value, onClick }) => {
  return (
    <Fragment>
      <div className='flex font-public-sans'>
        <Checkbox size={5}
          onClick={onClick}
          color="green" checked={value}
          onChange={handleChange} name={name}
          className="h-5 w-5 -my-2 rounded-full dark:border-slate400 border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
        />
        {label && <label className='my-auto text-gray700 text-[12px] font-medium' htmlFor={name}>{label}</label>}
      </div>
    </Fragment>
  )
}

export default InputCheckValue