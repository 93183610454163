import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { IoClose } from 'react-icons/io5';
import { fetchDataFromApi, postDataToTheServer } from '../../../../../widgets/api';
import AppURL from '../../../../../widgets/AppURL';
import { MoneyFormat } from '../../../../../widgets/NumberFormat';
import SingleVoucherList from './SingleVoucherList';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VoucherDetailsList = ({ singleVoucherDetails, setOnSingleVoucherDetails }) => {
    const { account_ledger_report_select } = useSelector((state) => state.accounts);

    const handleClose = () => setOnSingleVoucherDetails({ open: false });
    const [ledgerListView, setOnLedgerListView] = useState([]);
    const { menu_list } = useSelector((state) => state?.software);

    const DisplayLedgerList = async (urls) => {
        try {
            const res = await fetchDataFromApi(urls);
            setOnLedgerListView(res);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchData = async () => await DisplayLedgerList(AppURL.AccountsURL + "account-display-ledger-create-list-view/");
    const DisplayLedgerPostView = async (sglsn, user_id, date_ne, mode, member_sn) => {
        const formData = new FormData();
        formData.append('sglsn', sglsn);
        formData.append('user_id', user_id);
        formData.append('fiscal_start', menu_list?.current_fiscal?.from_date_n);
        formData.append('date_ne', date_ne);
        formData.append('member_sn', member_sn ? member_sn : 1);
        formData.append('det_mode', 1);
        try {
            const res = await postDataToTheServer(AppURL.AccountsURL + "account-display-ledger-create-list-view/", formData);
            setOnLedgerListView(res);
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (account_ledger_report_select?.selectValues?.sglsn && singleVoucherDetails?.daten) {
            try {
                DisplayLedgerPostView(account_ledger_report_select?.selectValues?.sglsn?.value, 0, singleVoucherDetails?.daten, 1, account_ledger_report_select?.selectValues?.member_sn?.value);
            } catch (error) {
                console.log(error);
            }
        }
    }, [account_ledger_report_select?.selectValues?.sglsn, singleVoucherDetails, singleVoucherDetails?.daten, account_ledger_report_select?.selectValues?.member_sn]);

    const [onSingleVoucherPopup, setOnSingleVoucherPopup] = useState({
        open: false,
        tsn: 0
      })
    // const RenderComponent = React.forwardRef(() => <VoucherPrintView onPrintVoucherList={onPrintVoucherList} />);
    const [onBackColor, setOnBackColor] = useState(null);
    const [clickTimeout, setClickTimeout] = useState(null);
    const handleSingleClick = (index) => {
        // Set a timeout to check for double click
        const timeout = setTimeout(() => {
            setOnBackColor(index);
        }, 200); // 200ms timeout to wait for a possible second click
        setClickTimeout(timeout);
    };

    const handleDoubleClick = (tsn) => {
        // Clear the timeout so single click action doesn't fire
        clearTimeout(clickTimeout);
        setOnSingleVoucherPopup({
            open: true,
            tsn: tsn
          });
    };
    return (
        <React.Fragment>
            <SingleVoucherList onSingleVoucherPopup={onSingleVoucherPopup} setOnSingleVoucherPopup={setOnSingleVoucherPopup} />
            <Dialog className='ml-[80px]' fullScreen open={singleVoucherDetails?.open} onClose={handleClose} TransitionComponent={Transition}>
                <Paper className='bg-white dark:!bg-gray-800 h-screen'>
                    <div className='flex justify-between px-6 py-2 bg-customPrimary'>
                        <div className='font-public-sans'>
                            <h6 className='text-[20px] font-public-sans font-bold text-gray-200'>Ledger Details</h6>
                        </div>
                        <div>
                            <button onClick={handleClose} className='bg-white p-1.5 rounded-full'><IoClose size={20} /></button>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto mt-3 mx-5">
                        <table className="w-full text-sm text-left whitespace-nowrap rtl:text-right font-public-sans rounded-md text-gray-500 dark:text-gray-400">
                            <thead className="text-[13px] text-gray-700 capitalize font-public-sans rounded-t-md bg-gray200 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" align='center' className="px-2 py-1.5 border w-16">S.N.</th>
                                    <th scope="col" align='left' className="px-2 py-1.5 border">Date</th>
                                    <th scope="col" align='center' className="px-2 py-1.5 border w-16">Bill No</th>
                                    <th scope="col" align='center' className="px-2 py-1.5 border">TSN</th>
                                    <th scope="col" align='left' className="px-2 py-1.5 border">Particular Name</th>
                                    <th scope="col" align='center' className="px-2 py-1.5 border">Debit Amount</th>
                                    <th scope="col" align='center' className="px-2 py-1.5 border">Credit Amount</th>
                                    <th scope="col" align='center' className="px-2 py-1.5 border">Sub Ac Balance</th>
                                    <th scope="col" align='center' className="px-2 py-1.5 border w-10">Dr/Cr</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ledgerListView?.results?.map((ledger, index) => (
                                    <tr key={index} onClick={() => handleSingleClick(index)} onDoubleClick={() => handleDoubleClick(ledger?.tsn)} className={`bg-white cursor-pointer select-none hover:bg-green-600 hover:text-white border-b font-public-sans ${index === onBackColor ? "!bg-green-600 text-white" : ""} font-medium  text-[12px] text-gray800 dark:bg-gray-800 dark:border-gray-700`}>
                                        <th scope="row" align='center' className="px-2 py-1 whitespace-nowrap dark:text-white border">{index + 1}.</th>
                                        <td className="px-2 py-0.5 border">{ledger?.dated}</td>
                                        <td align='center' className="px-2 py-1 border">{ledger?.billrno ? ledger?.billrno : 0}</td>
                                        <td align='center' className="px-2 py-1 border">{ledger?.tasn ? ledger?.tasn : 0}</td>
                                        <td className="px-2 py-1 border">{ledger?.particulars}</td>
                                        <td align='right' className="px-2 py-1 border">{ledger?.dramt ? MoneyFormat(ledger?.dramt) : MoneyFormat(0)}</td>
                                        <td align='right' className="px-2 py-1 border">{ledger?.cramt ? MoneyFormat(ledger?.cramt) : MoneyFormat(0)}</td>
                                        <td align='right' className="px-2 py-1 border">{ledger?.subaccbal ? MoneyFormat(ledger?.subaccbal) : MoneyFormat(0)}</td>
                                        <td align='center' className="px-2 py-1 border">{ledger?.subdrcr}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='flex justify-end gap-3 mt-4'>
                         {/* <PrevBtn disabled={ledgerListView?.previous ===null?true:false} onClick={async ()=>{
                            if(ledgerListView?.previous){
                               await DisplayLedgerList(ledgerListView?.previous)
                            }
                         }} />   <NextBtn  disabled={ledgerListView?.next ===null?true:false} onClick={async ()=>{
                            if(ledgerListView?.next){
                               await DisplayLedgerList(ledgerListView?.next)
                            }
                         }} />  */}
                        </div>
                    </div>
                </Paper>
            </Dialog>
        </React.Fragment>
    );
};

export default VoucherDetailsList;
