import React from 'react';
import { HiOutlineArrowRight } from "react-icons/hi";

export const MenuDrawer = ({ label, onClick }) => {
    return (
        <div onClick={onClick} className='flex gap-2 select-none mt-2.5 hover:text-customPrimary cursor-pointer text-slate600 font-normal items-center'>
            <HiOutlineArrowRight className='text-[10px] font-public-sans' />
            <span className='text-[13.5px] font-public-sans'>{label}</span>
        </div>
    )
}

