
import React, { Fragment, useState } from 'react';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa6';
import { useLanguage } from '../../../../widgets/LangContext';
import { useSelector } from "react-redux";
import { FaRegEyeSlash } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";
import { putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';

const AppStudentListView = ({ OnDataList, onURL }) => {
    const { language } = useLanguage();
    const { class_wise_app_details_std_list } = useSelector((state) => state?.academics);
    const listItems = class_wise_app_details_std_list?.students;

    const [showPasswordForStudent, setShowPasswordForStudent] = useState(null);
    const togglePasswordVisibility = (studentID) => {
        setShowPasswordForStudent(studentID);
    };

    const GenerateUsernamePassword = async (id) => {
        if (window.confirm("Are you sure you want to generate a new username and password?")) {
            try {
                await putDataToTheServer(AppURL.AcademicsURL + "academics-app-user-details-create-username-and-password-view/" + id).then((res) => {
                    ToastSuccess(res?.message);
                    OnDataList(onURL);
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Student List" : "टिमको सूची"} />
                <div className="relative overflow-x-scroll">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray600  capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Full Name" : "पुरा नाम"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Roll No" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Username" : "रोल नं"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Password" : "फोन"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    Username / Password Generate
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    Username / Password Show
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(listItems) && listItems.length > 0 ? (
                                listItems.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">

                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 border-gray300 font-medium capitalize">
                                            {`${cls?.first_name} ${cls?.middle_name ? cls?.middle_name : ""} ${cls?.last_name}`}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.roll_no}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {(cls?.username ? showPasswordForStudent === cls.member_sn ? cls?.username : '*'.repeat(cls?.username.length) : <span className='text-[10px] text-red-500 px-3 py-1 bg-red-100 rounded-md'>Generate Password</span>)}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.password ? showPasswordForStudent === cls.member_sn ? cls?.username : '*'.repeat(cls?.password.length) : <span className='text-[10px] text-red-500 px-3 py-1 bg-red-100 rounded-md'>Generate Password</span>}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            <button className='px-2 py-0.5 text-[10px] bg-green-600 hover:bg-green-900 text-white rounded-md' onClick={() => GenerateUsernamePassword(cls.member_sn)}>Generate</button>
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            <button className='bg-customPrimary p-1.5 cursor-pointer !text-white rounded-full' onClick={() => togglePasswordVisibility(cls.member_sn === showPasswordForStudent ? null : cls.member_sn)} > {cls.member_sn === showPasswordForStudent ? <FaRegEyeSlash /> : <FaRegEye />} </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    Student not found.
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>

            </div>
        </Fragment>
    )
}

export default AppStudentListView