import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';
import { Grid } from '@mui/material';
import VehicleRoutineFormView from '../../../../components/academics/create/vehicle_routine/VehicleRoutineFormView';
import VehicleRoutineListView from '../../../../components/academics/create/vehicle_routine/VehicleRoutineListView';
import VehicleRoutineEditView from '../../../../components/academics/create/vehicle_routine/VehicleRoutineEditView';

const AcaVehicleRoutineCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);

    const [vehicleOptions, setOnVehicleOptions] = useState([]);
    const fetchAcademicsOptionsListView = async (urls) => {
        setOnUpdateURL(urls);
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnVehicleOptions(res);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchAcademicsOptionsListView(AppURL.AcademicsURL + "academics-school-vehicle-create-list-view/");
    }, []);


    const [onEditID, setOnEditID] = useState(false);
    const [onAcaListData, setOnAcaListData] = useState([]);
    const url = AppURL.AcademicsURL + "academics-school-vehicle-routine-create-list-view/";
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const fetchAcademicsListView = async (urls) => {
        setOnUpdateURL(urls);
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnAcaListData(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchAcademicsListView(url);
    }
    useEffect(() => {
        FetchData();
    }, []);
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xl={4} lg={4} sm={12} xs={12} md={4}>
                    {
                        onEditID ? <VehicleRoutineEditView setOnEditID={setOnEditID} fetchAcademicsListView={fetchAcademicsListView} onUpdateURL={onUpdateURL} onEditID={onEditID} vehicleOptions={vehicleOptions} /> : <VehicleRoutineFormView vehicleOptions={vehicleOptions} FetchData={FetchData} />
                    }
                </Grid>
                <Grid item xl={8} lg={8} sm={12} xs={12} md={8}>
                    <VehicleRoutineListView fetchAcademicsListView={fetchAcademicsListView} onAcaListData={onAcaListData} setOnEditID={setOnEditID} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default AcaVehicleRoutineCreateListView