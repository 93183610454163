import React, { Fragment } from 'react'
import { NextBtn, PrevBtn } from '../../../widgets/AppBtn'
import ComponentHeading from '../../../widgets/ComponentHeading'
import { FaListUl } from 'react-icons/fa'
import { putDataToTheServer } from '../../../widgets/api'
import AppURL from '../../../widgets/AppURL'
import { ToastSuccess } from '../../../widgets/ToastMessage'
import InputCheckBox from '../../../widgets/InputCheckBox'

const ResultsPublishListView = ({ onResultPublishList, fetchResultsPublishedList, FetchData }) => {
    const updateResultPublishedView = async (member_sn, values) => {
        const formData = new FormData();
        formData.append('publish', values)
        try {
            await putDataToTheServer(AppURL.MobileAppURL + "mobile-app-student-results-publish-retrieve-update-view/" + member_sn, formData).then((res) => {
                ToastSuccess(res?.message);
                FetchData();
            })
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={"Result Published List"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-2 border dark:border-slate600 w-10">
                                    {"S.N."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {"Batch Name"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {"Class Name"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {"Section"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {"Medium "}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {"Exam Type "}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {"Published "}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onResultPublishList?.results) &&
                                onResultPublishList?.results?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 capitalize">
                                            {cls?.batch_name}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {cls?.class_name}
                                        </td>
                                        <td className="px-2 border dark:border-slate600">
                                            {cls?.section_name}
                                        </td>
                                        <td className="px-2 border dark:border-slate600">
                                            {cls?.medium === 2 ? "Nepali" : "English"}
                                        </td>
                                        <td className="px-2 border dark:border-slate600">
                                            {cls?.exam_name}
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            <InputCheckBox value={cls.publish} handleChange={() => updateResultPublishedView(cls?.sn, !cls.publish)} name={'publish'} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onResultPublishList?.previous === null ? true : false} onClick={async () => {
                    if (onResultPublishList?.previous) {
                        await fetchResultsPublishedList(onResultPublishList?.previous)
                    }
                }} />
                <NextBtn disabled={onResultPublishList?.next === null ? true : false} onClick={async () => {
                    if (onResultPublishList?.next) {
                        await fetchResultsPublishedList(onResultPublishList?.next)
                    }
                }} />
            </div>
        </Fragment>
    )
}

export default ResultsPublishListView