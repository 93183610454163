import { Grid } from '@mui/material'
import React from 'react'
import GalleryItem from './widgets/GalleryItem'

const GalleryListView = ({ onGalleryList }) => {
    return (
        <Grid container spacing={1}>
            {
                Array.isArray(onGalleryList?.results) && onGalleryList?.results?.map((gallery, index) => (
                    <Grid key={index} item lg={4} md={4} sm={12} xs={12} xl={4}>
                        <GalleryItem title={gallery?.title} images={gallery?.images} gallery_category={gallery?.gallery_category_name} />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default GalleryListView