import React, { Fragment } from 'react'
import { useSelector } from "react-redux";
import AppURL from '../../../../../../widgets/AppURL';
import { NepNowDateFormat } from '../../../../../../widgets/DateFormat';
import { MoneyFormat } from '../../../../../../widgets/NumberFormat';

const PrintSingleVoucher = ({ ledgerList }) => {
    const { account_date_form_get_list } = useSelector((state) => state.accounts);
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();

    // Calculate totals
    const totalDebit = ledgerList?.data?.reduce((total, ledger) => total + (ledger?.debit_amt || 0), 0);
    const totalCredit = ledgerList?.data?.reduce((total, ledger) => total + (ledger?.credit_amt || 0), 0);

    return (
        <Fragment>
            <style>
                {`@media print {
                        @page {
                            size: A4 portrait;
                            page-break-before: always;
                            margin: 0.2in; /* Set margin for all sides */
                        }
                    }`}
            </style>
            <div className='bg-white h-screen p-2 border-[1px] border-gray-300'>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>
                            </tr>
                            <tr >
                                <td rowSpan={4} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">

                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Single Voucher Report
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                            <tr>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    Date: {DateNow}
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="relative mt-3">
                    <table className="w-full text-sm text-left rtl:text-right text-black ">
                        <thead className="text-[12px] text-black capitalize">
                            <tr className='bg-gray-100'>
                                <th scope="col" align='center' className="px-1 py-0.5 border-[1px] border-gray-300 w-14">
                                    S.N.
                                </th>
                                <th scope="col" align='center' className="px-1 py-0.5 border-[1px] border-gray-300">
                                    Particulars
                                </th>
                                <th scope="col" align='center' className="px-1 py-0.5 border-[1px] border-gray-300">
                                    Credit Amount
                                </th>
                                <th scope="col" align='center' className="px-1 py-0.5 border-[1px] border-gray-300">
                                    Debit Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-[10px] font-medium text-black capitalize">
                            {
                                Array.isArray(ledgerList?.data) && ledgerList?.data?.map((ledger, index) => (
                                    <tr key={index} className={`text-[11.5px] text-gray700 border-b font-public-sans`}>
                                        <td align='center' className="px-2 border-[1px] border-gray-300">
                                            {index + 1}.
                                        </td>
                                        <td align='left' className="px-2 capitalize border-[1px] border-gray-300">
                                            {ledger?.gl_name}
                                        </td>
                                        <td align='right' className="px-2 border-[1px] border-gray-300">
                                            {ledger?.debit_amt ? MoneyFormat(ledger?.debit_amt) : MoneyFormat(0)}
                                        </td>
                                        <td align='right' className="px-2 border-[1px] border-gray-300">
                                            {ledger?.credit_amt ? MoneyFormat(ledger?.credit_amt) : MoneyFormat(0)}
                                        </td>
                                    </tr>
                                ))
                            }
                            <tr className='font-public-sans text-black bg-gray100'>
                                <td align='right' colSpan={2} className="px-2 font-bold border-[1px] border-gray-300">
                                    Total
                                </td>
                                <td align='left' className="px-2 font-bold border-[1px] border-gray-300">
                                    {MoneyFormat(totalDebit)}
                                </td>
                                <td align='left' className="px-2 font-bold border-[1px] border-gray-300">
                                    {MoneyFormat(totalCredit)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
            </div >
        </Fragment>
    )
}

export default PrintSingleVoucher
