import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { DeleteBtn } from '../../../../widgets/AppBtn';
import { deleteDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastWarning } from '../../../../widgets/ToastMessage';

const AssignStudentSubjectListView = ({ fetchViewDataList }) => {
    const { assign_subject_report_list } = useSelector(state => state.exam);
    const [expandedStudent, setExpandedStudent] = useState(null);

    // Sort student_subject_list by roll_no
    const sortedStudentList = assign_subject_report_list?.student_subject_list?.slice().sort((a, b) => {
        return a?.student_details?.roll_no - b?.student_details?.roll_no;
    });

    const toggleExpand = (memberSn) => {
        if (expandedStudent === memberSn) {
            setExpandedStudent(null);
        } else {
            setExpandedStudent(memberSn);
        }
    };

    const handleDelete = (sub_id) => {
        if (window.confirm("Are you sure you want to delete this record?")) {
            deleteRecord(sub_id);
        }
    };

    const deleteRecord = async (sub_id) => {
        try {
            await deleteDataToTheServer(AppURL.ExamURL + "exam-student-get-all-assign-subject-delete-view/" + sub_id).then((res) => {
                fetchViewDataList();
                ToastWarning("Assign Subject deleted successfully.");
            });
        } catch (error) {
            console.error('Error deleting record:', error);
        }
    };

    return (
        <div className='bg-white rounded-md p-1 shadow-sm dark:bg-slate800'>
            <div className='overflow-x-auto overflow-auto h-[78vh]'>
                <table className="table-auto w-full border-collapse border border-gray300 font-public-sans">
                    <thead className="bg-gray300 top-0 sticky z-10 dark:text-gray-50 dark:bg-gray900 text-[12px]">
                        <tr>
                            <th className="border border-gray-300 dark:border-gray700 px-2 py-1.5">Roll No</th>
                            <th className="border border-gray-300 dark:border-gray700 px-2 py-1.5">Full Name</th>
                            <th className="border border-gray-300 dark:border-gray700 px-2 py-1.5">Member SN</th>
                            <th className="border border-gray-300 dark:border-gray700 px-2 py-1.5">DOB</th>
                            <th className="border border-gray-300 dark:border-gray700 px-2 py-1.5">Father Name</th>
                            <th className="border border-gray-300 dark:border-gray700 px-2 py-1.5">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray800 text-[12px]">
                        {sortedStudentList?.length > 0 ? (sortedStudentList.map((student, index) => (
                            <React.Fragment key={student.student_details?.member_sn}>
                                <tr onClick={() => toggleExpand(student.student_details?.member_sn)} className="cursor-pointer dark:text-gray-200 hover:bg-gray100">
                                    <td align='center' className="border border-gray-300 dark:border-gray700 px-1 py-1">{student.student_details?.roll_no}.</td>
                                    <td className="border border-gray-300 px-1 dark:border-gray700 py-1">{`${student?.student_details?.first_name ? student?.student_details?.first_name : ""} ${student?.student_details?.middle_name ? student?.student_details?.middle_name : ""} ${student?.student_details?.last_name ? student?.student_details?.last_name : ""}`}</td>
                                    <td align='center' className="border dark:border-gray700 border-gray-300 px-1 py-1">{`${student?.student_details?.member_sn}`}</td>
                                    <td className="border border-gray-300 dark:border-gray700 px-1 py-1">{`${student?.student_details?.dobn ? student?.student_details?.dobn : ""}`}</td>
                                    <td className="border border-gray-300 dark:border-gray700 px-1 py-1">{`${student?.student_details?.father_name ? student?.student_details?.father_name : ""}`}</td>
                                    <td className="border border-gray-300 dark:border-gray700 px-1 py-1"></td>
                                </tr>
                                {expandedStudent === student.student_details?.member_sn && <tr className='bg-gray200 dark:bg-gray900 dark:text-gray-200 font-semibold'>
                                    <td align='center' className="border border-gray-300 dark:border-gray600 px-1 py-1">SN</td>
                                    <td className="border border-gray-300 px-1 py-1 dark:border-gray600">Subject Name</td>
                                    <td align='center' className="border border-gray-300 dark:border-gray600 px-1 py-1">Subject ID</td>
                                    <td className="border border-gray-300 px-1 py-1 dark:border-gray600">Full Marks</td>
                                    <td className="border border-gray-300 px-1 py-1 dark:border-gray600">Practical</td>
                                    <td align='center' className="border border-gray-300 dark:border-gray600 px-1 py-1">Action</td>
                                </tr>}
                                {expandedStudent === student.student_details?.member_sn && (
                                    student?.subject_list?.length > 0 ? (
                                        student?.subject_list?.map((subject, index) => (
                                            <Fragment key={subject.sn}>
                                                <tr className='bg-gray100 my-2 dark:bg-gray700 dark:text-gray-200 hover:bg-gray200'>
                                                    <td align='center' className="border border-gray-300 dark:border-gray600 px-1 py-0.5">{index + 1}.</td>
                                                    <td className="border border-gray-300 px-1 py-0.5 dark:border-gray600">{subject.subject_name}</td>
                                                    <td align='center' className="border border-gray-300 dark:border-gray600 px-1 py-0.5">{subject.subj_id}</td>
                                                    <td className="border border-gray-300 px-1 py-0.5 dark:border-gray600">{subject.subj_full_marks}</td>
                                                    <td className="border border-gray-300 px-1 py-0.5 dark:border-gray600">{subject.practical === 0 ? "No" : "Yes"}</td>
                                                    <td align='center' className="border border-gray-300 dark:border-gray600 px-1 py-0.5">
                                                        <DeleteBtn onClick={() => handleDelete(subject?.sn)} wh={5} />
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        ))
                                    ) : (
                                        <tr className='bg-gray100 dark:bg-gray700 dark:text-gray-200 hover:bg-gray200'>
                                            <td align='center' colSpan={6} className="border border-gray-300 dark:border-gray600 px-1 py-0.5">Data Not Found</td>
                                        </tr>
                                    )
                                )}

                            </React.Fragment>
                        ))) : <tr>
                            <th colSpan={6} className="border border-gray-300 dark:border-gray700 px-2 py-1.5">Data Not Found</th>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AssignStudentSubjectListView;
