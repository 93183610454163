import React, { Fragment } from 'react'
import { useLanguage } from '../../../widgets/LangContext'
import { EditPenBtn, NextBtn, PrevBtn } from '../../../widgets/AppBtn';
import { MaxLengthParagraph } from '../../../widgets/StringTrim';

const NewsEventsListView = ({ onNewsEventList, setOnEditID, fetchNewsEventList }) => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-2 shadow-sm dark:bg-slate800'>
                {/* <ComponentHeading icon={FaListUl} label={language ? "Book Category List" : "कक्षाको सूची"} /> */}
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-[30%]">
                                    {language ? "Title" : "श्रेणी नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Details" : "पुस्तकको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "Action" : "भाषा"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {
                                Array.isArray(onNewsEventList?.results) && onNewsEventList?.results?.length > 0 ? (onNewsEventList?.results?.map((item, index) => (
                                    <tr className="odd:bg-white text-[12px] text-gray600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 py-1 border dark:border-slate600">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 py-1 border dark:border-slate600 capitalize">
                                            <MaxLengthParagraph text={item?.event_title} maxLength={50} />
                                        </td>
                                        <td align='left' className="px-2 py-1 border capitalize dark:border-slate600 ">
                                            <MaxLengthParagraph text={item?.event_details} maxLength={90} />
                                        </td>
                                        <td align='center' className="px-2 py-1 border capitalize dark:border-slate600 ">
                                            <EditPenBtn wh={6} />
                                        </td>
                                    </tr>
                                ))) :
                                    <tr className="odd:bg-white text-[12px] text-gray600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' colSpan={4} className="px-2 py-1 border dark:border-slate600  ">
                                            No Data Found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onNewsEventList?.previous === null ? true : false} onClick={async () => {
                    if (onNewsEventList?.previous) {
                        await fetchNewsEventList(onNewsEventList?.previous)
                    }
                }} />
                <NextBtn disabled={onNewsEventList?.next === null ? true : false} onClick={async () => {
                    if (onNewsEventList?.next) {
                        await fetchNewsEventList(onNewsEventList?.next)
                    }
                }} />
            </div>
        </Fragment>
    )
}

export default NewsEventsListView