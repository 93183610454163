import React from 'react';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaRegEye } from "react-icons/fa";
import moment from 'moment-timezone';

const NotificationSeenUserListVIew = ({ onNotificationDetails }) => {
    return (
        <div className='h-80vh scroll-y-auto'>
            <div className='bg-white rounded-md p-3 shadow'>
                <ComponentHeading icon={FaRegEye} label={"Seen Notification"} />
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right font-public-sans text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 capitalize border-b bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-2 py-1.5">
                                    First Name
                                </th>
                                <th scope="col" className="px-2 py-1.5">
                                    Date/time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(onNotificationDetails?.seen_user_list) && onNotificationDetails?.seen_user_list?.map((items, index) => {
                                const formattedDate = moment(items.date).format('MMM D');
                                const formattedTime = moment.utc(`1970-01-01T${items.time}`).tz('Asia/Kathmandu').format('hh:mm:ss A');
                                return (
                                    <tr key={index} className="odd:bg-white text-[12px] odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                        <td className="px-2 py-1 whitespace-nowrap dark:text-white">
                                            {`${items?.first_name ?? ''} ${items?.middle_name ?? ''} ${items?.last_name ?? ''}`}
                                        </td>
                                        <td className="px-2 py-1">
                                            {formattedDate}, {formattedTime}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default NotificationSeenUserListVIew;
