import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { useLanguage } from '../../../../widgets/LangContext';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa';
import { putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import URLImage from '../../../../assets/img/img/default-img.png';
const StdPhotoUploadListView = () => {
    const { student_lists } = useSelector((state) => state?.academics);
    const listItems = student_lists?.students;
    const { language } = useLanguage();

    const handleFileChange = async (event, member_sn) => {
        const files = event.target.files;
        const file = files[0];
        if (file) {
            const formData = new FormData();
            formData.append('photo', file);
            try {
                await putDataToTheServer(AppURL.AcademicsURL + "academics-student-photo-retrieve-update-view/" + member_sn, formData).then((res) => {
                    ToastSuccess(res?.message);
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            console.warn('No file selected for upload.');
        }
    };


    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Student List" : "टिमको सूची"} />
                <div className="relative overflow-x-auto overflow-y-scroll !h-[60vh]">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray600 capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr className='bg-gray200'>

                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Full Name" : "पुरा नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Full Name(Nepali)" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-12">
                                    {language ? "Roll No" : "रोल नं"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Phone" : "फोन"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "DOB" : "DOB"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Gender" : "लिङ्ग"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Address" : "ठेगाना"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Father Name" : "बुबाको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Action " : "कार्य"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(listItems) && listItems?.length > 0 ? (
                                listItems.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 border-gray300 font-medium capitalize">
                                            <div className='flex flex-row gap-3'>
                                                <img src={cls?.photo ? AppURL.BaseURL + cls?.photo : URLImage} alt='P' className='w-8 h-8 rounded-full inline-block' />  <div className='my-auto'>{`${cls?.first_name} ${cls?.middle_name ? cls?.middle_name : ""} ${cls?.last_name}`}</div>
                                            </div>
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.name_nep}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.roll_no}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.phone}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.dobn}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.gender === 2 ? "Male" : cls?.gender === 1 ? "Female" : "Others"}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.add_eng}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.father_name}
                                        </td>
                                        <td align='left' className="px-2 py-0.5 border border-gray300 dark:border-slate600 ">
                                            <input type="file" className='text-[11px]' onChange={(event) => handleFileChange(event, cls?.member_sn)} accept="image/jpeg, image/png" />
                                        </td>
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    Student not found.
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>

            </div>
        </Fragment>
    )
}

export default StdPhotoUploadListView