import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useLanguage } from '../../../widgets/LangContext';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoAddCircle } from 'react-icons/io5';
import { SaveBtn } from '../../../widgets/AppBtn';
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastError, ToastSuccess } from '../../../widgets/ToastMessage';
import SelectOptionBox from '../../../widgets/SelectOptionBox';
import { MediumOptions, StdBatchOptions, StdClassOptions, StdSectionOptions, ExamTypeOptions } from '../../../widgets/Options';
import { lanugage_medium } from '../../../widgets/json';
import InputCheckValue from '../../../widgets/InputCheckValue';

const ResultsPublishFormView = ({ onStudentOptionList, FetchData }) => {
    const { language } = useLanguage();
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
        batch: "",
        exam_type: ""
    });
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOption = StdSectionOptions(onStudentOptionList?.section);
    const StdBatchOption = StdBatchOptions(onStudentOptionList?.batch);
    const ExamTypeOption = ExamTypeOptions(onStudentOptionList?.exam_type);

    const MediumOption = MediumOptions(lanugage_medium);
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value);
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    }; const initialValues = {
        class_id: "",
        section: "",
        medium: "",
        batch: "",
        publish: false,
        exam_type: ""
    };

    const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("class_id", selectValues?.class_id?.value);
                formData.append("section", selectValues?.section?.value);
                formData.append("medium", selectValues?.medium?.value);
                formData.append("batch", selectValues?.batch?.value);
                formData.append("publish", values.publish || false);
                formData.append("exam_type", selectValues?.exam_type?.value);
                try {
                    await postDataToTheServer(AppURL.MobileAppURL + "mobile-app-student-results-publish-create-list-view/", formData).then((res) => {
                        if (res?.status === 201) {
                            ToastSuccess(res?.message);
                            action.resetForm();
                            setOnSelectValues({
                                class_id: "",
                                section: "",
                                medium: "",
                                batch: "",
                            });
                            FetchData();
                        }
                        else {
                            ToastError(res?.message);
                        }
                    });
                } catch (error) {
                    console.log(error);
                }
                console.log(values);
            },
        });

    return (
        <div className='bg-white p-4 dark:bg-gray-800 rounded-md'>
            <ComponentHeading icon={IoAddCircle} label={language ? 'Add Results Published' : 'कक्षा'} />
            <form onSubmit={handleSubmit} className='mt-3'>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <SelectOptionBox
                            label={language ? "Batch" : "ब्याच"}
                            placeholder=""
                            value={selectValues.batch}
                            name="batch"
                            onFocus={true}
                            options={StdBatchOption}
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.batch}
                            errors={errors.batch}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <SelectOptionBox
                            label={language ? "Class" : "कक्षा"}
                            placeholder=""
                            value={selectValues.class_id}
                            name="class_id"
                            onFocus={true}
                            options={StdClassOption}
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.class_id}
                            errors={errors.class_id}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <SelectOptionBox
                            label={language ? "Section" : "खण्ड"}
                            placeholder=""
                            value={selectValues.section}
                            name="section"
                            onFocus={true}
                            options={StdSectionOption}
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.section}
                            errors={errors.section}
                            required={true}
                        />
                    </Grid>

                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <SelectOptionBox
                            label={language ? "Medium" : "मध्यम"}
                            placeholder=""
                            value={selectValues.medium}
                            name="medium"
                            onFocus={true}
                            options={MediumOption}
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.medium}
                            errors={errors.medium}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <SelectOptionBox
                            label={language ? "Exam Type" : "परीक्षा प्रकार"}
                            placeholder=""
                            value={selectValues.exam_type}
                            name="exam_type"
                            onFocus={true}
                            options={ExamTypeOption}
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.exam_type}
                            errors={errors.exam_type}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <InputCheckValue label={'Published'} value={values.publish} handleChange={handleChange} name={'publish'} />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <div className='flex justify-end'>
                            <SaveBtn type={'submit'} />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default ResultsPublishFormView;
