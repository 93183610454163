import React, { Fragment } from 'react';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa';
import { useLanguage } from '../../../widgets/LangContext';
import { MaxLengthParagraph } from '../../../widgets/StringTrim';
import { EditPenBtn, NextBtn, PrevBtn } from '../../../widgets/AppBtn';

const NotificationStudentView = ({ onNotificationList, fetchNotificationList }) => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-2 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Message List" : "सन्देश सूची"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-[30%]">
                                    {language ? "Class" : "कक्षा"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-[30%]">
                                    {language ? "Title" : "श्रेणी नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Message" : "पुस्तकको नाम"}
                                </th>

                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "Action" : "भाषा"}
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {
                                Array.isArray(onNotificationList?.results) && onNotificationList?.results?.map((item, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 py-0.5 border dark:border-slate600">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 py-0.5 border dark:border-slate600 capitalize">
                                            {item?.class_id ===null? "Global" :item?.std_class_name}
                                        </td>
                                        <td className="px-2 py-0.5 border dark:border-slate600 capitalize">
                                            <MaxLengthParagraph text={item?.title} maxLength={40} />
                                        </td>
                                        <td align='left' className="px-2 py-0.5 border capitalize dark:border-slate600 ">
                                            <MaxLengthParagraph text={item?.details} maxLength={90} />
                                        </td>
                                        <td align='center' className="px-2 py-0.5 border capitalize dark:border-slate600 ">
                                            <EditPenBtn wh={6} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onNotificationList?.previous === null ? true : false} onClick={async () => {
                    if (onNotificationList?.previous) {
                        await fetchNotificationList(onNotificationList?.previous)
                    }
                }} />
                <NextBtn disabled={onNotificationList?.next === null ? true : false} onClick={async () => {
                    if (onNotificationList?.next) {
                        await fetchNotificationList(onNotificationList?.next)
                    }
                }} />
            </div>
        </Fragment>)
}

export default NotificationStudentView

