import React, { Fragment } from 'react';

const AppPageTitle = ({ onIconsButton, title }) => {
  return (
    <Fragment>
      <div className="pl-8 pr-5 pb-2 w-full">
        <div className="flex justify-between items-center">
          <div className="">
            <div className="flex text-center my-auto">
              <h3 className="select-none font-semibold font-public-sans text-[12.5px] text-gray-600 dark:text-gray-500 mt-0.5">{title}</h3>
            </div>
          </div>
          {onIconsButton}
        </div>
      </div>
    </Fragment>
  );
};

export default AppPageTitle;
