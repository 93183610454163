import React, { Fragment } from 'react'
import ComponentHeading from '../../../../widgets/ComponentHeading'
import { FaListUl } from 'react-icons/fa'
import { EditPenBtn, NextBtn, PrevBtn } from '../../../../widgets/AppBtn'
import { useLanguage } from "../../../../widgets/LangContext";

const LeaveTypeListView = ({ onAcaListData, fetchAcademicsListView, setOnEditID }) => {
  const { language } = useLanguage();
  return (
    <Fragment>
      <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
        <ComponentHeading icon={FaListUl} label={language ? "Leave Type List" : "छोड्ने प्रकारको सूची"} />
        <div className="relative overflow-x-auto">
          <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
            <thead className="text-[12px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
              <tr>
                <th scope="col" align='center' className="px-2 py-2 border dark:border-slate600 w-10">
                  {language ? "S.N." : "क्र.सं."}
                </th>
                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                  {language ? "Leave Type Name" : "छोड्ने प्रकार नाम"}
                </th>
                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                  {language ? "Leave Type (Nepali)" : "छोड्ने प्रकार नाम (नेपाली)"}
                </th>
                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                  {language ? "Action " : "कार्य"}
                </th>
              </tr>
            </thead>
            <tbody className='text-slate-800'>
              {Array.isArray(onAcaListData?.results) &&
                onAcaListData?.results?.length > 0 ? (onAcaListData?.results?.map((cls, index) => (
                  <tr key={index} className="odd:bg-white text-[12px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                    <td align='center' className="px-2 border dark:border-slate600  ">
                      {index + 1}.
                    </td>
                    <td className="px-2 border dark:border-slate600 capitalize">
                      {cls?.leave_type_name}
                    </td>
                    <td align='left' className="px-2 border dark:border-slate600 ">
                      {cls?.leave_type_name_nep}
                    </td>

                    <td className="px-2 border dark:border-slate600 ">
                      <EditPenBtn onClick={async () => await setOnEditID(cls?.sn)} />
                    </td>
                  </tr>
                ))) :
                <tr className="odd:bg-white text-[12px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                  <td align='center' colSpan={4} className="px-2 py-1 border dark:border-slate600  ">
                    <span>No Data Found</span>
                  </td>
                </tr>
              }
            </tbody>
          </table>

        </div>
      </div>
      <div className='flex gap-3 mt-3 justify-end'>
        <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
          if (onAcaListData?.previous) {
            await fetchAcademicsListView(onAcaListData?.previous)
          }
        }} />
        <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
          if (onAcaListData?.next) {
            await fetchAcademicsListView(onAcaListData?.next)
          }
        }} />
      </div>
    </Fragment>
  )
}

export default LeaveTypeListView