import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import HallRoomCreateView from '../../../components/exam/setup/hall_room/HallRoomCreateView';
import HallRoomListView from '../../../components/exam/setup/hall_room/HallRoomListView';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import HallRoomEditView from '../../../components/exam/setup/hall_room/HallRoomEditView';

const EmisHallRoomSetUpCreateListView = ({ setIconsButton, PageTitle }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    const [onBatchList, setOnBatchList]= useState([]);

    const StudentBatchListView = async()=>{
        try{
            await fetchDataFromApi(AppURL.ExamURL+'exam-student-batch-list-view/').then((res)=>{
                setOnBatchList(res);
            })
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        StudentBatchListView();
    },[]);
    const URLS = AppURL.ExamURL+"exam-seat-plan-hall-room-create-list-view/";
    const [onAcaListData, setOnAcaListData] = useState([]);
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const [onEditID, setOnEditID] = useState(false);

    const fetchHallRoomList = async (urls)=>{
        setOnUpdateURL(urls);

        try{
            await fetchDataFromApi(urls).then((res)=>{
                setOnAcaListData(res);
            })
        }catch(error){
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchHallRoomList(URLS);
    }
    useEffect(() => {
        FetchData();
    }, []);
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xl={3} lg={3} sm={12} xs={12} md={3}>
                    {
                        onEditID? <HallRoomEditView setOnEditID={setOnEditID} fetchHallRoomList={fetchHallRoomList} onUpdateURL={onUpdateURL} onBatchList={onBatchList} onEditID={onEditID}  /> :<HallRoomCreateView onBatchList={onBatchList} fetchHallRoomList={fetchHallRoomList} FetchData={FetchData} />
                    }
                </Grid>
                <Grid item xl={9} lg={9} sm={12} xs={12} md={9}>
                    <HallRoomListView onAcaListData={onAcaListData} fetchHallRoomList={fetchHallRoomList} setOnEditID={setOnEditID} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default EmisHallRoomSetUpCreateListView