import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';
import ReportLabel from '../../../../widgets/ReportLabel';

const MarksLedgerPdfView = () => {
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    const { marks_ledger_view } = useSelector((state) => state.exam);
    const Ledgers = marks_ledger_view?.data;
    const [students, setStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);
    useEffect(() => {
        if (Ledgers) {
            setStudents(Ledgers);
            const uniqueSubjects = [...new Set(Ledgers?.map(subject => subject?.subj_title))];
            setSubjects(uniqueSubjects);
        }
    }, [Ledgers]);

    const groupDetailsData = students.reduce((acc, student) => {
        if (!acc[student?.member_sn_id]) {
            acc[student?.member_sn_id] = {
                member_sn_id: student?.member_sn_id,
                name: student?.studname,
                roll_no: student?.roll_no,
                class: student?.class_name,
                sec: student?.sec_id,
                marks: {},
                studtotalgrade: student?.studtotalgrade,
                studtotalgpa: student?.studtotalgpa
            };
        }
        acc[student?.member_sn_id].marks[student?.subj_title] = {
            total: student?.total,
            grade: student?.totalgrade,
            thgrade: student?.thgrade,
            prgrade: student?.prgrade,
        }
        return acc;
    }, {})
    const studentList = groupDetailsData ? Object.values(groupDetailsData) : [];
    return (
        <div className=''>
            <div className='p-10 bg-white h-screen print-portrait'>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">

                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>

                            </tr>
                            <tr >
                                <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>

                                <td className="w-[25%]">
                                </td>

                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                    Date: {DateNow}
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Mark Ledger Report
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className='font-public-sans overflow-x-auto text-[11px] gap-2 flex flex-row'>
                {marks_ledger_view?.class && <ReportLabel label="Class Name: " value={marks_ledger_view?.class?.class_name} />}
                {marks_ledger_view?.batch && <ReportLabel label="Batch Name" value={marks_ledger_view?.batch?.batch_name} />}
                {marks_ledger_view?.exam_type && <ReportLabel label="Exam" value={marks_ledger_view?.exam_type?.ex_type_name} />}

            </div>
                <div className='mt-1'>
                    <table width="100%" border={1} className='whitespace-nowrap text-[10px]'>
                        <thead>
                            <tr className='bg-gray200 text-black opacity-80 dark:bg-gray900'>
                                <th rowSpan={3} align='center' className='border border-gray300 px-2 w-12'>
                                    S.N.
                                </th>
                                <th rowSpan={3} align='center' className='border border-gray300 px-2 w-16'>
                                    S.ID
                                </th>
                                <th rowSpan={3} align='center' className='border border-gray300 px-2 w-16'>
                                    Roll No.
                                </th>
                                <th rowSpan={3} className='border border-gray300 px-2'>
                                    Student Name
                                </th>
                                <th rowSpan={3} className='border border-gray300 px-2 w-24'>
                                    Section
                                </th>
                                <th className='text-center border border-gray300 px-2' colSpan={subjects?.length*2}>
                                    Subjects
                                </th>
                                <th className='text-center border border-gray300 px-2' rowSpan={3}>
                                    Grade
                                </th>
                            </tr>
                            <tr className='bg-gray200 text-black opacity-75'>
                                {subjects?.map((subject, index) => (
                                    <th key={index} colSpan={2} className='border border-gray300 px-2'>{subject}</th>
                                ))}
                            </tr>
                            <tr className='bg-gray200'>
                                {subjects.map((subject, index) => (
                                    <Fragment key={index}>
                                        <td className='border border-gray300 px-2' align='center'>Th</td>
                                        <td className='border border-gray300 px-2' align='center'>Pr</td>
                                    </Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(studentList) && studentList?.length > 0 ? (studentList?.map((student, index) => (
                                <tr key={index} className='text-gray-800'>
                                    <td align='center' className='border px-1 border-gray300'>
                                        {index + 1}.
                                    </td>
                                    <td align='center' className='border px-1 border-gray300'>{student?.member_sn_id}</td>
                                    <td align='center' className='border px-1 border-gray300'>{student?.roll_no} </td>
                                    <td className='border px-1 border-gray300'>{student?.name}</td>
                                    <td align='center' className='border px-1 border-gray300'> {student?.sec}</td>
                                    {subjects.map((subject, index) => (
                                        <Fragment key={index}>
                                            <td key={index} align='center' className={`px-1 border border-gray300 ${student?.marks[subject]?.thgrade ===null?"bg-red-500":""}`}>
                                                {student?.marks[subject] ? `${student?.marks[subject]?.thgrade ===null?"Absent":student?.marks[subject]?.thgrade}` : '-'}
                                            </td>
                                            <td key={subject} align='center' className='px-1 border border-gray300'>
                                                {student?.marks[subject] ? `${student?.marks[subject].prgrade === null ? "-" : student?.marks[subject]?.prgrade}` : '-'}
                                            </td>
                                        </Fragment>
                                    ))}
                                    <td align='center' className='border border-gray300 px-1'>{student?.studtotalgrade}</td>
                                </tr>
                            ))) :
                                <tr className='text-gray-800'>
                                    <td align='center' colSpan={20} className='border font-public-sans text-[10px] px-1 border-gray300'>
                                        No Data Found
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MarksLedgerPdfView