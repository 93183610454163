import React from 'react';

const ReportLabel = ({ label, value }) => (
    value ? (
        <>
            <div className='flex flex-row dark:text-gray500 gap-2 whitespace-nowrap'>
                <h6 className='font-semibold'>{label}:</h6>
                <h6 className='hover:underline hover:text-customPrimary cursor-pointer'>{value}</h6>
            </div>
            <h2>/</h2>
        </>
    ) : null
);

export default ReportLabel;
