import { Grid } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { staff_details_menu_list } from '../../../../widgets/json'
import StaffPersonalDetails from './widgets/StaffPersonalDetails';
import StaffCard from './widgets/StaffCard';

const EmployeeDetailsView = ({ onEmployeeDetails }) => {
  const staff_details_menu = staff_details_menu_list;
  const [activateMenu, setOnActiveMenu] = useState("1");

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
          <div className='flex-1 flex-col bg-white rounded-md w-full h-full px-3 py-3 font-public-sans shadow-md dark:bg-slate800'>
            <h2 className='font-public-sans text-center text-[18px] py-3 font-bold text-gray700 dark:text-gray300'>Staff Details</h2>
            {
              staff_details_menu?.map((item, index) => (
                <div className='group' key={index} onClick={() => setOnActiveMenu(item?.value)}>
                  <div className={`flex flex-row gap-3 px-2 py-2 hover:bg-gray-200 dark:hover:bg-slate700  cursor-pointer rounded-md ${activateMenu === item?.value ? "bg-gray-200 dark:bg-slate700" : ""}`}>
                    <div className='text-gray-800 text-[20px] my-auto dark:text-slate200'>{item?.icon}</div>
                    <div className='my-auto'>
                      <div className='text-gray-800 font-semibold text-[14px] dark:text-slate200'>{item?.label}</div>
                      <div><h5 className='text-[9px] text-gray-500'>{item?.des} </h5></div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </Grid>
        <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
          {activateMenu ==="1"&&  <StaffPersonalDetails onEmployeeDetails={onEmployeeDetails} />}
                  {/*   {activateMenu ==="2"&&  <StudentAddressDetails onStudentDetails={onStudentDetails} />}
                    {activateMenu ==="3"&&  <StudentParentDetails onStudentDetails={onStudentDetails} />} */}

        </Grid>
        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
          <StaffCard photo={onEmployeeDetails?.photo?onEmployeeDetails?.photo:""} phone={onEmployeeDetails?.phone} perAdd={onEmployeeDetails?.add_eng}  designation={onEmployeeDetails?.designations}  joining_date={onEmployeeDetails?.date_of_joining} staff_qualification={onEmployeeDetails?.qualification} member_sn={onEmployeeDetails?.member_sn} full_name={`${onEmployeeDetails?.first_name?onEmployeeDetails?.first_name:""} ${onEmployeeDetails?.middle_name?onEmployeeDetails?.middle_name:""} ${onEmployeeDetails?.last_name?onEmployeeDetails?.last_name:""}`} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default EmployeeDetailsView