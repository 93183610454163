import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import ReportLabel from '../../../../widgets/ReportLabel';

const MarksLedNumListView = () => {
  const { marks_ledger_number_list_view, marks_ledger_view } = useSelector((state) => state.exam);

  if (!marks_ledger_number_list_view || !marks_ledger_number_list_view.data || marks_ledger_number_list_view.data.length === 0) {
    return (
      <div className='bg-white p-1 rounded-md font-public-sans text-[12px] dark:bg-gray-800'>
        <p className='text-gray-500 dark:text-gray-400 text-center'>Data not found</p>
      </div>
    );
  }

  // Group students by roll number
  const groupedStudents = {};
  marks_ledger_number_list_view.data.forEach((student) => {
    const rollNo = student.roll_no;
    if (!groupedStudents[rollNo]) {
      groupedStudents[rollNo] = {
        roll_no: rollNo,
        first_name: student.first_name,
        last_name: student.last_name,
        subjects: {},
      };
    }
    groupedStudents[rollNo].subjects[student.subj_name] = student.th;
  });

  // Get all unique subjects
  const subjects = Object.keys(
    marks_ledger_number_list_view.data.reduce((acc, student) => {
      acc[student.subj_name] = true;
      return acc;
    }, {})
  );

  return (
    <Fragment>
       <div className='-mt-2.5 mb-2 font-public-sans overflow-x-auto text-[12px] gap-2 flex flex-row'>
                {marks_ledger_view?.class && <ReportLabel label="Class Name: " value={marks_ledger_view?.class} />}
                {marks_ledger_view?.batch && <ReportLabel label="Batch Name" value={marks_ledger_view?.batch} />}
                {marks_ledger_view?.exam_type && <ReportLabel label="Exam" value={marks_ledger_view?.exam_type} />}

            </div>
    <div className='bg-white p-1 rounded-md font-public-sans text-[12px] dark:bg-gray800'>
      <div className='overflow-x-auto overflow-auto h-[78vh]'>
        <table width="100%" border={1} className='whitespace-nowrap'>
          <thead className='top-0 sticky z-10'>
            <tr className='bg-slate200 text-black dark:text-gray-400 opacity-80 dark:bg-gray-900'>
              <th align='center' className='border border-gray-300 px-2 py-1 w-12'>Roll No</th>
              <th className='border border-gray-300 px-2 py-1'>Full Name</th>
              {subjects.map((subject) => (
                <th className='border border-gray-300 px-2 py-1 w-28' key={subject}>{subject}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(groupedStudents).map((student, index) => (
              <tr key={index} className='text-gray-800 dark:text-gray-600'>
                <td align='center' className='border px-1 py-1 border-gray-300'>{student.roll_no}</td>
                <td className='border px-1 py-1 border-gray-300'>{`${student.first_name ? student.first_name : ""} ${student.middle_name ? student.middle_name : ""} ${student.last_name ? student.last_name : ""}`}</td>

                {subjects.map((subject) => (
                  <td align='center' className='border px-1 py-1 border-gray-300' key={subject}>
                    {student.subjects[subject] || '-'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </Fragment>

  );
};

export default MarksLedNumListView;
