import React, { Fragment, useEffect, useRef } from 'react';
import LogBookReportListView from '../../../../components/academics/report/teach_daily_log_book/LogBookReportListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { CardPrintBtn } from '../../../../widgets/AppBtn';
import { useReactToPrint } from 'react-to-print';
import LogBookPrintView from '../../../../components/academics/report/teach_daily_log_book/LogBookPrintView';

const DailyTeacherLogBookPageListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(<CardPrintBtn onClick={handlePrintCard} bg_color={1} />);
    }, []);
    const componentListRef = useRef();
    const RenderListComponent = React.forwardRef(() => (
        <LogBookPrintView />
    ));

    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });
    return (
        <Fragment>
            <LogBookReportListView />
            <div className='hidden'>
                <div ref={componentListRef}>
                    <RenderListComponent />
                </div>
            </div>
        </Fragment>
    )
}

export default DailyTeacherLogBookPageListView