import React, { Fragment, useState } from 'react'
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../widgets/LangContext';
import { useFormik } from 'formik';
import { CustomBtn } from '../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { MediumOptions, StdClassOptions, StdSectionOptions } from '../../../widgets/Options';
import { lanugage_medium } from '../../../widgets/json';
import { useDispatch } from "react-redux";
import { Student_List_Search } from '../../../store/academicsSlice';
import { IoMdAddCircle } from 'react-icons/io';
import AssignmentFormView from './AssignmentFormView';

const SearchSubjectView = ({ onStudentOptionList }) => {
    const [onOpenForm, setOnOpenForm ] = useState(false);
    const { language } = useLanguage();
    const dispatch = useDispatch();
    const lang = lanugage_medium;
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const MediumOption = MediumOptions(lang)
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
    };

    const { errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("class_id", selectValues.class_id.value);
                formData.append("section", selectValues.section?.value);
                formData.append("medium", selectValues.medium?.value);
                try {
                    await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-search-filter-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&medium=" + selectValues?.medium?.value, formData).then((res) => {
                        dispatch(Student_List_Search(res));
                    });

                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <AssignmentFormView onOpenForm={onOpenForm} setOnOpenForm={setOnOpenForm} />
            <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>

                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Subjects" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <div className='flex justify-end mt-5'>
                                <CustomBtn type="button" icon={IoMdAddCircle} onClick={()=>setOnOpenForm(true)} label={'Add Assignment'} />
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Fragment>
    )
}

export default SearchSubjectView