import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import NepaliInputTextBox from '../../../../widgets/NepaliInputTextBox';
import { ClearBtn, SaveBtn, UpdateBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { fetchDataFromApi, putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";

const LeaveTypeEditView = ({ onEditID, setOnEditID, fetchAcademicsListView, onUpdateURL }) => {
  const { language } = useLanguage();
  const [orgStrDetails, setOnClassStrDetails] = useState({});
  const [customValues, setCustomValues] = useState({
    leave_type_name_nep: "",
  });
  
  useEffect(() => {
    try {
      if (onEditID) {
        fetchDataFromApi(AppURL.AttendanceURL + "attendace-leave-type-retrieve-update-view/" + onEditID).then((res) => {
          setOnClassStrDetails(res?.data);
          setCustomValues({
            leave_type_name_nep: res?.data?.leave_type_name_nep || "",
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [onEditID])

  
  const handleNepaliInputChange = (fieldName, value) => {
    handleChange(fieldName)(value)
    setCustomValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  };
  const initialValues = {
    leave_type_name: orgStrDetails?.leave_type_name ||"",
    leave_type_name_nep: "",
};

const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: async (values, action) => {
            const formData = new FormData();
            formData.append("leave_type_name", values.leave_type_name);
            formData.append("leave_type_name_nep", customValues.leave_type_name_nep);
            try {
                await putDataToTheServer(AppURL.AttendanceURL + "attendace-leave-type-retrieve-update-view/" + onEditID, formData).then((res) => {
                    ToastSuccess(res?.message);
                    fetchAcademicsListView(onUpdateURL)
                });
            } catch (error) {
                console.log(error);
            }
        },
    });
  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
        <ComponentHeading label={language ? "Edit Class" : "कक्षा थप्नु"} icon={IoMdAddCircle} />
        <Grid container spacing={1.5}>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="leave_type_name"
              type="text"
              label={language ? "Leave Type Name" : "छोड्ने प्रकार नाम"}
              value={values.leave_type_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              placeholder="Enter Leave Type Name"
              touched={touched.leave_type_name}
              errors={errors.leave_type_name}
              required={true}
            />
          </Grid>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <NepaliInputTextBox
              name="leave_type_name_nep"
              label={language ? "Leave Type Name (Nepali)" : "छोड्ने प्रकार नाम (नेपाली)"}
              value={customValues.leave_type_name_nep}
              onCustomValueChange={handleNepaliInputChange}
              handleBlur={handleBlur}
              touched={touched.leave_type_name_nep}
              placeholder="Enter Leave Type Name(Nepali)"
              errors={errors.leave_type_name_nep}
              required={true}
            />
          </Grid>
        </Grid>
        <div className="flex justify-end mt-3 gap-2">
          <ClearBtn onClick={async () => await setOnEditID(false)} /> <UpdateBtn type="submit" />
        </div>
      </form>
    </Fragment>
  )
}

export default LeaveTypeEditView