import { configureStore } from '@reduxjs/toolkit';
import softSlice from './softSlice';
import  academicSlice from './academicsSlice';
import accountSlice from './accountSlice';
import inventorySlice from './inventorySlice';
import librarySlice from './librarySlice';
import examSlice from './examSlide';
import attendanceSlice from './attendanceSlice';
import mobileAppSlice from './mobileAppSlice';
export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state check
    }),
  reducer: {
    software: softSlice,
    academics: academicSlice,
    accounts: accountSlice,
    inventory: inventorySlice,
    library: librarySlice,
    exam: examSlice,
    attendance: attendanceSlice,
    mobile_app: mobileAppSlice
  },
});
