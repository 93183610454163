import React, { Fragment, useState } from 'react'
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { useFormik } from 'formik';
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import DefaultSelectOptionBox from '../../../../widgets/DefaultSelectBox';
import NepaliInputTextBox from '../../../../widgets/NepaliInputTextBox';
import { ClearBtn, SaveBtn } from '../../../../widgets/AppBtn';
import { useSelector } from "react-redux";
import { AcGeneralLedgOptions, GeneralLedgerTypeOptions, YesNoOptions } from '../../../../widgets/Options';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { gl_type_list, yes_no_list } from '../../../../widgets/json';
import { ToastSuccess } from '../../../../widgets/ToastMessage';

const AcountSubLedgerCreateform = ({ selectValues, setOnSelectValuesForm, setOnOpenSubLedgCreate }) => {
    const { general_ledger_list } = useSelector((state) => state?.accounts);
    const { sub_ledg_list } = useSelector((state) => state.accounts);
    const AcGeneralLedgOption = AcGeneralLedgOptions(general_ledger_list?.data);
    const YesNoOption = YesNoOptions(yes_no_list);
    const GeneralLedgerTypeOption = GeneralLedgerTypeOptions(gl_type_list);

    const [selectValue, setOnSelectValues] = useState({
        glid: selectValues?.glid?.value || "",
        diff_ac_on: "",
        status: "",
        gl_type_cat: "",
        show_det_mode: "",
        mem_cate_id: "1"
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));

    };
    const [customValues, setCustomValues] = useState({
        sub_ledg_name_nep: "",
    });
    const handleNepaliInputChange = (fieldName, value) => {
        handleChange(fieldName)(value)
        setCustomValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));

    };
    const initialValues = {
        sub_ledg_name: "",
        sub_ledg_name_nep: "",
        glid: selectValues.glid.value || "",
        diff_ac_on: "",
        sub_ledg_id: sub_ledg_list?.data?.account_id || "",
        sub_account_sn: sub_ledg_list?.data?.sub_account_sn || "",
        status: "",
        mem_cate_id: "1",
        show_det_mode: "",
        gl_type_cat: "",
        branch_id: "",
    };
    const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("sub_ledg_name", values.sub_ledg_name);
                formData.append("sub_ledg_name_nep", customValues.sub_ledg_name_nep);
                formData.append("glid", values.glid);
                formData.append("sub_ledg_id", sub_ledg_list?.data?.sub_account_sn || "1");
                formData.append("diff_ac_on", selectValue.diff_ac_on.value);
                formData.append("status", selectValue.status.value);
                if (selectValue.mem_cate_id.value) {
                    formData.append("mem_cate_id", selectValue.mem_cate_id.value);
                }
                // if (values.DepRate) {
                //     formData.append("DepRate", values.DepRate);
                // }
                formData.append("show_det_mode", selectValue.show_det_mode.value);
                formData.append("gl_type_cat", selectValue.gl_type_cat.value);
                formData.append("branch_id", 1);
                try {
                    await postDataToTheServer(AppURL.AccountsURL + "account-sub-ledger-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        setOnOpenSubLedgCreate(false);
                        setOnSelectValuesForm(
                            (prevValues) => ({
                                ...prevValues,
                                glid: ""
                            }));
                        setOnSelectValues({
                            glid: "",
                            diff_ac_on: "",
                            Status: "",
                            mem_cate_id: "",
                            show_det_mode: "",
                            gl_type_cat: "",
                        });
                        setCustomValues({
                            SubLedgNameNep: ""
                        });
                        action.resetForm();
                    });
                } catch (error) {

                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1} className="border bg-gray-50 mb-3 rounded p-1 px-2 pb-3">
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <InputTextBox
                            name="glid"
                            type="text"
                            label="Account ID"
                            value={values.glid}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.glid}
                            errors={errors.glid}
                            disabled={true}
                            required={false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <InputTextBox
                            name="sub_ledg_id"
                            type="text"
                            label="Account SN"
                            value={values.sub_ledg_id}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.sub_ledg_id}
                            errors={errors.sub_ledg_id}
                            disabled={true}
                            required={false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <InputTextBox
                            name="sub_account_sn"
                            type="text"
                            label="Sub Account ID"
                            value={values.sub_account_sn}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.sub_account_sn}
                            errors={errors.sub_account_sn}
                            disabled={true}
                            required={false}
                        />
                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <DefaultSelectOptionBox label="General Ledger" value={selectValue.glid} name="glid" options={AcGeneralLedgOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.glid} errors={errors.glid} disabled={true} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <InputTextBox
                            name="sub_ledg_name"
                            type="text"
                            label=" Sub Leadger Name "
                            value={values.sub_ledg_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.sub_ledg_name}
                            errors={errors.sub_ledg_name}
                            disabled={false}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <NepaliInputTextBox
                            name="sub_ledg_name_nep"
                            label=" Sub Leadger Name (Nepali)"
                            value={customValues.sub_ledg_name_nep}
                            onCustomValueChange={handleNepaliInputChange}
                            handleBlur={handleBlur}
                            touched={touched.sub_ledg_name_nep}
                            errors={errors.sub_ledg_name_nep}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <SelectOptionBox label="General Leadger Category" value={selectValue.gl_type_cat} name="gl_type_cat" options={GeneralLedgerTypeOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.gl_type_cat} errors={errors.gl_type_cat} required={true} />

                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <SelectOptionBox label="Different A/C ON" value={selectValue.diff_ac_on} name="diff_ac_on" options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.diff_ac_on} errors={errors.diff_ac_on} required={true} />
                    </Grid>

                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <SelectOptionBox label="Show Details Model" value={selectValue.show_det_mode} name="show_det_mode" options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.show_det_mode} errors={errors.show_det_mode} required={true} />

                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <SelectOptionBox label="Show Student List" value={selectValue.mem_cate_id} name="mem_cate_id" options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.mem_cate_id} errors={errors.mem_cate_id} required={true} />

                    </Grid>
                    <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                        <SelectOptionBox label="Status " value={selectValue.status} name="status" options={YesNoOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.status} errors={errors.status} required={true} />
                    </Grid>
                    {
                        selectValues.glid.value === "130" && <Grid item xl={4} sm={6} md={4} lg={4} xs={12}>
                            <InputTextBox
                                name="DepRate"
                                type="text"
                                label="Dep Rate"
                                value={values.DepRate}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.DepRate}
                                errors={errors.DepRate}
                                required={false}
                            />
                        </Grid>
                    }

                </Grid>
                <div className="flex justify-end mt-3 gap-3">
                    <ClearBtn onClick={() => setOnOpenSubLedgCreate(false)} />  <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default AcountSubLedgerCreateform