import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import AdmissionRequestListView from '../../../../components/academics/create/admission_request/AdmissionRequestListView';

const AcaAdmissionRequestCreateListView = ({PageTitle, setIconsButton}) => {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(pageTitle({ "title": PageTitle }))
      document.title = `Nexapp - ${PageTitle}`;
      setIconsButton(null);
  }, []);
  return (
    <Fragment>
      <AdmissionRequestListView />
    </Fragment>
  )
}

export default AcaAdmissionRequestCreateListView