import React, { Fragment, useEffect } from 'react';
import AssignStudentSubjectListView from '../../../../components/exam/report/assign_sub_rpt/AssignStudentSubjectListView';
import { fetchDataFromApi } from '../../../../widgets/api';
import { AssignSubjectReportList } from '../../../../store/examSlide';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';

const ExamAssignSubjectReportListView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
    const {assign_subject_report_url} = useSelector((state)=>state.exam);
    const fetchViewDataList = async()=>{
        try {
            await fetchDataFromApi(assign_subject_report_url).then((res) => {
                dispatch(AssignSubjectReportList(res));
            });
        } catch (error) {
            console.log(error);
        }
    }
  return (
    <Fragment>
        <AssignStudentSubjectListView fetchViewDataList={fetchViewDataList} />
    </Fragment>
  )
}

export default ExamAssignSubjectReportListView