import Select from "react-select";
import React, { Fragment } from "react";
import { FormHelperText } from "@mui/material";
import { SelectErrorTheme, SelectSuccessTheme } from "./ThemeSelectBox";
const DefaultSelectOptionBox = ({
    name,
    required,
    label,
    value,
    options,
    disabled,
    optionSelect,
    handleBlur,
    touched,
    errors
}) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: "silver",
            border: state.isFocused ? "2px solid green" : errors || (errors && touched) ? "1.5px solid red" : "1px solid silver",
            height: "30px",
            fontFamily: 'Public Sans, sans-serif',
            minHeight: "30px",
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: "30px",
            bottom: "3.5px",
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: "15px",
            fontWeight: 600,
            fontFamily: 'Public Sans, sans-serif'
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            marginTop: "0px",
            fontSize: '12px',
            fontFamily: 'Public Sans, sans-serif',
            color: "gray",
            opacity: ".5",
            userSelect: "none",
            overflow: "hidden"

        }),
        option: (provided, state) => ({
            ...provided,
            zIndex: "99999",
            position: "sticky",
            paddingLeft: '10px',
            paddingRight: '6px',
            paddingTop: '6px',
            paddingBottom: '6px',
            fontSize: '12px',
            fontFamily: 'Public Sans, sans-serif',
        }),
    };
    return (<>
        {label ? <div className='flex flex-row'><label className="block text-[12px] font-medium mb-1 text-gray700 dark:text-gray-200 font-public-sans" htmlFor={name}>{label}</label>{required ? <span className='text-red-500 pl-2'>*</span> : ""}</div> : ""}
        {options && options.length > 0 ? (
            <Select
                placeholder={false}
                onChange={(selectedOption) => {
                    optionSelect(name, selectedOption);
                }}
                styles={customStyles}
                value={
                    options
                        ? options?.find(
                            (options) =>
                                options?.value === value?.toString()
                        )
                        : ""
                }
                theme={
                    errors && touched
                        ? SelectErrorTheme
                        : SelectSuccessTheme
                }
                classNamePrefix="my-react-select"
                openMenuOnFocus
                isSearchable={true}
                isDisabled={disabled || false}
                options={options}
                name={name}
                classNames={{
                    control: (state) =>
                        state.isFocused ? 'border-red-600' : 'border-grey-300 ',
                }}
                handleBlur={handleBlur}
                className={`font-public-sans text-gray-600 font-medium text-[13px] my-react-select-container
                ${errors && touched
                        ? "text-red-600"
                        : ""
                    }
                `}
                isLoading={false}
            />) : (
            <Fragment>


            </Fragment>
        )}
        <FormHelperText>
            {errors && touched && (
                <span className="text-red-600 font-public-sans text-[10px] font-normal">
                    {errors}
                </span>
            )}
        </FormHelperText>
    </>
    );
};

export default DefaultSelectOptionBox;
