import { createSlice } from '@reduxjs/toolkit';

export const mobileAppSlice = createSlice({
    name: 'mobile_app',
    initialState: {
        homework_class_sec_medium_list: {},
       
    },
    reducers: {
        HomeworkClassSecMediumList: (state, action) => {
            state.homework_class_sec_medium_list = action.payload;
        },
        
    }
});

// Action creators are generated for each case reducer function
export const { HomeworkClassSecMediumList } = mobileAppSlice.actions
export default mobileAppSlice.reducer