import React, { Fragment } from 'react';
import MarksheetStudentSearchFormView from '../../../../components/exam/report/marksheet/MarksheetStudentSearchFormView';

const ExamMarksheetFormView = () => {
    return (
        <Fragment>
            <MarksheetStudentSearchFormView />
        </Fragment>
    )
}

export default ExamMarksheetFormView