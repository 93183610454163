import React, { Fragment, useEffect, useState } from 'react';
import LogBookReportFormView from '../../../../components/academics/report/teach_daily_log_book/LogBookReportFormView';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';

const DailyTeacherLogBookReportFormView = () => {
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
  const fetchStudentFilterOptionListView = async () => {
      try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-teacher-daily-log-book-options-list-view/").then((res) => {
              setOnStudentOptionList(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
      fetchStudentFilterOptionListView();
  }, []);
  return (
    <Fragment>
      <LogBookReportFormView onStudentOptionList={onStudentOptionList} />
    </Fragment>
  )
}

export default DailyTeacherLogBookReportFormView