import React, { Fragment } from 'react'
import SeatPlanFormView from '../../../../components/exam/report/seat_plan/SeatPlanFormView'

const ExamStudentSeatPlanFormView = () => {
    return (
        <Fragment>
            <SeatPlanFormView />
        </Fragment>
    )
}

export default ExamStudentSeatPlanFormView