import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import ResultsPublishFormView from '../../../components/mobile_app/result_publish/ResultsPublishFormView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
import ResultsPublishListView from '../../../components/mobile_app/result_publish/ResultsPublishListView';

const ExamResultsPublishedCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, [PageTitle]);

    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.MobileAppURL + "mobile-app-student-results-publish-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    const [onResultPublishList, setOnResultPublishList] = useState([]);
    const urls = AppURL.MobileAppURL + "mobile-app-student-results-publish-create-list-view/";
    const fetchResultsPublishedList = async (urls) => {
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnResultPublishList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchResultsPublishedList(urls);
    }
    useEffect(() => {
        FetchData();
    }, [])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xl={4} xs={12} md={4} lg={4} sm={12}>
                    <ResultsPublishFormView onStudentOptionList={onStudentOptionList} FetchData={FetchData} />
                </Grid>
                <Grid item xl={8} xs={12} md={8} lg={8} sm={12}>
                    <ResultsPublishListView onStudentOptionList={onStudentOptionList} FetchData={FetchData} fetchResultsPublishedList={fetchResultsPublishedList} onResultPublishList={onResultPublishList} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default ExamResultsPublishedCreateListView