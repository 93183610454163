import React, { Fragment, useEffect, useState } from 'react';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa';
import InputCheckValue from '../../../../widgets/InputCheckValue';
import { useLanguage } from '../../../../widgets/LangContext'
import { useSelector } from 'react-redux';

const ListUpgradeStudentView = ({setSelectedStudents, selectedStudents}) => {
    const { language } = useLanguage();
    const { upgrade_student_list } = useSelector((state) => state?.academics);
    const listItems = upgrade_student_list?.students;
    const handleHeaderCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const allStudentIDs = upgrade_student_list?.students?.map(
            (student) => student?.member_sn
        );
        setSelectedStudents(isChecked ? allStudentIDs : []);
    };
    useEffect(() => {
    }, [selectedStudents]);

    const handleCheckboxChange = (event, studentID) => {
        if (event.target.checked) {
            setSelectedStudents((prevSelected) => [...prevSelected, studentID]);
        } else {
            setSelectedStudents((prevSelected) =>
                prevSelected?.filter((id) => id !== studentID)
            );
        }
    };

    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Student List" : "टिमको सूची"} />
                <div className="relative overflow-x-scroll">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray600  capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                    <InputCheckValue value={
                                        selectedStudents?.length ===
                                        upgrade_student_list?.students?.length
                                    }
                                        handleChange={handleHeaderCheckboxChange} />
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Full Name" : "पुरा नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Full Name(Nepali)" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-12">
                                    {language ? "Roll No" : "रोल नं"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Phone" : "फोन"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "DOB" : "DOB"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Gender" : "लिङ्ग"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Address" : "ठेगाना"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Father Name" : "बुबाको नाम"}
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(listItems) && listItems.length > 0 ? (
                                listItems.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            <InputCheckValue value={selectedStudents?.includes(
                                                cls?.member_sn
                                            )}
                                                handleChange={(event) =>
                                                    handleCheckboxChange(
                                                        event,
                                                        cls?.member_sn
                                                    )
                                                } />
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 border-gray300 font-medium capitalize">
                                            {`${cls?.first_name} ${cls?.middle_name ? cls?.middle_name : ""} ${cls?.last_name}`}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.name_nep}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.roll_no}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.phone}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.dobn}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.gender === 2 ? "Male" : cls?.gender === 1 ? "Female" : "Others"}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.add_eng}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.father_name}
                                        </td>

                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    Student not found.
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>

            </div>
        </Fragment>
    )
}

export default ListUpgradeStudentView