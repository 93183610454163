import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from '@mui/material';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SidebarReportClose } from '../../../../store/softSlice';
import { CustomOptions, VehicleOptions } from '../../../../widgets/Options';
import { vehicle_rpt_type } from '../../../../widgets/json';
import { VehicleRoutineRpt } from '../../../../store/academicsSlice';

const VehicleRoutineFormView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const [onVehileNum, setOnVehileNum] = useState([])
    const fetchDataBalanceSheet = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-school-vehicle-create-list-view/").then((res) => {
                setOnVehileNum(res);
            });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchDataBalanceSheet();
    }, []);
    const VehicleOption = VehicleOptions(onVehileNum?.results);
    const ReportTypeOption = CustomOptions(vehicle_rpt_type);
    const [selectValues, setOnSelectValues] = useState({
        rpt_type: "",
        vehicle_number: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };

    const initialValues = {
        vehicle_number: "",
        rpt_type: "",
    }
    const { handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.AcademicsURL + "academics-school-vehicle-routine-report-list-view/?vehicle_number=" + selectValues?.vehicle_number?.value + "&rpt_type=" + selectValues?.rpt_type?.value).then((res) => {
                        dispatch(VehicleRoutineRpt(res));
                        CloseDispatch();
                        navigate("/academics-vehicle-routine-report-page-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });


    return (
        <div className=''>
            <ReportHeading heading="Vehicle Routine Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="vehicle_number" label="Vehicle Number" options={VehicleOption} value={selectValues.vehicle_number} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.vehicle_number} errors={errors.vehicle_number} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="rpt_type" label="Report Type" options={ReportTypeOption} value={selectValues.rpt_type} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.rpt_type} errors={errors.rpt_type} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-8'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}
export default VehicleRoutineFormView