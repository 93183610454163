import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import GenerateSelectOptionsFormView from '../../../../components/academics/create/roll_no_generate/GenerateSelectOptionsFormView';

const AcaRollNoGenerateCreateListView = ({ setIconsButton, PageTitle }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }));
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL + "exam-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    return (
        <Fragment>
            <GenerateSelectOptionsFormView onStudentOptionList={onStudentOptionList} />
        </Fragment>
    )
}

export default AcaRollNoGenerateCreateListView