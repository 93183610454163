import React from 'react';

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import AcountSubLedgerCreateform from '../../../../components/account/setup/sub_ledger/AcountSubLedgerCreateform';

const AccountSubLedgerCreateView = ({ setOnSelectValues, setOnOpenSubLedgCreate, selectValues, openSubLedgCreate }) => {
    const handleOpen = () => setOnOpenSubLedgCreate(false);
    return (
        <Dialog className='ml-[100px]' open={openSubLedgCreate} handler={handleOpen}>
            <DialogHeader className='font-public-sans font-semibold text-[17px]'>Create Sub Ledger</DialogHeader>
            <DialogBody>
                <AcountSubLedgerCreateform  setOnSelectValuesForm={setOnSelectValues} setOnOpenSubLedgCreate={setOnOpenSubLedgCreate} selectValues={selectValues} />
            </DialogBody>

        </Dialog>
    )
}

export default AccountSubLedgerCreateView