import React, { useState, useEffect } from 'react';
import { GiTireIronCross } from "react-icons/gi";
import { Link } from 'react-router-dom';

const SubjectBoxCreateView = ({ subject_name, sn }) => {
  const [trimmedSubjectName, setTrimmedSubjectName] = useState('');

  const trimStringData = (stringLabel, len) => {
    if (stringLabel.length > len) {
      return stringLabel.substring(0, len);
    }
    return stringLabel;
  };

  useEffect(() => {
    const trimmedName = trimStringData(subject_name, 15);
    setTrimmedSubjectName(trimmedName);
  }, [subject_name]);

  return (
    <Link to={`/subject-homework-create-list-view/${sn}`}>
      <div className={`bg-red-600  px-2 py-5 rounded-md cursor-pointer items-center flex justify-center`}>
        <div className='flex flex-col justify-center'>
          <div className="relative inline-flex items-center justify-center w-6 h-6 mx-auto overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span className="font-medium text-gray-600 dark:text-gray-300">
              <GiTireIronCross />
            </span>
          </div>
          <h1 className='text-white mt-1.5 font-public-sans text-[12px]'>{trimmedSubjectName}</h1>
        </div>
      </div>
    </Link>
  );
};

export default SubjectBoxCreateView;
