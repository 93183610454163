import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import HomeworkCreateFormView from '../../../components/mobile_app/homework/HomeworkCreateFormView';
import HomeworkListView from '../../../components/mobile_app/homework/HomeworkListView';

const SubjectHomeworkCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, []);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item lg={4} xl={4} md={4} xs={12} sm={12}>
          <HomeworkCreateFormView />
        </Grid>
        <Grid item lg={8} xl={8} md={8} xs={12} sm={12}>
          <HomeworkListView />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default SubjectHomeworkCreateListView