
class AppURL {
    static BaseURL = "https://schooldemo.prefacetechnology.com.np/";
    static URL = "https://schooldemo.prefacetechnology.com.np/";
    static ApplicationURL = this.URL + "nexapp-school-app/";
    static AcademicsURL = this.URL + "nexapp-school-academics/";
    static AccountsURL = this.URL + "nexapp-school-accounts/";
    static InventoryURL = this.URL + "nexapp-school-inventory/";
    static HR_URL = this.URL + "nexapp-school-hr/";
    static LibraryURL = this.URL + "nexapp-school-library/";
    static ExamURL = this.URL + "nexapp-school-exam/";
    static AttendanceURL = this.URL + "nexapp-school-attendance/";
    static MobileAppURL = this.URL + "nexapp-school-mobile-app/";
    static TeacherAppURL = this.URL + "nexapp-school-teacher-mobile-app/";
}
export default AppURL

