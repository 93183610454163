import InventorySalesReportListView from "../../components/inventory/sales_rpt/SalesRptForm";
import AcaClassRoutineFormView from "../../views/academics/report/cls_routine/AcaClassRoutineFormView";
import DailyTeacherLogBookReportFormView from "../../views/academics/report/teach_daily_log_book/DailyTeacherLogBookReportFormView";
import VehicleRoutineReportFormView from "../../views/academics/report/vehicle_rpt/VehicleRoutineReportFormView";
import WeeklyLessionPlanReportFormView from "../../views/academics/report/weekly_lession_plan/WeeklyLessionPlanReportFormView";
import AccountLedgerReportViewView from "../../views/account/report/ac_ledger/AccountLedgerReportViewView";
import AccountBalanceSheetRptFormView from "../../views/account/report/balance_sheet/AccountBalanceSheetRptFormView";
import AccountDayBookFormView from "../../views/account/report/day_book/AccountDayBookFormView";
import StudentDuesReportFromListView from "../../views/account/report/dues_rpt/StudentDuesReportFromListView";
import FeeMappingReportFormView from "../../views/account/report/fee_mapping_rpt/FeeMappingReportFormView";
import AccountProfitLossReportFormView from "../../views/account/report/profit_loss_rpt/AccountProfitLossReportFormView";
import TransactionReportCreateFormView from "../../views/account/report/tran_rpt/TransactionReportCreateFormView";
import AccountTrialReportFormView from "../../views/account/report/trial_rpt/AccountTrialReportFormView";
import AccountVoucherReportForm from "../../views/account/report/voucher_rpt/AccountVoucherReportForm";
import AttStudentAttendanceFormView from "../../views/attendance/reports/std_attendance_rpt/AttStudentAttendanceFormView";
import AssignSubjectReportFormView from "../../views/exam/reports/assign_sub_rpt/AssignSubjectReportFormView";
import ExamStudentSeatPlanFormView from "../../views/exam/reports/exam_seat_plan/ExamStudentSeatPlanFormView";
import MarksEvaluationReportFormView from "../../views/exam/reports/marks_evaluation_rpt/MarksEvaluationReportFormView";
import MarksLedgerNumberFormView from "../../views/exam/reports/marks_ledg_num/MarksLedgerNumberFormView";
import MarksLedgerCreateFormView from "../../views/exam/reports/marks_ledger/MarksLedgerCreateFormView";
import ExamMarksEntryFormView from "../../views/exam/reports/marks_rpt/ExamMarksEntryFormView";
import ExamMarksheetFormView from "../../views/exam/reports/marksheet/ExamMarksheetFormView";
import ExamSubjectListFormView from "../../views/exam/reports/subj_list/ExamSubjectListFormView";
import IssueReportFormView from "../../views/inventory/report/issue_rpt/IssueReportFormView";
import PurchaseReportFormView from "../../views/inventory/report/purchase_rpt/PurchaseReportFormView";
import StockReportFormView from "../../views/inventory/report/stock_rpt/StockReportFormView";
import InventoryIssueReturnReportFrom from "../../views/library/report/issue_rtn_rpt/InventoryIssueReturnReportFrom";

const ImportDrawerCom = (route) => {
    switch (route) {
        case 'InventorySalesReportListView':
            return <InventorySalesReportListView />;
        case 'PurchaseReportFormView':
            return <PurchaseReportFormView />;
        case 'IssueReportFormView':
            return <IssueReportFormView />
        case 'StockReportFormView':
            return <StockReportFormView />
        case 'InventoryIssueReturnReportFrom':
            return <InventoryIssueReturnReportFrom />;
        case 'AccountVoucherReportForm':
            return <AccountVoucherReportForm />;
        case 'AccountTrialReportFormView':
            return <AccountTrialReportFormView />;
        case 'AccountProfitLossReportFormView':
            return <AccountProfitLossReportFormView />
        case 'AccountBalanceSheetRptFormView':
            return <AccountBalanceSheetRptFormView />
        case 'ExamSubjectListFormView':
            return <ExamSubjectListFormView />;
        case 'ExamMarksEntryFormView':
            return <ExamMarksEntryFormView />;
        case 'AccountDayBookFormView':
            return <AccountDayBookFormView />;
        case 'VehicleRoutineReportFormView':
            return <VehicleRoutineReportFormView />;
        case 'AcaClassRoutineFormView':
            return <AcaClassRoutineFormView />;
        case 'ExamStudentSeatPlanFormView':
            return <ExamStudentSeatPlanFormView />;
        case 'MarksLedgerCreateFormView':
            return <MarksLedgerCreateFormView />;
        case 'ExamMarksheetFormView':
            return <ExamMarksheetFormView />;
        case 'AttStudentAttendanceFormView':
            return <AttStudentAttendanceFormView />;
        case 'TransactionReportCreateFormView':
            return <TransactionReportCreateFormView />;
        case 'DailyTeacherLogBookReportFormView':
            return <DailyTeacherLogBookReportFormView />;
        case 'WeeklyLessionPlanReportFormView':
            return <WeeklyLessionPlanReportFormView />;
        case 'MarksEvaluationReportFormView':
            return <MarksEvaluationReportFormView />;
        case 'MarksLedgerNumberFormView':
            return <MarksLedgerNumberFormView />;
        case 'AssignSubjectReportFormView':
            return <AssignSubjectReportFormView />;
        case 'FeeMappingReportFormView':
            return <FeeMappingReportFormView />;
        case 'AccountLedgerReportViewView':
            return <AccountLedgerReportViewView />;
        case 'StudentDuesReportFromListView':
            return <StudentDuesReportFromListView />;
        default:
            return null;
    }
};
export default ImportDrawerCom