import React, { useEffect } from 'react';
import StudentAttendanceListRptView from '../../../../components/attendance/report/std_attend_report/StudentAttendanceListRptView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';

const StdAttendanceReportListViewPage = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, []);
  return (
    <StudentAttendanceListRptView />
  )
}

export default StdAttendanceReportListViewPage