import React, { Fragment } from 'react';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa';
import { useLanguage } from '../../../../widgets/LangContext';
import DefaultPhoto from '../../../../assets/img/img/default-img.png';
import { putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { NextBtn, PrevBtn } from '../../../../widgets/AppBtn';
import { MoneyFormat } from '../../../../widgets/NumberFormat';

const PhotoUploadTableView = ({ onEmployeeList, FetchData, fetchAcademicsListView }) => {
    const { language } = useLanguage();
    const handleFileChange = async (event, member_sn) => {
        const files = event.target.files;
        const file = files[0];
        if (file) {
            const formData = new FormData();
            formData.append('photo', file);
            try {
                await putDataToTheServer(AppURL.AcademicsURL + "academics-student-photo-retrieve-update-view/" + member_sn, formData).then((res) => {
                    ToastSuccess(res?.message);
                    FetchData();
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            console.warn('No file selected for upload.');
        }
    };
    return (
        <Fragment>
            <div className='bg-white p-4 rounded-md dark:bg-gray800'>
                <ComponentHeading icon={FaListUl} label={language ? "Employee List" : "कर्मचारीको सूची"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray200  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-2 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Emp. Name" : "कर्मचारीको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Designation" : "कर्मचारीको नाम (नेपाली)"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "DOB" : "जन्म मिति"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Phone" : "फोन नं"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Father Name" : "बुबाको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Permanent Address" : "स्थाई ठेगाना"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Initial Salary" : "प्रारम्भिक तलब"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Photo Upload" : "फोटो अपलोडहरू"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onEmployeeList?.results) &&
                                onEmployeeList?.results?.map((item, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray-700 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border dark:border-slate600 py-1">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 capitalize">
                                            <div className='flex gap-2'>
                                                <img src={item?.photo ? item?.photo : DefaultPhoto} className='rounded-full w-4 h-4' alt="" />  <span className='my-auto'>{`${item?.first_name ? item?.first_name : ""} ${item?.middle_name ? item?.middle_name : ""}  ${item?.last_name ? item?.last_name : ""}`}</span>
                                            </div>
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.designations || '-'}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.dobn || '-'}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.phone || '-'}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.father_name || '-'}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.add_eng || '-'}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.initail_salary ? MoneyFormat(item?.initail_salary) : MoneyFormat(0)}
                                        </td>
                                        <td className="px-2 border dark:border-slate600">
                                            <input type="file" className='text-[11px]' onChange={(event) => handleFileChange(event, item?.member_sn)} accept="image/jpeg, image/png" />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onEmployeeList?.previous === null ? true : false} onClick={async () => {
                    if (onEmployeeList?.previous) {
                        await fetchAcademicsListView(onEmployeeList?.previous)
                    }
                }} />
                <NextBtn disabled={onEmployeeList?.next === null ? true : false} onClick={async () => {
                    if (onEmployeeList?.next) {
                        await fetchAcademicsListView(onEmployeeList?.next)
                    }
                }} />
            </div>
        </Fragment>
    )
}

export default PhotoUploadTableView