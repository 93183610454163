import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from '@mui/material';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SidebarReportClose } from '../../../../store/softSlice';
import { StdClassOptions, SubjectListOptions, TeacherOptions } from '../../../../widgets/Options';
import { TeacherDailyDayBookSeachForm, TeacherWeeklyLessionPlanReportList } from '../../../../store/academicsSlice';
import { useLanguage } from '../../../../widgets/LangContext';
import InputNumber from '../../../../widgets/InputNumber';
import { BSToADFormat } from '../../../../widgets/DateFormat';

const WeeklyLessionPlanFormView = ({ onStudentOptionList }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StaffOption = TeacherOptions(onStudentOptionList?.teachers);
    const [selectValues, setOnSelectValues] = useState({
        member_sn: "",
        class_id: "",
        subjects: ""
    });

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const [subList, setOnSubList] = useState([]);
    const FilterListView = async (id) => {
        if (selectValues?.class_id) {
            try {
                await fetchDataFromApi(AppURL.AcademicsURL + "academics-class-routine-options-list-view/?class_id=" + id).then((res) => {
                    setOnSubList(res);
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        FilterListView(selectValues?.class_id?.value);
    }, [selectValues?.class_id]);

    const SubjectListOption = SubjectListOptions(subList?.subjects);
    const initialValues = {
        date_from: "",
        date_to: "",
        member_sn: ""
    };

    const { handleChange, handleBlur, isSubmitting, handleSubmit, values, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const apiUrl = AppURL.AcademicsURL + "academics-teacher-weekly-lession-plan-reports-list-view/";

                // Construct query parameters
                const queryParams = {};

                if (selectValues.class_id?.value) {
                    queryParams.class_id = selectValues.class_id.value;
                }
                if (selectValues.subjects?.value) {
                    queryParams.subjects = selectValues.subjects.value;
                }
                if (selectValues.teachers?.value) {
                    queryParams.member_sn = selectValues.teachers.value;
                }
                if (values.date_to) {
                    queryParams.date_to = BSToADFormat(values.date_to);
                }
                if (values.date_from) {
                    queryParams.date_from = BSToADFormat(values.date_from);
                }
                const queryString = new URLSearchParams(queryParams).toString();
                const fullUrl = apiUrl + (queryString ? `?${queryString}` : '');
                try {
                    const res = await fetchDataFromApi(fullUrl);
                    dispatch(TeacherWeeklyLessionPlanReportList(res));
                    dispatch(TeacherDailyDayBookSeachForm({
                        date_from: BSToADFormat(values.date_from),
                        date_to: BSToADFormat(values.date_to),
                        subjects: selectValues.subjects?.label,
                        class_id: selectValues.class_id?.label,
                        teacher: selectValues.teachers?.label,
                    }));
                    CloseDispatch();
                    navigate("/weekly-lession-plan-report-page-list-view");
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <div className=''>
            <ReportHeading heading="Weely Lession Plan Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Teachers" : "शिक्षकहरू"} placeholder="" options={StaffOption} value={selectValues.teachers} name="teachers" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.teachers} errors={errors.teachers} required={false} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Subjects" : "कक्षा"} placeholder="" value={selectValues.subjects} options={SubjectListOption} name="subjects" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.subjects} errors={errors.subjects} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputNumber name="date_from" value={values.date_from} handleChange={handleChange} mask="9999/99/99" required={true} label={language ? "From Date (BS)" : "मिति (BS)"} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputNumber name="date_to" value={values.date_to} handleChange={handleChange} mask="9999/99/99" required={true} label={language ? "To Date (BS)" : "मिति (BS)"} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-8'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}
export default WeeklyLessionPlanFormView