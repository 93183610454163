import React, { Fragment, useState } from 'react'
import { InputMask } from "primereact/inputmask";

const InputNumber = ({ label, name, handleChange,value, required, mask, placeholder, disabled }) => {

  return (
    <Fragment>
      <div className='block'>
        {label ? <div className='flex flex-row'><label className="block text-[12px] font-medium text-slate700 dark:text-gray-200 mb-1 font-public-sans" htmlFor={name}>{label}</label>{required ? <span className='text-red-500 pl-2'>*</span> : ""}</div> : ""}
        <InputMask onChange={handleChange} className='px-3 py-[5px] text-[12px] w-full !ring-gray-400 ring-[1px] rounded-[3px] placeholder:text-[12px] focus:!ring-green-600 focus:ring-[2px] focus:!outline-none font-public-sans' value={value} name={name} mask={mask} placeholder={placeholder} required={required} disabled={disabled} autoComplete='off' />
      </div>
    </Fragment>
  )
}

export default InputNumber