import { useFormik } from 'formik';
import React, { Fragment } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../widgets/InputTextBox';
import { SaveBtn } from '../../../widgets/AppBtn';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { useLanguage } from "../../../widgets/LangContext";

const GalleryCateCreateView = ({ FetchData }) => {
  const { language } = useLanguage();
 
  const initialValues = {
    cate_name: "",
  };
  const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("name", values.cate_name);
        try {
          await postDataToTheServer(AppURL.MobileAppURL + "gallery-category-create-list-view/", formData).then((res) => {
            ToastSuccess(res?.message);
            action.resetForm();
            FetchData();
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  return (
    <Fragment>
      <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
        <ComponentHeading label={language ? "Add Category" : "श्रेणी थप्नुहोस्"} icon={IoMdAddCircle} />
        <Grid container spacing={1.5}>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
            <InputTextBox
              name="cate_name"
              type="text"
              placeholder="Enter Category Name"
              label={language ? "Category Name" : "श्रेणी नाम"}
              value={values.cate_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.cate_name}
              errors={errors.cate_name}
              required={true}
            />
          </Grid>
        </Grid>
        <div className="flex justify-end mt-5 gap-2">
          <SaveBtn type="submit" />
        </div>
      </form>
    </Fragment>
  )
}

export default GalleryCateCreateView