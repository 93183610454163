import React, { Fragment } from 'react'
import SeatPlanListView from '../../../../components/exam/report/seat_plan/SeatPlanListView'

const ExamHallRoomReportPageListView = () => {
  return (
    <Fragment>
        <SeatPlanListView />
    </Fragment>
  )
}

export default ExamHallRoomReportPageListView