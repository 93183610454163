import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
const StudentAdmitCardPrintView = ({ student }) => {
    const { menu_list } = useSelector((state) => state?.software);
    const { std_admit_card_details_list } = useSelector((state) => state?.academics);

    
    const Org_Details = menu_list?.structure;
    return (
        <Fragment>
            <div>
                <div className='border-dashed'>
                    <div className='flex flex-row justify-between font-public-sans'>
                        <div className='text-[10px]'>
                            Reg. No: {Org_Details?.org_reg}
                        </div>
                        <div className='text-[10px]'>
                            Phone: {Org_Details?.org_phone}
                        </div>
                    </div>
                    <div className='flex flex-row gap-2 justify-center font-public-sans '>
                        <div>
                            <div className='text-[15px] font-semibold'>
                                {Org_Details?.org_name}
                            </div>
                            <div className='text-center text-[11px] font-medium'>
                                {Org_Details?.org_add}
                            </div>
                            <div className='text-center text-[13px] font-semibold'>
                                Student Admit Card
                            </div>
                            <div className='text-center text-[13px] font-semibold'>
                                {std_admit_card_details_list?.exam_center}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between border-t pt-2'>
                    <div>
                        <div className='flex flex-row gap-2'><h6 className='font-public-sans text-[12px] font-semibold'>Roll No: </h6><h6 className='font-public-sans text-[12px] font-normal'>{student?.roll_no} </h6> </div>
                        <div className='flex flex-row gap-2'><h6 className='font-public-sans text-[12px] font-semibold'>Name: </h6><h6 className='font-public-sans text-[12px] font-normal'>{`${student.first_name ? student.first_name : ""} ${student.middle_name ? student.middle_name : ""} ${student.last_name ? student.last_name : ""}`}</h6> </div>
                        <div className='flex flex-row gap-2'><h6 className='font-public-sans text-[12px] font-semibold'>Class: </h6><h6 className='font-public-sans text-[12px] font-normal'> {student?.class_name} </h6> </div>
                        <div className='flex flex-row gap-2'><h6 className='font-public-sans text-[12px] font-semibold'>Section: </h6><h6 className='font-public-sans text-[12px] font-normal'> {student?.section_name}</h6> </div>
                        <div className='flex flex-row gap-2'><h6 className='font-public-sans text-[12px] font-semibold'>Exam Time: </h6><h6 className='font-public-sans text-[12px] font-normal'>{std_admit_card_details_list?.exam_time}</h6></div>
                        <div className='flex flex-row gap-2'><h6 className='font-public-sans text-[12px] font-semibold'>Exam Date: </h6><h6 className='font-public-sans text-[12px] font-normal'>{std_admit_card_details_list?.exam_date}</h6></div>

                    </div>
                    <div>
                        <img src={student?.photo} alt="" className='h-20 rounded-md border w-20' />
                    </div>
                </div>
            </div>
        </Fragment>
        // <div style={{ border: "1px solid black", padding: "10px" }}>
        //     {/* <table className="w-full text-sm text-left rtl:text-right text-black">
        //         <tbody>
        //             <tr>
        //                 <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
        //                     Reg. No: {Org_Details?.org_reg}
        //                 </td>
        //                 <td className="w-[25%]">
        //                 </td>
        //                 <td className="w-[25%]">
        //                 </td>
        //                 <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
        //                     Phone: {Org_Details?.org_phone}
        //                 </td>
        //             </tr>
        //             <tr >
        //                 <td align='center' colSpan={6} className="w-[100%] text-black font-bold text-[15px]">
        //                     {Org_Details?.org_name}
        //                 </td>
        //             </tr>
        //             <tr >
        //                 <td align='center' colSpan={6} className="w-[100%] -mt-1 text-black font-medium text-[12px]">
        //                     {Org_Details?.org_add}
        //                 </td>

        //             </tr>
        //             <tr >
        //                 <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
        //                     Trial Report
        //                 </td>
        //                 <td className="w-[25%]">

        //                 </td>
        //             </tr>
        //         </tbody>
        //     </table> */}

        // </div>

    )
}

export default StudentAdmitCardPrintView