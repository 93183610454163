import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import { ExamTypeOptions, StdBatchOptions, StdClassOptions, StdSectionOptions, SubjectListOptions } from '../../../../widgets/Options';
import ReportHeading from '../../../../widgets/ReportHeading';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ClassWiseSubjectListView, MarksReportListOptionView, SubjectWiseMarksListView } from '../../../../store/examSlide';

const ExamMarksFormView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL + "exam-student-marks-filter-subject-exam-wise-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const StdBatchOption = StdBatchOptions(onStudentOptionList?.batch);
    const ExamTypeOption = ExamTypeOptions(onStudentOptionList?.exam_type);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        exam_type: "",
        section_id:""
    });
    const [subList, setOnSubList] = useState([]);
    const FilterListView = async (id) => {
        if (selectValues?.class_id) {
            try {
                await fetchDataFromApi(AppURL.ExamURL + "exam-subject-retrive-class-wise-view/?class_id=" + id).then((res) => {
                    setOnSubList(res);
                    dispatch(ClassWiseSubjectListView(res))
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(()=>{
        FilterListView(selectValues?.class_id?.value);
    },[selectValues?.class_id])

    const SubjectListOption = SubjectListOptions(subList?.subjects);
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };

    const initialValues = {
        fiscal_year_name: selectValues?.fiscal_year_name || "",
        from_date: '',
        to_date: '',
        branch_id: "",
    }
    const { handleChange, handleBlur, isSubmitting, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(`${AppURL.ExamURL}exam-subject-marks-class-wise-subj-retreive-view/?class_id=${selectValues?.class_id?.value}&subject_id=${selectValues?.subject_id?.value}&exam_type=${selectValues?.exam_type?.value}&section=${selectValues?.section_id?.value}&batch_id=${selectValues?.batch?.value}`).then((res) => {
                        dispatch(SubjectWiseMarksListView(res));
                        dispatch(MarksReportListOptionView({
                            class_id: selectValues?.class_id?.label,
                            subject_id: selectValues?.subject_id?.label,
                            exam_type: selectValues?.exam_type?.label,
                            section_id: selectValues?.section_id?.label,
                            batch: selectValues?.batch?.label,
                        }))
                        CloseDispatch();
                        navigate("/exam-marks-report-page-list-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <div className=''>
            <ReportHeading heading="Marks Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="class_id" label="Class" options={StdClassOption} value={selectValues.class_id} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="section_id" label="Section" options={StdSectionOpt} value={selectValues.section_id} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section_id} errors={errors.section_id} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="batch" label="Batch" options={StdBatchOption} value={selectValues.batch} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.batch} errors={errors.batch} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="exam_type" label="Exam Type" options={ExamTypeOption} value={selectValues.exam_type} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.exam_type} errors={errors.exam_type} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="subject_id" label="Subject" options={SubjectListOption} value={selectValues.subject_id} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.subject_id} errors={errors.subject_id} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ExamMarksFormView