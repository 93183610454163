import React, { Fragment } from 'react'
import StockRptForm from '../../../../components/inventory/stock_rpt/StockRptForm'

const StockReportFormView = () => {
  return (
    <Fragment>
        <StockRptForm />
    </Fragment>
  )
}

export default StockReportFormView