import React, { Fragment, useEffect } from 'react';
import TransReportListView from '../../../../components/account/report/trans_rpt/TransReportListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';

const AccountTransactionReportPageListView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, []);
  return (
    <Fragment>
        <TransReportListView />
    </Fragment>
  )
}

export default AccountTransactionReportPageListView