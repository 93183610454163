import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import MaeesageListTableView from '../../../components/mobile_app/message/MaeesageListTableView';
import MessageListFormView from '../../../components/mobile_app/message/MessageListFormView';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';

const MobileAppMessageCreateListView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, []);
    const [onMessageList, setOnMessageList] = useState({});
    const fetchMessageList = async (url) => {
        try {
            await fetchDataFromApi(url).then((res)=>{
                setOnMessageList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const fetchDataList = async () => {
        await fetchMessageList(AppURL.MobileAppURL + "teacher-message-create-list-view/");
    }
    useEffect(() => {
        fetchDataList();
    }, [])
  return (
    <Fragment>
        <Grid container spacing={2}>
            <Grid item xl={4} sm={12} xs={12} md={4} lg={4}>
                <MessageListFormView fetchDataList={fetchDataList} />
            </Grid>
            <Grid item xl={8} sm={12} xs={12} md={8} lg={8}>
                <MaeesageListTableView fetchMessageList={fetchMessageList} onMessageList={onMessageList} />
            </Grid>
        </Grid>
    </Fragment>
  )
}

export default MobileAppMessageCreateListView