import React, { Fragment, useEffect, useState } from 'react';
import { useLanguage } from '../../../../widgets/LangContext';
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { FormatDate } from '../../../../widgets/DateFormat';
import { MoneyFormat } from '../../../../widgets/NumberFormat';
import { NextBtn, PrevBtn } from '../../../../widgets/AppBtn';
import { useSelector } from 'react-redux';
import SingleVoucherList from './widgets/SingleVoucherList';
import VoucherDetailsList from './widgets/VoucherDetailsList';


const SingleVoucherListView = () => {
  const { account_ledger_report_select } = useSelector((state) => state.accounts);
  const { language } = useLanguage();
  const { menu_list } = useSelector((state) => state?.software);
  const FiscalDate = menu_list?.current_fiscal?.from_date_n;

  const DisplayLedgerList = async (urls) => {
    try {
      await fetchDataFromApi(urls).then((res) => {
        setOnLedgerListView(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const fetchData = async () => {
    await DisplayLedgerList(AppURL.AccountsURL + "account-display-ledger-create-list-view/");
  }
  const [ledgerListView, setOnLedgerListView] = useState([]);
  const DisplayLedgerPostView = async (sglsn, user_id, date_ne, mode, member_sn) => {
    const formData = new FormData();
    formData.append('sglsn', sglsn);
    formData.append('user_id', user_id);
    formData.append('fiscal_start', FiscalDate);
    formData.append('date_ne', date_ne ? FormatDate(date_ne) : "");
    formData.append('det_mode', mode);
    if (member_sn) {
      formData.append('member_sn', member_sn);
    } else {
      formData.append('member_sn', 1);
    }
    try {
      await postDataToTheServer(AppURL.AccountsURL + "account-display-ledger-create-list-view/", formData).then((res) => {
        setOnLedgerListView(res);
        fetchData();
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (account_ledger_report_select?.date_en, account_ledger_report_select?.selectValues?.sglsn) {
      console.log("asdasdasd")
      DisplayLedgerPostView(account_ledger_report_select?.selectValues?.sglsn?.value, 1, account_ledger_report_select?.date_en, account_ledger_report_select?.selectValues?.glid?.value === "90" ? 1 : 0, account_ledger_report_select?.selectValues?.member_sn?.value)
    }
  }, [account_ledger_report_select?.selectValues?.sglsn, account_ledger_report_select?.selectValues?.member_sn]);
  const [hoverIndexBG, setOnHoverIndexBG] = useState(null)
  const [onSingleVoucherPopup, setOnSingleVoucherPopup] = useState({
    open: false,
    tsn: 0
  })

  const [singleVoucherDetails, setOnSingleVoucherDetails] = useState({
    daten: "",
    sglsn: account_ledger_report_select?.sglsn?.value,
    open: false
  });
  return (
    <Fragment>
      <SingleVoucherList onSingleVoucherPopup={onSingleVoucherPopup} setOnSingleVoucherPopup={setOnSingleVoucherPopup} />
      <VoucherDetailsList singleVoucherDetails={singleVoucherDetails} setOnSingleVoucherDetails={setOnSingleVoucherDetails} />
      <div className='bg-white rounded-md p-4 h-full dark:bg-slate800 overflow-y-auto'>
        <div className="relative overflow-x-auto">
          <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
            <thead className="text-[13px] text-gray500 capitalize border-b dark:bg-slate800 dark:text-gray-400 font-public-sans">
              <tr className='bg-gray100'>
                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600 w-10">
                  {language ? "S.N." : "क्र.सं."}
                </th>
                <th scope="col" className="px-2 py-1 border dark:border-slate600">
                  {language ? "Date" : "मिति"}
                </th>
                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600">
                  {language ? "TSN" : "TSN"}
                </th>
                <th scope="col" className="px-2 py-1 border dark:border-slate600">
                  {language ? "Particular Name" : "बिबरण नाम"}
                </th>
                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600">
                  {language ? "Bill No." : "बिल नं "}
                </th>
                <th scope="col" className="px-2 py-1 border dark:border-slate600 w-20">
                  {language ? "Cr" : "क्रे."}
                </th>
                <th scope="col" className="px-2 py-1 border dark:border-slate600 w-20">
                  {language ? "Dr" : "डे."}
                </th>
                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600 w-20">
                  {language ? "SubAcBal" : "SubAcBal"}
                </th>
                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600 w-20">
                  {language ? "Dr/Cr" : "क्रे./डे."}
                </th>

              </tr>
            </thead>
            <tbody className='text-slate-800'>
              {
                Array.isArray(ledgerListView?.results) && ledgerListView?.results?.map((ledger, index) => (
                  <tr key={index}
                    onClick={() => {
                      setOnHoverIndexBG(index);
                      if (account_ledger_report_select?.selectValues?.glid?.value === "90") {
                        setOnSingleVoucherPopup({
                          open: true,
                          tsn: ledger?.tsn
                        });
                      } else {
                        setOnSingleVoucherDetails({
                          daten: ledger?.dated,
                          sglsn: account_ledger_report_select?.selectValues?.sglsn,
                          open: true
                        });
                      }
                    }}
                    className={`transition duration-150 select-none ease-in-out hover:bg-green-600 hover:text-white hover:shadow focus:bg-green-600 focus:shadow-sm focus:outline-none focus:ring-0 active:bg-green-400 active:shadow-sm motion-reduce:transition-none text-[11.5px] ${index === hoverIndexBG ? "bg-green-600 text-white" : ""} cursor-pointer text-gray-700 dark:text-gray-400 border-b font-public-sans dark:border-gray-700`}>
                    <td align='center' className="px-2 py-1 capitalize">
                      {index + 1}.
                    </td>
                    <td align='left' className="px-2 font-medium capitalize">
                      {ledger?.dated}
                    </td>
                    <td align='center' className="px-2 capitalize">
                      {ledger?.tasn}
                    </td>
                    <td align='left' className="px-2 capitalize">
                      {ledger?.particulars}
                    </td>
                    <td align='center' className="px-2 capitalize">
                      {ledger?.billrno}
                    </td>
                    <td align='left' className="px-2 capitalize">
                      {ledger?.dramt ? MoneyFormat(ledger?.dramt) : MoneyFormat(0)}
                    </td>
                    <td align='left' className="px-2 capitalize">
                      {ledger?.cramt ? MoneyFormat(ledger?.cramt) : MoneyFormat(0)}
                    </td>
                    <td align='center' className="px-2 capitalize">
                      {ledger?.subaccbal ? MoneyFormat(ledger?.subaccbal) : MoneyFormat(0)}
                    </td>
                    <td align='center' className="px-2 capitalize">
                      {ledger?.subdrcr}
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

      </div>
      {
        ledgerListView?.results ?
          <div className='flex justify-end bottom-0 sticky right-0 place-self-end mt-4'>
            <div className="gap-3 flex justify-end">
              <PrevBtn disabled={ledgerListView?.previous === null ? true : false} onClick={async () => {
                if (ledgerListView?.previous) {
                  await DisplayLedgerList(ledgerListView?.previous);
                }
              }} />
              <NextBtn disabled={ledgerListView?.next === null ? true : false} onClick={async () => {
                if (ledgerListView?.next) {
                  await DisplayLedgerList(ledgerListView?.next);
                }
              }} />
            </div>
          </div> : ""
      }
    </Fragment>
  )
}

export default SingleVoucherListView