import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import { FiscalYearOptions, StdClassOptions } from '../../../../widgets/Options';
import ReportHeading from '../../../../widgets/ReportHeading';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SubjectDetailsEditURL, SubjectListViewClassWiseList } from '../../../../store/examSlide';


const SubjectSearchListView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }

    const [classList, setOnClassList] = useState([]);
    const fetchDataBalanceSheet = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL+"exam-student-subject-class-options-list-view/").then((res)=>{
                setOnClassList(res?.class);
            })
        } catch (error) {
            console.log(error);
        }
    }
    const ClassOption =StdClassOptions(classList);
    useEffect(() => {
        fetchDataBalanceSheet();
    }, []);

    const [selectValues, setOnSelectValues] = useState({
        class_id: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };



    const initialValues = {
        fiscal_year_name: selectValues?.fiscal_year_name || "",
        from_date: '',
        to_date: '',
        branch_id: "",
    }
    const { isSubmitting, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try{
                    await fetchDataFromApi(AppURL.ExamURL+"exam-student-class-wise-subject-list-view/?class_id="+selectValues?.class_id?.value).then((res)=>{
                        dispatch(SubjectDetailsEditURL({
                            url: AppURL.ExamURL+"exam-student-class-wise-subject-list-view/?class_id="+selectValues?.class_id?.value,
                            class: selectValues?.class_id?.label
                        }));
                        dispatch(SubjectListViewClassWiseList(res));
                        CloseDispatch();
                        navigate("/exam-class-wise-subject-list-view-page");
                    });
                }catch(error){
                    console.log(error);
                }
            },
        });


    return (
        <div className=''>
            <ReportHeading heading="Subject Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="class_id" label="Class" options={ClassOption} value={selectValues.class_id} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SubjectSearchListView