import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import NotificationMessageFormView from '../../../components/mobile_app/notification/NotificationMessageFormView';
import NotificationStudentView from '../../../components/mobile_app/notification/NotificationStudentView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';

const MobileAppNotificationCreateListView = ({ setIconsButton, PageTitle }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, [PageTitle]);
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);

    const [onNotificationList, setOnNotificationList] = useState({});
    const fetchNotificationList = async (url) => {
        try {
            await fetchDataFromApi(url).then((res)=>{
              setOnNotificationList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const fetchDataList = async () => {
        await fetchNotificationList(AppURL.MobileAppURL + "mobile-app-student-notification-create-list-view/");
    }
    useEffect(() => {
        fetchDataList();
    }, [])

    
  return (
    <Fragment>

      <Grid container spacing={2}>
        <Grid item md={4} lg={4} sm={12} xs={12} xl={4}>
          <NotificationMessageFormView onStudentOptionList={onStudentOptionList} />
        </Grid>
        <Grid item md={8} lg={8} sm={12} xs={12} xl={8}>
          <NotificationStudentView fetchNotificationList={fetchNotificationList} onNotificationList={onNotificationList} />
        </Grid>
      </Grid>
      
    </Fragment>
  )
}

export default MobileAppNotificationCreateListView