import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchClassWiseSubjectListView from '../../../../components/academics/create/class_routine/SearchClassWiseSubjectListView'
import SubjectRoutineCreateListView from '../../../../components/academics/create/class_routine/SubjectRoutineCreateListView'
import { fetchDataFromApi } from '../../../../widgets/api'
import AppURL from '../../../../widgets/AppURL'
import { useDispatch } from 'react-redux'
import { pageTitle } from '../../../../store/softSlice'

const AcaRoutineCreateListView = ({PageTitle, setIconsButton}) => {
  const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
  return (
    <Grid container>
      <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
        <SearchClassWiseSubjectListView onStudentOptionList={onStudentOptionList} />
      </Grid>
      {/* <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
        <SubjectRoutineCreateListView />
      </Grid> */}
    </Grid>
  )
}

export default AcaRoutineCreateListView