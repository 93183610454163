import React from 'react'
import { Chip } from "@material-tailwind/react";
import { Autocomplete, TextField } from "@mui/material"
const AutoCompleteBox = ({ options, optionAutoSelect, handleKeyPress, handleChange, errors, label, name, value, required }) => {
    const theme = localStorage.getItem("theme");
    return (
        <div className="-mt-1">
            <label htmlFor="ConsumerID" className="mb-1 text-[12px] dark:text-gray-400 select-none text-gray-700 font-public-sans font-medium mr-2">{label}
                {required && <span className="text-red-500 ml-2">*</span>}
            </label><br />
            <Autocomplete
                disablePortal
                size='small'
                options={options}
                onChange={(event, value) => {
                    optionAutoSelect(name, value);
                }}
                className='dark:text-slate300 text-slate800'
                sx={{
                    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                        paddingTop: '3.5px',
                        paddingBottom: '3.5px',
                        border: theme === "dark" ? "1px solid white" : "",
                        backgroundColor: "white"
                    },
                    '& .MuiOutlinedInput-input': {
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        fontSize: '12px',
                        color: theme === "dark" ? "gray" : "gray",
                        fontFamily: "Public Sans, sans-serif",
                        backgroundColor: "white"
                    },
                }}
                isOptionEqualToValue={(option, value) => option.label === value?.label && option?.value === value?.value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        sx={{ fontSize: "12px", }}
                        inputProps={params?.inputProps || { fontSize: "12px" }}
                        value={value}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        className='!mt-1 dark:!text-slate200 w-full !bg-white !text-[12px] dark:bg-slate-700 rounded'
                        name={name}
                        errors={name}
                        required={required}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "13px", padding: "8px", cursor: "pointer" }} className='font-public-sans hover:bg-slate200'>
                        {option.label}
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            label={option.label}
                            {...getTagProps({ index })}
                            style={{ fontSize: "11px", margin: "2px", backgroundColor: "your-chosen-color", color: "your-text-color" }}
                        />
                    ))
                }
            />
        </div>
    )
}

export default AutoCompleteBox