import React from 'react';
import { VscWarning } from "react-icons/vsc";
import { Box, Snackbar, Alert } from '@mui/material';

const AppSnackbar = ({onLicense}) => {
    return (
        <Box sx={{ width: 500 }}>
            <Snackbar className='ml-9 sm:ml-0'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={true}
                message="I love snacks"
                key='bottomright'
            >
                <Alert icon={<VscWarning className='text-white' />} severity="success" className='text-white' sx={{ width: '100%', backgroundColor: "#ed6c02", color: "white" }}>
                    Please your license is expiring in {parseInt(onLicense)} days                       </Alert>
            </Snackbar>
        </Box>
    )
}

export default AppSnackbar