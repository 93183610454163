import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import NepaliInputTextBox from '../../../../widgets/NepaliInputTextBox';
import { ClearBtn, UpdateBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { fetchDataFromApi, putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";

const BookKindEditView = ({ onEditID, setOnEditID, fetchAcademicsListView, onUpdateURL }) => {
    const { language } = useLanguage();
    const [orgStrDetails, setOnAuthorStrDetails] = useState({});
    const [customValues, setCustomValues] = useState({
        book_cate_name_nep: ""
    });

    useEffect(() => {
        try {
            if (onEditID) {
                fetchDataFromApi(AppURL.LibraryURL + "library-book-category-retrieve-update-view/" + onEditID).then((res) => {
                    setOnAuthorStrDetails(res?.data);
                    setCustomValues({
                        book_cate_name_nep: res?.data?.book_cate_name_nep || "",
                    });

                });

            }
        } catch (error) {
            console.log(error);
        }
    }, [onEditID])

    const handleNepaliInputChange = (fieldName, value) => {
        handleChange(fieldName)(value)
        setCustomValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };
    const initialValues = {
        book_cate_name: orgStrDetails?.book_cate_name || "",
        book_cate_name_nep: "",
        remarks: orgStrDetails?.remarks || "",
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("book_cate_name", values.book_cate_name);
                formData.append("book_cate_name_nep", customValues.book_cate_name_nep);
                formData.append("remarks", values.remarks);
                try {
                    await putDataToTheServer(AppURL.LibraryURL + "library-book-category-retrieve-update-view/"+  onEditID, formData).then((res) => {
                        ToastSuccess(res?.message);
                        fetchAcademicsListView(onUpdateURL);
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Edit Book Kind" : "कक्षा थप्नु"} icon={IoMdAddCircle} />
                <Grid container spacing={2}>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="book_cate_name"
                            type="text"
                            placeholder="Enter Kind Name"
                            label={language ? "Kind Name" : "पुस्तक लेखक नाम"}
                            value={values.book_cate_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.book_cate_name}
                            errors={errors.book_cate_name}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <NepaliInputTextBox
                            name="book_cate_name_nep"
                            label={language ? "Kind Name (Nepali)" : "नाम (नेपाली)"}
                            value={customValues.book_cate_name_nep}
                            onCustomValueChange={handleNepaliInputChange}
                            handleBlur={handleBlur}
                            touched={touched.book_cate_name_nep}
                            placeholder="Enter Kind Name(Nepali)"
                            errors={errors.book_cate_name_nep}
                            required={true}
                        />
                    </Grid>

                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="remarks"
                            type="text"
                            label={language ? "Remarks" : "ठेगाना"}
                            value={values.remarks}
                            handleChange={handleChange}
                            placeholder="Enter Remarks"
                            handleBlur={handleBlur}
                            touched={touched.remarks}
                            errors={errors.remarks}
                            required={false}
                        />
                    </Grid>


                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <ClearBtn onClick={async () => await setOnEditID(false)} /> <UpdateBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default BookKindEditView