import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from "../../../../widgets/LangContext";
import { ordinalSuffix, timeAMandPMformat } from '../../../../widgets/NumberFormat';
import { CreatedDateFormat } from '../../../../widgets/DateFormat';

const LogBookReportListView = () => {
    const { language } = useLanguage();
    const { teacher_daily_day_book_list } = useSelector((state) => state.academics);
    return (
        <Fragment>
            <div className='bg-white rounded-md p-2 shadow-sm dark:bg-slate-800'>
                <div className="relative overflow-x-scroll">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray600 capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr className='bg-gray200'>

                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Period/Time" : "अवधि समय"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Class/Sec" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Chapter/Subjects" : "रोल नं"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Class Activities" : "फोन"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Home Assignment" : "DOB"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Remarks" : "लिङ्ग"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                    {language ? "Created At" : "लिङ्ग"}
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(teacher_daily_day_book_list?.results) && teacher_daily_day_book_list?.results?.length > 0 ? (
                                teacher_daily_day_book_list?.results?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 py-1 border dark:border-slate600 border-gray300 font-medium">
                                            {`${cls?.priority}`} <sup> {`${ordinalSuffix(cls?.priority)}`}</sup> / {`${cls?.start_time ? timeAMandPMformat(cls?.start_time) : ""} - ${cls?.start_time ? timeAMandPMformat(cls?.end_time) : ""}`}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.class_name}/{cls?.sec_name}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.chapter_subj}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.activities}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.homework_assignment}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.remarks}
                                        </td>
                                        <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                            {cls?.created_at ? CreatedDateFormat(cls?.created_at) : ""} {timeAMandPMformat(cls?.created_at)}
                                        </td>
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    No Data Found
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>

        </Fragment >
    );
}

export default LogBookReportListView;
