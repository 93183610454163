import { createSlice } from '@reduxjs/toolkit';

export const examSlice = createSlice({
    name: 'exam',
    initialState: {
        exam_sub_student_list: {},
        exam_marks_entry_std_details: {},
        class_wise_subj: {},
        subj_wise_marks_list: {},
        hall_room_seat_list: {},
        marks_ledger_view: {},
        marks_entry_schedule_list: {},
        class_wise_subject_list: {},
        assign_subject_report_list: {},
        assign_subject_report_url: {},
        marks_report_list_options: {}
    },
    reducers: {
        ExamStudentSubjectList: (state, action) => {
            state.exam_sub_student_list = action.payload;
        },
        MultiMarksEntryStdDetails: (state, action) => {
            state.exam_marks_entry_std_details = action.payload;
        },
        SubjectListViewClassWiseList: (state, action) => {
            state.class_wise_subj = action.payload;
        },
        SubjectWiseMarksListView: (state, action) => {
            state.subj_wise_marks_list = action.payload;
        },
        HallRoomSeatListView: (state, action) => {
            state.hall_room_seat_list = action.payload;
        },
        MarksLedgerListView: (state, action) => {
            state.marks_ledger_view = action.payload;
        },
        MarksScheduleListView: (state, action) => {
            state.marks_entry_schedule_list = action.payload;
        },
        ClassWiseSubjectListView: (state, action) => {
            state.class_wise_subject_list = action.payload;
        },
        StudentMarksLedgerNumberListView: (state, action) => {
            state.marks_ledger_number_list_view = action.payload;
        },
        ExamMarksEvaluationReportListView: (state, action) => {
            state.exam_marks_evaluation_rpt_list = action.payload;
        },
        AssignSubjectReportList: (state, action) => {
            state.assign_subject_report_list = action.payload;
        },
        AssignSubjectReportURL: (state, action) => {
            state.assign_subject_report_url = action.payload;
        },
        SubjectDetailsEditURL: (state, action) => {
            state.subject_details_url = action.payload;
        },
        MarksReportListOptionView: (state, action) => {
            state.marks_report_list_options = action.payload;
        },
     
    }
});

// Action creators are generated for each case reducer function
export const { ExamStudentSubjectList, MarksReportListOptionView, SubjectDetailsEditURL, AssignSubjectReportURL, AssignSubjectReportList, ClassWiseSubjectListView, ExamMarksEvaluationReportListView, StudentMarksLedgerNumberListView, MarksScheduleListView, MarksLedgerListView, HallRoomSeatListView, SubjectWiseMarksListView, MultiMarksEntryStdDetails, SubjectListViewClassWiseList } = examSlice.actions
export default examSlice.reducer 