import React, { Fragment } from 'react'
import DayBookFormView from '../../../../components/account/report/day_book/DayBookFormView'

const AccountDayBookFormView = () => {
  return (
    <Fragment>
        <DayBookFormView />
    </Fragment>
  )
}

export default AccountDayBookFormView