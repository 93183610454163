import React, { Fragment } from 'react'
import ComponentHeading from '../../../../widgets/ComponentHeading'
import { FaListUl } from 'react-icons/fa'
import { ActiveChip, InActiveChip } from '../../../../widgets/ChipBox'
import { EditPenBtn, NextBtn, PrevBtn } from '../../../../widgets/AppBtn'
import { useLanguage } from "../../../../widgets/LangContext";

const AppDepartmentListView = ({ onDepartmentList, fetchAcademicsListView, setOnEditID }) => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language? "Department List":"विभागको सूची" } /> 
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Department Name" : "विभागको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                      {language? "Department Name(Nepali)":"विभागको नाम (नेपाली)" }
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onDepartmentList?.results) &&
                                onDepartmentList?.results?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 capitalize">
                                            {cls?.dep_name}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {cls?.dep_name_nep}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                  
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                        <PrevBtn disabled={onDepartmentList?.previous ===null?true:false} onClick={async ()=>{
                            if(onDepartmentList?.previous){
                                await fetchAcademicsListView(onDepartmentList?.previous)
                            }
                        }}/>
                        <NextBtn disabled={onDepartmentList?.next ===null?true:false} onClick={async ()=>{
                            if(onDepartmentList?.next){
                                await fetchAcademicsListView(onDepartmentList?.next)
                            }
                        }} />
                    </div>
        </Fragment>
    )
}

export default AppDepartmentListView