import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const SeatPlanListView = () => {
   
    return (
        <Fragment>
          
        </Fragment>
    );
};

export default SeatPlanListView;
