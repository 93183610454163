import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from '@mui/material';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SidebarReportClose } from '../../../../store/softSlice';
import { MediumOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { ClassRoutineListView, ClassRoutineSearchListOption, Student_List_Search } from '../../../../store/academicsSlice';
import { useLanguage } from '../../../../widgets/LangContext';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { CgSearch } from 'react-icons/cg';

const StdPhotoUploadFormView = ({ onStudentOptionList }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const MediumOption = MediumOptions(lanugage_medium);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
    };

    const { handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-search-filter-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&medium=" + selectValues?.medium?.value).then((res) => {
                        dispatch(Student_List_Search(res));
                      });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    useEffect(()=>{
        dispatch(Student_List_Search([]));
    }, [])

    return (
        <div className='bg-white p-3 shadow rounded-md '>
                <ComponentHeading label={'Search Student'} icon={CgSearch } />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>
                        <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                            <div className='flex justify-end items-end h-full'>
                                <SearchBtn type="submit" />
                            </div>
                        </Grid>
                    </Grid>

                </form>
        </div>
    )
}
export default StdPhotoUploadFormView