import React, { Fragment, useState } from 'react'
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useLanguage } from '../../../../widgets/LangContext';
import { NextBtn, PrevBtn } from '../../../../widgets/AppBtn';
import { putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';

const MobileAppDetailsListView = ({ onEmployeeList, onUpdateURL, fetchAcademicsListView }) => {
    const { language } = useLanguage();
    const [showPasswordForStudent, setShowPasswordForStudent] = useState(null);
    const togglePasswordVisibility = (studentID) => {
        setShowPasswordForStudent(studentID);
    };
    const GenerateUsernamePassword = async (id) => {
        if (window.confirm("Are you sure you want to generate a new username and password?")) {
            try {
                await putDataToTheServer(AppURL.HR_URL + "hr-app-user-details-create-username-and-password-view/" + id).then((res) => {
                    ToastSuccess(res?.message);
                    fetchAcademicsListView(onUpdateURL);
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Employee List" : "कर्मचारीको सूची"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray200  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-2 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Emp. Name" : "कर्मचारीको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Designation" : "कर्मचारीको नाम (नेपाली)"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "DOB" : "जन्म मिति"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Phone" : "फोन नं"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Father Name" : "बुबाको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Permanent Address" : "स्थाई ठेगाना"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Username" : "प्रयोगकर्ता नाम"}
                                </th>

                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Password" : "पासवर्ड"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Generate" : "उत्पन्न "}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Action " : "कार्य"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onEmployeeList?.results) &&
                                onEmployeeList?.results?.map((item, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray-700 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border dark:border-slate600 py-1">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 capitalize">
                                            <div className='flex gap-2'>
                                                <span className='my-auto'>{`${item?.first_name ? item?.first_name : ""} ${item?.middle_name ? item?.middle_name : ""}  ${item?.last_name ? item?.last_name : ""}`}</span>
                                            </div>
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.designations}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.dobn}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.phone}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.father_name}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {item?.add_eng}
                                        </td>

                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {(item?.username ? showPasswordForStudent === item.member_sn ? item?.username : '*'.repeat(item?.username.length) : <span className='text-[10px] text-red-500 px-3 py-1 bg-red-100 rounded-md'>Generate Password</span>)}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {(item?.password ? showPasswordForStudent === item.member_sn ? item?.password : '*'.repeat(item?.password.length) : <span className='text-[10px] text-red-500 px-3 py-1 bg-red-100 rounded-md'>Generate Password</span>)}
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            <button className='bg-customPrimary px-2 text-white rounded-lg py-0.5 text-[10px] hover:bg-white hover:text-black border-customPrimary border' onClick={() => GenerateUsernamePassword(item.member_sn)}>Generate</button>
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            <button className='bg-customPrimary p-1.5 cursor-pointer !text-white rounded-full' onClick={() => togglePasswordVisibility(item.member_sn === showPasswordForStudent ? null : item.member_sn)} > {item.member_sn === showPasswordForStudent ? <FaRegEyeSlash /> : <FaRegEye />} </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onEmployeeList?.previous === null ? true : false} onClick={async () => {
                    if (onEmployeeList?.previous) {
                        await fetchAcademicsListView(onEmployeeList?.previous)
                    }
                }} />
                <NextBtn disabled={onEmployeeList?.next === null ? true : false} onClick={async () => {
                    if (onEmployeeList?.next) {
                        await fetchAcademicsListView(onEmployeeList?.next)
                    }
                }} />
            </div>

        </Fragment>
    )
}

export default MobileAppDetailsListView