import React from 'react';
import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoAddCircle } from 'react-icons/io5';
import { NepNowDateFormat } from '../../../widgets/DateFormat';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import InputTextBox from '../../../widgets/InputTextBox';
import { useLanguage } from '../../../widgets/LangContext';
import { IoMdAddCircle } from 'react-icons/io';
import { ClearBtn, CustomBtn } from '../../../widgets/AppBtn';
import TextAreaBox from '../../../widgets/TextAreaBox';
import InputCheckValue from '../../../widgets/InputCheckValue';
import InputNumber from '../../../widgets/InputNumber';

const AssignmentFormView = ({ onOpenForm, setOnOpenForm }) => {
    const { language } = useLanguage();
    const handleOpen = () => setOnOpenForm(!onOpenForm);
    const DateNp = NepNowDateFormat();
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
    };

    const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();

            },
        });
    return (
        <Dialog size='lg' className='ml-[120px] py-3' open={onOpenForm} handler={handleOpen}>
            <ComponentHeading label={'Add Assignment'} icon={IoAddCircle} />
            <DialogBody>
                <div className='flex justify-between'>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Class:
                        </div>
                        <div>
                            One
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Medium:
                        </div>
                        <div>
                            Nepali
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Section:
                        </div>
                        <div>
                            A
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Subject:
                        </div>
                        <div>
                            Nepali
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Date:
                        </div>
                        <div>
                            {DateNp}
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className='mt-3'>
                    <Grid container spacing={1}>
                        <Grid item xl={9} md={9} lg={9} xs={12} sm={12}>
                            <InputTextBox label={language ? "Assignment Title" : "कक्षा"} placeholder="Assignment Title" value={values.class_id} name="class_id" handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>

                        <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                            <InputNumber name="dobn" value={values.dobn} handleChange={handleChange} mask="9999/99/99" placeholder={'Deadline Date'} required={true} label={language ? "Deadline Date" : "जन्म मिति (BS)"} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <TextAreaBox label={language ? "Details" : "विवरणहरू"} height={10} placeholder="Details" value={values.medium} name="medium" handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <InputTextBox type={'file'} label={language ? "PDF" : "कक्षा"} placeholder="Assignment Title" value={values.class_id} name="class_id" handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>

                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12} className='my-auto'>
                            <InputCheckValue label={language ? "Published" : "सेक्सन"} placeholder="" value={values.section} name="section" handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>

                    </Grid>
                </form>
            </DialogBody>
            <DialogFooter>
                <div className='flex justify-end gap-3'>
                    <ClearBtn />
                    <CustomBtn type="button" icon={IoMdAddCircle} onClick={() => setOnOpenForm(true)} label={'Add Assignment'} />
                </div>
            </DialogFooter>
        </Dialog>
    )
}

export default AssignmentFormView