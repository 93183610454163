import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { HallRoomOptions, StdClassOptions } from '../../../../widgets/Options';

const ClassConfigureFormView = ({ FetchData }) => {
    const { language } = useLanguage();
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);

    const fetchStudentFilterOptionListView = async () => {
        try {
            const res = await fetchDataFromApi(AppURL.ExamURL + "exam-student-hall-room-seat-plan-options-list-view/");
            setOnStudentOptionList(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    const HallRoomOption = HallRoomOptions(onStudentOptionList?.hall);
    const ClassListOption = StdClassOptions(onStudentOptionList?.class);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        hall_room: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };

    const initialValues = {
        hall_room: "",
        class_id: "",
        from_roll: "",
        to_roll: "",
        order: "",
    };

    const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("hall_room", selectValues.hall_room?.value);
                formData.append("class_id", selectValues.class_id?.value);
                formData.append("from_roll", values.from_roll);
                formData.append("to_roll", values.to_roll);
                formData.append("order", 1);

                try {
                    await postDataToTheServer(AppURL.ExamURL + "exam-class-configure-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        setOnSelectValues({
                            hall_room: "",
                            class_id: "",
                        })
                        action.resetForm();
                        FetchData();
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add Class Configure" : "कक्षा कन्फिगर थप्नुहोस्"} icon={IoMdAddCircle} />
                <Grid container spacing={1.5}>

                    <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                        <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} name="class_id" options={ClassListOption} onFocus={true} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.class_id} errors={errors.class_id} required={true} />
                    </Grid>
                    <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                        <SelectOptionBox label={language ? "Hall No" : "हल नं"} placeholder="" value={selectValues.hall_room} name="hall_room" options={HallRoomOption} onFocus={true} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.hall_room} errors={errors.hall_room} required={true} />
                    </Grid>
                    <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                        <InputTextBox
                            name="from_roll"
                            type="text"
                            placeholder="Enter From Roll No"
                            label={language ? "From Roll No" : "रोल नं. बाट"}
                            value={values.from_roll}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.from_roll}
                            errors={errors.from_roll}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
                        <InputTextBox
                            name="to_roll"
                            type="text"
                            placeholder="Enter To Roll No"
                            label={language ? "To Roll No" : "रोल नम्बर को लागी"}
                            value={values.to_roll}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.to_roll}
                            errors={errors.to_roll}
                            required={true}
                        />
                    </Grid>

                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default ClassConfigureFormView