import { Grid } from '@mui/material'
import React, { Fragment } from 'react'
import SearchStudentFormVIew from './widgets/SearchStudentFormVIew'
import SearchStudentListView from './widgets/SearchStudentListView'

const StudentAttendanceAddView = ({onStudentOptionList}) => {
  return (
    <Fragment>
      <Grid container spacing={2}>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <SearchStudentFormVIew onStudentOptionList={onStudentOptionList} />
          </Grid>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <SearchStudentListView />
          </Grid>
      </Grid>
    </Fragment>
  )
}

export default StudentAttendanceAddView