import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FaAddressCard } from 'react-icons/fa';
import { useLanguage } from '../../../../widgets/LangContext';
import InputTextBox from '../../../../widgets/InputTextBox';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { VoucherContext } from '../../../../context/VoucherEntryContext';
import { useDispatch, useSelector } from 'react-redux';
import { AcGeneralLedgOptions, ParticularOptions, StdClassOptions, StudentListOptions, SubLedgersOptions, TrasactionOptions } from '../../../../widgets/Options';
import { trans_type } from '../../../../widgets/json';
import AccountParticularCreateView from '../../../../views/account/setup/particular/AccountParticularCreateView';
import AccountSubLedgerCreateView from '../../../../views/account/setup/sub_ledg/AccountSubLedgerCreateView';
import { Grid } from '@mui/material';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { Account_ledger_Report_Select_, Particular_List_AC, Sub_Ledger_List } from '../../../../store/accountSlice';
import AutoCompleteBox from '../../../../widgets/AutoCompleteBox';
import { ToastError } from '../../../../widgets/ToastMessage';
import { ADToBSConvert } from '../../../../widgets/DateFormat';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import InputNumber from '../../../../widgets/InputNumber';
import { VoucherContextReport } from '../../../../context/VoucherEntryRpt';

const HeaderLedgerForm = ({ onGeneralLedgList }) => {
    const { language } = useLanguage();
    const { setOnSelectValues, selectValues, values, handleChange, errors, touched } = useContext(VoucherContextReport);
    const { menu_list } = useSelector((state) => state?.software);
    const dispatch = useDispatch();

    const [enToNPDate, setOnEnToNPDate] = useState("");
    const [on_ledger_list, setOnLedgerList] = useState([]);
    const [on_particular_list, setOnParticularList] = useState([]);
    const FiscalYearName = menu_list?.current_fiscal?.fiscal_year_name;
    const [openSubLedgCreate, setOnOpenSubLedgCreate] = useState(false);
    const [openParticularCreate, setOnOpenParticuCreate] = useState(false);
    const AcGeneralLedgOption = AcGeneralLedgOptions(onGeneralLedgList?.data);
    const SubLedgerOption = SubLedgersOptions(on_ledger_list?.data?.sub_ledger);
    const ParticularOption = ParticularOptions(on_particular_list?.data?.particular);
    const TrasactionOption = TrasactionOptions(trans_type);
    const optionHandleSelect = (fieldName, data) => {
        if (data && data.value) {
            handleChange(fieldName)(data.value);
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                [fieldName]: data || "",
            }));
            // Additional logic...
        } else {
            console.error(`Invalid data for field ${fieldName}: `, data);
        }
    };

    const fetchSubLedgAndClassList = async () => {
        try {
            if (selectValues?.glid) {
                await fetchDataFromApi(AppURL.AccountsURL + "account-general-ledg-list-sub-ledg-list-view/?glid=" + selectValues?.glid?.value).then((res) => {
                    dispatch(Sub_Ledger_List(res));
                    setOnLedgerList(res);
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchParticularList = async () => {
        try {
            if (selectValues?.sglsn) {
                await fetchDataFromApi(AppURL.AccountsURL + "account-sub-ledg-wise-particular-list-view/?sglsn=" + selectValues?.sglsn?.value).then((res) => {
                    dispatch(Particular_List_AC(res));
                    setOnParticularList(res);
                });
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchSubLedgAndClassList();
        fetchParticularList();
        if (values?.date_en) {
            setOnEnToNPDate(ADToBSConvert(values?.date_en));
        }
    }, [selectValues?.glid, selectValues?.sglsn, values?.date_en])
    const OnClassListOptions = StdClassOptions(on_ledger_list?.data?.class_list);

    const [studentListData, setOnStudentListData] = useState([]);
    const fetchStudentList = async () => {
        try {
            const response = await fetch(AppURL.AcademicsURL + "academics-search-student-list-view/?class_id=" + selectValues?.class_id?.value);
            const res = await response.json();
            setOnStudentListData(res);
        } catch (error) {
            alert(error.response?.detail || "An error occurred");
        }
    }
    useEffect(() => {
        if (selectValues?.class_id) {
            fetchStudentList();
        }
    }, [selectValues?.class_id])
    const StudentListOption = StudentListOptions(studentListData?.students);
    useEffect(() => {
        dispatch(Account_ledger_Report_Select_({
            date_en: ADToBSConvert(values?.date_en),
            from_date: values.from_date,
            selectValues: selectValues
        }));
    }, [values?.date_en, selectValues])

    return (
        <div className='bg-white p-4 border dark:border-gray600 rounded-md relative z-10 dark:bg-slate800'>
            {selectValues?.member_sn?.value}
            <AccountParticularCreateView setOnSelectValues={setOnSelectValues} setOnOpenParticuCreate={setOnOpenParticuCreate} selectValues={selectValues} openParticularCreate={openParticularCreate} />
            <AccountSubLedgerCreateView setOnSelectValues={setOnSelectValues} setOnOpenSubLedgCreate={setOnOpenSubLedgCreate} selectValues={selectValues} openSubLedgCreate={openSubLedgCreate} />
            <ComponentHeading icon={FaAddressCard} label={language ? "Voucher Information" : "भाउचर जानकारी"} />
            <Grid container spacing={1}>
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                    <InputNumber name="from_date" value={values.from_date} disabled={false} handleChange={handleChange} mask="9999.99.99" required={true} label={"From Date"} />
                </Grid>
                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                    <InputNumber name="to_date" value={values.to_date} disabled={false} handleChange={handleChange} mask="9999.99.99" required={true} label={"To Date"} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <InputTextBox type="text" label={language ? "Date(NP)" : "मिति(NP)"} value={enToNPDate} errors={errors.date_ne} touched={touched.date_ne} disabled={true} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <InputTextBox type="text" label={language ? "Fiscal Year" : "आर्थिक वर्ष"} name={'fiscal_year'} value={FiscalYearName} errors={errors.date_ne} disabled={true} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <SelectOptionBox label={language ? "Voucher Type" : "भाउचर प्रकार"} optionSelect={optionHandleSelect} name="voucher_type" values={selectValues?.voucher_type} options={TrasactionOption} required={true} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <SelectOptionBox label={language ? "General Ledger" : "भाउचर प्रकार"} optionSelect={optionHandleSelect} name="glid" values={selectValues?.glid} errors={errors.glid} options={AcGeneralLedgOption} required={true} />
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <SelectOptionBox label={language ? "Sub Ledger" : "उप प्रकार"} required={true} name="sglsn" add_btn={selectValues?.glid ? true : false} optionSelect={optionHandleSelect} value={selectValues.sglsn} options={SubLedgerOption} onClick={async () => await setOnOpenSubLedgCreate(true)} />
                </Grid>
                {selectValues?.sglsn?.userShow === 1 ? <Fragment>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <SelectOptionBox label={language ? "Class" : "कक्षा"} required={true} name="class_id" add_btn={selectValues?.class_id ? true : false} optionSelect={optionHandleSelect} value={selectValues.class_id} options={OnClassListOptions} />
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                        <SelectOptionBox label={language ? "Student" : "Student"} placeholder="" value={selectValues.student} options={StudentListOption} name="student" optionSelect={optionHandleSelect}
                            touched={touched.student} errors={errors.student} required={true} />
                    </Grid>
                </Fragment>
                    : ""
                }
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <SelectOptionBox label={language ? "Particulars" : "विवरणहरु"} required={true} value={selectValues?.particularid} name="particularid" options={ParticularOption} optionSelect={optionHandleSelect} add_btn={selectValues?.glid && selectValues?.sglsn ? true : false} onClick={() => setOnOpenParticuCreate(true)} errors={errors.particularid} />
                </Grid>
            </Grid>
        </div>
    )
}

export default HeaderLedgerForm