
import React, { Fragment, useEffect, useState } from 'react';
import { MoneyFormat } from '../../../../widgets/NumberFormat';
import { useSelector } from 'react-redux';

const TransReportListView = () => {
    const { account_transaction_rpt_list } = useSelector((state) => state.accounts);
    console.log("resuplsts", account_transaction_rpt_list)
    const [totalAmt, setOnTotalAmt] = useState({ totalDRAmt: 0, totalCRYear: 0 })
    useEffect(() => {
        const totalDrAmount = account_transaction_rpt_list?.results
            ? account_transaction_rpt_list?.results?.reduce((total, voucher) => total + (voucher?.totdr || 0), 0)
            : 0;
        const totalCRAmount = account_transaction_rpt_list?.results
            ? account_transaction_rpt_list?.results?.reduce((total, voucher) => total + (voucher?.totcr || 0), 0)
            : 0;
        setOnTotalAmt({ totalDRAmt: totalDrAmount, totalCRYear: totalCRAmount });
    }, [account_transaction_rpt_list]);
    return (
        <Fragment>
            <div className='bg-white rounded-md p-2 shadow dark:bg-slate800'>
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[11px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" rowSpan={2} align='center' className="px-2 border border-gray-300 dark:border-slate600 w-10">
                                    S.N.
                                </th>
                                <th scope="col" rowSpan={2} align='center' className="px-2 border border-gray-300 dark:border-slate600 w-10">
                                    GLID
                                </th>
                                <th scope="col" rowSpan={2} align='center' className="px-2 border border-gray-300 dark:border-slate600">
                                    Particulars
                                </th>
                                <th scope="col" colSpan={2} align='center' className="px-2 border border-gray-300 dark:border-slate600 w-[25%]">
                                    Debit Amount
                                </th>

                                <th scope="col" colSpan={2} align='center' className="px-2 border border-gray-300 dark:border-slate600 w-[25%]">
                                    Credit Amount
                                </th>
                                <th scope="col" rowSpan={2} align='center' className="px-2 border border-gray-300 dark:border-slate600">
                                    Remarks
                                </th>
                            </tr>
                            <tr>
                                <th scope="col" align='center' className="px-2 border border-gray-300 dark:border-slate600">
                                    Amount
                                </th>
                                <th scope="col" align='center' className="px-2 border border-gray-300 dark:border-slate600">
                                    Total Amount
                                </th>
                                <th scope="col" align='center' className="px-2 border border-gray-300 dark:border-slate600">
                                    Amount
                                </th>
                                <th scope="col" align='center' className="px-2 border border-gray-300 dark:border-slate600">
                                    Total Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(account_transaction_rpt_list?.results) &&
                                account_transaction_rpt_list?.results?.map((ledger, index) =>
                                (
                                    <tr key={index} className="odd:bg-white text-[11px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border border-gray-300 font-medium dark:border-slate600 capitalize">
                                            {index + 1}.
                                        </td>
                                        <td align='center' className="px-2 border border-gray-300 font-medium dark:border-slate600 capitalize">
                                            {`${ledger?.sub_ledg_id === 0 ? ledger?.glid : ledger?.glid + "." + ledger?.sub_ledg_id}`}
                                        </td>
                                        <td align='left' className="px-2 font-medium py-1 border border-gray-300 dark:border-slate600 capitalize text-[11px]">
                                            <span className='font-semibold underline'>{ledger?.sub_ledg_id === 0 ? ledger?.gl_name : ""} </span> <span className='pl-14 font-normal'>{ledger?.sub_ledg_id !== 0 ? ledger?.sub_ledg_name : ""}</span>
                                        </td>
                                        <td align='right' className="px-2 border border-gray-300 dark:border-slate600">
                                            {ledger?.subdr ? MoneyFormat(ledger?.subdr) : MoneyFormat(0)}
                                        </td>
                                        <td align='right' className="px-2 border border-gray-300 dark:border-slate600">
                                            {ledger?.totdr ? MoneyFormat(ledger?.totdr) : MoneyFormat(0)}
                                        </td>
                                        <td align='right' className="px-2 border border-gray-300 dark:border-slate600">
                                            {ledger?.subcr ? MoneyFormat(ledger?.subcr) : MoneyFormat(0)}
                                        </td>
                                        <td align='right' className="px-2 border border-gray-300 dark:border-slate600">
                                            {ledger?.totcr ? MoneyFormat(ledger?.totcr) : MoneyFormat(0)}
                                        </td>
                                        <td className="px-2 border border-gray-300 dark:border-slate600 ">

                                        </td>
                                    </tr>
                                )
                                )
                            }
                            <tr className='font-public-sans text-black bg-gray100'>
                                <td align='right' colSpan={3} className="px-2 font-medium border border-gray-300 dark:border-slate600 uppercase text-[12px]">
                                    Total
                                </td>
                                <td align='right' colSpan={2} className="px-2 font-medium border border-gray-300 dark:border-slate600 capitalize text-[12px]">
                                    {totalAmt?.totalDRAmt ? MoneyFormat(totalAmt?.totalDRAmt) : MoneyFormat(0)}
                                </td>
                                <td align='right' colSpan={2} className="px-2 font-medium border border-gray-300 dark:border-slate600 capitalize text-[12px]">
                                    {totalAmt?.totalCRYear ? MoneyFormat(totalAmt?.totalCRYear) : MoneyFormat(0)}
                                </td>
                                <td align='right' className="px-2 font-medium border border-gray-300 dark:border-slate600 capitalize text-[12px]">

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>
    )
}

export default TransReportListView