import React, { useState } from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
// import { useSelector } from "react-redux";
import { postDataToTheServer } from "../../../../widgets/api";
import AppURL from "../../../../widgets/AppURL";
import { ToastSuccess } from "../../../../widgets/ToastMessage";
import InputTextBox from "../../../../widgets/InputTextBox";
import NepaliInputTextBox from "../../../../widgets/NepaliInputTextBox";
import SelectOptionBox from "../../../../widgets/SelectOptionBox";
import { ActiveInactiveOptions, DebitCreaditOptions } from "../../../../widgets/Options";
import { active_inactive_list, debit_credit_list } from "../../../../widgets/json";
import { ClearBtn, SaveBtn } from "../../../../widgets/AppBtn";
import { useSelector } from "react-redux";


const AccountParticularCreateForm = ({ selectValueForm, setOnOpenParticuCreate, ParticularCreateClear }) => {
    const ActiveInActiveOption = ActiveInactiveOptions(active_inactive_list);
    const DebitCreaditOption = DebitCreaditOptions(debit_credit_list);
    const { particular_list } = useSelector((state) => state?.accounts);
    const [selectValues, setOnSelectValues] = useState({
        status: "",
        is_debit: "",
        depri_method: ""
    });
    const [customValues, setCustomValues] = useState({
        particular_name_nep: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const handleNepaliInputChange = (fieldName, value) => {
        handleChange(fieldName)(value)
        setCustomValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    const initialValues = {
        particular_id: particular_list?.data?.particular_id || "",
        virtual_pid: particular_list?.data?.particular_sn || "",
        particular_name: "",
        particular_name_nep: "",
        sglsn: particular_list?.data?.sglsn || "",
        glid: selectValueForm?.glid?.value || "",
        is_debit: "",
        status: "",
        sub_gl_related_id: "",
        branch_id: "",
        particu_for_assets: "",
        assets_dep_perc: ""
    };
    const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("particular_name", values.particular_name);
                formData.append("particular_name_nep", customValues.particular_name_nep);
                formData.append("glid", values.glid);
                formData.append("is_debit", selectValues?.is_debit?.value=== "2"?"0":"1");
                formData.append("sub_gl_related_id", values.sglsn);
                formData.append("status", selectValues?.status?.value=== "2"?"0":"1");
                formData.append("virtual_pid", values.virtual_pid);
                formData.append("branch_id", 1);
                if (values.particu_for_assets) {
                    formData.append("particu_for_assets", values.particu_for_assets);
                }
                if (values.assets_dep_perc) {
                    formData.append("assets_dep_perc", values.assets_dep_perc);
                }
                try {
                    await postDataToTheServer(AppURL.AccountsURL + "account-particular-create-list-view/", formData).then((response) => {
                        action.resetForm();
                        ToastSuccess(response?.message);
                        setOnOpenParticuCreate(false);
                        ParticularCreateClear(
                            (prevValues) => ({
                                ...prevValues,
                                sglsn: "",
                            }));
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <div
            className="rounded p-3">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1} className="border bg-gray-50 mb-3 rounded p-1 pe-2 pb-3">
                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <InputTextBox
                            name="glid"
                            type="text"
                            label="General Leadger ID"
                            value={values.glid}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.glid}
                            errors={errors.glid}
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <InputTextBox
                            name="sglsn"
                            type="text"
                            label="Sub Ledger ID"
                            value={values.sglsn}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.sglsn}
                            errors={errors.sglsn}
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <InputTextBox
                            name="particular_id"
                            type="text"
                            label="Particular ID"
                            value={values.particular_id}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.particular_id}
                            errors={errors.particular_id}
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <InputTextBox
                            name="virtual_pid"
                            type="text"
                            label="Particular SN"
                            value={values.virtual_pid}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.virtual_pid}
                            errors={errors.virtual_pid}
                            disabled={true}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <InputTextBox
                            name="particular_name"
                            type="text"
                            label="Particular Name"
                            value={values.particular_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.particular_name}
                            errors={errors.particular_name}
                        />
                    </Grid>
                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <NepaliInputTextBox
                            name="particular_name_nep"
                            label="Particular Name (Nepali)"
                            value={customValues.particular_name_nep}
                            onCustomValueChange={handleNepaliInputChange}
                            handleBlur={handleBlur}
                            touched={touched.particular_name_nep}
                            errors={errors.particular_name_nep}
                        />
                    </Grid>


                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <SelectOptionBox label="Debit/Credit" name="is_debit" options={DebitCreaditOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.is_debit} errors={errors.is_debit} />
                    </Grid>

                    <Grid item xl={6} sm={6} xs={12} md={6} lg={6}>
                        <SelectOptionBox label="Status" name="status" options={ActiveInActiveOption} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.status} errors={errors.status} />
                    </Grid>
                    {/* {
                        selectedValuesForm.GLID.value === "130" && (
                        <>
                            <Grid item xl={6} sm={6} xs={12}>
                            <SelectOptionBox label="Depariciation Method" name="DepriMethod" option={DepMethodOptions} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.DepriMethod} errors={errors.DepriMethod} />
                            </Grid>
                        
                            <Grid item xl={6} sm={6} xs={12}>
                            <InputTextBox
                                name="ParticuForAssets"
                                type="number"
                                label="Number of Depariciation"
                                value={values.ParticuForAssets}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.ParticuForAssets}
                                errors={errors.ParticuForAssets}  
                            />
                            </Grid>
                        </>
                        )
                    } */}
                    <Grid container className="flex justify-end  gap-3 mt-2 pb-1">
                        <ClearBtn type="button" onClick={async () => await setOnOpenParticuCreate(false)} />  <SaveBtn type="submit" />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default AccountParticularCreateForm;
