import { createSlice } from '@reduxjs/toolkit';

export const academicSlice = createSlice({
    name: 'academics',
    initialState: {
        student_lists: {},
        student_card_list: {},
        vehicle_routine_rpt:{},
        class_wise_subject_list:{},
        class_routine_select_field:{},
        class_wise_app_details_std_list:{},
        class_routine_search_list_option: {},
        std_admit_card_details_list:{}
    },
    reducers: {
        Student_List_Search: (state, action) => {
            state.student_lists = action.payload;
        },
        Student_Card_List: (state, action) => {
            state.student_card_list = action.payload;
        },
        VehicleRoutineRpt:(state, action) => {
            state.vehicle_routine_rpt = action.payload;
        },
        Class_Wise_Subject_List: (state, action) => {
            state.class_wise_subject_list = action.payload;
        },
        ClassRoutineSelectField:(state, action) => {
            state.class_routine_select_field = action.payload;
        },
        ClassRoutineListView:(state, action)=>{
            state.class_routine_list_view = action.payload;
        },
        ClassWiseAppDetailsStudentList:(state, action)=>{
            state.class_wise_app_details_std_list = action.payload;
        },
        ClassRoutineSearchListOption:(state, action)=>{
            state.class_routine_search_list_option = action.payload;
        },
        StdAdmitCardDetailsList:(state,action)=>{
            state.std_admit_card_details_list = action.payload;
        },
        UpgradeStudentList:(state, action)=>{
            state.upgrade_student_list = action.payload;
        },
        TeacherDailyDayBookList:(state,action)=>{
            state.teacher_daily_day_book_list = action.payload;
        },
        TeacherDailyDayBookSeachForm:(state,action)=>{
            state.teacher_daily_day_book_saerch_form = action.payload;
        },
        TeacherWeeklyLessionPlanReportList:(state,action)=>{
            state.teacher_weekly_lession_plan_list = action.payload;
        },
        
        
    }
});

// Action creators are generated for each case reducer function
export const { Student_List_Search, TeacherDailyDayBookList, TeacherWeeklyLessionPlanReportList, TeacherDailyDayBookSeachForm, UpgradeStudentList, ClassRoutineSearchListOption, StdAdmitCardDetailsList, ClassRoutineSelectField, ClassWiseAppDetailsStudentList, ClassRoutineListView, Student_Card_List, Class_Wise_Subject_List, VehicleRoutineRpt } = academicSlice.actions
export default academicSlice.reducer