import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from '@mui/material';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SidebarReportClose } from '../../../../store/softSlice';
import { TeacherOptions } from '../../../../widgets/Options';
import { TeacherDailyDayBookList, TeacherDailyDayBookSeachForm } from '../../../../store/academicsSlice';
import { useLanguage } from '../../../../widgets/LangContext';
import InputNumber from '../../../../widgets/InputNumber';
import { BSToADFormat } from '../../../../widgets/DateFormat';

const LogBookReportFormView = ({ onStudentOptionList }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ "action": false }));
  }

  const StaffOption = TeacherOptions(onStudentOptionList?.teachers);

  const [selectValues, setOnSelectValues] = useState({
    member_sn: ""
  });

  const optionHandleSelect = (fieldName, data) => {
    handleChange(fieldName)(data.value)
    setOnSelectValues((prevValues) => ({
      ...prevValues,
      [fieldName]: data,
    }));
  };
  const initialValues = {
    date: "",
    member_sn: ""
  };

  const { handleChange, handleBlur, handleSubmit, isSubmitting, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const apiUrl = AppURL.AcademicsURL + "academics-teacher-daily-log-book-report-list-view/";

        // Construct query parameters
        const queryParams = {};

        if (selectValues.teachers?.value) {
          queryParams.member_sn = selectValues.teachers.value;
        }
        if (values.date) {
          queryParams.date = BSToADFormat(values.date);
        }
        const queryString = new URLSearchParams(queryParams).toString();
        const fullUrl = apiUrl + (queryString ? `?${queryString}` : '');
        try {
          // Fetch data from API
          const res = await fetchDataFromApi(fullUrl);
          dispatch(TeacherDailyDayBookList(res));
          // Optional: Dispatch additional actions
        
          dispatch(TeacherDailyDayBookSeachForm({
            date: BSToADFormat(values.date),
            teacher: selectValues.teachers?.label,
           
          }));
         

          CloseDispatch();
          navigate("/daily-teacher-log-book-page-list-view");
        } catch (error) {
          console.log(error);
        }
      },
    });


  return (
    <div className=''>
      <ReportHeading heading="Daily Teacher Log Book Report" />
      <div className='mt-4'>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Teachers" : "शिक्षकहरू"} placeholder="" options={StaffOption} value={selectValues.teachers} name="teachers" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.teachers} errors={errors.teachers} required={false} />
            </Grid>
            <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
              <InputNumber name="date" value={values.date} handleChange={handleChange} mask="9999/99/99" required={true} label={language ? "Date (BS)" : "मिति (BS)"} />
            </Grid>
          </Grid>
          <div className='flex justify-between mt-8'>
            <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
          </div>
        </form>
      </div>
    </div>
  )
}
export default LogBookReportFormView