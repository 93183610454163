import { Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import SearchStudentListView from '../../../../components/academics/create/app_details/SearchStudentListView'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import AppStudentListView from '../../../../components/academics/create/app_details/AppStudentListView';
import { ClassWiseAppDetailsStudentList } from '../../../../store/academicsSlice';
import { useReactToPrint } from 'react-to-print';
import PrintStudentUsernamePassword from '../../../../components/academics/create/app_details/widgets/PrintStudentUsernamePassword';
import { CardPrintBtn } from '../../../../widgets/AppBtn';

const AcaAppUserDetailsCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(<CardPrintBtn onClick={handlePrintCard} />);
  }, []);

  const [onStudentOptionList, setOnStdOptionList] = useState([]);
  const AddStudentOption = async () => {
    try {
      await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-options-list-view/").then((res) => {
        setOnStdOptionList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    AddStudentOption();
  }, [])
  const [onURL, setOnURL] = useState("");

  const FetchDataStdList = async (class_id, section, medium) => {
    OnDataList(AppURL.AcademicsURL + "academics-app-user-details-create-list-view/?class_id=" + class_id + "&section=" + section + "&medium=" + medium);
  }
  const OnDataList = async (url) => {
    try {
      const res = await fetchDataFromApi(url);
      console.log("App User Details List:", res);
      dispatch(ClassWiseAppDetailsStudentList(res));
      setOnURL(url);
    } catch (error) {
      console.log(error);
    }
  }

  const componentListRef = useRef();
  const RenderListComponent = React.forwardRef(() => (
    <PrintStudentUsernamePassword />
  ));

  const handlePrintCard = useReactToPrint({
    content: () => componentListRef.current,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <SearchStudentListView FetchDataStdList={FetchDataStdList} onStudentOptionList={onStudentOptionList} />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AppStudentListView OnDataList={OnDataList} onURL={onURL} />
      </Grid>
      <div className='hidden'>
        <div ref={componentListRef}>
          <RenderListComponent />
        </div>
      </div>
    </Grid>

  )
}

export default AcaAppUserDetailsCreateListView