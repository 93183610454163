import React, { Fragment } from 'react'

const TextAreaBox = ({ label, value, handleChange, required, height, name, placeholder }) => {
    return (
        <Fragment>
            <label htmlFor="message" className="block mb-2 text-[12px] mr-1 text-slate600 font-medium font-public-sans dark:text-gray-400">{label}{required && <span className='text-red-500'>*</span>}</label>
            <textarea value={value}
                onChange={handleChange} autoComplete='off' rows={height} name={name} height={2} placeholder={placeholder || ''} className="block py-1 text-[12px] px-2 w-full placeholder:text-[12px] font-normal font-public-sans text-gray-700 bg-white rounded-[4px] border border-gray-300 focus:ring-green-600 focus:border-green-600 dark:bg-slate-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  focus:outline-green-600 dark:focus:border-green-600" ></textarea>
        </Fragment>
    )
}
export default TextAreaBox