import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import DepartmentCreateFormView from '../../../components/application/setup/department/DepartmentCreateFormView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
import AppDepartmentListView from '../../../components/application/setup/department/AppDepartmentListView';
// 
const AppDepartmentsCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }));
    setIconsButton(null);
    document.title = `Nexapp - ${PageTitle}`;
  }, []);
  const [onDepartmentList, setOnDepartmentList] = useState([]);
  const url = AppURL.ApplicationURL+"app-department-create-list-view/";
  const fetchDepartmentListView = async (urls) => {
      try {
          await fetchDataFromApi(urls).then((res) => {
            setOnDepartmentList(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  const FetchData = async () => {
      await fetchDepartmentListView(url);
  }
  useEffect(() => {
      FetchData();
  }, []);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <DepartmentCreateFormView />
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <AppDepartmentListView onDepartmentList={onDepartmentList} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default AppDepartmentsCreateListView