import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import './assets/style/index.css'
import { Provider } from 'react-redux';
import { store } from './store/store';
import AuthContext from './widgets/AuthProvider';
import VoucherEntryContext from './context/VoucherEntryContext';
import VoucherEntryRpt from './context/VoucherEntryRpt';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AuthContext>
      <VoucherEntryRpt>
        <VoucherEntryContext>
          <App />
        </VoucherEntryContext>
      </VoucherEntryRpt>
    </AuthContext>
  </Provider>
);
