import React, { Fragment } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ImageLazyLoading = ({ image, alt, height,className, width, src }) => {
    return (
        <Fragment>
            <LazyLoadImage
            className={className}
                alt={alt}
                height={height}
                src={src} // use normal <img> attributes as props
                width={width} />
        </Fragment>
    )
}

export default ImageLazyLoading