import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Helper function to get the days in a month
const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

const StudentAttendanceListRptView = () => {
    const { student_attendance_rpt_list } = useSelector((state) => state?.attendance);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // January is 0, February is 1, and so on
    const daysInMonth = getDaysInMonth(currentYear, currentMonth);
    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        if (!student_attendance_rpt_list || !student_attendance_rpt_list.data) return;

        // Extract unique students from the API data
        const studentMap = new Map();
        student_attendance_rpt_list.data.forEach(record => {
            if (!studentMap.has(record.member_sn)) {
                studentMap.set(record.member_sn, {
                    id: record.member_sn,
                    name: `${record?.first_name} ${record?.middle_name?record?.middle_name:""} ${record?.last_name}`, // Use a placeholder name. Ideally, the real name should come from the API.
                    rollNo: record.roll_no, // Use member_sn as roll number for now.
                    attendance: Array(daysInMonth).fill('')
                });
            }
        });

        const studentList = Array.from(studentMap.values());

        // Update the attendance for each student
        studentList.forEach(student => {
            student_attendance_rpt_list.data.forEach(record => {
                if (record.member_sn === student.id) {
                    const recordDate = new Date(record.date_en);
                    if (recordDate.getMonth() === currentMonth && recordDate.getFullYear() === currentYear) {
                        const dayIndex = recordDate.getDate() - 1;
                        student.attendance[dayIndex] = record.status.toLowerCase() === 'present' ? 'P' : 'A';
                    }
                }
            });
        });

        setStudents(studentList);
    }, [currentMonth, currentYear, student_attendance_rpt_list]);

    return (
        <div className="relative overflow-x-auto max-h-64 rounded-md bg-white p-2">
            <table className="w-full text-sm text-left rtl:text-right font-public-sans text-gray500 dark:text-gray300">
                <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr className="sticky top-0 z-10 bg-slate100 font-normal text-[12px] text-gray700 capitalize dark:text-gray300">
                        <th scope="col" rowSpan={2} align='center' className="px-2 py-1.5 border border-gray-300 w-10">
                            S.N.
                        </th>
                        <th scope="col" rowSpan={2} className="px-2 py-1.5 border border-gray-300 w-[20%]">
                            Student Name
                        </th>
                        <th scope="col" rowSpan={2} align='center' className="px-2 py-1.5 border border-gray-300 w-16">
                            Roll No
                        </th>
                        <th scope="col" align='center' colSpan={daysInMonth} className="px-2 py-1.5 border border-gray-300">
                            Days
                        </th>
                    </tr>
                    <tr className='bg-slate100'>
                        {daysArray.map(day => (
                            <td key={day} className="px-2 py-1.5 text-[12px] border border-gray300">
                                {day}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white dark:bg-gray-800">
                    {students.map((student, index) => (
                        <tr key={student.id} className="bg-white border-b text-[12px] dark:bg-gray800 dark:border-gray700">
                            <td align='center' className="px-2 py-1 text-gray900 whitespace-nowrap border border-gray300">
                                {index + 1}
                            </td>
                            <td className="px-2 py-1 border border-gray300">
                                {student.name}
                            </td>
                            <td className="px-2 py-1 border border-gray300" align='center'>
                                {student.rollNo}
                            </td>
                            {student.attendance.map((status, dayIndex) => (
                                <td key={dayIndex} align='center' className={`px-2 py-1 ${status === 'A' ? "bg-red-500 text-white" : status === 'L' ? "bg-yellow-500 text-white" : ""} border border-gray300`}>
                                    {status}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StudentAttendanceListRptView;
