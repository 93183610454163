import React from 'react';

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";
import AccountParticularCreateForm from '../../../../components/account/setup/particular/AccountParticularCreateForm';

const AccountParticularCreateView = ({ selectValues, setOnSelectValues, setOnOpenParticuCreate, openParticularCreate }) => {
    const handleOpen = async () => await setOnOpenParticuCreate(false);
    return (
        <Dialog className='ml-[100px]' open={openParticularCreate} handler={handleOpen}>
            <DialogHeader className='font-public-sans font-semibold text-[17px]'>Create Particular</DialogHeader>
            <DialogBody>
                <AccountParticularCreateForm setOnOpenParticuCreate={setOnOpenParticuCreate}  selectValueForm={selectValues} ParticularCreateClear={setOnSelectValues} />
            </DialogBody>
        </Dialog>
    )
}

export default AccountParticularCreateView