import { createSlice } from '@reduxjs/toolkit';

export const librarySlice = createSlice({
    name: 'library',
    initialState: {
        library_issue_return_rpt: [],
    },
    reducers: {
        LibraryIssueReturnReport: (state, action) => {
            state.library_issue_return_rpt = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { LibraryIssueReturnReport } = librarySlice.actions
export default librarySlice.reducer