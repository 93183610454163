import React, { useEffect, useState } from 'react'
import { Dialog, DialogBody } from "@material-tailwind/react";
import ComponentHeading from '../../../widgets/ComponentHeading';
import { TbPasswordUser } from "react-icons/tb";
import { Grid } from '@mui/material';
import InputTextBox from '../../../widgets/InputTextBox';
import { UpdateBtn } from '../../../widgets/AppBtn';
import { useFormik } from 'formik';
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastError, ToastSuccess } from '../../../widgets/ToastMessage';
import * as Yup from 'yup';

const ChangePasswordModal = ({ onPasswordModal, setOnPasswordModal }) => {
    const validationSchema = Yup.object().shape({
        current_password: Yup.string()
            .required('Current password is required'),
        new_password: Yup.string()
            .required('New password is required'),
        con_password: Yup.string()
            .oneOf([Yup.ref('new_password'), null], 'Passwords must match') 
            .required('Confirm password is required'),
    });
    const handleOpen = () => setOnPasswordModal(!onPasswordModal);
    const [onResponse, setOnResponse] = useState([]);
    const initialValues = {
        user_id: "",
        current_password: "",
        new_password: ""
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setOnResponse([]);
        }, 10000); 
        return () => clearTimeout(timer); 
    }, [onResponse]);
    const { values, handleChange, handleBlur, handleSubmit, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            validationSchema: validationSchema,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("user_id", 1);
                formData.append("current_password", values.current_password);
                formData.append("new_password", values.new_password);
                try {
                    await postDataToTheServer(AppURL.ApplicationURL + "app-author-user-retrieve-and-change-password-update/", formData).then((res) => {
                        setOnResponse(res);
                        if (res?.status === 200) {
                            ToastSuccess(res?.message);
                            action.resetForm();
                        } else if (res?.status === 404) {
                            ToastError(res?.message);
                        } else if (res?.status === 400) {
                            ToastError(res?.message);
                        } else {
                            ToastError("Something Server Error");
                        }
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <Dialog open={onPasswordModal} size='xs' className='ml-[120px] dark:bg-slate800' handler={handleOpen}>
            <DialogBody>
                <ComponentHeading icon={TbPasswordUser} label={'Change Password'} />
                <form onSubmit={handleSubmit}>
                    <div className='p-2'>
                        <Grid container spacing={1}>
                            {
                                onResponse?.message && <Grid item xl={12} xs={12} md={12} sm={12}>
                                    <div className={`px-2 rounded-md py-1 text-[12px] text-white ${onResponse?.status === 200 ? "bg-green-600 text-gray600" : onResponse?.status === 400 || 404 ? "bg-red-600 text-gray600" : ""}`}>
                                        {onResponse?.message}
                                    </div>
                                </Grid>
                            }
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <InputTextBox label={'Current Password'} type={'password'} name="current_password"
                                    value={values.current_password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.current_password}
                                    errors={errors.current_password}
                                    required={true} />
                            </Grid>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <InputTextBox label={'New Password'} type={'password'} name="new_password"
                                    value={values.new_password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.new_password}
                                    errors={errors.new_password}
                                    required={true} />
                            </Grid>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <InputTextBox label={'Confirm Password'} type={'password'} name="con_password"
                                    value={values.con_password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    touched={touched.con_password}
                                    errors={errors.con_password}
                                    required={true} />
                            </Grid>
                            <Grid item xl={12} xs={12} md={12} sm={12}>
                                <div className='flex justify-end mt-3'>
                                    <UpdateBtn type={'submit'} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </DialogBody>
        </Dialog>
    )
}

export default ChangePasswordModal