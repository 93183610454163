import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../widgets/InputTextBox';
import { ClearBtn, UpdateBtn } from '../../../widgets/AppBtn';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { fetchDataFromApi, putDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { useLanguage } from "../../../widgets/LangContext";

const GalleryCateEditView = ({ onEditID, setOnEditID, fetchAcademicsListView, onUpdateURL }) => {
  const { language } = useLanguage();
  const [orgStrDetails, setOnClassStrDetails] = useState({});

  useEffect(() => {
    try {
      if (onEditID) {
        fetchDataFromApi(AppURL.MobileAppURL + "gallery-category-retreive-update-view/" + onEditID).then((res) => {
          setOnClassStrDetails(res?.data);
        });

      }
    } catch (error) {
      console.log(error);
    }
  }, [onEditID])

  const initialValues = {
    name: orgStrDetails?.name || "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("name", values.name);
        try {
          await putDataToTheServer(AppURL.MobileAppURL + "gallery-category-retreive-update-view/" + onEditID, formData).then((res) => {
            ToastSuccess(res?.message);
            fetchAcademicsListView(onUpdateURL);
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
        <ComponentHeading label={language ? "Edit Gallery Category" : "ग्यालरी श्रेणी अपडेट गर्नुहोस्"} icon={IoMdAddCircle} />
        <Grid container spacing={1.5}>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
            <InputTextBox
              name="name"
              type="text"
              label={language ? "Category Name" : "श्रेणी नाम"}
              value={values.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.name}
              errors={errors.name}
              required={true}
            />
          </Grid>
        </Grid>
        <div className="flex justify-end mt-3 gap-2">
          <ClearBtn onClick={async () => await setOnEditID(false)} /> <UpdateBtn type="submit" />
        </div>
      </form>
    </Fragment>
  )
}

export default GalleryCateEditView