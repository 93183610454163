import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';

const AcaStudentLeaveRequestListView = ({ setIconsButton, PageTitle }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, [PageTitle]);
    return (
        <div>Comming Soon...</div>
    )
}

export default AcaStudentLeaveRequestListView