import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';
import ReportLabel from '../../../../widgets/ReportLabel';
import { Grid } from '@mui/material';

const MarksEvaluationReportPdfView = () => {
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    const { exam_sub_student_list, class_wise_subject_list } = useSelector((state) => state.exam);

    useEffect(() => {
        // Your effect logic here
    }, [JSON.stringify(exam_sub_student_list)]);
    const { exam_marks_evaluation_rpt_list = {} } = useSelector((state) => state.exam);
    const [totalStd, setTotalStd] = useState({ totalStdCount: 0 });

    useEffect(() => {
        if (exam_marks_evaluation_rpt_list?.total_class_report) {
            const totalCountStd = exam_marks_evaluation_rpt_list.total_class_report.reduce((total, item) => total + item.grade_count, 0);
            setTotalStd({ totalStdCount: totalCountStd });
        }
    }, [exam_marks_evaluation_rpt_list]);

    if (!exam_marks_evaluation_rpt_list || !exam_marks_evaluation_rpt_list.subject_wise_reports) {
        return <div className='text-red-500 font-public-sans'>Data Not Found...</div>;
    }

    // Grouping data by subj_title for row span calculation
    const groupedData = exam_marks_evaluation_rpt_list.subject_wise_reports.reduce((acc, obj) => {
        const key = `${obj.subj_title}_${obj.thgrade}`;
        if (!acc[key]) {
            acc[key] = {
                subj_title: obj.subj_title,
                thgrade: obj.thgrade,
                count: obj.count,
                rowspan: 1, // Initialize rowspan
            };
        } else {
            acc[key].count += obj.subj_title;
            acc[key].rowspan += 1; // Increment rowspan for duplicate subj_title
        }
        return acc;
    }, {});

    // Prepare the total grade count data
    const totalGradeCounts = {};
    exam_marks_evaluation_rpt_list.subject_wise_reports.forEach((item) => {
        if (!totalGradeCounts[item.thgrade]) {
            totalGradeCounts[item.thgrade] = 0;
        }
        totalGradeCounts[item.thgrade] += item.count;
    });

    // Render the table rows for each unique combination of subj_title and thgrade
    const renderRows = Object.keys(groupedData).map((key, index) => {
        const item = groupedData[key];
        return (
            <tr key={index}>
                {/* Render subj_title with rowspan */}

                <td className="px-2 font-medium py-1 border border-gray-400 dark:border-slate600 uppercase text-[12px]">
                    {item.subj_title}
                </td>
                <td align='center' className="px-2 font-medium py-1 border border-gray-400 dark:border-slate600 uppercase text-[12px]">{item.thgrade}</td>
                <td align='center' className="px-2 font-medium py-1 border border-gray-400 dark:border-slate600 uppercase text-[12px]">{item.count}</td>
            </tr>
        );
    });
    return (
        <div className='bg-white h-screen'>
             <style>
                {`@media print {
                    @page {
                        size: A4 portrait;
                        margin: 0.5in; 
                    }
                }`}
            </style>
            <div className="relative">
                <table className="w-full text-sm text-left rtl:text-right text-black">
                    <tbody>
                        <tr>
                            <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                Reg. No: {Org_Details?.org_reg}
                            </td>
                            <td className="w-[25%]">
                            </td>
                            <td className="w-[25%]">
                            </td>
                            <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                Phone: {Org_Details?.org_phone}
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                            </td>
                            <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                {Org_Details?.org_name}
                            </td>
                            <td className="w-[25%]">
                            </td>
                        </tr>
                        <tr>
                            <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                {Org_Details?.org_add}
                            </td>
                            <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                Date: {DateNow}
                            </td>
                        </tr>
                        <tr>
                            <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                Student Marks Evaluations
                            </td>
                            <td align='right' className="w-[25%]">
                               
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='mt-2 font-public-sans overflow-x-auto text-[12px] gap-2 flex flex-row'>
                {class_wise_subject_list?.class_id && <ReportLabel label='Class: ' value={class_wise_subject_list?.class_id} />}
                {class_wise_subject_list?.exam_type && <ReportLabel label={'Exam: '} value={class_wise_subject_list?.exam_type} />}
                {class_wise_subject_list?.medium && <ReportLabel label={'Medium: '} value={class_wise_subject_list?.medium} />}
            </div>
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
                    <div className='bg-white rounded-md p-2 shadow dark:bg-slate800'>
                        <div className="relative overflow-x-auto">
                            <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                                <thead className="text-[13px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                                    <tr>
                                        <th scope="col" colSpan={4} align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                            All Student Reports
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                            S.N.
                                        </th>
                                        <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                            Grade
                                        </th>
                                        <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600">
                                            Total Students
                                        </th>
                                        <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600">
                                            Percentage
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-slate-800'>
                                    {
                                        exam_marks_evaluation_rpt_list?.total_class_report?.map((item, index) => (
                                            <tr key={index} className='font-public-sans text-black bg-gray100'>
                                                <td align='center' className="px-2 font-medium py-2 border dark:border-slate600 uppercase text-[12px]">
                                                    {index + 1}.
                                                </td>
                                                <td align='center' className="px-2 font-medium py-2 border dark:border-slate600 capitalize text-[12px]">
                                                    {item?.studtotalgrade}
                                                </td>
                                                <td align='center' className="px-2 font-medium py-2 border dark:border-slate600 capitalize text-[12px]">
                                                    {item?.grade_count}
                                                </td>
                                                <td align='center' className="px-2 font-medium py-2 border dark:border-slate600 capitalize text-[12px]">
                                                    {(item?.grade_count * 100 / totalStd.totalStdCount).toFixed(2)}%
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12} xl={8}>
                    <div className='mt-4'>
                        <h2 className='font-public-sans pb-2 font-semibold text-[14px] underline'>Grade Count Total Student</h2>
                        <table className="border-collapse border border-gray-200 font-public-sans">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="border border-gray-400 px-2 py-1">Subject Title</th>
                                    <th className="border border-gray-400 px-2 py-1">Grade</th>
                                    <th className="border border-gray-400 px-2 py-1">Total Student</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderRows.map((row, index) => (
                                    <React.Fragment key={index}>
                                        {row}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Grid>
            </Grid>
            <br />
        </div>
    )
}

export default MarksEvaluationReportPdfView;
