import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';
import ReportHeading from '../../../../widgets/ReportHeading';
import InputNumber from '../../../../widgets/InputNumber';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { StudentAttendanceReportListView } from '../../../../store/attendanceSlice';

const StudentAttendanceReportFormView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }

    const initialValues = {
        fiscal_year_name: "",
        from_date: "",
        to_date: "",
        branch_id: "",
    }
    const { values, handleChange, handleSubmit } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.AttendanceURL + "student-attendace-student-report-list-view/?class_id=1&section=1&medium=1").then((res) => {
                        dispatch(StudentAttendanceReportListView(res));
                        navigate("/student-attendance-report-page-list-view");
                        CloseDispatch();
                    })
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <div className=''>
            <ReportHeading heading="Attendance Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumber name="from_date" value={values.from_date} disabled={false} handleChange={handleChange} mask="9999.99.99" required={true} label={"From Date"} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <InputNumber name="to_date" value={values.to_date} disabled={false} handleChange={handleChange} mask="9999.99.99" required={true} label={"To Date"} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default StudentAttendanceReportFormView