import React, { Fragment, useEffect, useRef } from 'react';
import MarksEvaluationReportListView from '../../../../components/exam/report/marks_evl_rpt/MarksEvaluationReportListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { useReactToPrint } from 'react-to-print';
import MarksEvaluationReportPdfView from '../../../../components/exam/report/marks_evl_rpt/MarksEvaluationReportPdfView';
import { PdfExportBtn } from '../../../../widgets/AppBtn';

const MarksEvulationReportPageListView = ({ setIconsButton, PageTitle }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageTitle({ title: PageTitle }));
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(<PdfExportBtn onClick={handlePrintCard} />);
  }, [dispatch, PageTitle, setIconsButton]);
  const componentListRef = useRef();
  const RenderListComponent = React.forwardRef(() => (
    <MarksEvaluationReportPdfView />
  ));

  const handlePrintCard = useReactToPrint({
    content: () => componentListRef.current,
  });
  return (
    <Fragment>
      <MarksEvaluationReportListView />
      <div className='hidden'>
        <div ref={componentListRef}>
          <RenderListComponent />
        </div>
      </div>
    </Fragment>
  )
}

export default MarksEvulationReportPageListView