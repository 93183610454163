import { Input } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { GiWaterSplash } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { fetchDataFromApi } from '../../widgets/api';
import AppURL from '../../widgets/AppURL';
import { ToastError, ToastSuccess } from '../../widgets/ToastMessage';

const RenewLicenseView = () => {
    const initialValues = {
        license_key: "",
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values) => {
                const formData = new FormData();
                formData.append('license_key', values.license_key);
                if(values.license_key){
                    try {
                        await fetchDataFromApi(AppURL.ApplicationURL + 'app-license-key-renew-update-active/?license_key=' + values.license_key).then((res) => {
                            if (res?.status === 200) {
                                ToastSuccess(res?.message);
                            } else {
                                ToastError(res?.response?.data?.message);
                            }
                        });
                    } catch (error) {
                        console.log(error)
                    }
                }else{
                    ToastError("Enter license key")
                }
                
            },
        });
    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-9 mx-auto md:h-screen lg:py-0">
                <div className="w-full dark:border md:mt-0 sm:max-w-[45rem] xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="space-y-4 md:space-y-6">
                        <div className='sm:flex block bg-white sm:shadow-xl'>
                            <div className='bg-white flex-1 p-5 sm:py-16'>
                                <div className='block'>
                                    <h1 className='text-2xl font-public-sans font-bold select-none'>Renew License</h1>
                                </div>
                                <div>
                                    <p className='text-[10px] font-public-sans select-none font-medium mb-2 text-gray-800'>License Key provided by Preface Administration                         </p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className='w-full block mt-14'>
                                        <Input
                                            placeholder='Enter License Key'
                                            sx={{ fontSize: "12px", color: "#585858", width: '100%', '& input::placeholder': {fontSize: "12px", color: '#575757', }}}
                                            name='license_key'
                                            value={values.license_key}
                                            onChange={handleChange}
                                        />
                                        <div className="text-[10px] mt-2 flex justify-end">
                                            <Link to='/'>
                                                <span className="font-normal text-customPrimary transition-colors duration-300 delay-200 hover:text-customPrimary hover:underline hover:transition-all hover:delay-300 cursor-pointer">
                                                    Go back to Login
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='mt-8 flex justify-center text-center '>
                                        <button type="submit" className="px-7 text-[12px] select-none mt-4 w-full justify-center py-2 group font-medium text-center inline-flex items-center text-white outline-none bg-customPrimary rounded-sm hover:customPrimary dark:hover:opacity-96 font-public-sans">
                                            Renew License
                                        </button>
                                    </div>
                                </form>
                                <div className='mt-8 text-center'>
                                    <div className='block'>
                                        <p className='text-xs font-public-sans font-normal'>Licensed to: <span className='text-customPrimary'>Preface Technology PVT LTD</span></p>
                                    </div>
                                    <div className='mt-1'>
                                        <p className='text-xs font-public-sans font-normal'>Copyright © 2023. <span className='text-customPrimary'>Preface Technology PVT LTD</span></p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 pt-3 sm:p-7 hidden sm:flex justify-center items-center sm:bg-gradient-to-br rounded-r-md sm:from-gray-50 sm:to-gray-400 sm:bg-transparent rounded-l-md'>
                                <div className='text-center w-full'>
                                    <div className="font-7xl flex justify-center">
                                        <GiWaterSplash className="sm:text-9xl text-7xl text-customPrimary " />
                                    </div>
                                    <h4 className="justify-center font-bold select-none sm:flex hidden mt-2 font-public-sans tracking-tighter text-4xl text-gray-800 dark:text-gray-300">
                                        H2O
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RenewLicenseView