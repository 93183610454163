import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete } from "react-icons/md";
import { deleteDataToTheServer, fetchDataFromApi } from "../../../../widgets/api";
import AppURL from "../../../../widgets/AppURL";
import { ToastWarning } from "../../../../widgets/ToastMessage";
import { ClassRoutineListView } from "../../../../store/academicsSlice";
import ReportLabel from "../../../../widgets/ReportLabel";

const ClassRoutineDetailsListView = () => {
    const timeAMandPMformat=(time)=>{
        let [hours, minutes] = time.split(':');
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert '0' or '12' to '12'
        return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
    }
    
    const { class_routine_list_view, class_routine_search_list_option } = useSelector((state) => state.academics);
    const [activeTab, setActiveTab] = useState('1'); 
    const [scheduleList, setOnScheduleList] = useState([]);
    const dispatch = useDispatch();
    const handleTabChange = (value) => {
        setActiveTab(value?value:'1');
        const RoutineList = class_routine_list_view?.routine?.filter(day => day.dayy === parseInt(value));
        setOnScheduleList(RoutineList || []);
    };
    useEffect(() => {
        handleTabChange(activeTab);
    }, [activeTab, scheduleList]);
    
    const fetchClassRoutineList = async () => {
        try {
            const res = await fetchDataFromApi(`${AppURL.AcademicsURL}academics-class-routine-details-list-view/?class_id=${class_routine_search_list_option?.class_id}&section=${class_routine_search_list_option?.section}&medium=${class_routine_search_list_option?.medium}`);
            dispatch(ClassRoutineListView(res));
        } catch (error) {
            console.log(error);
        }
    };

    const DeleteRoutineListView = async (id) => {
        try {
            const res = await deleteDataToTheServer(`${AppURL.AcademicsURL}academics-student-school-class-routine-delete-view/${id}`);
            ToastWarning(res?.message);
            fetchClassRoutineList();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Fragment>
            <div className='-mt-2.5 mb-2 font-public-sans overflow-x-auto text-[12px] gap-2 flex flex-row'>
            {class_routine_search_list_option?.class_name && <ReportLabel label="Class Name: " value={class_routine_search_list_option?.class_name} />}
            {class_routine_search_list_option?.section_name && <ReportLabel label="Section Name" value={class_routine_search_list_option?.section_name} />}
            {class_routine_search_list_option?.medium_name && <ReportLabel label="Medium" value={class_routine_search_list_option?.medium_name} />}
               
            </div>
           
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate-800'>
                <div className="relative overflow-x-scroll">
                    <Tabs id="custom-animation" value={activeTab}>
                        <TabsHeader className="rounded-none border-b border-blue-gray-50 bg-transparent p-0 z-0 relative" indicatorProps={{
                            className: "bg-transparent border-b-[1px] mx-4 border-green-500 shadow-none rounded-none",
                        }}>
                            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map((day, index) => {
                                const tabIndex = (index + 1).toString();
                                return (
                                    <Tab onClick={()=>handleTabChange(tabIndex)}
                                        className={parseInt(activeTab) === parseInt(tabIndex) ? "text-green-500 font-public-sans text-[13px] font-bold" : "text-slate-700 dark:text-white font-public-sans text-[13px]"}
                                        key={tabIndex} value={tabIndex}>
                                        {day}
                                    </Tab>
                                );
                            })}
                        </TabsHeader>
                        <TabsBody className='z-0 mt-0 px-4 relative mb-0' animate={{ initial: { y: 250 }, mount: { y: 0 }, unmount: { y: 250 } }}>
                            {['1', '2', '3', '4', '5', '6'].map((tab) => (
                                <TabPanel key={tab} value={tab}>
                                    <table className="font-public-sans text-[12px] w-full">
                                        <thead>
                                            <tr className="bg-gray100 font-semibold">
                                                <td align="center" className="px-1.5 py-1 border border-gray-300">S.N.</td>
                                                <td className="px-1.5 py-1 border border-gray-300">Subject Name</td>
                                                <td className="px-1.5 py-1 border border-gray-300">Teacher Name</td>
                                                <td className="px-1.5 py-1 border border-gray-300">Time</td>
                                                <td align="center" className="px-1.5 py-1 border border-gray-300">Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(scheduleList) && scheduleList.length > 0 ? (
                                                scheduleList.map((item, index) => (
                                                    <tr key={index}>
                                                        <td align="center" className="px-1.5 py-0.5 border border-gray-300">{index + 1}.</td>
                                                        <td className="px-1.5 py-0.5 border border-gray-300">{item?.subject_name}</td>
                                                        <td className="px-1.5 py-0.5 border border-gray-300">{`${item.first_name ? item.first_name : ""} ${item.middle_name ? item.middle_name : ""} ${item.last_name ? item.last_name : ""}`}</td>
                                                        <td className="px-1.5 py-0.5 border border-gray-300">{timeAMandPMformat(item?.start_time)} - {timeAMandPMformat(item?.end_time)}</td>
                                                        <td align="center" className="px-1.5 py-0.5 border border-gray-300">
                                                            <button className="p-1.5 bg-gray-400 rounded-full" onClick={async () => await DeleteRoutineListView(item?.sn)}>
                                                                <MdDelete />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={5} align="center" className="px-1.5 py-1 border border-gray-300"><span>No Data Found</span></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </TabPanel>
                            ))}
                        </TabsBody>
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

export default ClassRoutineDetailsListView;
