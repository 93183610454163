import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import TeacherAttendanceFormView from '../../../components/attendance/create/teacher_attendance/TeacherAttendanceFormView';
import TeacherAttedanceListView from '../../../components/attendance/create/teacher_attendance/TeacherAttedanceListView';

const AttendanceTeacherCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, [PageTitle]);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TeacherAttendanceFormView />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TeacherAttedanceListView />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default AttendanceTeacherCreateListView