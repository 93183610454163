import React from 'react'
import { useLanguage } from '../../../../widgets/LangContext';
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';

const VechileRoutinePrintView = () => {
    const { language } = useLanguage();
    const { menu_list } = useSelector((state) => state?.software);
    const { vehicle_routine_rpt } = useSelector((state) => state.academics);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    return (
        <div className='p-5 bg-white h-screen print-portrait'>
            <div className="relative">
                <table className="w-full text-sm text-left rtl:text-right text-black">
                    <tbody>
                        <tr>
                            <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                Reg. No: {Org_Details?.org_reg}
                            </td>
                            <td className="w-[25%]">
                            </td>
                            <td className="w-[25%]">

                            </td>
                            <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                Phone: {Org_Details?.org_phone}
                            </td>

                        </tr>
                        <tr >
                            <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                            </td>
                            <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                {Org_Details?.org_name}
                            </td>

                            <td className="w-[25%]">
                            </td>

                        </tr>
                        <tr >
                            <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                {Org_Details?.org_add}
                            </td>
                            <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                Date: {DateNow}
                            </td>
                        </tr>
                        <tr >
                            <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                Bus Routine Report
                            </td>
                            <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                            Driver Contact: {vehicle_routine_rpt?.bus_details?.driver_contact}
                            </td>
                        </tr>
                       

                    </tbody>
                </table>
            </div>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <div className="relative overflow-x-scroll">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[11px] text-gray600 bg-gray200 capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Bus Stop Name" : "पुरा नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Notice" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Pickup Time" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 w-12">
                                    {language ? "Drop Time" : "रोल नं"}
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(vehicle_routine_rpt?.rotine) && vehicle_routine_rpt?.rotine?.length > 0 ? (
                                vehicle_routine_rpt?.rotine?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[11px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 py-0.5 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td align='left' className="px-2 py-0.5 border font-semibold border-gray300 dark:border-slate600 ">
                                            {cls?.bus_stop_name}
                                        </td>
                                        <td align='left' className="px-2 py-0.5 border border-gray300 dark:border-slate600 ">
                                            {cls?.notice}
                                        </td>
                                        <td align='left' className="px-2 py-0.5 border border-gray300 dark:border-slate600 ">
                                            {cls?.pick_up_time} AM
                                        </td>
                                        <td align='left' className="px-2 py-0.5 border border-gray300 dark:border-slate600 ">
                                            {cls?.drop_time} PM
                                        </td>
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    Student not found.
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
        </div >
    )
}

export default VechileRoutinePrintView