import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import { ExamTypeOptions, StdBatchOptions, StdClassOptions } from '../../../../widgets/Options';
import ReportHeading from '../../../../widgets/ReportHeading';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { MarksLedgerListView, StudentMarksLedgerNumberListView } from '../../../../store/examSlide';

const MarksNumReportFromView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL + "exam-student-marks-filter-subject-exam-wise-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const ExamTypeOption = ExamTypeOptions(onStudentOptionList?.exam_type);
    const BatchOptions = StdBatchOptions(onStudentOptionList?.batch);

    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        exam_type: "",
        batch: ""
    });

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        exam_type: '',
        year: "",
    }
    const { handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(`${AppURL.ExamURL}exam-student-marks-leder-number-report-list-view/?class_id=${selectValues?.class_id?.value}&exam_type=${selectValues?.exam_type?.value}&batch=${selectValues?.batch?.value}`).then((res) => {
                        dispatch(StudentMarksLedgerNumberListView(res));
                        dispatch(MarksLedgerListView({
                            "class": selectValues?.class_id?.label,
                            "batch": selectValues?.batch?.label,
                            "exam_type": selectValues?.exam_type?.label,
                        }))
                        CloseDispatch();
                        navigate("/marks-ledger-number-report-list-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });



    return (
        <div className=''>
            <ReportHeading heading="Marks Report(Number Format)" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="class_id" label="Class" options={StdClassOption} value={selectValues.class_id} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="batch" label="Batch" value={selectValues?.batch} options={BatchOptions} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.batch} errors={errors.batch} required={true} />
                        </Grid>
                        <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
                            <SelectOptionBox name="exam_type" label="Exam Type" options={ExamTypeOption} value={selectValues.exam_type} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.exam_type} errors={errors.exam_type} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-4'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default MarksNumReportFromView