import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const MarksheetStudentListView = ({ setOnSelectedStudent, onSelectedStudent }) => {
    const { marks_ledger_view } = useSelector((state) => state.exam);
    const Ledgers = marks_ledger_view?.data;
    const [students, setStudents] = useState([]);
    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        if (Ledgers) {
            setStudents(Ledgers);
            const uniqueSubjects = [...new Set(Ledgers?.map(subject => subject?.subj_title))];
            setSubjects(uniqueSubjects);
        }
    }, [Ledgers]);

    const groupDetailsData = students.reduce((acc, student) => {
        if (!acc[student?.member_sn_id]) {
            acc[student?.member_sn_id] = {
                member_sn_id: student?.member_sn_id,
                name: student?.studname,
                roll_no: student?.roll_no,
                class: student?.class_name,
                sec: student?.sec_id,
                marks: {},
                studtotalgrade: student?.studtotalgrade,
                studtotalgpa: student?.studtotalgpa,
                selected: false // New property to track selection
            };
        }
        acc[student?.member_sn_id].marks[student?.subj_title] = {
            total: student?.total,
            grade: student?.totalgrade,
            thgrade: student?.thgrade,
            prgrade: student?.prgrade,
        };
        return acc;
    }, {});

    const studentList = groupDetailsData ? Object.values(groupDetailsData) : [];


    return (
        <Fragment>
            <div className='bg-white p-1 rounded-md font-public-sans text-[12px] dark:bg-gray-800'>
                <div className='overflow-x-auto overflow-auto h-[78vh]'>
                    <table width="100%" border={1} className='whitespace-nowrap'>
                        <thead className='top-0 sticky z-10'>
                            <tr className='bg-slate300 text-black dark:text-gray-400 opacity-80 dark:bg-gray-900'>
                                <th rowSpan={3} align='center' className='border border-gray-300 px-2 py-1 w-12'>
                                    S.N.
                                </th>
                                <th rowSpan={3} align='center' className='border border-gray-300 px-2 w-16'>
                                    Roll No.
                                </th>
                                <th rowSpan={3} className='border border-gray-300 px-2'>
                                    Student Name
                                </th>
                                <th rowSpan={3} className='border border-gray-300 px-2 w-24'>
                                    Section
                                </th>
                                <th className='text-center border border-gray-300 px-2' colSpan={subjects.length * 2}>
                                    Subjects
                                </th>
                                <th className='text-center border border-gray-300 px-2' rowSpan={3}>
                                    Grade
                                </th>
                            </tr>
                            <tr className='bg-slate200 dark:bg-gray-800 dark:text-gray-400 text-black opacity-75'>
                                {subjects.map((subject, index) => (
                                    <Fragment key={index}>
                                        <th colSpan={2} className='border border-gray-300 px-2'>{subject}</th>
                                    </Fragment>
                                ))}
                            </tr>
                            <tr className='bg-slate200 dark:bg-gray-800 dark:text-gray-400'>
                                {subjects.map((subject, index) => (
                                    <Fragment key={index}>
                                        <td className='border border-gray-300 px-2' align='center'>Th</td>
                                        <td className='border border-gray-300 px-2' align='center'>Pr</td>
                                    </Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(studentList) && studentList.length > 0 ? (
                                studentList.map((student, index) => (
                                    <tr key={index} className='text-gray-800 dark:text-gray-600'>
                                        <td align='center' className='border px-1 py-1 border-gray-300'>
                                            {index + 1}.
                                        </td>

                                        <td align='center' className='border px-1 py-1 border-gray-300'>{student?.roll_no}</td>
                                        <td className='border px-1 py-1 border-gray-300'>{student?.name}</td>
                                        <td align='center' className='border px-1 py-1 border-gray-300'>{student?.sec}</td>
                                        {subjects.map((subject, subIndex) => (
                                            <Fragment key={subIndex}>
                                                <td key={index} align='center' className={`px-1 border border-gray300 ${student?.marks[subject]?.thgrade === null ? "bg-red-500" : ""}`}>
                                                    {student?.marks[subject] ? student?.marks[subject]?.thgrade : '-'}
                                                </td>
                                                <td align='center' className='px-1 py-1 border border-gray-300'>
                                                    {student?.marks[subject] ? (student?.marks[subject]?.prgrade === null ? '-' : student?.marks[subject]?.prgrade) : '-'}
                                                </td>
                                            </Fragment>
                                        ))}
                                        <td align='center' className='border py-1 border-gray-300 px-1'>{student?.studtotalgrade}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr className='text-gray-800'>
                                    <td align='center' colSpan={20} className='border font-public-sans text-[12px] px-1 py-1.5 border-gray-300'>
                                        No Data Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    );
}

export default MarksheetStudentListView;
