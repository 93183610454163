import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import { Grid } from '@mui/material';
import InputTextBox from '../../../../../widgets/InputTextBox';
import SelectOptionBox from '../../../../../widgets/SelectOptionBox';
import { SaveBtn } from '../../../../../widgets/AppBtn';
import { postDataToTheServer } from '../../../../../widgets/api';
import AppURL from '../../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../../widgets/ToastMessage';
import { useFormik } from 'formik';
import { CustomOptions } from '../../../../../widgets/Options';
import { useLanguage } from '../../../../../widgets/LangContext';
import { color_name_list } from '../../../../../widgets/json';
import ComponentHeading from '../../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";

const AcademicsCalenderEventModal = ({ onModalOpen, onUpdateURL, fetchAcademicsListView, setOnModalOpen }) => {
    const handleOpen = () => setOnModalOpen({ open: false });
    const { language } = useLanguage();
    const [selectValues, setOnSelectValues] = useState({
        vehicle_type: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const ColorTypeOptions = CustomOptions(color_name_list);
    const initialValues = {
        start: onModalOpen.date,
        end: onModalOpen.date,
        color_type: "",
        title: ""
    };

    const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("start", values.start);
                formData.append("color", selectValues.color_type.label);
                formData.append("end", values.end);
                formData.append("title", values.title);
             
                try {
                    await postDataToTheServer(AppURL.AcademicsURL + "academics-calender-event-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        fetchAcademicsListView(onUpdateURL);
                        action.resetForm();
                        setOnSelectValues({
                            color_type: ""
                        });
                        setOnModalOpen({ open: false });
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Dialog open={onModalOpen?.open} className='ml-[120px]' handler={handleOpen}>
            <DialogBody>
                <form onSubmit={handleSubmit} onReset={handleReset} className="px-2 py-2 dark:bg-slate800 bg-white rounded-md ">
                    <ComponentHeading icon={IoMdAddCircle} label={'Add Events'} />
                    <Grid container spacing={1.5}>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <InputTextBox
                                name="start"
                                type="date"
                                placeholder="Enter Start Date"
                                label={language ? "Start Date" : "नाम"}
                                value={values.start}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.start}
                                errors={errors.start}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <InputTextBox
                                name="end"
                                type="date"
                                placeholder="Enter End Date"
                                label={language ? "End Date" : "नाम"}
                                value={values.end}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.end}
                                errors={errors.end}
                                required={true}
                                min={onModalOpen.date}
                            />
                        </Grid>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Color Type" : "सवारी साधनको प्रकार"} placeholder="Select Color Type" value={selectValues.color_type} name="color_type" onFocus={true} options={ColorTypeOptions} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.color_type} errors={errors.color_type} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox
                                name="title"
                                type="text"
                                placeholder="Enter Event Title"
                                label={language ? "Event Title" : "नाम"}
                                value={values.title}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.title}
                                errors={errors.title}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                    <div className="flex justify-end mt-5 gap-2">
                        <SaveBtn type="submit" />
                    </div>
                </form>
            </DialogBody>

        </Dialog>
    )
}

export default AcademicsCalenderEventModal
