import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import StudentAttendanceAddView from '../../../components/attendance/create/std_attend_add/StudentAttendanceAddView';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';

const AttendanceStudentCreateListView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, [PageTitle]);
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
  return (
    <Fragment>
        <StudentAttendanceAddView onStudentOptionList={onStudentOptionList} />
    </Fragment>
  )
}

export default AttendanceStudentCreateListView