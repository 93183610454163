import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from '@mui/material';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SidebarReportClose } from '../../../../store/softSlice';
import { MediumOptions, StdBatchOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { ClassRoutineListView, ClassRoutineSearchListOption } from '../../../../store/academicsSlice';
import { useLanguage } from '../../../../widgets/LangContext';
import { AssignSubjectReportList, AssignSubjectReportURL } from '../../../../store/examSlide';

const SearchAssignSubjectFormView = ({ onStudentOptionList }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const StdBatchOption = StdBatchOptions(onStudentOptionList?.batch);

    const MediumOption = MediumOptions(lanugage_medium);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        batch: "",
    };

    const { handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.ExamURL + "exam-student-get-all-assign-subject-report-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&batch=" + selectValues?.batch?.value,).then((res) => {
                        dispatch(AssignSubjectReportList(res));
                        dispatch(AssignSubjectReportURL(AppURL.ExamURL + "exam-student-get-all-assign-subject-report-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&batch=" + selectValues?.batch?.value));
                        // dispatch(ClassRoutineSearchListOption({
                        //     class_id: selectValues.class_id?.value,
                        //     section: selectValues.section.value,
                        //     medium: selectValues.medium.value,
                        //     class_name: selectValues.class_id.label,
                        //     section_name: selectValues.section.label,
                        //     medium_name: selectValues.medium.label,
                        // }));
                        CloseDispatch();
                        navigate("/exam-assign-subject-report-list-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <div className=''>
            <ReportHeading heading="Assign Subject Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Batch" : "ब्याच"} placeholder="" options={StdBatchOption} value={selectValues.batch} name="batch" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.batch} errors={errors.batch} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>


                    </Grid>
                    <div className='flex justify-between mt-8'>
                        <ClearBtn type="button" disabled={isSubmitting} onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}
export default SearchAssignSubjectFormView