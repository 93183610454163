import React from 'react';
import toast from 'react-hot-toast';


export const ToastSuccess = (mesage) => {
    toast(mesage, {
        className: "text-green-700 text-[13px] font-public-sans rounded-[2px] border-l border-l-4 border-green-600 bg-green-50",
        position: "top-right", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-green-600 font-public-sans">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
    },);
};


export const ToastError = (message) => {
    toast(message, {
        className: "text-red-700 rounded-[2px] text-[13px] drop-shadow-md shadow-red-600/50 border-l border-l-4 border-red-600 font-public-sans bg-red-100",
        position: "top-right", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-red-600 font-public-sans">
            <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
    },);
};

export const ToastWarning = (message) => {
    toast(message, {
        className: "text-yellow-700 rounded-[2px] text-[13px] drop-shadow-md shadow-red-600/50 border-l border-l-4 border-yellow-600 font-public-sans bg-yellow-100",
        position: "top-right", icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-yellow-600">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
        </svg>

    },);
};