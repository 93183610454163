import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import InputTextBox from '../../../widgets/InputTextBox';
import { Grid } from '@mui/material';
import { useLanguage } from '../../../widgets/LangContext';
import TextAreaBox from '../../../widgets/TextAreaBox';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoAddCircle } from 'react-icons/io5';
import { SaveBtn } from '../../../widgets/AppBtn';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';

const HomeworkCreateFormView = () => {
    const { language } = useLanguage();
    const { homework_class_sec_medium_list } = useSelector((state) => state.mobile_app);
    const { id } = useParams();
    const initialValues = {
        class_id: homework_class_sec_medium_list.class_id || "",
        section: homework_class_sec_medium_list.section || "",
        medium: homework_class_sec_medium_list.medium || "",
        homework_title: "",
        homework_des: "",
        files: []
    };

    const { errors, touched, values, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("class_id", values.class_id);
                formData.append("section", values.section);
                formData.append("medium", values.medium);
                formData.append("homework_title", values.homework_title);
                formData.append("homework_des", values.homework_des);
                values.files.forEach(file => {
                    formData.append("files", file);  // Ensure files are correctly appended
                });
                formData.append("subject", id);
                formData.append("status", true);

                try {
                    await postDataToTheServer(AppURL.MobileAppURL + "mobile-app-student-homework-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        action.resetForm();
                    });
                } catch (error) {
                    console.log(error);
                }
                console.log(values);
            },
        });
    const navigate = useNavigate();
    // Function to handle file input change and update form state
    const handleFileChange = (event) => {
        const filesArray = Array.from(event.target.files);
        setFieldValue('files', filesArray);
    };
    useEffect(() => {
        if (!homework_class_sec_medium_list.class_id) {
            navigate("/mobile-app-homework-create-list-view");
        }
    }, [homework_class_sec_medium_list])
    return (
        <div className='bg-white p-4 dark:bg-gray-800 rounded-md'>
            <ComponentHeading icon={IoAddCircle} label={language ? 'Add Homework' : 'कक्षा'} />
            <form onSubmit={handleSubmit} className='mt-3'>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <InputTextBox
                            label={language ? 'Homework Title' : 'कक्षा'}
                            placeholder="Assignment Title"
                            value={values.homework_title}
                            name="homework_title"
                            onBlur={handleBlur}
                            handleChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <TextAreaBox
                            label={language ? 'Homework Description' : 'कार्य'}
                            placeholder="Homework Description"
                            value={values.homework_des}
                            name="homework_des"
                            onBlur={handleBlur}
                            height={4}
                            handleChange={handleChange}
                            error={errors.homework_des && touched.homework_des}
                            helperText={errors.homework_des}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <div className='flex flex-col'>
                            <label htmlFor="file" className='text-[12px] text-gray-700 font-public-sans font-semibold mb-1'>Upload Image</label>
                            <input
                                type='file'
                                id="file"
                                className='text-[12px] text-gray-700 font-public-sans font-normal'
                                multiple
                                onChange={handleFileChange}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <div className='flex justify-end'>
                            <SaveBtn type={'submit'} />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default HomeworkCreateFormView;
