import React, { Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import ReportLabel from '../../../../widgets/ReportLabel';
import { MoneyFormat } from '../../../../widgets/NumberFormat';
import { AiOutlineCaretRight } from "react-icons/ai";

const AccountDuesReportListView = () => {
    const { account_dues_report_list, fee_mapping_options_list } = useSelector((state) => state?.accounts);
    const [onOpenDetails, setOnOpenDetails] = useState(null);
    return (
        <Fragment>
            <div className='font-public-sans overflow-x-auto text-[12px] gap-2 flex flex-row'>
                {fee_mapping_options_list?.class && <ReportLabel label='Class: ' value={fee_mapping_options_list?.class} />}
                {fee_mapping_options_list?.medium && <ReportLabel label={'Medium: '} value={fee_mapping_options_list?.medium} />}
                {fee_mapping_options_list?.section && <ReportLabel label={'Section: '} value={fee_mapping_options_list?.section} />}
            </div>
            <div className='bg-white rounded-md p-1 shadow dark:bg-slate800'>
                <div className='overflow-x-auto overflow-auto h-[78vh]'>
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] top-0 sticky z-10 text-gray700 bg-slate300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600 w-10">
                                    Roll No
                                </th>
                                <th scope="col" align='left' className="px-2 py-1 border dark:border-slate600">
                                    Full Name
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border dark:border-slate600 w-20">
                                    DOB
                                </th>
                                <th scope="col" align='left' className="px-2 py-1 border dark:border-slate600">
                                    Address
                                </th>
                                <th scope="col" align='left' className="px-2 py-1 border dark:border-slate600">
                                    Father Name
                                </th>
                                <th scope="col" align='left' className="px-2 py-1 border dark:border-slate600">
                                    Phone No.
                                </th>
                                <th scope="col" align='left' className="px-2 py-1 border dark:border-slate600">
                                    Total Dues Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(account_dues_report_list?.students) &&
                                account_dues_report_list?.students?.map((student, index) =>
                                (<Fragment key={student?.student?.member_sn}>
                                    <tr className={`odd:bg-white text-[12px] hover:cursor-pointer ${student?.student?.member_sn===onOpenDetails?"!bg-customPrimary text-white":""} hover:bg-slate200 text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700`}>
                                        <td align='center' className="px-2 border font-normal py-1 dark:border-slate600 capitalize">
                                            <div className='flex justify-around'>
                                                <AiOutlineCaretRight onClick={() => setOnOpenDetails(student?.student?.member_sn?student?.student?.member_sn:null)} size={16} title='Fee Details' className='my-auto hover:text-customPrimary' />  <h6>{student?.student?.roll_no}</h6>
                                            </div>
                                        </td>
                                        <td align='left' className="px-2 border font-medium py-1 dark:border-slate600 capitalize">
                                            {`${student?.student?.first_name} ${student?.student?.middle_name ? student?.student?.middle_name : ""} ${student?.student?.last_name}`}
                                        </td>
                                        <td align='center' className="px-2 font-normal py-1 border dark:border-slate600 capitalize">
                                            {student?.student.dobn || "*"}
                                        </td>
                                        <td align='left' className="px-2 font-normal py-1 border dark:border-slate600 capitalize">
                                            {student?.student.add_eng}
                                        </td>
                                        <td align='left' className="px-2 font-normal py-1 border dark:border-slate600 capitalize">
                                            {student?.student.father_name}
                                        </td>
                                        <td align='left' className="px-2 font-normal py-1 border dark:border-slate600 capitalize">
                                            {student?.student.phone || "*"}
                                        </td>
                                        <td align='right' className="px-2 border font-bold py-1 dark:border-slate600">
                                            Rs. {student?.total_amount ? MoneyFormat(student?.total_amount) : "0.00"}
                                        </td>
                                    </tr>
                                    {onOpenDetails === student?.student?.member_sn && <Fragment>
                                        <tr className='text-[12px] top-0 sticky z-10 text-gray700   capitalize border-b dark:text-gray-400 font-public-sans'>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th scope="col" align='center' className="px-2 py-0.5 border bg-slate300 dark:bg-slate900 dark:border-slate600 w-10">
                                                Particular ID
                                            </th>
                                            <th scope="col" align='left' className="px-2 py-0.5 border bg-slate300 dark:bg-slate900 dark:border-slate600">
                                                Particular Name
                                            </th>
                                            <th scope="col" align='center' className="px-2 py-0.5 border bg-slate300 dark:bg-slate900 dark:border-slate600 w-20">
                                                Amount
                                            </th>
                                        </tr>
                                        {
                                            Array.isArray(student?.results) && student?.results?.length>0?
                                           ( student?.results?.map((particular, index) =>
                                            (<tr key={particular?.particularid} className='text-[12px] top-0 sticky z-10 text-gray700 capitalize border-b dark:text-gray-400 font-public-sans'>
                                               <td></td>
                                               <td></td>
                                               <td></td>
                                               <td></td>

                                                <td align='center' className="px-2 font-normal py-0.5 border border-gray-400 dark:border-slate600 w-10">
                                                    {particular?.particularid}
                                                </td>
                                                <td align='left' className="px-2 py-0.5 font-normal border border-gray-400 dark:border-slate600">
                                                    {particular?.particular_name}
                                                </td>
                                                <td align='center' className="px-2 py-0.5 font-normal border border-gray-400 dark:border-slate600 w-20">
                                                    {particular?.amt ? MoneyFormat(particular?.amt) : "0.00"}
                                                </td>
                                            </tr>))):<tr className='text-[12px] top-0 sticky z-10 text-gray700 capitalize border-b dark:text-gray-400 font-public-sans'>
                                            <td></td>
                                               <td></td>
                                               <td></td>
                                               <td></td>
                                                <td align='center' colSpan={3} className="px-2 font-normal py-0.5 border border-gray-400 dark:border-slate600 w-10">
                                                    No Data Found
                                                </td>
                                            </tr>
                                        }
                                    </Fragment>
                                    }
                                </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>
    )
}

export default AccountDuesReportListView