import React from "react";
import { HiAcademicCap } from "react-icons/hi";
import { MdDashboard, MdOutlineMenuBook } from "react-icons/md";
import { MdAccountBox } from "react-icons/md";
import { MdInventory } from "react-icons/md";
import { SiAlipay } from "react-icons/si";
import { FaUsersViewfinder } from "react-icons/fa6";
import { FaUserTie } from "react-icons/fa";
import { HiBookOpen } from "react-icons/hi2";
import { PiExamFill } from "react-icons/pi";
import { TbDeviceMobileFilled } from "react-icons/tb";
import { PiUserRectangleFill } from "react-icons/pi";

export const ImportMenuIcon = (icon) => {
    switch (icon) {
        case 'MdDashboard':
            return <MdDashboard />;
        case 'MdOutlineMenuBook':
            return <MdOutlineMenuBook />
        case 'HiAcademicCap':
            return <HiAcademicCap />
        case 'MdAccountBox':
            return <MdAccountBox />;
        case 'MdInventory':
            return <MdInventory />;
        case 'FaUsersViewfinder':
            return <FaUsersViewfinder />;
        case 'SiAlipay':
            return <SiAlipay />;
        case 'PiUserRectangleFill':
            return <PiUserRectangleFill />;
        case 'HiBookOpen':
            return <HiBookOpen />;
        case 'PiExamFill':
            return <PiExamFill />;
        case 'TbDeviceMobileFilled':
            return <TbDeviceMobileFilled />;
        case 'FaUserTie':
            return <FaUserTie />;
        default:
            return null;
    }
};





