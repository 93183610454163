import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import PhotoUploadTableView from '../../../components/hr/create/photo_uploads/PhotoUploadTableView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';

const HREmpPhotoUploadsCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }));
        setIconsButton(null);
        document.title = `Nexapp - ${PageTitle}`;
    }, []);

    const [onEmployeeList, setOnEmployeeList] = useState([]);
    const url = AppURL.HR_URL+"hr-employee-relation-details-list-view/";
    const fetchAcademicsListView = async (urls) => {
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnEmployeeList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchAcademicsListView(url);
    }
    useEffect(() => {
        FetchData();
    }, []);
    return (
        <Fragment>
            <PhotoUploadTableView fetchAcademicsListView={fetchAcademicsListView} FetchData={FetchData} onEmployeeList={onEmployeeList} />
        </Fragment>
    )
}

export default HREmpPhotoUploadsCreateListView