import React, { Fragment, useEffect, useRef } from 'react';
import FeeMappingRptListView from '../../../../components/account/report/fee_mapping_rpt/FeeMappingRptListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { PdfExportBtn } from '../../../../widgets/AppBtn';
import { useReactToPrint } from 'react-to-print';
import FeeMappingRptPDFView from '../../../../components/account/report/fee_mapping_rpt/FeeMappingRptPDFView';

const FeeMappingReportPageListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(<PdfExportBtn onClick={handlePrintCard} />)
    }, []);
    const componentListRef = useRef();
    const RenderListComponent = React.forwardRef(() => (
        <FeeMappingRptPDFView />
    ));

    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });
    return (
        <Fragment>
            <FeeMappingRptListView />
            <div className='hidden'>
                <div ref={componentListRef}>
                    <RenderListComponent />
                </div>
            </div>
        </Fragment>
    )
}

export default FeeMappingReportPageListView