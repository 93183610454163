import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { Grid } from '@mui/material';
import SearchAdmitCardStudentView from '../../../../components/academics/create/admit_card/SearchAdmitCardStudentView';
import StudentSearchList from '../../../../components/academics/common/studentList/StudentSearchList';
import { CardPrintBtn } from '../../../../widgets/AppBtn';
import StudentAdmitCardPrintView from '../../../../components/academics/create/admit_card/widgets/StudentAdmitCardPrintView';
import { useReactToPrint } from 'react-to-print';

const AcaAdmitCardCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(<CardPrintBtn onClick={handlePrintCard} />);
    }, []);
    const componentListRef = useRef();

    const RenderListComponent = React.forwardRef((props, ref) => {
        const { student_card_list, student_lists } = useSelector((state) => state.academics);

        return (
            <div ref={ref} className="w-full">
              <div className="flex flex-wrap -m-1">
                {Array.isArray(student_card_list) && student_card_list.map((studentId, index) => {
                  const student = student_lists?.students?.find(
                    (student) => student?.member_sn === studentId
                  );
        
                  return (
                    <div className="w-full sm:w-1/2 p-1" key={index}>
                      <div className="card bg-white p-4 shadow rounded">
                        <StudentAdmitCardPrintView student={student} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        });
 
    const [onStdID, setOnStdID] = useState([]);
    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });
    return (
        <Fragment>
            {/* <StudentAdmitCardPrintView /> */}

            <Grid container>
                <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                    <SearchAdmitCardStudentView />
                </Grid>
                <Grid item xl={12} lg={12} sm={12} xs={12} md={12}>
                    <StudentSearchList setOnStdID={setOnStdID} />
                </Grid>
            </Grid>
            <div className='hidden'>
                <div ref={componentListRef}>
                    <RenderListComponent />
                </div>
            </div>


        </Fragment>
    )
}

export default AcaAdmitCardCreateListView