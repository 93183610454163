import React, { Fragment } from 'react'
import { FaChair } from 'react-icons/fa';

const PrintSeatPlanPDFView = ({ studentSeats }) => {
    const renderRows = () => {
        const columns = {};
        studentSeats?.desk_list?.forEach(seat => {
            const { row_number, column_number } = seat;
            const key = `${row_number}-${column_number}`;
            if (!columns[column_number]) {
                columns[column_number] = [];
            }
            columns[column_number].push(seat);
        });
        var desk_no = 1;
        return Object.keys(columns)?.map((columnNumber) => (
            <tr key={columnNumber}>
                {columns[columnNumber]?.map((seat, index) => (
                    <td key={`${seat?.row_number}-${seat?.column_number}-${index}`} className='px-3 py-1'>
                        <div className={`${seat?.status ? "bg-gray100 " : "bg-red-100"} rounded-md flex justify-center`}>
                            <div className='w-full'>
                                <div className='flex flex-row justify-between w-full px-2 pb-0.5'>
                                    {
                                        seat?.seats?.map((seat, index) => (
                                            <div title={seat?.student_name} className={` ${seat?.roll_no ? "border-[1px] border-gray500 px-2 py-2 rounded-sm" : "border-[1px] border-gray500 px-2 py-2 bg-black rounded-sm"} cursor-pointer w-full text-slate800 flex-1 flex-row`}><div>
                                                {
                                                    seat?.roll_no ?
                                                        <Fragment>
                                                            <div className='w-full text-center'>
                                                                <h6 className='text-[10px] font-bold font-public-sans'>Roll No: {seat?.roll_no}</h6>
                                                                <h6 className='text-[10px] font-bold font-public-sans -mt-2'>Class: {seat?.class_name}</h6>
                                                            </div>
                                                        </Fragment> : ""
                                                }

                                            </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                ))}
            </tr>
        ));
    };
    return (
        <div className='px-4 bg-white p-2 rounded-md'>
            <div className='px-1 rounded-sm'>
                <div className='flex justify-between py-1 px-2 my-auto'>
                    <div>
                        <div className='bg-white rounded-full h-10'>
                            {studentSeats?.hall_details?.door_side === 1 &&
                                <div className='flex flex-row px-2'>
                                    <h6 className='my-auto text-[14px] font-public-sans font-bold'>Door Side</h6></div>}
                        </div>
                    </div>
                    <div className={`bg-white rounded-full h-10 ${studentSeats?.hall_details?.door_side === 1 ? "-ml-28" : "ml-28"}`}>
                        <div className='flex flex-row px-2'> <h6 className=' my-auto font-public-sans font-bold'>{studentSeats?.hall_details?.hall_name}</h6></div>
                    </div>
                    <div className='bg-white rounded-full h-10'>
                        {studentSeats?.hall_details?.door_side === 2 &&
                            <div className='flex flex-row px-2 text-[14px]'>
                                <h6 className=' my-auto font-public-sans font-bold'>Door Side</h6></div>}
                    </div>
                </div>
            </div>
            <table className="text-sm text-left rtl:text-right overflow-y-scroll whitespace-nowrap scroll-x-auto rounded w-full">
                <tbody>
                    {renderRows()}
                </tbody>
            </table>

        </div>
    )
}

export default PrintSeatPlanPDFView