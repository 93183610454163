import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import TeacherNotificationFormView from '../../../components/mobile_app/teacher_noti/TeacherNotificationFormView';
import TeacherNotificationListView from '../../../components/mobile_app/teacher_noti/TeacherNotificationListView';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';

const MobileAppTeacherNotificationCreateListView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, []);
    const [onAcaListData, setOnAcaListData] = useState([]);
    const url = AppURL.MobileAppURL + "teacher-notification-create-list-view/";
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const fetchAcademicsListView = async (urls) => {
      setOnUpdateURL(urls);
      try {
        await fetchDataFromApi(urls).then((res) => {
          setOnAcaListData(res);
        });
      } catch (error) {
        console.log(error);
      }
    }
    const FetchData = async () => {
      await fetchAcademicsListView(url);
    }
    useEffect(() => {
      FetchData();
    }, []);


    
  return (
    <Fragment>
        <Grid container spacing={2}>
            <Grid item xl={4} sm={12} xs={12} md={4} lg={4}>
                <TeacherNotificationFormView />
            </Grid>
            <Grid item xl={8} sm={12} xs={12} md={8} lg={8}>
                <TeacherNotificationListView onAcaListData={onAcaListData} />
            </Grid>
        </Grid>
    </Fragment>
  )
}

export default MobileAppTeacherNotificationCreateListView