import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import NewsEventsFromView from '../../../components/mobile_app/news_events/NewsEventsFromView';
import NewsEventsListView from '../../../components/mobile_app/news_events/NewsEventsListView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
const MobileAppNewEventCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, []);

    const [onNewsEventList, setOnNewsEventList] = useState({});
    const fetchNewsEventList = async (url) => {
        try {
            await fetchDataFromApi(url).then((res) => {
                setOnNewsEventList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const fetchDataList = async () => {
        await fetchNewsEventList(AppURL.MobileAppURL + "teacher-news-notice-create-list-view/");
    }
    useEffect(() => {
        fetchDataList();
    }, [])
    const [onEditID, setOnEditID] = useState(false);
    const [onAcaListData, setOnAcaListData] = useState([]);
    const [onUpdateURL, setOnUpdateURL] = useState("");
    return (
        <Fragment>
            {/* NewsEventsEditView */}
            <Grid container spacing={2}>
                <Grid item xl={4} sm={12} xs={12} md={4} lg={4}>
                    <NewsEventsFromView fetchDataList={fetchDataList} />
                </Grid>
                <Grid item xl={8} sm={12} xs={12} md={8} lg={8}>
                    <NewsEventsListView setOnEditID={setOnEditID} fetchNewsEventList={fetchNewsEventList} onNewsEventList={onNewsEventList} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default MobileAppNewEventCreateListView