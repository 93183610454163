import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import MarksScheduleFormView from '../../../components/mobile_app/exam_schedule/MarksScheduleFormView';
import MarkEntryTimeRangeView from '../../../components/mobile_app/exam_schedule/MarkEntryTimeRangeView';

const MobileAppMarksEntryScheduleView = ({ setIconsButton, PageTitle }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }));
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, [PageTitle]);
  
  return (
    <Fragment>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MarksScheduleFormView />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <MarkEntryTimeRangeView />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default MobileAppMarksEntryScheduleView