import { useLanguage } from "./LangContext";
export const BranchOptions = [
    { value: "1", label: "School" },
];
export const GeneralLedgerTypeOptions = (gl_type_list) => {
    const { language } = useLanguage();
    if (gl_type_list && Array.isArray(gl_type_list)) {
        return gl_type_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};

export const OptionalGroupOptions = (items)=>{
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: item?.sn + (". ") + item?.optional_grp_name 
        }));
    } else {
        return [];
    }
}
export const SubLedgerTypeModeOptions = (sub_led_type_list) => {
    const { language } = useLanguage();
    if (sub_led_type_list && Array.isArray(sub_led_type_list)) {
        return sub_led_type_list.map((item, index) => ({
            value: item.sn.toString(),
            label: language ? item.sub_ledger_type_name : item.sub_ledger_type_name_nep
        }));
    } else {
        return [];
    }
};

export const SubjectCategoryOptions = (items)=>{
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: item?.sn + (". ") + item?.subj_cat_name 
        }));
    } else {
        return [];
    }
}

export const BookAuthorOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.author_id?.toString(),
            label: language ? item?.author_id + (". ") + item?.author_name : item?.author_id + (". ") + item?.author_name_nep
        }));
    } else {
        return [];
    }
};

export const BookPublicationOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.pub_id?.toString(),
            label: language ? (item?.pub_id + (". ") + item?.pub_name) : (item?.pub_id + (". ") + item?.pub_name_nep)
        }));
    } else {
        return [];
    }
};

export const VehicleOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? (item?.sn + (". ") + item?.vehicle_number) : (item?.sn + (". ") + item?.vehicle_number)
        }));
    } else {
        return [];
    }
};

export const GalleryCategoryOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? (item?.sn + (". ") + item?.name) : (item?.sn + (". ") + item?.name)
        }));
    } else {
        return [];
    }
};


export const BookCategoryOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? item?.sn + (". ") + item?.book_cate_name : item?.sn + (". ") + item?.book_cate_name_nep
        }));
    } else {
        return [];
    }
};
export const BookKindOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};

export const BookTypeOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};
export const MemberTypeOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};
export const IssuedForOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value?.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};
export const YesNoOptions = (yes_no) => {
    const { language } = useLanguage();
    if (yes_no && Array.isArray(yes_no)) {
        return yes_no.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};

export const AppStudentListOptions = (student_lists) => {
    if (student_lists && Array.isArray(student_lists)) {
        return student_lists.map((item, index) => ({
            value: item.member_sn?.toString(),
            label: `${item?.member_sn}. ${item?.first_name?item?.first_name:""} ${item?.middle_name?item?.middle_name:""} ${item?.last_name?item?.last_name:""} | Roll No: ${item?.roll_no?item?.roll_no:""} | ${item?.phone?item?.phone:""} `
        }));
    } else {
        return [];
    }
};
export const MarriedOptions = (married_status_list) => {
    const { language } = useLanguage();
    if (married_status_list && Array.isArray(married_status_list)) {
        return married_status_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep
        }));
    } else {
        return [];
    }
};
export const MenuOptions = [
    { value: "1", label: "Application" },
    { value: "2", label: "Account" },
    { value: "3", label: "Academics" },
    { value: "4", label: "Inventory" },
    { value: "5", label: "Attendance" },
    { value: "6", label: "HR" },
    { value: "7", label: "Library" },
    { value: "8", label: "Exam" },
    { value: "9", label: "Mobile App" },



];
export const MonthOptions = [
    { value: "01", label: "April (बैशाख)" },
    { value: "02", label: "May (जेठ)" },
    { value: "03", label: "June (असार)" },
    { value: "04", label: "July (साउन)" },
    { value: "05", label: "August (भदौ)" },
    { value: "06", label: "September (असोज)" },
    { value: "07", label: "October (कार्तिक)" },
    { value: "08", label: "November (मंसिर)" },
    { value: "09", label: "December (पौष)" },
    { value: "10", label: "January (माघ)" },
    { value: "11", label: "February (फागुन)" },
    { value: "12", label: "March (चैत्र)" },
];


export const MahasulIncomeReportOptions = [
    { value: "0", label: "Income Details Report(आय विवरण रिपोर्ट)" },
    { value: "1", label: "Daily Income Report(दैनिक आय रिपोर्ट)" },
    { value: "2", label: "Monthly Income Report(मासिक आय प्रतिवेदन)" },

];
export const RollNoGenerateTypeOptions = [
    { value: "1", label: "Auto Generate Default" },
    { value: "2", label: "Alphabets Name" },
    { value: "3", label: "Gender" },
    { value: "4", label: "Date Of Birth" },
    { value: "5", label: "Admission Date Wise" },
    { value: "6", label: "Student Serial Number" },

];

export const MasterCategoryOptions = (category) => {
    // Master Category Options
    if (category && Array?.isArray(category)) {
        return category?.map((cate, index) => ({
            value: cate?.product_master_id?.toString(),
            label: cate?.product_master_id + ". " + cate?.product_master_name,
        }));
    } else {
        return [];
    }
}

export const SubMasterOptions = (subMaster) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language
    if (subMaster && Array?.isArray(subMaster)) {
        return subMaster?.map((item, index) => ({
            value: item?.product_sub_master_id?.toString(),
            label: language ? item?.product_sub_master_id + ". " + item?.product_sub_master_name : item?.product_sub_master_id + ". " + item?.product_sub_master_name_nep,
        }));
    } else {
        return [];
    }
}

export const ItemUnitListOptions = (units) => {
    // General Ledger Options
    if (units && Array?.isArray(units)) {
        return units?.map((item, index) => ({
            value: item?.unit_id?.toString(),
            label: item?.unit_id + ". " + item?.short_name,
        }));
    } else {
        return [];
    }
}

export const FiscalYearOptions = (fiscal_year) => {
    // General Ledger Options
    if (fiscal_year && Array?.isArray(fiscal_year)) {
        return fiscal_year?.map((item, index) => ({
            value: item?.fiscal_year_id?.toString(),
            label: item?.fiscal_year_id + ". " + item?.fiscal_year_name,
        }));
    } else {
        return [];
    }
}
export const ActiveInactiveOptions = (active_inactive_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (active_inactive_list && Array.isArray(active_inactive_list)) {
        return active_inactive_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};
export const CustomOptions = (items) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};
export const MonthListOptions = (month_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (month_list && Array.isArray(month_list)) {
        return month_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? `${item?.value}. ${item.label}` : `${item?.value}. ${item.label_nep}` // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};
export const DebitCreaditOptions = (debit_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (debit_list && Array.isArray(debit_list)) {
        return debit_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};
export const TrasactionOptions = (trans) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (trans && Array.isArray(trans)) {
        return trans.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const ShiftOptions = [
    { value: "0", label: "Day" },
    { value: "1", label: "Night" },
    { value: "3", label: "Morning" },

];
export const StockReportOptions = [
    { value: "0", label: "Total Purchase Report" },
    { value: "1", label: "Total Sales Report" },
    { value: "2", label: "Total Issue Report" },
    { value: "3", label: "Total Available Report" },
];
export const AcGeneralLedgOptions = (ledg_list) => {
    const { language } = useLanguage();
    if (ledg_list && Array?.isArray(ledg_list)) {
        return ledg_list?.map((item, index) => ({
            value: item?.glid?.toString(),
            label: language ? item?.glid + ". " + item?.gl_name : item?.glid + ". " + item?.gl_name_nep,
        }));
    } else {
        return [];
    }
}
export const ParticularOptions = (particulars) => {
    const { language } = useLanguage();
    if (particulars && Array?.isArray(particulars)) {
        return particulars?.map((item, index) => ({
            value: item?.particular_id?.toString(),
            label: language ? item?.particular_id + ". " + item?.particular_name : item?.particular_id + ". " + item?.particular_name_nep,
            is_debit: item?.is_debit,
        }));
    } else {
        return [];
    }
}
export const ScholarshipStyleOptions = (items) => {
    const { language } = useLanguage();
    if (items && Array?.isArray(items)) {
        return items?.map((item, index) => ({
            value: item?.scholarship_styles_id?.toString(),
            label: language ? index + 1 + ". " + item?.scholarship_styles_name : index + 1 + ". " + item?.scholarship_styles_name_dev,
        }));
    } else {
        return [];
    }
}
export const StdClassOptions = (std_class) => {
    const { language } = useLanguage();
    if (std_class && Array?.isArray(std_class)) {
        return std_class?.map((item, index) => ({
            value: item?.class_id?.toString(),
            label: language ? index + 1 + ". " + item?.class_name : index + 1 + ". " + item?.class_name_nep,
        }));
    } else {
        return [];
    }
}


export const ExamTypeOptions = (exam_types) => {
    const { language } = useLanguage();
    if (exam_types && Array?.isArray(exam_types)) {
        return exam_types?.map((item, index) => ({
            value: item?.ex_type_id?.toString(),
            name: item?.ex_type_name,
            label: language ? index + 1 + ". " + item?.ex_type_name : index + 1 + ". " + item?.ex_type_name_nep,
            fm: item?.ex_type_fm,
            p_fm: item?.ex_mrg_perc,
            pm:item?.ex_type_pm
        }));
    } else {
        return [];
    }
}

export const StdBatchOptions = (batch_lists) => {
    if (batch_lists && Array?.isArray(batch_lists)) {
        return batch_lists?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: index + 1 + ". " + item?.batch_name,
        }));
    } else {
        return [];
    }
}
export const FeeTermsOptions = (fee_terms) => {
    const { language } = useLanguage();
    if (fee_terms && Array?.isArray(fee_terms)) {
        return fee_terms?.map((item, index) => ({
            value: item?.fee_term_id?.toString(),
            label: language ? index + 1 + ". " + item?.fee_term_name : index + 1 + ". " + item?.fee_term_name_nep,
        }));
    } else {
        return [];
    }
}
export const StdSectionOptions = (std_section) => {
    const { language } = useLanguage();
    if (std_section && Array?.isArray(std_section)) {
        return std_section?.map((item, index) => ({
            value: item?.section_id?.toString(),
            label: language ? index + 1 + ". " + item?.sec_name : index + 1 + ". " + item?.sec_name_nep,
        }));
    } else {
        return [];
    }
}
export const StdTeamOptions = (std_team) => {
    const { language } = useLanguage();
    if (std_team && Array?.isArray(std_team)) {
        return std_team?.map((item, index) => ({
            value: item?.team_id?.toString(),
            label: language ? index + 1 + ". " + item?.team_name : index + 1 + ". " + item?.team_name_nep,
        }));
    } else {
        return [];
    }
}
export const StudentListOptions = (students) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language
    if (students && Array.isArray(students)) {
        return students.map((item, index) => ({
            value: item.member_sn.toString(),
            label: language ? `${item?.member_sn}. ${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}` : item?.name_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};


export const StudentsOptions = (students) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language
    if (students && Array.isArray(students)) {
        return students?.map((item, index) => ({
            value: item?.member_sn?.toString(),
            label: language ? `${item?.member_sn}. ${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}` : item?.name_nep, // Use the current language to choose the label
            class_id: item?.roll_no.toString(),
            add_eng: item?.add_eng,
            roll_no: item?.roll_no,
            photo: item?.photo,
            class_name: item?.class_name
        }));
    } else {
        return [];
    }
};


export const MediumOptions = (lanugage_medium) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (lanugage_medium && Array.isArray(lanugage_medium)) {
        return lanugage_medium.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const PaymentTypeOptions = (items) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const StudentTypeOptions = (type_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (type_list && Array.isArray(type_list)) {
        return type_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};
export const GenderOptions = (gender_list) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (gender_list && Array.isArray(gender_list)) {
        return gender_list.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.label_nep // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};


export const PurchaseStatusOptions = (items) => {
    const { language } = useLanguage(); // Assuming useLanguage() is defined and correctly returns the current language

    if (items && Array.isArray(items)) {
        return items.map((item, index) => ({
            value: item.value.toString(),
            label: language ? item.label : item.nep_label // Use the current language to choose the label
        }));
    } else {
        return [];
    }
};

export const YearSemesterOptions = (year_sem) => {
    // General Ledger Options
    if (year_sem && Array?.isArray(year_sem)) {
        return year_sem?.map((item, index) => ({
            value: item?.YearSemesterID?.toString(),
            label: item?.YearSemesterID + ". " + item?.YearSemesterName + "(" + item?.YearSemesterNameNep + ")",
        }));
    } else {
        return [];
    }
}

export const SubjectListOptions = (dest) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (dest && Array?.isArray(dest)) {
        return dest?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? index+1+". "+item?.subj_name : index+1+". "+item?.subj_name_nep // Use the current language to choose the label

        }));
    } else {
        return [];
    }
}

export const DestinationOptions = (dest) => {
    // General Ledger Options
    if (dest && Array?.isArray(dest)) {
        return dest?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: index + 1 + ". " + item?.desti_name + "(" + item?.desti_name_nep + ")",
        }));
    } else {
        return [];
    }
}

export const DesignationOptions = (dest) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (dest && Array?.isArray(dest)) {
        return dest?.map((item, index) => ({
            value: item?.desig_id?.toString(),
            label: language ? item?.desig_name : item?.desti_name_nep // Use the current language to choose the label

        }));
    } else {
        return [];
    }
}
export const MemberCategoryOptions = (member_category) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (member_category && Array?.isArray(member_category)) {
        return member_category?.map((item, index) => ({
            value: item?.mc_id?.toString(),
            label: language ? item?.mc_id + ". " + item?.mc_name : item?.mc_id + ". " + item?.mc_name_nep // Use the current language to choose the label

        }));
    } else {
        return [];
    }
}
export const colorSwitch = (status) => {
    switch (status) {
      case 'Present':
        return 'bg-green-600 border border-green-500'; // Green background for Present
      case 'Absent':
        return 'bg-red-600 border border-red-500'; // Red background for Absent
      case 'Leave':
        return 'bg-yellow-500 border border-yellow-500'; // Yellow background for Leave
      case 'Late':
        return 'bg-blue-600 border border-blue-500'; // Blue background for Late
      default:
        return 'bg-gray-400 border border-gray-500'; // Default grey background
    }
}

  

export const EmployeeOptions = (emps) => {
    if (emps && Array?.isArray(emps)) {
        return emps?.map((emp, index) => ({
            value: emp?.emp_salary_id?.toString(),
            label: emp?.member_sn + ". " + emp?.first_name?emp?.first_name:" "+ emp?.middle_name?emp?.middle_name:""+ emp?.last_name?emp?.last_name:"",
            branch_id: emp?.branch_id,
            designation: emp?.designation,
            grade_amt: emp?.grade_amt,
            grade_count: emp?.grade_count,
            initail_salary: emp?.initail_salary,
            pf_amount: emp?.pf_amount,
            pf_status: emp?.pf_status,
            pf_thap_perc: emp?.pf_thap_perc,
            ss_tax: emp?.ss_tax,
            tax_amt: emp?.tax_amt
        }));
    } else {
        return [];
    }
}


export const TeacherOptions = (emps) => {
    if (emps && Array?.isArray(emps)) {
        return emps?.map((emp, index) => ({
            value: emp?.member_sn?.toString(),
            label: `${emp?.member_sn}. ${emp?.first_name? emp?.first_name:""} ${emp?.middle_name? emp?.middle_name:""} ${emp?.last_name? emp?.last_name:""}`,
           
        }));
    } else {
        return [];
    }
}

export const ProgramOptions = (programs) => {
    // General Ledger Options
    if (programs && Array?.isArray(programs)) {
        return programs?.map((program, index) => ({
            value: program?.ProgramID?.toString(),
            label: program?.ProgramID + ". " + program?.ProgramTitle + "(" + program?.ProgramDev + ")",
        }));
    } else {
        return [];
    }
}

export const SubMenuOptions = (sub_menu) => {
    // General Ledger Options
    if (sub_menu && Array?.isArray(sub_menu)) {
        return sub_menu?.map((item, index) => ({
            value: item?.sub_m_id?.toString(),
            label: item?.sub_m_id + ". " + item?.name + "(" + item?.name_nep + ")",
        }));
    } else {
        return [];
    }
}

export const LevelOptions = (levels) => {
    // General Ledger Options
    if (levels && Array?.isArray(levels)) {
        return levels?.map((level, index) => ({
            value: level?.LevelID?.toString(),
            label: level?.LevelID + ". " + level?.LevelName + "(" + level?.AcLevelNameDev + ")",
        }));
    } else {
        return [];
    }
}

export const FacultyOptions = (faculties) => {
    const { language } = useLanguage();

    // General Ledger Options
    if (faculties && Array?.isArray(faculties)) {
        return faculties?.map((faculty, index) => ({
            value: faculty?.FacID?.toString(),
            labelL: `${language ? faculty?.FacName : faculty?.FacNameDev}`
        }));
    } else {
        return [];
    }
}
export const SectionOptions = (sections) => {
    // General Ledger Options
    if (sections && Array?.isArray(sections)) {
        return sections?.map((sec, index) => ({
            value: sec?.SectionID?.toString(),
            label: sec?.SectionID + ". " + sec?.SectionName + "(" + sec?.SectionNameNep + ")",
        }));
    } else {
        return [];
    }
}

export const HallRoomOptions = (hall_lists) => {
    // General Ledger Options
    if (hall_lists && Array?.isArray(hall_lists)) {
        return hall_lists?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: item?.sn + ". " + item?.hall_name,
        }));
    } else {
        return [];
    }
}

export const TeamOptions = (teams) => {
    // General Ledger Options
    if (teams && Array?.isArray(teams)) {
        return teams?.map((team, index) => ({
            value: team?.TeamID?.toString(),
            label: team?.TeamID + ". " + team?.TeamName + "(" + team?.TeamNameNep + ")",
        }));
    } else {
        return [];
    }
}

export const ShiftsOptions = (shifts) => {
    // General Ledger Options
    if (shifts && Array?.isArray(shifts)) {
        return shifts?.map((shift, index) => ({
            value: shift?.ShiftID?.toString(),
            label: shift?.ShiftID + ". " + shift?.ShiftName + "(" + shift?.ShiftNameNep + ")",
        }));
    } else {
        return [];
    }
}

export const ScholarshipOptions = (scholarship_list) => {
    const { language } = useLanguage();

    if (scholarship_list && Array.isArray(scholarship_list)) {
        return scholarship_list?.map((ledger, index) => ({
            value: ledger?.scholarship_id?.toString(),
            label: `${language ? index + 1 + ". " + ledger?.scholarship_name : index + 1 + ". " + ledger?.scholarship_name_dev}`,
        }))
    }
}


export const ScholarshipFeesOptions = (scholarship_list) => {

    if (scholarship_list && Array.isArray(scholarship_list)) {
        return scholarship_list?.map((ledger, index) => ({
            value: ledger?.sn?.toString(),
            label: ledger?.particular_name + "(" + ledger?.amount + ")",
        }))
    }
}



export const ProductSuppilerOptions = (suppiler) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (suppiler && Array?.isArray(suppiler)) {
        return suppiler?.map((item, index) => ({
            value: item?.sup_id?.toString(),
            label: `${language ? index + 1 + ". " + item?.sup_name : index + 1 + ". " + item?.sup_name_nep}`,
        }));
    } else {
        return [];
    }
}


export const ProductStoreOptions = (store) => {
    const { language } = useLanguage();
    if (store && Array?.isArray(store)) {
        return store?.map((item, index) => ({
            value: item?.store_id?.toString(),
            label: `${language ? item?.store_id + ". " + item?.store_name : item?.store_id + ". " + item?.store_name_nep}`,
        }));
    } else {
        return [];
    }
}
export const ProductOptionsListOptions = (products) => {
    const { language } = useLanguage();

    if (products && Array?.isArray(products)) {
        return products?.map((product, index) => ({
            value: product?.product?.product_id,
            label: `${language ? product?.product?.product_id + ". " + product?.product?.product_name : product?.product?.product_id + ". " + product?.product?.product_name_nep}`,
            price: product?.product?.purchase_rate,
            available: product?.available_quantity
        }));
    } else {
        return [];
    }
}
export const ProductSellingOptionsListOptions = (products) => {
    const { language } = useLanguage();

    if (products && Array?.isArray(products)) {
        return products?.map((item, index) => ({
            value: item?.product?.product_id,
            label: `${language ? item?.product?.product_id + ". " + item?.product?.product_name : item?.product_id + ". " + item?.product?.product_name_nep}`,
            price: item?.product?.sales_rate,
            available: item?.available_quantity
        }));
    } else {
        return [];
    }
}


export const SubLedgersOptions = (ledger) => {
    const { language } = useLanguage();

    if (ledger && Array.isArray(ledger)) {
        return ledger?.map((ledger, index) => ({
            value: ledger?.sglsn?.toString(),
            label: `${language ? ledger?.sglsn + (". ") + ledger?.sub_ledg_name : ledger?.sglsn + (". ") + ledger?.sub_ledg_name_nep}`,
            userShow: ledger?.mem_cate_id
        }))
    }
}

export const majorSubjectOptions = (major_subject) => {
    // General Ledger Options
    if (major_subject && Array?.isArray(major_subject)) {
        return major_subject?.map((ms_data, index) => ({
            value: ms_data?.SN?.toString(),
            label: ms_data?.SN + ". " + ms_data?.MSubjectName + "(" + ms_data?.MSubjectNameNep + ")",
        }));
    } else {
        return [];
    }
}


export const SubCasteOptions = (onSubCasteData) => {
    const { language } = useLanguage();
    // General Ledger Options
    if (onSubCasteData && Array?.isArray(onSubCasteData)) {
        return onSubCasteData?.map((sub_caste, index) => ({
            value: sub_caste?.sub_caste_id?.toString(),
            label: `${language ? sub_caste?.sub_caste_id + (". ") + sub_caste?.sub_caste_name : sub_caste?.sub_caste_id + (". ") + sub_caste?.sub_caste_name_nep}`,
        }));
    } else {
        return [];
    }
}

export const TransportationOptions = (transportation) => {
    const { language } = useLanguage(); // Assuming `useLanguage` is properly defined
    if (transportation && Array?.isArray(transportation)) {
        return transportation?.map((item, index) => ({
            value: item?.sn?.toString(),
            label: language ? index + 1 + ". " + item?.transport_name : index + 1 + ". " + item?.transport_name_nep,
        }));
    } else {
        return [];
    }
}
export const CasteOptions = (castes) => {
    const { language } = useLanguage(); // Assuming `useLanguage` is properly defined
    if (castes && Array?.isArray(castes)) {
        return castes?.map((item, index) => ({
            value: item?.caste_id?.toString(),
            label: language ? index + 1 + ". " + item?.caste_name : index + 1 + ". " + item?.caste_name_nep,
        }));
    } else {
        return [];
    }
}
export const ProvinceOptions = (province) => {
    const { language } = useLanguage(); // Assuming `useLanguage` is properly defined

    if (province && Array.isArray(province)) {
        return province.map((item, index) => ({
            value: item?.province_id.toString(),
            label: language ? index + 1 + ". " + item?.province_name : index + 1 + ". " + item?.province_name_nep,
        }));
    } else {
        return [];
    }
}


export const DistrictOptions = (districtData) => {
    const { language } = useLanguage();
    if (districtData && Array?.isArray(districtData)) {
        return districtData?.map((district, index) => ({
            value: district?.district_id?.toString(),
            label: language ? index + 1 + ". " + district?.district_name : index + 1 + ". " + district?.district_name_nep,
        }));
    } else {
        return [];
    }
}

export const MunOptions = (munData) => {
    const { language } = useLanguage();

    // General Ledger Options
    if (munData && Array?.isArray(munData)) {
        return munData?.map((mun, index) => ({
            value: mun?.muni_id?.toString(),
            label: language ? index + 1 + ". " + mun?.muni_name : index + 1 + ". " + mun?.muni_name_nep,
        }));
    } else {
        return [];
    }
}
