import React, { Fragment } from 'react';
import TransReportFormView from '../../../../components/account/report/trans_rpt/TransReportFormView';

const TransactionReportCreateFormView = () => {
  return (
    <Fragment>
        <TransReportFormView />
    </Fragment>
  )
}

export default TransactionReportCreateFormView