import React, { Fragment, useEffect, useState } from 'react'
import ContentHeading from '../../../widgets/ContentHeading'
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../widgets/LangContext';
import { useFormik } from 'formik';
import { SaveBtn } from '../../../widgets/AppBtn';
import { FaSearch } from "react-icons/fa";
import { fetchDataFromApi, postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ExamTypeOptions, MediumOptions, StdClassOptions, StdSectionOptions } from '../../../widgets/Options';
import { lanugage_medium } from '../../../widgets/json';
import { useDispatch } from "react-redux";
import { MarksScheduleListView } from '../../../store/examSlide';
import InputTextBox from '../../../widgets/InputTextBox';
import { ToastSuccess } from '../../../widgets/ToastMessage';

const MarksScheduleFormView = () => {
    const { language } = useLanguage();
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const dispatch = useDispatch();
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL + "exam-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    const lang = lanugage_medium;
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const MediumOption = MediumOptions(lang);
    const ExamTypeOption = ExamTypeOptions(onStudentOptionList?.exam_type);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
        exam_type: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
        exam_type: "",
        end_date: "",
        start_date: "",
    };

    const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("class_id", selectValues.class_id.value);
                formData.append("section", selectValues.section?.value);
                formData.append("medium", selectValues.medium?.value);
                formData.append("exam_type", selectValues.exam_type?.value);
                formData.append("end_date", values.end_date);
                formData.append("start_date", values?.start_date);
                try {
                    await postDataToTheServer(AppURL.MobileAppURL + `marks-entry-schedule-create-list-view/`, formData).then((res) => {
                        ToastSuccess(res?.message);
                        fetchMarksEntryScheduleList();
                        action.resetForm();
                        setOnSelectValues({
                            class_id: "",
                            section: "",
                            medium: "",
                            exam_type: "",
                        });
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
        
    const fetchMarksEntryScheduleList = async (class_id, section, medium, exam_type) => {
        try {
            await fetchDataFromApi(AppURL.MobileAppURL + `marks-entry-schedule-create-list-view/?class_id=${class_id}&section=${section}&medium=${medium}&exam_type=${exam_type}`).then((res) => {
                dispatch(MarksScheduleListView(res));
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchMarksEntryScheduleList(selectValues.class_id.value, selectValues.section?.value, selectValues.medium?.value, selectValues.exam_type?.value)
    }, [selectValues.class_id.value, selectValues.section?.value, selectValues.medium?.value, selectValues.exam_type?.value])
    return (
        <Fragment>
            <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
                <ContentHeading label="Search Student" icon={FaSearch} margin={0} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Exam Type" : "परीक्षा प्रकार"} placeholder="" options={ExamTypeOption} value={selectValues.exam_type} name="exam_type" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.exam_type} errors={errors.exam_type} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <InputTextBox
                                name="start_date"
                                type="date"
                                placeholder=""
                                label={language ? "Start Date" : "नाम"}
                                value={values.start_date}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.start_date}
                                errors={errors.start_date}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <InputTextBox
                                name="end_date"
                                type="date"
                                placeholder=""
                                label={language ? "End Date" : "नाम"}
                                value={values.end_date}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.end_date}
                                errors={errors.end_date}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <div className='flex justify-end'>
                                <SaveBtn type="submit" />
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Fragment>
    )
}

export default MarksScheduleFormView