
import { Grid } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import WeeklyLessionPlanCreateView from '../../../../components/academics/create/weekly_lession_plan/WeeklyLessionPlanCreateView';
import WeeklyLessionPlanListView from '../../../../components/academics/create/weekly_lession_plan/WeeklyLessionPlanListView';

const WeeklyLessionPlanCreateListView = ({setIconsButton, PageTitle}) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(pageTitle({ "title": PageTitle }))
      document.title = `Nexapp - ${PageTitle}`;
      setIconsButton(null);
    }, []);
  
    const [onEditID, setOnEditID] = useState(false);
    const [onAcaListData, setOnAcaListData] = useState([]);
    const url = AppURL.AcademicsURL + "academics-teacher-weekly-lession-plan-create-list-view/";
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const fetchAcademicsListView = async (urls) => {
        setOnUpdateURL(urls);
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnAcaListData(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchAcademicsListView(url);
    }
    useEffect(() => {
        FetchData();
    }, []);


    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-teacher-daily-log-book-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xl={4} lg={4} md={4} xs={12} sm={12}>
                    <WeeklyLessionPlanCreateView FetchData={FetchData} onStudentOptionList={onStudentOptionList} />
                </Grid>
                <Grid item xl={8} lg={8} md={8} xs={12} sm={12}>
                    <WeeklyLessionPlanListView onAcaListData={onAcaListData} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default WeeklyLessionPlanCreateListView