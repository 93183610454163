import React from 'react'
import { Chip } from "@material-tailwind/react";

export const ActiveChip = ({ label }) => {
    return (
        <Chip
            className='px-2 w-fit py-1 flex-initial flex flex-col text-[11px] dark:bg-green-500 dark:text-white justify-center capitalize font-public-sans'
            variant="ghost"
            color="green"
            size="sm"
            value={label}
            icon={
                <span className="mx-auto mt-1 h-2 w-2 flex justify-center rounded-full dark:bg-white bg-green-900 content-['']" />
            }
        />

    )
}

export const InActiveChip = ({ label }) => {
    return (
        <Chip className='px-2 py-1 w-fit flex-initial text-[11px] dark:text-white dark:bg-red-400 flex justify-center capitalize font-public-sans'
            variant="ghost"
            color="red"
            size="sm"
            value={label}
            icon={
                <span className="mx-auto mt-1 block h-2 w-2 dark:bg-white rounded-full bg-red-900 content-['']" />
            }
        />
    )
}