import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { Grid } from '@mui/material';
import ClassConfigureFormView from '../../../components/exam/setup/class_configure/ClassConfigureFormView';
import ClassConfigureClassListView from '../../../components/exam/setup/class_configure/ClassConfigureClassListView';

const ExamClassConfigureExamHallView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    return (
        <div>
            
            <Grid container spacing={2}>
                <Grid item xl={12} xs={12} sm={12} md={12} lg={12}>
                    <ClassConfigureFormView />
                </Grid>
                <Grid item xl={12} xs={12} sm={12} md={12} lg={12}>
                    <ClassConfigureClassListView />
                </Grid>
            </Grid>
        </div>
    )
}

export default ExamClassConfigureExamHallView