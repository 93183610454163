
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import { useFormik } from 'formik';
import { FaSearch } from "react-icons/fa";
import { useLanguage } from '../../../../../widgets/LangContext';
import { lanugage_medium } from '../../../../../widgets/json';
import { MediumOptions, StdClassOptions, StdSectionOptions } from '../../../../../widgets/Options';
import ContentHeading from '../../../../../widgets/ContentHeading';
import SelectOptionBox from '../../../../../widgets/SelectOptionBox';
import AppURL from '../../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../../widgets/api';
import { SearchBtn } from '../../../../../widgets/AppBtn';
import InputNumber from '../../../../../widgets/InputNumber';
import { NepNowDateFormat } from '../../../../../widgets/DateFormat';
import { Attendance_Student_List_Search, Clear_Attendance_Student_List_Search } from '../../../../../store/attendanceSlice';
import { useDispatch } from 'react-redux';

const SearchStudentFormVIew = ({ onStudentOptionList }) => {
    const { language } = useLanguage();
    const lang = lanugage_medium;
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const MediumOption = MediumOptions(lang);
    const dispatch = useDispatch();
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
    });
    const Date_Nep = NepNowDateFormat();
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
        date_ne: Date_Nep,
    };
    const { errors, values, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("class_id", selectValues.class_id.value);
                formData.append("section", selectValues.section?.value);
                formData.append("medium", selectValues.medium?.value);
                try {
                    dispatch(Clear_Attendance_Student_List_Search());
                    const res = await fetchDataFromApi(AppURL.AttendanceURL + `student-attendace-student-search-list-view/?class_id=${selectValues.class_id.value}&section=${selectValues.section?.value}&medium=${selectValues.medium?.value}`);
                    dispatch(Attendance_Student_List_Search(res));
                    localStorage.setItem('attend_date', values.date_ne);
                } catch (error) {
                    console.log(error);
                }
            },
        });
    useEffect(() => {
        dispatch(Clear_Attendance_Student_List_Search());
    }, [values.date_ne, selectValues.class_id.value, selectValues.section?.value, selectValues.medium?.value]);

    return (
        <Fragment>
            <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
                <ContentHeading label="Search Student" icon={FaSearch} margin={0} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <InputNumber name="date_ne" value={values.date_ne} handleChange={handleChange} mask="9999/99/99" placeholder={''} required={true} label={language ? "Date" : "मिति (BS)"} />
                        </Grid>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <div className='flex justify-end mt-5'>
                                <SearchBtn type="submit" />
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Fragment>
    )
}

export default SearchStudentFormVIew