import React, { Fragment, useEffect, useState } from 'react';
import { Menu, MenuHandler, MenuList, MenuItem } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import NPFlag from '../../assets/img/icon/np.png';
import USAFlag from '../../assets/img/icon/usa.png';
import { FaLock } from "react-icons/fa";
import { IoMdHelpCircle } from "react-icons/io";
import { IoLogOutSharp } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { NepNowDateFormat } from '../../widgets/DateFormat';
import DarkTheme from '../../widgets/DarkTheme';
import { useLanguage } from '../../widgets/LangContext';
import { FaRegUserCircle } from "react-icons/fa";
import ChangePasswordModal from './modal/ChangePasswordModal';

const AppHeader = ({ onstructureName }) => {
    const navigate = useNavigate();
    const fullname = localStorage.getItem("fullname") || ""
    const logout = () => {
        localStorage.clear();
        navigate("/");
    }
    const NowDate = NepNowDateFormat();
    const [colorTheme, toggleTheme] = DarkTheme();
    const [darkSide, setDarkSide] = useState(colorTheme === 'dark');
    const toggleDarkMode = () => {
        toggleTheme();
        setDarkSide((prevDarkSide) => !prevDarkSide);
    };
    const { language, changeLanguage } = useLanguage();
    const handleLanguageChange = () => {
        changeLanguage((language) => !language);
        language === true ? localStorage.setItem("Lang", "Nep") : localStorage.setItem("Lang", "Eng");
    };

    useEffect(() => {
        if (colorTheme === 'dark') {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [colorTheme]);
    const [onPasswordModal, setOnPasswordModal] = useState(false);
    return (
        <Fragment>
            <ChangePasswordModal setOnPasswordModal={setOnPasswordModal} onPasswordModal={onPasswordModal} />
            <div className="sticky top-0 left-0 z-50 bg-gray100 border-t flex justify-between pl-8 pr-5 pt-2.5 pb-0 flex-row border-gray-200 dark:bg-slate900 dark:border-gray-600">
                <h3 className="font-semibold text-green-600 my-auto text-[13px] font-public-sans">{NowDate}</h3>
                {onstructureName?.structure?.org_name && <div className='shadow-lg bg-customPrimary rounded-full hidden md:block'>
                    <h3 className="font-bold text-gray-300 my-auto my_font px-5 py-1">{language ? (onstructureName?.structure?.org_name) : onstructureName?.structure?.org_name_nep}</h3>
                </div>}
                <div className="flex">
                    <div className='me-3 flex gap-3'>
                        <div className="relative inline-flex items-center justify-center w-7 h-7 overflow-hidden rounded-full bg-gray-200 cursor-pointer dark:bg-gray-600">
                            <span className="font-sm text-gray-800 border-gray-700 dark:text-gray-300"> <DarkModeSwitch checked={darkSide} onChange={toggleDarkMode} size={15} /></span>
                        </div>

                    </div>
                    <div className="relative text-left hidden sm:block">
                        <div className="relative me-3">
                            <div onClick={handleLanguageChange} className="relative inline-flex select-none items-center justify-center w-7 h-7 overflow-hidden rounded-full bg-gray-200 cursor-pointer dark:bg-gray-600">
                                {language ? <img src={NPFlag} width={15} alt='Nepali Flag' /> : <img src={USAFlag} height={15} width={15} alt='Nepali Flag' />}
                            </div>
                        </div>
                    </div>
                    <Menu placement="bottom-end">
                        <MenuHandler>
                            <Button className='p-0 bg-transparent shadow-none text-gray-600 -mt-1 hover:shadow-none'><div className='flex gap-1 capitalize'><FaRegUserCircle fontSize={15} />{fullname}</div></Button>
                        </MenuHandler>
                        <MenuList className='px-1'>
                            <MenuItem className='py-2'><div className='flex gap-2 ' onClick={() => setOnPasswordModal(true)}><FaLock className='my-auto text-yellow-500' /><span className='font-public-sans my-auto text-[12px]'>Change Password</span></div></MenuItem>
                            <MenuItem className='py-1.5'><div className='flex gap-2 '><CgProfile className='my-auto text-[18px] text-green-500' /><span className='text-[12px] font-public-sans my-auto'>Profile</span></div></MenuItem>
                            <MenuItem className='py-1.5'><div className='flex gap-2 '><IoMdHelpCircle className='my-auto text-[18px] text-blue-500' /><span className='text-[12px] font-public-sans my-auto'>Help</span></div></MenuItem>
                            <div className='mt-2 border-b'></div>
                            <MenuItem onClick={logout} className='py-1.5 mt-2'><div className='flex gap-2 '><IoLogOutSharp className='my-auto text-[18px] text-red-500' /><span className='text-[12px] font-public-sans my-auto'>Logout</span></div></MenuItem>
                        </MenuList>
                    </Menu>
                </div>

            </div>
        </Fragment>
    );
}

export default AppHeader;
