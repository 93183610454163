import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";
import moment from 'moment';
import { LoginContext } from './AuthProvider';

const RequireAuth = () => {
    const location = useLocation();
    const { authUser, setOnAuthUser, setOnUserID } = useContext(LoginContext);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
      const checkTokenValidity = () => {
        if (token) {
          try {
            const decoded = jwtDecode(token);
            const currentTime = moment().unix();
            if (decoded.exp > currentTime) {
              setOnAuthUser(true);
              setOnUserID(decoded.user_id);
            } else {
              setOnAuthUser(false);
              localStorage.removeItem('access_token');
            }
          } catch (error) {
            console.error('Error decoding JWT token:', error);
            setOnAuthUser(false);
          }
        } else {
          setOnAuthUser(false);
        }
      };

      checkTokenValidity();
    }, [token, authUser, setOnAuthUser, setOnUserID]);

    return (
      authUser ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
    );
};

export default RequireAuth;
