
import React, { useEffect, useState } from 'react';
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { SidebarReportClose } from '../../../../store/softSlice';
import { ExamTypeOptions, HallRoomOptions, StdBatchOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import ReportHeading from '../../../../widgets/ReportHeading';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { HallRoomSeatListView } from '../../../../store/examSlide';
import MultiSelectOptions from '../../../../widgets/MultiSelectOptions';

const SeatPlanFormView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CloseDispatch = () => {
    dispatch(SidebarReportClose({ "action": false }));
  }
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
  const fetchStudentFilterOptionListView = async () => {
    try {
      await fetchDataFromApi(AppURL.ExamURL + "exam-student-hall-room-seat-plan-options-list-view/").then((res) => {
        setOnStudentOptionList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchStudentFilterOptionListView();
  }, []);

  const HallRoomOption = HallRoomOptions(onStudentOptionList?.hall);

  const [selectValues, setOnSelectValues] = useState({
    hall_id: ""
});
const optionHandleSelect = (fieldName, data) => {
    setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: data,
    }));
};

  const initialValues = {
    from_date: '',
    to_date: '',
    branch_id: "",
  }
  const { handleBlur, handleSubmit, errors, isSubmitting, touched } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append('hall_id', selectValues?.hall_id?.value);
        try {
          await fetchDataFromApi(`${AppURL.ExamURL}exam-student-hall-room-seat-plan-seat-list-view/?hall_id=${selectValues?.hall_id?.value}`).then((res) => {
            dispatch(HallRoomSeatListView(res));
            // CloseDispatch();
            navigate("/exam-hall-room-report-page-list-view");
          });
        } catch (error) {
          console.log(error);
        }
      },
    });

  return (
    <div className=''>
      <ReportHeading heading="Seat Plan Report" />
      <div className='mt-4'>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0.5}>
          <Grid item xl={12} lg={12} xs={12} sm={12} md={12}>
          <SelectOptionBox name="hall_id" label="Class" options={HallRoomOption} value={selectValues?.hall_id} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.hall_id} errors={errors.hall_id} required={true} />
            </Grid>
          </Grid>
          <div className='flex justify-between mt-4'>
            <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default SeatPlanFormView