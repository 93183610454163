import { Grid } from '@mui/material';
import React, { Fragment, useState } from 'react';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { SaveBtn } from '../../../widgets/AppBtn';
import { useLanguage } from '../../../widgets/LangContext';
import { useFormik } from 'formik';
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { IoMdAddCircle } from 'react-icons/io';
import SelectOptionBox from '../../../widgets/SelectOptionBox';
import { GalleryCategoryOptions } from '../../../widgets/Options';
import InputTextBox from '../../../widgets/InputTextBox';

const GalleryAddFormView = ({ onGalleryCateList, FetchData }) => {
    const [images, setImages] = useState([]);
    const [previews, setPreviews] = useState([]);
    const GalleryCategoryOption = GalleryCategoryOptions(onGalleryCateList?.results);
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const currentImageCount = images.length;

        // Filter out files that are larger than 1MB
        const validFiles = files.filter((file) => file.size <= 1024 * 1024);

        // Check if any files exceed the size limit
        const oversizedFiles = files.filter((file) => file.size > 1024 * 1024);
        if (oversizedFiles.length > 0) {
            alert('Some files were not added because they exceed the 1MB size limit.');
        }

        // Limit the number of valid files
        const remainingCapacity = 5 - currentImageCount;
        const limitedFiles = validFiles.slice(0, remainingCapacity);

        setImages((prevImages) => [...prevImages, ...limitedFiles]);

        const newPreviews = limitedFiles.map((file) => URL.createObjectURL(file));
        setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    };
    const removeImage = (index) => {
        const newImages = images.filter((_, imgIndex) => imgIndex !== index);
        const newPreviews = previews.filter((_, prevIndex) => prevIndex !== index);
        URL.revokeObjectURL(previews[index]);
        setImages(newImages);
        setPreviews(newPreviews);
    };

    const { language } = useLanguage();
    const [selectValues, setOnSelectValues] = useState({
        gallery_category: "",
    });

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value);
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };

    const initialValues = {
        gallery_category: "",
        title: ""
    };
    const { errors, touched, handleBlur, values, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, actions) => {
                const formData = new FormData();
                formData.append("title", values.title);
                images.forEach(file => {
                    formData.append("images", file);
                });
                formData.append("gallery_category", selectValues?.gallery_category?.value);
                try {
                    const response = await postDataToTheServer(AppURL.MobileAppURL + "mobile-app-student-gallery-create-list-view/", formData);
                    ToastSuccess(response?.message);
                    actions.resetForm();
                    setOnSelectValues({
                        gallery_category: "",
                    });
                    setImages([]);
                    FetchData();
                    setPreviews([]);
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md">
                <ComponentHeading label={language ? "Add Gallery" : "ग्यालेरी थप्नुहोस्"} icon={IoMdAddCircle} />
                <Grid container spacing={1}>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <SelectOptionBox
                            label={language ? "Gallery Category" : "ग्यालेरी श्रेणी"}
                            placeholder=""
                            value={selectValues.gallery_category}
                            name="gallery_category"
                            onFocus={true}
                            options={GalleryCategoryOption}
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.gallery_category}
                            errors={errors.gallery_category}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                        <InputTextBox
                            label={language ? 'Title' : 'शीर्षक'}
                            placeholder="Title"
                            value={values.title}
                            name="title"
                            onBlur={handleBlur}
                            handleChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <div className='flex flex-col'>
                            <div className='flex flex-col '>
                                <label htmlFor="" className='font-public-sans text-[12px] mb-0.5 font-medium text-gray800'>Upload Image</label>
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleImageChange}
                                    required
                                    className='font-public-sans text-[12px] text-gray-500 cursor-pointer'
                                />
                            </div>
                            <div className="flex flex-wrap mt-1">
                                {previews.map((preview, index) => (
                                    <div key={index} className="relative m-2 border-green-800">
                                        <img
                                            src={preview}
                                            alt="preview"
                                            className="w-16 h-16 rounded-sm border-gray-500 object-cover"
                                        />
                                        <button
                                            onClick={() => removeImage(index)}
                                            className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-[12px] cursor-pointer"
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-5 gap-2">
                    <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    );
}

export default GalleryAddFormView;
