import React, { useEffect, useState, } from 'react'
import AcademicsCalenderView from '../../../../components/academics/create/calender/AcademicsCalenderView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';

const AcaCalenderCreateListView = ({PageTitle, setIconsButton}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
  const currentYear = currentDate.getFullYear();
  const [onCalenderListData, setOnCalenderListData] = useState([]);
  const url = AppURL.AcademicsURL + "academics-calender-event-create-list-view/?month="+currentMonth+"&&year="+currentYear;
  const [onUpdateURL, setOnUpdateURL] = useState("");
  const fetchAcademicsListView = async (urls) => {
      setOnUpdateURL(urls);
      try {
          await fetchDataFromApi(urls).then((res) => {
            setOnCalenderListData(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  const FetchData = async () => {
      await fetchAcademicsListView(url);
  }
  useEffect(() => {
      FetchData();
  }, []);
  return (
    <div>              
      <AcademicsCalenderView onUpdateURL={onUpdateURL} fetchAcademicsListView={fetchAcademicsListView} onCalenderListData={onCalenderListData} />
    </div>
  )
}

export default AcaCalenderCreateListView