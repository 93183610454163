import React, { Fragment, useEffect, useState } from 'react'
import ContentHeading from '../../../../widgets/ContentHeading'
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../../widgets/LangContext';
import { useFormik } from 'formik';
import { SaveBtn } from '../../../../widgets/AppBtn';
import { FaSearch } from "react-icons/fa";
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { CustomOptions, MediumOptions, StdClassOptions, StdSectionOptions, SubjectListOptions, TeacherOptions } from '../../../../widgets/Options';
import { days_name_list, lanugage_medium, yes_no_list } from '../../../../widgets/json';
import { useDispatch } from "react-redux";
import { Class_Wise_Subject_List, ClassRoutineSelectField } from '../../../../store/academicsSlice';
import InputTextBox from "../../../../widgets/InputTextBox";
import { ToastSuccess, ToastWarning } from '../../../../widgets/ToastMessage';

const SearchClassWiseSubjectListView = ({ onStudentOptionList }) => {
  const { language } = useLanguage();
  const dispatch = useDispatch();
  const lang = lanugage_medium;
  const CustomOption = CustomOptions(yes_no_list);
  const StdClassOption = StdClassOptions(onStudentOptionList?.class);
  const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
  const DayOptions = CustomOptions(days_name_list);
  const [selectValues, setOnSelectValues] = useState({
    class_id: "",
    section: "",
    medium: "",
    day: "",
    all_week: "",
    subject_id: "",
    teacher_id: ""
  });
  const optionHandleSelect = (fieldName, data) => {
    if (data === null || data.value === "") {
      // If data is null or empty, clear the input value
      handleChange(fieldName)("");
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
    } else {
      // Otherwise, update the input value as usual
      handleChange(fieldName)(data.value);
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: data,
      }));
    }
    if (fieldName === 'class_id') {
      dispatch(Class_Wise_Subject_List([]));
    }
  };
  const [subList, setOnSubList] = useState([]);
  const FilterListView = async (id) => {
    if (selectValues?.class_id) {
      try {
        await fetchDataFromApi(AppURL.AcademicsURL + "academics-class-routine-options-list-view/?class_id=" + id).then((res) => {
          setOnSubList(res);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
  useEffect(() => {
    FilterListView(selectValues?.class_id?.value);
  }, [selectValues?.class_id])
  const StaffOption = TeacherOptions(subList?.teachers);
  const SubjectListOption = SubjectListOptions(subList?.subjects);
  const MediumOption = MediumOptions(lang)
  const initialValues = {
    class_id: "",
    section: "",
    medium: "",
    day: "",
    end_time: "",
    teacher_id: "",
    start_time: "",
    order: ""
  };
  const { errors, touched, handleBlur, values, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("class_id", selectValues.class_id.value);
        formData.append("section", selectValues.section?.value);
        formData.append("medium", selectValues.medium?.value);
        formData.append("all_week", selectValues.all_week?.value);
        formData.append("subject_id", selectValues.subject_id?.value);
        formData.append("teacher_id", selectValues.teacher_id?.value);
        formData.append("order", values.order);
        formData.append("day", selectValues.day?.value);
        formData.append("end_time", values.end_time);
        formData.append("start_time", values.start_time);
        try {
          await postDataToTheServer(AppURL.AcademicsURL + "academics-class-routine-create-list-view/", formData).then((res) => {
            if (res?.status === 200) {
              ToastSuccess(res?.message);
              setOnSelectValues({
                class_id: "",
                section: "",
                medium: "",
                day: "",
                all_week: "",
                subject_id: "",
                teacher_id: ""
              });
              action.resetForm();
            } else {
              ToastWarning(res?.message)
            }
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  useEffect(() => {
    dispatch(ClassRoutineSelectField(selectValues))
  }, [selectValues])
  return (
    <Fragment>
      <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
        <ContentHeading label="Create Class Routine" icon={FaSearch} margin={0} />
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={2} columnSpacing={3}>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.class_id} errors={errors.class_id} required={true} />
            </Grid>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.section} errors={errors.section} required={true} />
            </Grid>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.medium} errors={errors.medium} required={true} />
            </Grid>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Subject" : "विषय"} placeholder="" options={SubjectListOption} value={selectValues.subject_id} name="subject_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.subject_id} errors={errors.subject_id} required={true} />
            </Grid>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Teacher" : "शिक्षक"} placeholder="" options={StaffOption} value={selectValues.teacher_id} name="teacher_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.teacher_id} errors={errors.teacher_id} required={true} />
            </Grid>

            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "All Week" : "सबै हप्ता"} placeholder="" options={CustomOption} value={selectValues.all_week} name="all_week" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.all_week} errors={errors.all_week} required={true} />
            </Grid>
            {
              selectValues?.all_week?.value ==="2" && <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <SelectOptionBox label={language ? "Day" : "दिन"} placeholder="" options={DayOptions} value={selectValues.day} name="day" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                touched={touched.day} errors={errors.day} required={true} />
            </Grid>
            }
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <InputTextBox label={language ? "Start Time" : "माध्यम"} type={'time'} placeholder="" value={values.start_time} name="start_time" handleChange={handleChange} handleBlur={handleBlur}
                touched={touched.start_time} errors={errors.start_time} required={true} />
            </Grid>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <InputTextBox label={language ? "End Time" : "माध्यम"} type={'time'} placeholder="" value={values.end_time} name="end_time" handleChange={handleChange} handleBlur={handleBlur}
                touched={touched.end_time} errors={errors.end_time} required={true} />
            </Grid>
            <Grid item xl={3} md={3} lg={3} xs={12} sm={12}>
              <InputTextBox label={language ? "Order" : "माध्यम"} placeholder="" type={'number'} value={values.order} name="order" handleChange={handleChange} handleBlur={handleBlur}
                touched={touched.order} errors={errors.order} required={true} />
            </Grid>

          </Grid>
          <div className='flex justify-end mt-5'>
            <SaveBtn type="submit" />
          </div>
        </form>
      </div>
    </Fragment>
  )
}

export default SearchClassWiseSubjectListView