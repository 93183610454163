import React, { useCallback, useEffect, useState } from 'react'
import ComponentHeading from '../../../widgets/ComponentHeading'
import { MdMessage } from "react-icons/md";
import { Grid } from '@mui/material';
import InputTextBox from '../../../widgets/InputTextBox';
import { useFormik } from 'formik';
import { SendBtn } from '../../../widgets/AppBtn';
import TextAreaBox from '../../../widgets/TextAreaBox';
import SelectOptionBox from '../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../widgets/LangContext';
import { AppStudentListOptions, StdClassOptions } from '../../../widgets/Options';
import AutoCompleteBox from '../../../widgets/AutoCompleteBox';
import { fetchDataFromApi, postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { validateNotification } from './utils/Validate';

const NotificationMessageFormView = ({ onStudentOptionList }) => {
  const { language } = useLanguage();
  const [selectValues, setOnSelectValues] = useState({
    class_id: "",
    notify_type: ""
  });
  const optionHandleSelect = (fieldName, data) => {
    if (data === null || data.value === "") {
      handleChange(fieldName)("");
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: "",
      }));
    } else {
      handleChange(fieldName)(data.value);
      setOnSelectValues((prevValues) => ({
        ...prevValues,
        [fieldName]: data,
      }));
    }
  };
  const StdClassOption = StdClassOptions(onStudentOptionList?.class);
  const REQUEST_TIMEOUT = 500;
  const [autoComValue, setOnAutoComValue] = useState({
    employee: "",
  });
  const initialValues = {
    title: "",
    section: "",
    details: "",
  };

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: validateNotification,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append('title', values.title);
        if (selectValues.class_id) {
          formData.append('class_id', values.class_id);
        }
        formData.append('details', values.details);
        formData.append('notify_type', 1);
        formData.append('sent_by', 1);
        if (autoComValue.employee) {
          formData.append('member_sn', autoComValue.employee.value);
        }

        try {
          await postDataToTheServer(AppURL.MobileAppURL + "mobile-app-student-notification-create-list-view/", formData).then((res) => {
            ToastSuccess(res?.message);
            action.resetForm();
            setOnAutoComValue({ employee: "", });
            setOnSelectValues({
              class_id: "",
              notify_type: ""
            });
          })
        } catch (error) {
          console.log(error);
        }

      },
    });

  const optionAutoSelect = (fieldName, value) => {
    setOnAutoComValue((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
  }
  const [isFetching, setIsFetching] = useState(false);
  const [employeeListData, setEmployeeListData] = useState([]);
  const [inputValues, setInputValues] = useState("");

  // Debounced fetch function
  const fetchEmployeeData = useCallback(async () => {

    if (inputValues && selectValues?.class_id) {
      setIsFetching(true);
      try {
        const res = await fetchDataFromApi(
          `${AppURL.MobileAppURL}student-academics-search-list-view/?search=${inputValues}&class_id=${selectValues.class_id?.value}`
        );
        setEmployeeListData(res);
      } catch (error) {
        alert(error.response?.detail || 'An error occurred');
      } finally {
        setIsFetching(false);
      }
    } else {

      setEmployeeListData([]);
    }
  }, [inputValues]);

  // Debounce input changes
  useEffect(() => {
    const handler = setTimeout(() => {
      fetchEmployeeData();
    }, REQUEST_TIMEOUT);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValues, fetchEmployeeData]);
  console.log(autoComValue)
  const AppStudentListOption = AppStudentListOptions(employeeListData?.student);
  return (
    <div className='bg-white p-3 rounded-md'>
      <ComponentHeading icon={MdMessage} label='Message' />
      <form onSubmit={handleSubmit} className='mt-3'>
        <Grid container spacing={1}>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} clear={true} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
              touched={touched.class_id} errors={errors.class_id} required={false} />
          </Grid>
          <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
            <AutoCompleteBox
              label="Choose Student"
              handleChange={(e) => setInputValues(e.target.value)}
              options={AppStudentListOption}
              optionAutoSelect={optionAutoSelect}
              value={inputValues.employee}
              name="employee"
              disabled={isFetching}
            />
          </Grid>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
            <InputTextBox label={"Notification Title"} placeholder="" value={values.title} name="title" handleBlur={handleBlur} handleChange={handleChange}
              touched={touched.title} errors={errors.title} required={true} />
          </Grid>
          <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
            <TextAreaBox label={"Message"} height={7} placeholder="" handleChange={handleChange} value={values.details} name="details" handleBlur={handleBlur}
              touched={touched.details} errors={errors.details} required={true} />
          </Grid>

          <Grid item xl={12} md={12} lg={12} xs={12} sm={12} className='my-auto'>
            <div className='flex justify-end'>
              <SendBtn type={'submit'} />
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default NotificationMessageFormView