import React from 'react';
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';

const MarksheetPrintPageView = ({ student, subjects }) => {
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    const { marks_ledger_view } = useSelector((state) => state.exam);

    return (
        <div className='print:p-3 flex h-screen flex-row gap-3 pagebreak'>
            <style>
                {`@media print {
                    @page {
                        size: A4 portrait;
                    }
                    .page-footer {
                        position: fixed;
                        bottom: 4%;
                        left: 10%;
                        width: 80%;
                        text-align: center;
                        font-size: 13px;
                    }
                }`}
            </style>
            <div className='w-full border-[1px] border-gray-400'>
                <div className='print:p-2'>
                    <div className="relative mb-4">
                        <table className="w-full text-sm text-left rtl:text-right text-black">
                            <tbody>
                                <tr>
                                    <td className="w-[25%] "></td>
                                    <td className="w-[25%]"></td>
                                    <td className="w-[25%]"></td>
                                    <td align='right' className="w-[25%]"></td>
                                </tr>
                                <tr>
                                    <td rowSpan={3} className="w-[20%] font-medium text-black whitespace-nowrap dark:text-white"><img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" /></td>
                                    <td align='center' colSpan={2} className="w-[60%] text-black font-bold text-[20px]">{Org_Details?.org_name}</td>
                                    <td className="w-[20%]"> </td>
                                </tr>
                                <tr>
                                    <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[13px] "> {Org_Details?.org_add} </td>
                                    <td align='right' className="w-[25%] text-[11px] text-black font-semibold"> </td>
                                </tr>
                                <tr>
                                    <td align='center' colSpan={2} className="w-[25%] text-white font-bold text-[15px] my_font">
                                        GRADE SHEET
                                    </td>
                                    <td align='right' className="w-[25%] text-[11px] text-black font-semibold">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='border-t-[1px] border-gray-400 my-2 py-4 font-medium'>
                        <table width="100%" className='p-0 text-[12px] text-justify'>
                            <tbody>
                                <tr>
                                    <td className='text-justify p-0 uppercase py-1.5 tracking-wide'>
                                        The Marks Secured by     <span className='border-b border-dotted border-black text-center inline-block w-[37%] capitalize font-normal italic'>{student?.name}</span>
                                        Date of Birth <span className='border-b border-dotted border-black text-center -mb-1 inline-block w-[22%] capitalize italic font-normal'>{student?.dobn}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-justify p-0 uppercase py-1.5 tracking-wide'>
                                        Roll No<span className='border-b border-dotted border-black text-center -mb-1 inline-block w-[5%] capitalize italic font-normal '>{student?.roll_no}</span>Grade<span className='border-b border-dotted border-black text-center -mb-1 inline-block w-[15%] capitalize italic font-normal '>{student?.class}</span>District<span className='border-b border-dotted border-black text-center -mb-1 inline-block w-[20%] capitalize italic font-normal '>{student?.district_name}</span>  Father Name<span className='border-b border-dotted border-black text-center -mb-1 inline-block w-[20%] capitalize italic font-normal'>{student?.father_name}</span></td>
                                </tr>
                                <tr>
                                    <td className='text-justify p-0 uppercase py-1.5 tracking-wide'>
                                        In the {marks_ledger_view?.exam_type?.ex_type_name} {marks_ledger_view?.exam_type?.exam_year} in Given Below
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='border-t-[1px] border-gray-400'></div>
                    <div style={{ marginTop: "10px", marginBottom: "10px", fontFamily: "'Bai Jamjuree', sans-serif" }}>
                        <table width="100%" border={1} style={{ borderCollapse: "collapse", padding: 0 }}>
                            <tbody>
                                <tr className='uppercase'>
                                    <td rowSpan={2} align="center" width="5%" className='border px-2 w-8 border-gray-400 text-[11px] font-bold'>S.N.</td>
                                    <td rowSpan={2} align="center" width="50%" className='border border-gray-400 text-[11px] font-bold'>Subjects</td>
                                    <td rowSpan={2} className="text-center text-[11px] font-semibold [writing-mode:vertical-rl] [text-orientation:mixed] p-1.5 border border-gray-400">Cr. Hrs</td>
                                    <td colSpan={2} align="center" className='border border-gray-400 text-[11px] font-bold'>Obt. Grade</td>
                                    <td rowSpan={2} align="center" className='border border-gray-400 text-[11px] font-bold'>Grade</td>
                                    <td rowSpan={2} align="center" className='border border-gray-400 text-[11px] font-bold'>GPA</td>
                                    <td rowSpan={2} align="center" className='border border-gray-400 text-[11px] font-bold'>Remarks</td>
                                </tr>
                                <tr className='uppercase'>
                                    <td align="center" className='border border-gray-400 text-[11px] font-bold'>Th</td>
                                    <td align="center" className='border border-gray-400 text-[11px] font-bold'>Pr</td>
                                </tr>
                                {subjects?.map((subject, index) => (
                                    <tr key={index}>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{index + 1}</td>
                                        <td align="start" width="50%" className='font-public-sans px-2 py-0.5 text-[11px] border border-gray-400'>{subject}</td>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{student.marks[subject]?.creditHours || ''}</td>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{student.marks[subject]?.thgrade || ''}</td>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{student.marks[subject]?.prgrade || ''}</td>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{student.marks[subject]?.grade || ''}</td>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{student.marks[subject]?.totalgpa || ''}</td>
                                        <td align="center" className='font-public-sans text-[11px] py-0.5 border border-gray-400'>{student.marks[subject]?.remarks || ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='border-t-[1px] border-gray-400'></div>
                    <div style={{ marginTop: "10px", marginBottom: "10px", fontFamily: "'Bai Jamjuree', sans-serif" }}>
                        <table width="100%" border={1} style={{ borderCollapse: "collapse", padding: 0 }}>
                            <tbody>
                                <tr className='uppercase'>
                                    <td align="center" rowSpan={3} width="25%" className='border border-gray-400 text-[11px] font-bold'>Result Summary</td>
                                    <td align="start" width="50%" className='border border-gray-400 text-[11px] font-bold'>Total Grade</td>
                                    <td align="start" width="25%" className='border border-gray-400 text-[11px] font-bold'>Total GPA</td>
                                </tr>
                                <tr>
                                    <td align="start" className='font-public-sans px-2 text-[11px] border border-gray-400'>{student?.studtotalgrade || ''}</td>
                                    <td align="start" className='font-public-sans px-2 text-[11px] border border-gray-400'>{student?.studtotalgpa || ''}</td>
                                </tr>
                                <tr>
                                    <td align="start" colSpan={2} className='font-public-sans px-2 text-[11px] border border-gray-400'>Remarks: {student?.remarks || ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-10'>
                        <table width="70%" border={1} style={{ borderCollapse: "collapse", padding: 0 }}>
                            <tbody>
                                <tr className='uppercase'>
                                    <td align="center" width="5%" className='border px-2 w-8 border-gray-400 text-[11px] font-bold'>S.N.</td>
                                    <td align="center" width="50%" className='border border-gray-400 text-[11px] font-bold'>Name</td>
                                    <td className="text-center text-[11px] font-semibold p-1.5 border border-gray-400">GPA</td>
                                    <td align="center" className='border border-gray-400 text-[11px] font-bold'>Grade</td>
                                    <td align="center" className='border border-gray-400 text-[11px] font-bold'>Remarks</td>
                                </tr>
                                {
                                    marks_ledger_view?.gap_structure?.map((item, index) => (
                                        <tr key={index}> <td align="center" className='font-public-sans text-[11px] border border-gray-400'>{index + 1}.</td>
                                            <td align="start" width="50%" className='font-public-sans px-2 text-[11px] border border-gray-400'>{item.perc_from} {index === 0 ? ' to' : ' to below'} {item.perc_to}</td>
                                            <td align="center" className='font-public-sans text-[11px] border border-gray-400'>{item.gpa}</td>
                                            <td align="center" className='font-public-sans text-[11px] border border-gray-400'>{item.grade}</td>
                                            <td align="center" className='font-public-sans text-[11px] border border-gray-400'>{item.grade_des}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-4 text-[13px]'>
                        <ol>
                            <li>One Credit Hours Equals 32 Clock Hours</li>
                            <li>TH: <span className='font-normal'>Theory</span></li>
                            <li>PR: <span className='font-normal'>Practical</span></li>
                            <li>AB: <span className='font-normal'>Absent</span></li>
                        </ol>
                    </div>
                    <div className='page-footer'>
                        <div className='flex justify-between w-full items-end'>
                            <div>
                                <h6 className='font-semibold'>Date Of Issue: <span className='font-normal italic'>{DateNow}</span></h6>
                            </div>
                            <div className='text-center block'>
                                <span>.................................</span>
                                <h6 className='-mt-1 font-semibold'>Principal Signature</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarksheetPrintPageView;
