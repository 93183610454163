import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import ReportLabel from '../../../../widgets/ReportLabel';

const FeeMappingRptListView = () => {
    const { fee_mapping_report_list, fee_mapping_options_list } = useSelector((state) => state?.accounts);
    return (
        <Fragment>
             <div className='font-public-sans overflow-x-auto text-[12px] gap-2 flex flex-row'>
                {fee_mapping_options_list?.class && <ReportLabel label='Class: ' value={fee_mapping_options_list?.class} />}
                {fee_mapping_options_list?.medium && <ReportLabel label={'Medium: '} value={fee_mapping_options_list?.medium} />}
                {fee_mapping_options_list?.section && <ReportLabel label={'Section: '} value={fee_mapping_options_list?.section} />}
            </div>
            <div className='bg-white rounded-md p-1 shadow dark:bg-slate800'>
                <div className='overflow-x-auto overflow-auto h-[78vh]'>
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] top-0 sticky z-10 text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    Roll No
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Full Name
                                </th>
                                <th scope="col" align='center' className="px-2 py-1.5 border dark:border-slate600 w-20">
                                    DOB
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Address
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Father Name
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Phone No.
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Fee Mapping
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Bus Facility
                                </th>
                                <th scope="col" align='left' className="px-2 py-1.5 border dark:border-slate600">
                                    Drom Facility
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(fee_mapping_report_list?.results) &&
                                fee_mapping_report_list?.results?.map((student, index) =>
                                (
                                    <tr key={index} className="odd:bg-white text-[12px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border font-medium py-1 dark:border-slate600 capitalize">
                                            {student.roll_no}.
                                        </td>
                                        <td align='left' className="px-2 border font-medium py-1 dark:border-slate600 capitalize">
                                            {`${student?.first_name} ${student?.middle_name ? student?.middle_name : ""} ${student?.last_name}`}
                                        </td>
                                        <td align='center' className="px-2 font-medium py-1 border dark:border-slate600 capitalize text-[12px]">
                                            {student.dobn ||"*"}
                                        </td>
                                        <td align='left' className="px-2 font-medium py-1 border dark:border-slate600 capitalize text-[12px]">
                                            {student.add_eng}
                                        </td>
                                        <td align='left' className="px-2 font-medium py-1 border dark:border-slate600 capitalize text-[12px]">
                                            {student.father_name}
                                        </td>
                                        <td align='left' className="px-2 font-medium py-1 border dark:border-slate600 capitalize text-[12px]">
                                            {student.phone ||"*"}
                                        </td>
                                        <td align='left' className="px-2 border py-1 dark:border-slate600">
                                            {student.scholarshipstyles_name ||"*"}
                                        </td>
                                        <td align='left' className="px-2 border py-1 dark:border-slate600">
                                            {student.bus_facility_name ||"*"}
                                        </td>
                                        <td align='left' className="px-2 border py-1 dark:border-slate600">
                                            {student.dorm_facility_name ||"*"}
                                        </td>
                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </Fragment>
    )
}

export default FeeMappingRptListView