import { useFormik } from 'formik';
import React, { Fragment, useRef, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../widgets/InputTextBox';
import { CustomBtn } from '../../../widgets/AppBtn';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { useLanguage } from "../../../widgets/LangContext";
import TextAreaBox from '../../../widgets/TextAreaBox';
import { BsSendArrowUp } from 'react-icons/bs';

const TeacherNotificationFormView = ({ FetchData }) => {
    const { language } = useLanguage();
    const [imageFile, setImageFile] = useState(null);
    const fileInputRef = useRef(null);
    const initialValues = {
        title: "",
        details: "",
        sent_by: "",
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("title", values.title);
                formData.append("details", values.details);
                formData.append("sent_by", 1);
                if (imageFile) {
                    formData.append("image", imageFile);
                }
                try {
                    await postDataToTheServer(AppURL.MobileAppURL + "teacher-notification-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        action.resetForm();
                        setImageFile(null);
                        FetchData();
                        fileInputRef.current.value = ''; // Reset file input field
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add Teacher Notification" : "सन्देश थप्नु"} icon={IoMdAddCircle} />
                <Grid container spacing={1.5}>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="title"
                            type="text"
                            placeholder="Enter Message Title"
                            label={language ? "Message Title" : "सन्देश शीर्षक"}
                            value={values.title}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.title}
                            errors={errors.title}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <TextAreaBox
                            name="details"
                            type="text"
                            label={language ? "Message" : "सन्देश"}
                            height={8}
                            value={values.details}
                            handleChange={handleChange}
                            placeholder="Enter message"
                            handleBlur={handleBlur}
                            touched={touched.details}
                            errors={errors.details}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <div className='flex flex-col gap-y-2'>
                            <label htmlFor="" className='font-public-sans text-[12px] text-gray700'>Image Upload</label>
                            <input
                                ref={fileInputRef}
                                type="file" name='image'
                                className='font-public-sans text-[12px] text-gray-700'
                                onChange={(e) => setImageFile(e.target.files[0])}
                            />
                        </div>
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <CustomBtn type="submit" disabled={true} icon={BsSendArrowUp} label={'Send'} />
                </div>
            </form>
        </Fragment>
    )
}

export default TeacherNotificationFormView