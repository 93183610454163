import React from 'react';

const AppFooter = () => {
    return (
        <section
            className="flex mt-3 sticky bottom-0 bg-gray100 shadow left-0 z-50 justify-center p-2 dark:bg-slate900 rounded-md font-public-sans">
            <div className="container dark:text-gray-300">
                <div className="text-center select-none">
                    Copyright © 2023, <b>Preface Technology PVT.LTD.</b> All Rights
                    Reserved. 
                </div>
            </div>
        </section>
    )
}

export default AppFooter