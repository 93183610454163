import React, { Fragment, useEffect, useState } from 'react'
import ClassRoutineRptFormView from '../../../../components/academics/report/class_routine/ClassRoutineRptFormView'
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';

const AcaClassRoutineFormView = () => {
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
  return (
    <Fragment>
        <ClassRoutineRptFormView onStudentOptionList={onStudentOptionList} />
    </Fragment>
  )
}

export default AcaClassRoutineFormView