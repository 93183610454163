import React, { Fragment, useEffect, useRef, useState } from 'react';
import MarksLedgerPageListView from '../../../../components/exam/report/marks_ledger/MarksLedgerPageListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { PdfExportBtn, ViewEyeBtn } from '../../../../widgets/AppBtn';
import { useReactToPrint } from 'react-to-print';
import MarksLedgerPdfView from '../../../../components/exam/report/marks_ledger/MarksLedgerPdfView';

const MarksLedgerPageCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    const [onGPA, setOnGPA] = useState(false);

    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(<div><PdfExportBtn onClick={handlePrintCard} /> <ViewEyeBtn onClick={() => setOnGPA(!onGPA)} /></div>);
    }, [onGPA]);
    const componentListRef = useRef();
    const RenderListComponent = React.forwardRef(() => (
        <MarksLedgerPdfView />
    ));

    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });
    return (
        <Fragment>
            <MarksLedgerPageListView onGPA={onGPA} />
            <div className='hidden'>
                <div ref={componentListRef}>
                    <RenderListComponent />
                </div>
            </div>
        </Fragment>
    )
}

export default MarksLedgerPageCreateListView