import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { useParams } from 'react-router-dom';
import EmployeeDetailsView from '../../../components/hr/report/emp_details/EmployeeDetailsView';
import { PdfExportBtn } from '../../../widgets/AppBtn';

const HRStaffDetailsPageView = ({PageTitle, setIconsButton}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }));
        setIconsButton(<PdfExportBtn />);
        document.title = `Nexapp - ${PageTitle}`;
    }, []);
    const {id} = useParams();
    const [onEmployeeDetails, setOnEmployeeDetails] = useState([]);
    const onEmployeeDetailsFetch=async ()=>{
        try{
            await fetchDataFromApi(AppURL.HR_URL+`hr-emloyee-profile-details-retrieve-view/?staff_id=${id}`).then((res)=>{
                setOnEmployeeDetails(res);
            });
        }catch(error){
            console.log(error);
        }
    }
    useEffect(()=>{
        onEmployeeDetailsFetch();
    },[])
  return (
    <Fragment>
        <EmployeeDetailsView onEmployeeDetails={onEmployeeDetails?.data} />
    </Fragment>
  )
}

export default HRStaffDetailsPageView