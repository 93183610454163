import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import { Grid } from "@mui/material";
import InputTextBox from '../../../widgets/InputTextBox';
import { SendBtn } from '../../../widgets/AppBtn';
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';
import { useLanguage } from "../../../widgets/LangContext";
import TextAreaBox from '../../../widgets/TextAreaBox';

const NewsEventsFromView = ({ fetchDataList }) => {
    const { language } = useLanguage();
    const initialValues = {
        event_title: "",
        event_details: "",
        image: "",
    };

    const { values, errors, touched, handleReset, isSubmitting, setFieldValue, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("event_title", values.event_title);
                formData.append("event_details", values.event_details);
                if (values.image) {
                    formData.append("image", values.image);
                }
                try {
                    await postDataToTheServer(AppURL.MobileAppURL + "teacher-news-notice-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        action.resetForm();
                        fetchDataList();
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add News & Events" : "समाचार र घटनाहरू थप्नुहोस्"} icon={IoMdAddCircle} />
                <Grid container spacing={1.5}>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="event_title"
                            type="text"
                            placeholder="Enter Event Title"
                            label={language ? "Event Title" : "सन्देश शीर्षक"}
                            value={values.event_title}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.event_title}
                            errors={errors.event_title}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <TextAreaBox
                            name="event_details"
                            type="text"
                            label={language ? "Details" : "सन्देश"}
                            height={8}
                            value={values.event_details}
                            handleChange={handleChange}
                            placeholder="Enter message"
                            handleBlur={handleBlur}
                            touched={touched.event_details}
                            errors={errors.event_details}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <div className='flex flex-col gap-1'>
                            <label htmlFor="" className='font-public-sans text-[12px] text-gray800'>Upload File</label>
                            <input type='file' id="image"
                                name="image"
                                onChange={(event) => {
                                    setFieldValue("image", event.currentTarget.files[0]);
                                }} className='font-public-sans text-[12px]' />
                        </div>
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <SendBtn type="submit" disabled={isSubmitting} />
                </div>
            </form>
        </Fragment>
    )
}

export default NewsEventsFromView