import { FaUserGraduate } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import { IoLibrary } from "react-icons/io5";
import { MdAccountBox } from "react-icons/md";
import { PiExamFill } from "react-icons/pi";
export const lanugage_medium = [
    { value: "1", label: "English", label_nep: "अंग्रेजी" },
    { value: "2", label: "Nepali", label_nep: "नेपाली" },

]
export const payment_type_list = [
    { value: "1", label: "Cash", label_nep: "नगद" },
    { value: "2", label: "Bank", label_nep: "बैंक" },
    { value: "3", label: "Wallets", label_nep: "वालेटहरू" },
]
export const payment_type_lists = [
    { value: "1", label: "Cash", label_nep: "नगद" },
    { value: "2", label: "Bank", label_nep: "बैंक" },
]
export const month_name_list = [
    { value: "01", label: "April", label_nep: 'बैशाख' },
    { value: "02", label: "May", label_nep: 'जेठ' },
    { value: "03", label: "June", label_nep:"असार" },
    { value: "04", label: "July", label_nep: 'साउन' },
    { value: "05", label: "August", label_nep:'भदौ' },
    { value: "06", label: "September", label_nep: 'असोज' },
    { value: "07", label: "October", label_nep: 'कार्तिक' },
    { value: "08", label: "November",label_nep: 'मंसिर' },
    { value: "09", label: "December", label_nep: 'पौष' },
    { value: "10", label: "January", label_nep:"माघ" },
    { value: "11", label: "February", label_nep:'फागुन' },
    { value: "12", label: "March", label_nep:'चैत्र' },
];

export const active_inactive_list = [
    { value: "1", label: "Active", label_nep: "सक्रिय" },
    { value: "2", label: "Inactive", label_nep: "निष्क्रिय" },
];

export const vehicle_rpt_type = [
    { value: "1", label: "Table", label_nep: "तालिका" },
    { value: "2", label: "Timeline", label_nep: "समय रेखा" },
];

export const days_name_list = [
    { value: "1", label: "Sunday", label_nep: 'आइतबार' },
    { value: "2", label: "Monday", label_nep: 'सोमबार' },
    { value: "3", label: "Tuesday", label_nep:"मंगलबार" },
    { value: "4", label: "Wednesday", label_nep: 'बुधबार' },
    { value: "5", label: "Thursday", label_nep:'बिहीबार' },
    { value: "6", label: "Friday", label_nep: 'शुक्रबार' },
    { value: "7", label: "Saturday", label_nep: 'शनिबार' },

];

export const color_name_list = [
    { value: "1", label: "Red", label_nep: 'आइतबार' },
    { value: "2", label: "Blue", label_nep: 'सोमबार' },
    { value: "3", label: "Green", label_nep:"मंगलबार" },
    { value: "4", label: "Yellow", label_nep: 'बुधबार' },
    { value: "5", label: "Voilet", label_nep:'बिहीबार' },
    { value: "6", label: "Orange", label_nep: 'शुक्रबार' },
    { value: "7", label: "Gray", label_nep: 'शनिबार' },

];
export const yes_no_list = [
    { value: "2", label: "No", label_nep: "छैन" },
    { value: "1", label: "Yes", label_nep: "छ" },
];
export const student_type_list = [
    { value: "1", label: "New", label_nep: "नयाँ"},
    { value: "2", label: "Old", label_nep: "पुरानो"},
];


export const trans_type = [
    { value: "1", label: "Single Vaucher", label_nep: 'एकल भाउचर' },
    { value: "2", label: "Contra Vaucher", label_nep: "कन्ट्रा भाउचर" },
];

export const gl_type_list = [
    { value: "1", label: "Assets" },
    { value: "2", label: "Liabilities" },
    { value: "3", label: "Income" },
    { value: "4", label: "Expenses" },
];

export const debit_credit_list = [
    { value: "1", label: "Debit", label_nep: "डेबिट" },
    { value: "2", label: "Credit", label_nep: "क्रेडिट" },
];
export const dep_method_list = [
    { value: "1", label: "Straight Line " },
    { value: "0", label: "Diminishing Balance " },
];

export const purchase_status_list = [
    { value: "1", label: "Ordered", nep_label: "आदेश" },
    { value: "2", label: "Received", nep_label: "प्राप्त भयो" },
    { value: "3", label: "Pending", nep_label: "विचाराधीन" },
];
export const member_type_list = [
    { value: "1", label: "Consumer" },
    { value: "2", label: "Other User" }

];

export const married_status_list = [
    { value: "1", label: "Unmarried", label_nep: "अविवाहित" },
    { value: "2", label: "Married", label_nep: "विवाहित" },

];

export const gender_lists = [
    { value: "1", label: "Female", label_nep: "महिला" },
    { value: "2", label: "Male", label_nep: "पुरुष" },
    { value: "3", label: "Other", label_nep: "अन्य" },

];

export const issued_for_list = [
    { value: "1", label: "All", label_nep: "सबै" },
    { value: "2", label: "Student", label_nep: "विद्यार्थी" },
    { value: "3", label: "Staff", label_nep: "कर्मचारी" },
    { value: "4", label: "Non Issuable", label_nep: "जारी गर्न नसकिने" },
];

export const item_category_list = [
    { value: "1", label: "Books", label_nep: "पुस्तकहरू" },
    { value: "2", label: "Playing Materials", label_nep: "खेल सामग्री" },
    { value: "3", label: "Non Refundable Material", label_nep: "फिर्ता नहुने सामग्री" },
];

export const book_type_list = [
    { value: "1", label: "Gifted", label_nep: "उपहार " },
    { value: "2", label: "Purches", label_nep: "खरिद" },
];

export const book_category_list = [
    { value: "1", label: "Compulsary", label_nep: "अनिवार्य " },
    { value: "2", label: "Optional", label_nep: "ऐच्छिक" },
];


export const student_details_menu_list = [
    { value: "1", label: "Personal", des: "Personal information of student", icon: <FaUserGraduate /> },
    { value: "2", label: "Address", des: "Where a student resides", icon: <FaAddressCard /> },
    { value: "3", label: "Parent", des: "Send this activity with.", icon: <RiParentFill /> },
    { value: "4", label: "Library", des: "Issue/Return library book", icon: <IoLibrary /> },
    { value: "5", label: "Account", des: "Any fees due or return", icon: <MdAccountBox /> },
    { value: "6", label: "Result", des: "Result and preformance", icon: <PiExamFill /> },

];



export const staff_details_menu_list = [
    { value: "1", label: "Personal", des: "Personal information of student", icon: <FaUserGraduate /> },
    { value: "2", label: "Address", des: "Where a student resides", icon: <FaAddressCard /> },
    { value: "4", label: "Library", des: "Issue/Return library book", icon: <IoLibrary /> },
    { value: "5", label: "Salary", des: "Any fees due or return", icon: <MdAccountBox /> },
];


export const vehicle_type = [
    { value: "1", label: "Bus", nep_label: "बस" },
    { value: "2", label: "Van", nep_label: "भ्यान" },
    { value: "3", label: "Jeep", nep_label: "जीप" },
];



export const door_side_list = [
    { value: "1", label: "Left Side", nep_label: "बायाँ पक्ष" },
    { value: "2", label: "Right Side", nep_label: "दाहिने तिर" },
];