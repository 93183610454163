import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import AppSidebar from '../components/common/AppSidebar';
import AppHeader from '../components/common/AppHeader';
import AppFooter from '../components/common/AppFooter';
import AppPageTitle from '../components/common/AppPageTitle';
import MainContent from '../components/common/MainContent';
import { fetchDataFromApi } from '../widgets/api';
import { useDispatch, useSelector } from 'react-redux';
import AppURL from '../widgets/AppURL';
import { LanguageProvider } from '../widgets/LangContext';
import AppSnackbar from '../widgets/AppSnackbar';
import { SidebarMenuList } from '../store/softSlice';
import { LoginContext } from '../widgets/AuthProvider';
const DefaultLayouts = () => {
    const dispatch = useDispatch();
    const [onSidebarMenu, setOnSidebarMenu] = useState([]);
    const [onLicense, setOnLicense] = useState('');
    const title = useSelector((state) => state?.software?.title);
    const [loading, setLoading] = useState(true);
    const [onIconsButton, setIconsButton] = useState("");
    const [onstructureName, setOnStructureName] = useState([]);
    const { authUser, userID } = useContext(LoginContext);
    useEffect(() => {
        const SidebarMenuDetails = async () => {
            if (authUser && userID) {
                try {
                    await fetchDataFromApi(AppURL.ApplicationURL + 'menu-details-list-view/?user_id=' + userID).then((res) => {
                        setOnSidebarMenu(res?.data);
                        dispatch(SidebarMenuList(res));
                        setOnLicense(res?.license);
                        setLoading(false);
                        setOnStructureName(res);
                    });
                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            }
        }
        SidebarMenuDetails();

    }, [title,  authUser, userID ]);
  
    return (
        <LanguageProvider>
            <Layout>
                <AppSidebar onSidebarMenu={onSidebarMenu} />
                <Layout className='min-h-screen bg-gray100 dark:bg-slate900 ml-[75px]'>
                    <AppHeader onstructureName={onstructureName} />
                    <AppPageTitle title={title?.title} onIconsButton={onIconsButton} />
                    <Content className='pl-8 pr-6 mt-[10px]'>
                        <MainContent setIconsButton={setIconsButton} />
        
                        {!loading && <Fragment>
                            {
                                onLicense <= 7 && <AppSnackbar onLicense={onLicense} />
                            }
                        </Fragment>
                        }
                    </Content>
                    <AppFooter />
                </Layout>
            </Layout>
        </LanguageProvider>
    )
}

export default DefaultLayouts