import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import MobileAppDetailsListView from '../../../components/hr/create/app_details/MobileAppDetailsListView';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';

const MobileAppDetailsGenerateCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }));
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
  const [onEmployeeList, setOnEmployeeList] = useState([]);
    const url = AppURL.HR_URL+"hr-employee-relation-details-list-view/";
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const fetchAcademicsListView = async (urls) => {
      setOnUpdateURL(urls);
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnEmployeeList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchAcademicsListView(url);
    }
    useEffect(() => {
        FetchData();
    }, []);
  
  return (
    <Fragment>
      <MobileAppDetailsListView onUpdateURL={onUpdateURL} onEmployeeList={onEmployeeList} fetchAcademicsListView={fetchAcademicsListView} />
    </Fragment>
  )
}

export default MobileAppDetailsGenerateCreateListView