import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { pageTitle } from '../../../store/softSlice';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
import { Grid } from "@mui/material";
import LeaveTypeCreateView from '../../../components/attendance/setup/leave_type/LeaveTypeCreateView';
import LeaveTypeEditView from '../../../components/attendance/setup/leave_type/LeaveTypeEditView';
import LeaveTypeListView from '../../../components/attendance/setup/leave_type/LeaveTypeListView';

const AttendanceLeaveTypeCreateListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null);
    }, []);
    const [onEditID, setOnEditID] = useState(false);
    const [onAcaListData, setOnAcaListData] = useState([]);
    const url = AppURL.AttendanceURL + "attendace-leave-type-create-list-view/";
    const [onUpdateURL, setOnUpdateURL] = useState("");
    const fetchAcademicsListView = async (urls) => {
        setOnUpdateURL(urls);
        try {
            await fetchDataFromApi(urls).then((res) => {
                setOnAcaListData(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const FetchData = async () => {
        await fetchAcademicsListView(url);
    }
    useEffect(() => {
        FetchData();
    }, []);
    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                    {
                        onEditID ? <LeaveTypeEditView setOnEditID={setOnEditID} fetchAcademicsListView={fetchAcademicsListView} onUpdateURL={onUpdateURL} onEditID={onEditID} /> : <LeaveTypeCreateView FetchData={FetchData} />
                    }
                </Grid>
                <Grid item xl={6} lg={6} sm={12} xs={12} md={6}>
                    <LeaveTypeListView fetchAcademicsListView={fetchAcademicsListView} onAcaListData={onAcaListData} setOnEditID={setOnEditID}/>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default AttendanceLeaveTypeCreateListView