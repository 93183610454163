import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import ReportHeading from '../../../../widgets/ReportHeading';
import { Grid } from '@mui/material';
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { ClearBtn, SearchBtn } from '../../../../widgets/AppBtn';
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { SidebarReportClose } from '../../../../store/softSlice';
import { MediumOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { ClassRoutineListView } from '../../../../store/academicsSlice';
import { useLanguage } from '../../../../widgets/LangContext';
import { Account_Dues_Report_List_View } from '../../../../store/accountSlice';

const AccountDuesReportFormView = ({ onStudentOptionList }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { language } = useLanguage();
    const CloseDispatch = () => {
        dispatch(SidebarReportClose({ "action": false }));
    }
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const MediumOption = MediumOptions(lanugage_medium);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
    };

    const { handleChange, handleBlur, handleSubmit, isSubmitting, errors, touched } =
        useFormik({
            initialValues: initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                try {
                    await fetchDataFromApi(AppURL.AccountsURL + "account-fee-dues-report-all-student-report-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&medium=" + selectValues?.medium?.value,).then((res) => {
                        dispatch(Account_Dues_Report_List_View(res));
                        CloseDispatch();
                        navigate("/account-student-dues-report-list-view");
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <div className=''>
            <ReportHeading heading="Student Dues Report" />
            <div className='mt-4'>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                    </Grid>
                    <div className='flex justify-between mt-8'>
                        <ClearBtn type="button" onClick={CloseDispatch} /> <SearchBtn disabled={isSubmitting} type="submit" />
                    </div>
                </form>
            </div>
        </div>
    )
}
export default AccountDuesReportFormView