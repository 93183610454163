import { ToWords } from 'to-words';

export const MoneyFormat = (number) => {
    const { FormatMoney } = require('format-money-js');
    const fm = new FormatMoney({
        decimals: 2
    });
    const convertedData = fm.from(number?number:0);
    return convertedData;
}


export const NumberToWord = (number) => {
    const toWords = new ToWords();
    const words = toWords.convert(number?number:0);
    return words;
}

export const NumberToNepaliNum = (number)=>{
    const nepaliNumber = require('nepali-number')
   const convert_num = nepaliNumber.englishToNepaliNumber(number, "ne");
   return convert_num;
}


export const NumNepali=(number)=>{
    const numWords = require('num-words');
    const Result =numWords(number?number:0);
    return Result;
}

export const ordinalSuffix = (num) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = num % 10;
    return suffixes[remainder - 1] || "th";
  };

 export const timeAMandPMformat=(time)=>{
    let [hours, minutes] = time.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert '0' or '12' to '12'
    return `${hours}:${minutes < 10 ?  minutes : minutes} ${period}`;
}