
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import NepaliInputTextBox from '../../../../widgets/NepaliInputTextBox';
import { SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";

const LeaveTypeCreateView = ({ FetchData, onEntincityList }) => {
    const { language } = useLanguage();
    const [customValues, setCustomValues] = useState({
        leave_type_name_nep: "",
    });
    ;

    const handleNepaliInputChange = (fieldName, value) => {
        handleChange(fieldName)(value)
        setCustomValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };
    const initialValues = {
        leave_type_name: "",
        leave_type_name_nep: "",
    };

    const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("leave_type_name", values.leave_type_name);
                formData.append("leave_type_name_nep", customValues.leave_type_name_nep);
                try {
                    await postDataToTheServer(AppURL.AttendanceURL + "attendace-leave-type-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        setCustomValues({
                            leave_type_name_nep: "",
                        });
                        action.resetForm();
                        FetchData();
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add Leave Type" : "छोड्ने प्रकार"} icon={IoMdAddCircle} />
                <Grid container spacing={1.5}>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <InputTextBox
                            name="leave_type_name"
                            type="text"
                            label={language ? "Leave Type Name" : "छोड्ने प्रकार नाम"}
                            value={values.leave_type_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            placeholder="Enter Leave Type Name"
                            touched={touched.leave_type_name}
                            errors={errors.leave_type_name}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
                        <NepaliInputTextBox
                            name="leave_type_name_nep"
                            label={language ? "Leave Type Name (Nepali)" : "छोड्ने प्रकार नाम (नेपाली)"}
                            value={customValues.leave_type_name_nep}
                            onCustomValueChange={handleNepaliInputChange}
                            handleBlur={handleBlur}
                            touched={touched.leave_type_name_nep}
                            placeholder="Enter Leave Type Name(Nepali)"
                            errors={errors.leave_type_name_nep}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default LeaveTypeCreateView