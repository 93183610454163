import React, { Fragment } from 'react'
import ComponentHeading from '../../../../widgets/ComponentHeading'
import { FaListUl } from 'react-icons/fa'
import { useLanguage } from '../../../../widgets/LangContext'
import { EditPenBtn, NextBtn, PrevBtn } from '../../../../widgets/AppBtn'

const VechileListView = ({ onAcaListData, fetchAcademicsListView, setOnEditID }) => {
  const { language } = useLanguage();
  return (
    <Fragment>
      <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
        <ComponentHeading icon={FaListUl} label={language ? "Student List" : "टिमको सूची"} />
        <div className="relative overflow-x-scroll">
          <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
            <thead className="text-[12px] text-gray600  capitalize border-b  dark:text-gray-400 font-public-sans">
              <tr>
                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                  {language ? "S.N." : "क्र.सं."}
                </th>
                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                  {language ? "Vehicle Type" : "पुरा नाम"}
                </th>
                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                  {language ? "Vehicle Number" : "पुरा नाम (नेपाली)"}
                </th>
                <th scope="col" align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 w-12">
                  {language ? "BlueBook Upto" : "रोल नं"}
                </th>
                <th scope="col" align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                  {language ? "Driver Name" : "फोन"}
                </th>
                <th scope="col" align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                  {language ? "Driver Phone" : "DOB"}
                </th>
                <th scope="col" align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                  {language ? "Driver Address" : "लिङ्ग"}
                </th>
                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                  {language ? "Action " : "कार्य"}
                </th>
              </tr>
            </thead>
            <tbody className='text-slate-800'>
              {Array.isArray(onAcaListData?.results) && onAcaListData?.results?.length > 0 ? (
                onAcaListData?.results?.map((cls, index) => (
                  <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                    <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                      {index + 1}.
                    </td>
                    <td className="px-2 border dark:border-slate600 border-gray300 font-medium capitalize">
                      {`${cls?.vehicle_type === 1 ? 'Bus' : cls?.vehicle_type === 2 ? 'Van' : 'Jeep'}`}
                    </td>
                    <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                      {cls?.vehicle_number}
                    </td>
                    <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                      {cls?.blue_book_upto}
                    </td>
                    <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                      {cls?.driver_name}
                    </td>
                    <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                      {cls?.driver_address}
                    </td>
                    <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                      {cls?.driver_contact}
                    </td>
                    <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                      <EditPenBtn wh={6} onClick={() => setOnEditID(cls?.sn)} />
                    </td>
                  </tr>
                ))
              ) : (<tr>
                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                  Student not found.
                </td>
              </tr>
              )}
            </tbody>
          </table>

        </div>
      </div>
      <div className='flex gap-3 mt-3 justify-end'>
        <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
          if (onAcaListData?.previous) {
            await fetchAcademicsListView(onAcaListData?.previous)
          }
        }} />
        <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
          if (onAcaListData?.next) {
            await fetchAcademicsListView(onAcaListData?.next)
          }
        }} />
      </div>
    </Fragment>
  )
}

export default VechileListView