import React from 'react'
import DefaultImage from '../../../../../assets/img/img/default-img.png'
import AppURL from "../../../../../widgets/AppURL";
import { useLanguage } from '../../../../../widgets/LangContext';
const StaffCard = ({ member_sn, photo, full_name, staff_qualification, joining_date, designation, perAdd, phone }) => {
  const { language } = useLanguage();
  return (
    <div className='bg-white shadow rounded-md px-3 py-3 dark:bg-slate800'>
      <div>
        <div>
          <img src={photo?AppURL.BaseURL+photo:DefaultImage} alt="" className='mx-auto border w-36 h-36 rounded-full' />
        </div>
        <h4 className='mt-3 font-public-sans font-semibold dark:text-gray-400 text-customPrimary text-center text-[18px]'>{full_name}</h4>
        <div className="relative overflow-x-auto p-3">
          <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap font-public-sans scroll-x-auto dark:text-gray-400 rounded w-full">
            <thead className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-medium font-public-sans dark:border-gray-700">
              <tr>
                <td align='left' className="px-2 py-1">
                  {language ? "Member SN" : "क्र.सं."}
                </td>
                <td align='left' className="px-2 py-1">
                  :
                </td>
                <td align='left' className="px-2 py-1">
                  {member_sn}
                </td>
              </tr>
            </thead>
            <tbody className='text-slate-800  font-medium'>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Qualification
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {staff_qualification}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Joining Date
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {joining_date}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Designation
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {designation}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Address
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {perAdd}
                </td>
              </tr>
              <tr className="text-[12.5px] text-gray600 dark:text-gray-400 border-b font-public-sans dark:border-gray-700">
                <td align='left' className="px-2 py-1">
                  Phone No
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  :
                </td>
                <td align='left' className="px-2 py-1 capitalize">
                  {phone}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default StaffCard