import React, { Fragment } from 'react';
import MarksNumReportFromView from '../../../../components/exam/report/marks_rpt_number/MarksNumReportFromView';

const MarksLedgerNumberFormView = () => {
  return (
    <Fragment>
        <MarksNumReportFromView />
    </Fragment>
  )
}

export default MarksLedgerNumberFormView