import React, { Fragment } from 'react'
import VoucherRptFormView from '../../../../components/account/report/voucher_report/VoucherRptFormView'

const AccountVoucherReportForm = () => {
  return (
    <Fragment>
        <VoucherRptFormView />
    </Fragment>
  )
}

export default AccountVoucherReportForm


