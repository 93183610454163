
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { ClearBtn, SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { fetchDataFromApi, putDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";
import { CustomOptions, StdBatchOptions } from '../../../../widgets/Options';
import { door_side_list } from '../../../../widgets/json';
import { CiEdit } from 'react-icons/ci';
import DefaultSelectOptionBox from '../../../../widgets/DefaultSelectBox';

const HallRoomEditView = ({ onUpdateURL, fetchHallRoomList, setOnEditID, onBatchList, onEditID }) => {
    const [hallRoomDetails, setOnHallRoomDetails] = useState({});
    const [selectValues, setOnSelectValues] = useState({
        door_side: "",
        batch_id: "",
    });
    useEffect(() => {
        const FetchExamHallView = async () => {
            if (onEditID) {
                try {
                    await fetchDataFromApi(AppURL.ExamURL + "exam-seat-plan-hall-room-retrieve-update-view/" + onEditID).then((res) => {
                        setOnHallRoomDetails(res?.data);
                        console.log(res?.data)
                        setOnSelectValues({
                            door_side: res?.data?.door_side || "",
                            batch_id: res?.data?.batch || "",
                        })

                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
        FetchExamHallView();
    }, [onEditID]);

    const { language } = useLanguage();
    const DoorSideOption = CustomOptions(door_side_list);
    const StdBatchOption = StdBatchOptions(onBatchList?.batch);

    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data?.value,
        }));
    };

    const initialValues = {
        door_side: "",
        batch_id: "",
        hall_name: hallRoomDetails?.hall_name || "",
        desk_row: hallRoomDetails?.desk_row || "",
        desk_col: hallRoomDetails?.desk_col || "",
        seat_cap: hallRoomDetails?.seat_cap || "",
    };

    const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("door_side", selectValues.door_side);
                formData.append("batch", selectValues.batch_id);
                formData.append("hall_name", values.hall_name);
                formData.append("hall_id", onEditID);
                formData.append("desk_row", values.desk_row);
                formData.append("desk_col", values.desk_col);
                formData.append("seat_cap", values.seat_cap);
                try {
                    await putDataToTheServer(AppURL.ExamURL + "exam-seat-plan-hall-room-retrieve-update-view/" + onEditID, formData).then((res) => {
                        ToastSuccess(res?.message);
                        fetchHallRoomList(onUpdateURL);
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Edit Hall Room/Class Room" : "हल कोठा/कक्षा कोठा थप्नु"} icon={CiEdit} />
                <Grid container spacing={1}>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <DefaultSelectOptionBox
                            label={language ? "Batch" : "ब्याच"}
                            placeholder="Select Batch"
                            value={selectValues.batch_id}
                            options={StdBatchOption}
                            name="batch_id"
                            optionSelect={optionHandleSelect}
                            handleBlur={handleBlur}
                            touched={touched.batch_id}
                            errors={errors.batch_id}
                            required={true} />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="hall_name"
                            type="text"
                            placeholder="Enter Hall Name"
                            label={language ? "Hall Name" : "हलको नाम"}
                            value={values.hall_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.hall_name}
                            errors={errors.hall_name}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <DefaultSelectOptionBox label={language ? "Door Side" : "ढोका साइड"} placeholder="Door Side" value={selectValues.door_side} options={DoorSideOption} name="door_side" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                            touched={touched.door_side} errors={errors.door_side} required={true} />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="desk_row"
                            type="number"
                            placeholder="Enter Row Line Desk Number"
                            label={language ? "Row Desk Number" : "परीक्षा पूर्ण अंक"}
                            value={values.desk_row}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.desk_row}
                            errors={errors.desk_row}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="desk_col"
                            type="number"
                            placeholder="Enter Column Line Desk Number"
                            label={language ? "Column Desk Number" : "परीक्षा पूर्ण अंक"}
                            value={values.desk_col}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.desk_col}
                            errors={errors.desk_col}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="seat_cap"
                            type="number"
                            placeholder="Enter Seat Capacity(Per Desk)"
                            label={language ? "Seat Capacity(Per Desk)" : "सिट क्षमता"}
                            value={values.seat_cap}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.seat_cap}
                            errors={errors.seat_cap}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-3 gap-2">
                    <ClearBtn onClick={() => setOnEditID(false)} /> <SaveBtn type="submit" />
                </div>
            </form>
        </Fragment>
    )
}

export default HallRoomEditView