import React, { Fragment, useEffect, useState } from 'react'
import ContentHeading from '../../../../widgets/ContentHeading'
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../../widgets/LangContext';
import { useFormik } from 'formik';
import { SaveBtn } from '../../../../widgets/AppBtn';
import { FaSearch } from "react-icons/fa";
import { fetchDataFromApi, postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { MediumOptions, StdClassOptions, StdSectionOptions, SubjectListOptions, TeacherOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { useDispatch } from "react-redux";
import { Class_Wise_Subject_List } from '../../../../store/academicsSlice';
import InputTextBox from "../../../../widgets/InputTextBox";
import { ToastSuccess, ToastWarning } from '../../../../widgets/ToastMessage';

const WeeklyLessionPlanCreateView = ({ onStudentOptionList, FetchData }) => {
    const { language } = useLanguage();
    const dispatch = useDispatch();
    const lang = lanugage_medium;
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
        member_sn: "",
        subject_id: "",
        teacher_id: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        if (data === null || data.value === "") {
            // If data is null or empty, clear the input value
            handleChange(fieldName)("");
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                [fieldName]: "",
            }));
        } else {
            // Otherwise, update the input value as usual
            handleChange(fieldName)(data.value);
            setOnSelectValues((prevValues) => ({
                ...prevValues,
                [fieldName]: data,
            }));
        }
        if (fieldName === 'class_id') {
            dispatch(Class_Wise_Subject_List([]));
        }
    };

    const [subList, setOnSubList] = useState([]);
    const FilterListView = async (id) => {
        if (selectValues?.class_id) {
            try {
                await fetchDataFromApi(AppURL.AcademicsURL + "academics-class-routine-options-list-view/?class_id=" + id).then((res) => {
                    setOnSubList(res);
                });
            } catch (error) {
                console.log(error);
            }
        }
    }
    useEffect(() => {
        FilterListView(selectValues?.class_id?.value);
    }, [selectValues?.class_id]);

    const StaffOption = TeacherOptions(subList?.teachers);
    const SubjectListOption = SubjectListOptions(subList?.subjects);
    
    const MediumOption = MediumOptions(lang)
    const initialValues = {
      class_id :"",
      section :"",
      member_sn :"",
      subject :"",
      medium :"",
      lession_to_be_taught :"",
      objective_to_lession :"",
      teachining_materials :"",
      teachining_methods :"",
      assignment_test :"",
    };
    const { errors, touched, handleBlur, values, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("class_id", selectValues.class_id.value);
                formData.append("section", selectValues.section?.value);
                formData.append("medium", selectValues.medium?.value);
                formData.append("member_sn", selectValues.member_sn?.value);
                formData.append("subject", selectValues.subject_id?.value);
                formData.append("lession_to_be_taught", values.lession_to_be_taught);
                formData.append("objective_to_lession", values.objective_to_lession);
                formData.append("teachining_materials", values.teachining_materials);
                formData.append("teachining_methods", values.teachining_methods);
                formData.append("teachining_materials", values.teachining_materials);
                formData.append("teachining_materials", values.teachining_materials);

                formData.append("remarks", values.remarks);
                try {
                    await postDataToTheServer(AppURL.AcademicsURL + "academics-teacher-weekly-lession-plan-create-list-view/", formData).then((res) => {
                        if (res?.status === 201) {
                            ToastSuccess(res?.message);
                            FetchData();
                            setOnSelectValues({
                                class_id: "",
                                section: "",
                                medium: "",
                                member_sn: "",
                                subject_id: "",
                                teacher_id: ""
                            });
                            action.resetForm();
                        } else {
                            ToastWarning(res?.message)
                        }
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });

    return (
        <Fragment>
            <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
                <ContentHeading label="Add Weekly Lession Plan" icon={FaSearch} margin={0} />
                <form onSubmit={handleSubmit}>
                    <Grid container rowSpacing={0.5} columnSpacing={0.5}>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Teacher" : "शिक्षक"} placeholder="" options={StaffOption} value={selectValues.member_sn} name="member_sn" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.member_sn} errors={errors.member_sn} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Subject" : "विषय"} placeholder="" options={SubjectListOption} value={selectValues.subject_id} name="subject_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.subject_id} errors={errors.subject_id} required={true} />
                        </Grid>
                        
                        
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox
                                name="lession_to_be_taught"
                                type="text"
                                label={language ? "Lession To Be Taught" : "अध्याय/विषयहरू"}
                                value={values.lession_to_be_taught}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.lession_to_be_taught}
                                errors={errors.lession_to_be_taught}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox
                                name="objective_to_lession"
                                type="text"
                                label={language ? "Objectives To the Lession" : "कक्षा गतिविधिहरू"}
                                value={values.objective_to_lession}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.objective_to_lession}
                                errors={errors.objective_to_lession}
                                required={false}
                            />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox
                                name="teachining_materials"
                                type="text"
                                label={language ? "Teaching Materials" : "गृह असाइनमेन्ट"}
                                value={values.teachining_materials}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.teachining_materials}
                                errors={errors.teachining_materials}
                                required={false}
                            />
                        </Grid>

                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox
                                name="teachining_methods"
                                type="text"
                                label={language ? "Teaching Methods" : "टिप्पणीहरू"}
                                value={values.teachining_methods}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.teachining_methods}
                                errors={errors.teachining_methods}
                                required={false}
                            />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox
                                name="assignment_test"
                                type="text"
                                label={language ? "Assignments Test or Project Works" : "टिप्पणीहरू"}
                                value={values.assignment_test}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched.assignment_test}
                                errors={errors.assignment_test}
                                required={false}
                            />
                        </Grid>
                    </Grid>
                    <div className='flex justify-end mt-5'>
                        <SaveBtn type="submit" />
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default WeeklyLessionPlanCreateView