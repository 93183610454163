import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useLanguage } from '../../../../widgets/LangContext';
import AcademicsCalenderEventModal from './widgets/AcademicsCalenderEventModal';
import AppURL from '../../../../widgets/AppURL';

const AcademicsCalenderView = ({onCalenderListData, onUpdateURL, fetchAcademicsListView}) => {
    const { language } = useLanguage();
    const eventData = [
        {
            title: 'Football and Volleyball Games',
            start: '2024-05-15', // Start date format: YYYY-MM-DD
            end: '2024-05-17',
            color: "green"
        },
        {
            title: 'Football and Volleyball Games',
            start: '2024-05-15', // Start date format: YYYY-MM-DD
            end: '2024-05-17',
            color: "green"
        },
        {
            title: 'School Sports',
            date: '2024-05-20',
            color: 'red'
        },
        // Add more events as needed
    ];
    const [currentDate, setCurrentDate] = useState(new Date());
    const calendarRef = useRef(null);

    useEffect(() => {
        if (calendarRef.current) {
            calendarRef.current.getApi().gotoDate(currentDate);
        }
    }, []);
    const [onModalOpen, setOnModalOpen] = useState({ open: false, date: "" })


    const handleDatesSet = (arg) => {
        setCurrentDate(arg.view.currentStart);
        const year = arg.view.currentStart.getFullYear();
        const month = arg.view.currentStart.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
        fetchAcademicsListView(AppURL.AcademicsURL + "academics-calender-event-create-list-view/?month=" + month + "&&year=" + year);
        console.log(month, year);
    };
    
    const handleDateClick = (info) => {
        const clickedDate = new Date(info.date.setMinutes(info.date.getMinutes() - info.date.getTimezoneOffset()));
        const formattedDate = clickedDate.toISOString().split('T')[0];
        setOnModalOpen({ open: true, date: formattedDate });
    };

    return (
        <div className="bg-white rounded-md p-5 font-public-sans">
            <AcademicsCalenderEventModal onUpdateURL={onUpdateURL} fetchAcademicsListView={fetchAcademicsListView} onModalOpen={onModalOpen} setOnModalOpen={setOnModalOpen} />
            <FullCalendar
                ref={calendarRef}

                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={"dayGridMonth"}
                headerToolbar={{
                    start: "today prev,next",
                    center: "title",
                    end: "dayGridMonth"
                }}
                height={'70vh'} // Tailwind CSS height class
                eventClassNames="capitalize"
                viewClassNames={'capitalize'}
                events={onCalenderListData}
                dateClick={handleDateClick}
                datesSet={handleDatesSet}
                dayHeaderClassNames={'bg-gray200'}
            />

        </div>
    );
}

export default AcademicsCalenderView;
