import React, { Fragment } from 'react';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa';
import { useLanguage } from '../../../../widgets/LangContext';
import { EditPenBtn } from '../../../../widgets/AppBtn';

const WeeklyLessionPlanListView = ({ onAcaListData }) => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Weekly Lession Plan List" : "दैनिक शिक्षक लग बुक सूची"} />
                <div className="relative overflow-x-auto overflow-y-scroll">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[12px] text-gray600 capitalize border-b  dark:text-gray-400 font-public-sans">
                            <tr className='bg-gray200'>

                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Teacher Name" : "पुरा नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1 border border-gray300 dark:border-slate600">
                                    {language ? "Subject" : "पुरा नाम (नेपाली)"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-12">
                                    {language ? "Class" : "रोल नं"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Lession To Be Taught" : "फोन"}
                                </th>
                                <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Objectives To the Lession" : "DOB"}
                                </th>
                                {/* <th scope="col" align='center' className="px-2 py-1 border border-gray300 dark:border-slate600 w-20">
                                    {language ? "Action" : "लिङ्ग"}
                                </th> */}

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onAcaListData?.results) && onAcaListData?.results?.length > 0 ? (
                                onAcaListData?.results?.map((cls, index) => (
                                    <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 border-gray300 font-medium capitalize">
                                            {`${cls?.first_name} ${cls?.middle_name ? cls?.middle_name : ""} ${cls?.last_name}`}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.subject_name}
                                        </td>
                                        <td align='center' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.class_name}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.lession_to_be_taught}
                                        </td>
                                        <td align='left' className="px-2 border border-gray300 dark:border-slate600 ">
                                            {cls?.objective_to_lession}
                                        </td>
                                        {/* <td align='center' className="px-2 py-0.5 border border-gray300 dark:border-slate600 ">
                                            <EditPenBtn wh={6} />
                                        </td> */}
                                    </tr>
                                ))
                            ) : (<tr>
                                <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                    Data not found.
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>

                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>

            </div>
        </Fragment>
    )
}

export default WeeklyLessionPlanListView