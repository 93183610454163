import React, { Fragment, Suspense, useContext } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import "nepali-datepicker-reactjs/dist/index.css";
import LoginView from './views/auth/LoginView';
import RenewLicenseView from './views/auth/RenewLicenseView';
import DefaultLayouts from './layouts/DefaultLayouts';
import AppLoading from './components/common/AppLoading';
import RequireAuth from './widgets/RequireAuth';
import { LoginContext } from './widgets/AuthProvider';
const App = () => {
  const { authUser } = useContext(LoginContext);

  return (
    <Fragment>
      <HashRouter>
        <Suspense fallback={<AppLoading />}>
          <Routes>
            {authUser ? (
              <Route path="/dash" element={<DefaultLayouts />} />
            ) : (
              <Route path="/" element={<LoginView />} />
            )}
            <Route path="/renew-license" element={<RenewLicenseView />} />
            <Route exact element={<RequireAuth />}>
              <Route path="*" element={<DefaultLayouts />} />
            </Route>
          </Routes>
        </Suspense>
      </HashRouter>
      <Toaster />
    </Fragment>
  );
};

export default App;
