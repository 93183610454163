import React, { Fragment, useEffect, useRef } from 'react'
import WeeklyLessionPlanListView from '../../../../components/academics/report/weekly_lession_plan/WeeklyLessionPlanListView'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { CardPrintBtn } from '../../../../widgets/AppBtn';
import { useReactToPrint } from 'react-to-print';
import WeeklyLessionPlanPDFView from '../../../../components/academics/report/weekly_lession_plan/WeeklyLessionPlanPDFView';

const WeeklyLessionPlanReportPageListView = ({setIconsButton, PageTitle}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(<CardPrintBtn onClick={handlePrintCard} bg_color={1} />);
    }, []);
    const componentListRef = useRef();
    const RenderListComponent = React.forwardRef(() => (
        <WeeklyLessionPlanPDFView />
    ));

    const handlePrintCard = useReactToPrint({
        content: () => componentListRef.current,
    });
  return (
    <Fragment>
        <WeeklyLessionPlanListView />
        <div className='hidden'>
                <div ref={componentListRef}>
                    <RenderListComponent />
                </div>
            </div>
    </Fragment>
  )
}

export default WeeklyLessionPlanReportPageListView