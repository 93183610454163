import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
    name: 'accounts',
    initialState: {
        general_ledger_list: {},
        sub_ledg_list: {},
        particular_list: {},
        contra_voucher_list: [],
        ContraVoucherInfo: {},
        voucher_list_rpt: [],
        std_fee_duesing_ledger: {},
        dues_billing_list: {},
        account_trial_balance_list: {},
        account_transaction_rpt_list: {},
        fee_mapping_report_list: {},
        account_date_form_get_list: {}
    },
    reducers: {
        GL_Ledger_List: (state, action) => {
            state.general_ledger_list = action.payload;
        },
        Sub_Ledger_List: (state, action) => {
            state.sub_ledg_list = action.payload;
        },
        Particular_List_AC: (state, action) => {
            state.particular_list = action.payload;
        },
        ContraVoucherInfo: (state, action) => {
            state.contra_voucher_info = action.payload;
        },
        ContraVoucherLedgerList: (state, action) => {
            const newData = action.payload;
            state.contra_voucher_list = [...state.contra_voucher_list, newData];
        },
        resetContraVoucherList: (state) => {
            state.contra_voucher_list = []; // Reset to an empty array
        },
        VoucherListViewReport: (state, action) => {
            state.voucher_list_rpt = action.payload;
        },
        StdFeeDuesingListView: (state, action) => {
            state.std_fee_duesing_ledger = action.payload;
        },
        DuesBillingListView: (state, action) => {
            state.dues_billing_list = action.payload;
        },
        AccountTrialBalanceRptListView: (state, action) => {
            state.account_trial_balance_list = action.payload;
        },
        AccountTreansactionBalanceRpt: (state, action) => {
            state.account_transaction_rpt_list = action.payload;
        },
        FeeMappingReportListView: (state, action) => {
            state.fee_mapping_report_list = action.payload;
        },
        FeeMappingReportOptionsList: (state, action) => {
            state.fee_mapping_options_list = action.payload;
        },
        Account_ledger_Report_Select_: (state, action) => {
            state.account_ledger_report_select = action.payload;
        },
        Account_Date_Form_Get_List_view: (state, action) => {
            state.account_date_form_get_list = action.payload;
        },
        Account_Dues_Report_List_View:(state, action)=>{
            state.account_dues_report_list = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { GL_Ledger_List, VoucherListViewReport, Account_Dues_Report_List_View, Account_Date_Form_Get_List_view, FeeMappingReportListView, Account_ledger_Report_Select_, FeeMappingReportOptionsList, AccountTreansactionBalanceRpt, AccountTrialBalanceRptListView, resetContraVoucherList, StdFeeDuesingListView, DuesBillingListView, ContraVoucherLedgerList, ContraVoucherInfo, Sub_Ledger_List, Particular_List_AC } = accountSlice.actions
export default accountSlice.reducer