import React, { Fragment } from 'react';
import { FaListUl } from 'react-icons/fa6';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { useLanguage } from '../../../../widgets/LangContext';
import { EditPenBtn, NextBtn, PrevBtn } from '../../../../widgets/AppBtn';

const BookKindListView = ({ onAcaListData, setOnEditID, fetchAcademicsListView }) => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Book Category List" : "कक्षाको सूची"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-2 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Category Name" : "कक्षाको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Category Name(Nepali)" : "कक्षाको नाम (नेपाली)"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Action " : "कार्य"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(onAcaListData?.results) &&
                                onAcaListData?.results?.map((cate, index) => (
                                    <tr key={index} className="odd:bg-white text-[13px] text-gray-800 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                        <td align='center' className="px-2 border dark:border-slate600  ">
                                            {index + 1}.
                                        </td>
                                        <td className="px-2 border dark:border-slate600 capitalize">
                                            {cate?.book_cate_name}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600 ">
                                            {cate?.book_cate_name_nep}
                                        </td>
                                        <td className="px-2 border dark:border-slate600 ">
                                            <EditPenBtn onClick={async () => await setOnEditID(cate?.sn)} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
                    if (onAcaListData?.previous) {
                        await fetchAcademicsListView(onAcaListData?.previous)
                    }
                }} />
                <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
                    if (onAcaListData?.next) {
                        await fetchAcademicsListView(onAcaListData?.next)
                    }
                }} />
            </div>
        </Fragment>
    )
}

export default BookKindListView