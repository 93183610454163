import React, { Fragment } from 'react'
import ComponentHeading from '../../../widgets/ComponentHeading'
import { FaListUl } from 'react-icons/fa'
import { useLanguage } from '../../../widgets/LangContext'

const AssignmentListView = () => {
    const { language } = useLanguage();
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
                <ComponentHeading icon={FaListUl} label={language ? "Author List" : "लेखकको सूची"} />
                <div className="relative overflow-x-auto">
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[13px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 py-2 border dark:border-slate600 w-10">
                                    {language ? "S.N." : "क्र.सं."}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-[20%]">
                                    {language ? "Teacher Name" : "लेखकको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-[60%]">
                                    {language ? "Assignment Title" : "लेखकको नाम"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600">
                                    {language ? "Assignment File" : "लेखकको नाम (नेपाली)"}
                                </th>
                                <th scope="col" className="px-2 py-1.5 border dark:border-slate600 w-10">
                                    {language ? "Action " : "कार्य"}
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>

                        </tbody>
                    </table>

                </div>
            </div>
            <div className='flex gap-3 mt-3 justify-end'>
                {/* <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
                    if (onAcaListData?.previous) {
                        await fetchAcademicsListView(onAcaListData?.previous)
                    }
                }} />
                <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
                    if (onAcaListData?.next) {
                        await fetchAcademicsListView(onAcaListData?.next)
                    }
                }} /> */}
            </div>
        </Fragment>
    )
}

export default AssignmentListView