import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";
import NepaliInputTextBox from '../../../../widgets/NepaliInputTextBox';

const DepartmentCreateFormView = ({ FetchData }) => {
    const { language } = useLanguage();
    const [customValues, setCustomValues] = useState({
        dep_name_nep: "",
    });

    const handleNepaliInputChange = (fieldName, value) => {
        handleChange(fieldName)(value)
        setCustomValues((prevValues) => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };
    const initialValues = {
        dep_name: "",
        dep_name_nep: ""
    };

    const { values, errors, touched, isSubmitting, handleReset, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("dep_name", values.dep_name);
                formData.append("dep_name_nep", customValues.dep_name_nep);
                try {
                    await postDataToTheServer(AppURL.ApplicationURL + "app-department-create-list-view/", formData).then((res) => {
                        ToastSuccess(res?.message);
                        setCustomValues({
                            dep_name_nep: ""
                        });
                        action.resetForm();
                        FetchData();
                       
                    });
                } catch (error) {
                    console.log(error);
                }
            },
        });
    return (
        <Fragment>
            <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
                <ComponentHeading label={language ? "Add Department" : "विभाग थप्नु"} icon={IoMdAddCircle} />
                <Grid container spacing={1.5}>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <InputTextBox
                            name="dep_name"
                            type="text"
                            placeholder="Enter Department Name"
                            label={language ? "Department Name" : "विभाग नाम"}
                            value={values.dep_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.dep_name}
                            errors={errors.dep_name}
                            required={true}
                        />
                    </Grid>
                    <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                        <NepaliInputTextBox
                            name="dep_name_nep"
                            label={language ? "Department Name (Nepali)" : "विभाग नाम (नेपाली)"}
                            value={customValues.dep_name_nep}
                            onCustomValueChange={handleNepaliInputChange}
                            handleBlur={handleBlur}
                            touched={touched.dep_name_nep}
                            placeholder="Enter Department Name (Nepali)"
                            errors={errors.dep_name_nep}
                            required={true}
                        />
                    </Grid>
                </Grid>
                <div className="flex justify-end mt-5 gap-2">
                    <SaveBtn type="submit" disabled={isSubmitting} />
                </div>
            </form>
        </Fragment>
    )
}

export default DepartmentCreateFormView