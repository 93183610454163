import React, { useState } from 'react'
import { useFormik } from 'formik';
import { ADToBSConvert, EngNowDate, FormatDate, GetMonthNumber, GetYearNumber } from '../widgets/DateFormat';
import { postDataToTheServer } from '../widgets/api';
import AppURL from '../widgets/AppURL';
import { useDispatch, useSelector } from 'react-redux';
import { ContraVoucherInfo, ContraVoucherLedgerList } from '../store/accountSlice';
import { ToastError, ToastSuccess, ToastWarning } from '../widgets/ToastMessage';
export const VoucherContext = React.createContext();
const VoucherEntryContext = ({ children }) => {
  const [voucherData, setOnVoucherData] = useState({
    bill_print: ""
  });
  const [selectValues, setOnSelectValues] = useState({
    gl_type: "",
    glid: "",
    sglsn: "",
    member_sn: "",
    particularid: "",
    voucher_type: "",
    bank_dep_ret: "",
    student: ""
  });
  const ResetForm = async () => {
    setOnVoucherData({
      bank_list_id: "",
      cash_type: "",
      voucher_type: "",
      glid: "",
      bank_dep_ret: "",
      particularid: "",
      sglsn: "",
    });
  }
  const get_month = GetMonthNumber();
  const get_year = GetYearNumber();

  const EnTodayDate = EngNowDate();
  const [onDisabled, setOnDisabled] = useState(false);
  const dispatch = useDispatch();
  const { menu_list } = useSelector((state) => state?.software);
  const FiscalYearDate = menu_list?.current_fiscal?.from_date_n;
  const initialValues = {
    date_en: EnTodayDate || "",
    fiscal_year_date: FiscalYearDate || "",
    date_ne: "",
    debitamt: "",
    creditamt: "",
    user_no: 1,
    chequeno: "",
    bearername: "",
    bank_type: 1,
    bank_dep_ret: "",
    billing_no: 1,
    voucher_type: "",
    trancomment: "",
    bill_print: voucherData?.bill_print || "",
    glid: "",
    cash_type: "",
    voucher_no: "",
    voucher_no_1: "",
    trans_counter_code: "",
    voucher_kind: "",
    member_sn: "",
    depositmonth: "",
    particularid: "",
    sglsn: "",
    bank_list_id: "",

  };
 

    const { values, handleChange, errors, touched, handleBlur, handleSubmit } = useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        if (onDisabled) return;
        setOnDisabled(true); // Disable the submit button
        try {
          const formData = new FormData();
          formData.append("date_en", FormatDate(values.date_en));
          formData.append("fiscal_start", values?.fiscal_year_date);
          formData.append("date_ne", ADToBSConvert(values.date_en));
          formData.append("user_id", 1);
          formData.append("trancomment", values.trancomment || "--");
          formData.append("voucher_type", selectValues?.voucher_type?.value);
          formData.append("particularname", selectValues?.particularid?.label || "");
          formData.append("cash_type", selectValues?.cash_type?.value || selectValues?.bank_dep_ret?.value || 0);
          formData.append("bank_list_id", selectValues?.bank_list_id?.value || 18);
          formData.append("class_id",selectValues?.class_id?.value||0);
          formData.append("member_sn", selectValues?.member_sn?.value || 1);
          formData.append("sglsn", selectValues?.sglsn?.value || "");
          formData.append("particularid", selectValues?.particularid?.value || "");
          formData.append("chequeno", values.chequeno || 0);
          formData.append("bearername", values.bearername || "0");
          formData.append("depositmonth", get_month);
          formData.append("deposityear", get_year);
          formData.append("debitamt", values.debitamt || 0);
          formData.append("creditamt", values.creditamt || 0);
  
          if (selectValues?.voucher_type?.value === "1") {
            handleSingleVoucherType(values, formData);
          } else {
            handleContraVoucherType(values, formData);
          }
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          setTimeout(() => {
            setOnDisabled(false);
          }, 3000);
        }
      }
    });
  
    const handleSingleVoucherType = async (values, formData) => {
      const creditamt = Number(values?.creditamt);
      const debitamt = Number(values?.debitamt);
      if (creditamt === 0 && debitamt === 0) {
        ToastWarning("Please enter either a debit or credit amount");
        return;
      }
  
      // Check if either cash_type or bank_dep_ret is selected
      if (!selectValues?.cash_type?.value && !selectValues?.bank_dep_ret?.value) {
        ToastWarning("Please Select Cash Or Bank");
        return;
      }
  
      // Check if debit/credit indicators match
      if ((selectValues?.cash_type?.is_debit ?? selectValues?.bank_dep_ret?.is_debit) === selectValues?.particularid?.is_debit) {
        ToastWarning("Please Check Debit Credit");
        return;
      }
  
      try {
        const res = await postDataToTheServer(`${AppURL.AccountsURL}account-single-voucher-create-list-view/`, formData);
        if (res && res?.message) {
          ToastSuccess(res?.message);
          resetFormFields();
        } else {
          ToastError("Invalid server response");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
  
    const handleContraVoucherType = (values, formData) => {
      // Ensure creditamt and debitamt are numbers for proper comparison
      const creditamt = Number(values?.creditamt);
      const debitamt = Number(values?.debitamt);
  
      // Validate creditamt and debitamt
      if (creditamt === 0 && debitamt === 0) {
        ToastWarning("Please enter either a debit or credit amount");
        return;
      }
  
      try {
        dispatch(ContraVoucherInfo({
          date_en: FormatDate(values.date_en),
          date_ne: ADToBSConvert(values.date_en),
          fiscal_start: values?.fiscal_year_date,
          class_id:selectValues?.member_sn?.class_id || 1,
          voucher_type: 2,
          user_id: 1,
          bill_print: values?.bill_print === "1" ? 1 : 0,
          member_sn: selectValues?.member_sn?.value || 1,
        }));
        dispatch(ContraVoucherLedgerList(Object.fromEntries(formData)));
        resetFormFields();
      } catch (error) {
        console.log(error);
      }
  
    };
  
    const resetFormFields = () => {
      handleChange("debitamt")("");
      handleChange("creditamt")("");
      handleChange("chequeno")("");
      handleChange("bearername")("");
      handleChange("trancomment")("");
      setOnSelectValues(prevValues => ({
        ...prevValues,
        bank_type: "",
        cash_type: "",
        bank_dep_ret: "",
        bank_list_id: "",
      }));
    };
  
    const saveBillNo = (bill_no) => {
      if (bill_no !== undefined && bill_no !== null) {
        localStorage.setItem("bill_no", bill_no);
      } else {
        console.error("bill_no is undefined or null");
      }
    };
  
  return (
    <div>
      <VoucherContext.Provider value={{ ResetForm, voucherData, onDisabled, setOnVoucherData, setOnSelectValues, selectValues, initialValues, values, handleChange, errors, touched, handleBlur, handleSubmit }}>
        {children}
      </VoucherContext.Provider>
    </div>
  )
}

export default VoucherEntryContext