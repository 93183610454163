import React, { Fragment } from 'react'
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { FaListUl } from 'react-icons/fa6';
import { useLanguage } from '../../../../widgets/LangContext';
import { DeleteBtn, EditPenBtn, NextBtn, PrevBtn } from '../../../../widgets/AppBtn';
import { deleteDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';

const ExamGapGradeListView = ({ onAcaListData, fetchAcademicsListView, setOnEditID, FetchData }) => {
  const { language } = useLanguage();
  const deleteGpaGradeView = async(id)=>{
    try{
      await deleteDataToTheServer(AppURL.ExamURL + "exam-gap-grade-division-retrieve-update-view/" + id).then((res)=>{
        ToastSuccess(res?.message);
        FetchData();
      });
    }catch(error){
      console.log(error);
    }
  }
  return (
    <Fragment>
      <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800'>
        <ComponentHeading icon={FaListUl} label={language ? "GPA/Grade List" : "GPA/ग्रेड सूची"} />
        <div className="relative overflow-scroll">
          <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
            <thead className="text-[12px] text-gray700 font-semibold border-b bg-slate200 dark:bg-slate800 capitalize dark:border-b-slate700 dark:text-slate400 font-public-sans">
              <tr>
                <td align='center' className="px-2 py-1.5 w-10">
                  {language ? "S.N." : "क्र.सं."}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "Division Name" : "डिभिजन नाम"}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "From Percentage" : "प्रतिशतबाट"}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "To Percentage" : "प्रतिशतमा"}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "GPA" : "GPA"}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "Grade" : "ग्रेड"}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "Des" : "Des"}
                </td>
                <td className="px-2 py-1.5">
                  {language ? "Action " : "कार्य"}
                </td>
              </tr>
            </thead>
            <tbody className='text-slate-800'>
              {Array.isArray(onAcaListData?.results) &&
                onAcaListData?.results?.map((cls, index) => (
                  <tr key={index} className="text-[12px] text-gray-800 border-b dark:text-slate400 mb-2 font-public-sans dark:border-gray-700">
                    <td align='center' className="px-2 py-1">
                      {index + 1}.
                    </td>
                    <td className="px-2 py-1 capitalize">
                      {cls?.division_name}
                    </td>
                    <td align='left' className="px-2 py-1">
                    {cls?.perc_from}
                    </td>
                    <td align='left' className="px-2 py-1">
                    {cls?.perc_to}
                    </td>
                    <td align='left' className="px-2 py-1">
                    {cls?.gpa}
                    </td>
                    <td align='left' className="px-2 py-1">
                    {cls?.grade}
                    </td>
                    <td align='left' className="px-2 py-1 capitalize">
                    {cls?.grade_des}
                    </td>
                    <td className="px-2">
                      <EditPenBtn onClick={async () => await setOnEditID(cls?.sn)} wh={6} /> <DeleteBtn onClick={async () => await deleteGpaGradeView(cls?.sn)} wh={6}  />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex justify-end gap-4 mt-5'>
        <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
          if (onAcaListData?.previous) {
            await fetchAcademicsListView(onAcaListData?.previous);
          }
        }} /> <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
          if (onAcaListData?.next) {
            await fetchAcademicsListView(onAcaListData?.next);
          }
        }} />
      </div>
    </Fragment>

  )
}

export default ExamGapGradeListView