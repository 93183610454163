import React from 'react';
import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
import ComponentHeading from '../../../widgets/ComponentHeading';
import { IoAddCircle } from 'react-icons/io5';
import { NepNowDateFormat } from '../../../widgets/DateFormat';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';
import InputTextBox from '../../../widgets/InputTextBox';
import { useLanguage } from '../../../widgets/LangContext';
import { IoMdAddCircle } from 'react-icons/io';
import { ClearBtn, CustomBtn } from '../../../widgets/AppBtn';
import TextAreaBox from '../../../widgets/TextAreaBox';
import InputCheckValue from '../../../widgets/InputCheckValue';
import InputNumber from '../../../widgets/InputNumber';
import { postDataToTheServer } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { ToastSuccess } from '../../../widgets/ToastMessage';

const DigitalContentFormView = ({ onOpenForm, setOnOpenForm }) => {
    const { language } = useLanguage();
    const handleOpen = () => setOnOpenForm(!onOpenForm);
    const DateNp = NepNowDateFormat();
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
        title: "",
        urls: ""
    };

    const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            enableReinitialize: true,
            onSubmit: async (values, action) => {
                const formData = new FormData();
                formData.append("title", values.title);
                formData.append("urls", values.urls);

                await postDataToTheServer(AppURL.MobileAppURL + "academics-student-filter-options-list-view/", formData).then((res) => {
                    ToastSuccess(res?.message);
                });
            },
        });
    return (
        <Dialog size='lg' className='ml-[120px] py-3' open={onOpenForm} handler={handleOpen}>
            <ComponentHeading label={'Add Digital Content'} icon={IoAddCircle} />
            <DialogBody>
                <div className='flex justify-between'>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Class:
                        </div>
                        <div>
                            One
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Medium:
                        </div>
                        <div>
                            Nepali
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Section:
                        </div>
                        <div>
                            A
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Subject:
                        </div>
                        <div>
                            Nepali
                        </div>
                    </div>
                    <div className='flex flex-row font-public-sans text-[12.5px] text-gray800 gap-2'>
                        <div className='font-semibold'>
                            Date:
                        </div>
                        <div>
                            {DateNp}
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className='mt-3'>
                    <Grid container spacing={1}>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox label={language ? "Digital Content Title" : "कक्षा"} placeholder="Digital Content Title" value={values.title} name="title" handleBlur={handleBlur}
                                touched={touched.title} errors={errors.title} required={true} />
                        </Grid>
                        <Grid item xl={12} md={12} lg={12} xs={12} sm={12}>
                            <InputTextBox label={language ? "Youtube Link" : "विवरणहरू"} placeholder="urls" handleChange={handleChange} value={values.urls} name="urls" handleBlur={handleBlur}
                                touched={touched.urls} errors={errors.urls} required={true} />
                        </Grid>
                        <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
                            <InputTextBox type={'file'} label={language ? "File Upload" : "कक्षा"}  placeholder="File Upload (PDF)"  handleChange={handleChange} value={values.file} name="file" handleBlur={handleBlur}
                                touched={touched.file} errors={errors.file} required={true} />
                        </Grid>
                    </Grid>
                </form>
            </DialogBody>
            <DialogFooter>
                <div className='flex justify-end gap-3'>
                    <ClearBtn />
                    <CustomBtn type="button" icon={IoMdAddCircle} onClick={() => setOnOpenForm(true)} label={'Add Digital Content'} />
                </div>
            </DialogFooter>
        </Dialog>
    )
}

export default DigitalContentFormView