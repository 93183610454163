import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import HallRoomDetailsView from '../../../components/exam/setup/hall_room/HallRoomDetailsView';
import { PdfExportBtn } from '../../../widgets/AppBtn';
import { useReactToPrint } from 'react-to-print';
import PrintSeatPlanPDFView from '../../../components/exam/setup/hall_room/PrintSeatPlanPDFView';
import { useParams } from 'react-router-dom';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';

const ExamStdHallSeatDetailsListView = ({ setIconsButton, PageTitle }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(<PdfExportBtn onClick={handlePrintCard} />);
  }, []);
  const [studentSeats, setStudentSeats] = useState([]);
  const { id } = useParams();
  const fetchHallDeskListView = async () => {
      try {
          await fetchDataFromApi(AppURL.ExamURL + "exam-seat-plan-hall-room-retrieve-details-view/?hall_id=" + id).then((res) => {
              setStudentSeats(res);
          });
      } catch (error) {
          console.log(error);
      }
  }
  useEffect(() => {
      fetchHallDeskListView();
  }, []);


  const componentListRef = useRef();
  const RenderListComponent = React.forwardRef(() => (
    <PrintSeatPlanPDFView studentSeats={studentSeats} />
  ));

  const handlePrintCard = useReactToPrint({
    content: () => componentListRef.current,
  });
  return (
    <Fragment>
      <HallRoomDetailsView studentSeats={studentSeats} fetchHallDeskListView={fetchHallDeskListView} />
      <div className='hidden'>
        <div ref={componentListRef}>
          <RenderListComponent />
        </div>
      </div>
    </Fragment>
  )
}

export default ExamStdHallSeatDetailsListView
