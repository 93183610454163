import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';
import ReportLabel from '../../../../widgets/ReportLabel';

const FeeMappingRptPDFView = () => {
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    const { fee_mapping_report_list, fee_mapping_options_list } = useSelector((state) => state?.accounts);

    return (
        <Fragment>

            <div className='p-5 bg-white h-screen'>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>
                            </tr>
                            <tr >
                                <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                    Date: {DateNow}
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Fee Mapping Report
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='mt-2'>
                    <div className='font-public-sans overflow-x-auto text-[12px] gap-2 flex flex-row'>
                        {fee_mapping_options_list?.class && <ReportLabel label='Class: ' value={fee_mapping_options_list?.class} />}
                        {fee_mapping_options_list?.medium && <ReportLabel label={'Medium: '} value={fee_mapping_options_list?.medium} />}
                        {fee_mapping_options_list?.section && <ReportLabel label={'Section: '} value={fee_mapping_options_list?.section} />}
                    </div>
                    <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                        <thead className="text-[11px] text-gray700 bg-gray300  capitalize border-b dark:bg-slate900 dark:text-gray-400 font-public-sans">
                            <tr>
                                <th scope="col" align='center' className="px-2 border dark:border-slate600 w-10">
                                    Roll No
                                </th>
                                <th scope="col" align='left' className="px-2 border dark:border-slate600">
                                    Full Name
                                </th>
                                <th scope="col" align='left' className="px-2 border dark:border-slate600">
                                    Father Name
                                </th>

                                <th scope="col" align='left' className="px-2 border dark:border-slate600">
                                    Fee Mapping
                                </th>
                                <th scope="col" align='left' className="px-2 border dark:border-slate600">
                                    Bus Facility
                                </th>
                                <th scope="col" align='left' className="px-2 border dark:border-slate600">
                                    Drom Facility
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-slate-800'>
                            {Array.isArray(fee_mapping_report_list?.results) &&
                                fee_mapping_report_list?.results?.map((student, index) =>
                                (
                                    <tr key={index} className=" text-[11px] text-gray-800 border-b font-public-sans">
                                        <td align='center' className="px-2 border font-medium dark:border-slate600 capitalize">
                                            {student.roll_no}.
                                        </td>
                                        <td align='left' className="px-2 border font-medium dark:border-slate600 capitalize">
                                            {`${student?.first_name} ${student?.middle_name ? student?.middle_name : ""} ${student?.last_name}`}
                                        </td>
                                        <td align='left' className="px-2 font-medium border dark:border-slate600 capitalize text-[11px]">
                                            {student.father_name}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600">
                                            {student.scholarshipstyles_name || "*"}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600">
                                            {student.bus_facility_name || "*"}
                                        </td>
                                        <td align='left' className="px-2 border dark:border-slate600">
                                            {student.dorm_facility_name || "*"}
                                        </td>
                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default FeeMappingRptPDFView