import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AppURL from '../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../widgets/api';
import { EditPenBtn, NextBtn, PrevBtn } from '../../../widgets/AppBtn';

const HomeworkListView = () => {
  const { homework_class_sec_medium_list } = useSelector((state) => state.mobile_app);
  const [OnHomeworkList, setOnHomeworkList] = useState([]);
  const FetchHomeWorkList = async () => {
    try {
      if (homework_class_sec_medium_list.class_id && homework_class_sec_medium_list.section && homework_class_sec_medium_list.medium) {
        await fetchDataFromApi(AppURL.MobileAppURL + "teacher-homework-create-list-view/?class_id=" + homework_class_sec_medium_list?.class_id + "&section=" + homework_class_sec_medium_list?.section + "&medium=" + homework_class_sec_medium_list?.medium).then((res) => {
          setOnHomeworkList(res);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    FetchHomeWorkList();
  }, [homework_class_sec_medium_list.class_id, homework_class_sec_medium_list.section, homework_class_sec_medium_list.medium])
  return (
    <Fragment>
      <div className="relative overflow-x-auto font-public-sans">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-[12px] text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-1.5">
                S.N.
              </th>
              <th scope="col" className="px-2 py-1.5">
                Homework Title
              </th>
              <th scope="col" className="px-2 py-1.5">
                Description
              </th>
              <th scope="col" align='center' className="px-2 py-1.5 w-20">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {
              Array.isArray(OnHomeworkList.results) && OnHomeworkList.results?.map((homework_list, index) => (
                <tr key={index} className="bg-white text-[12px] border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="px-2 w-10 py-0.5 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {index + 1}.
                  </td>
                  <td scope="row" class="px-2 py-0.5 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {homework_list?.homework_title}
                  </td>
                  <td className="px-2 py-0.5">
                    {homework_list?.homework_des}
                  </td>
                  <td align='center' className="px-2 py-0.5">
                    <EditPenBtn wh={6} />
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </div>
      {/* <div className='flex gap-3 mt-3 justify-end'>
                <PrevBtn disabled={onAcaListData?.previous === null ? true : false} onClick={async () => {
                    if (onAcaListData?.previous) {
                        await fetchAcademicsListView(onAcaListData?.previous)
                    }
                }} />
                <NextBtn disabled={onAcaListData?.next === null ? true : false} onClick={async () => {
                    if (onAcaListData?.next) {
                        await fetchAcademicsListView(onAcaListData?.next)
                    }
                }} />
            </div> */}
    </Fragment>
  )
}

export default HomeworkListView