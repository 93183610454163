import React, { Fragment, useEffect, useRef } from 'react';
import MarksLedNumListView from '../../../../components/exam/report/marks_rpt_number/MarksLedNumListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { useReactToPrint } from 'react-to-print';
import MarksLedgNumListPDFView from '../../../../components/exam/report/marks_rpt_number/MarksLedgNumListPDFView';
import { PdfExportBtn } from '../../../../widgets/AppBtn';

const MarksLedgerNumberListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pageTitle({ title: PageTitle }));
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(<PdfExportBtn onClick={handlePrintCard} />);
  }, [dispatch, PageTitle, setIconsButton]);
  const componentListRef = useRef();
  const RenderListComponent = React.forwardRef(() => (
    <MarksLedgNumListPDFView />
  ));

  const handlePrintCard = useReactToPrint({
    content: () => componentListRef.current,
  });
  return (
    <Fragment>
      <MarksLedNumListView />
      <div className='hidden'>
        <div ref={componentListRef}>
          <RenderListComponent />
        </div>
      </div>
    </Fragment>

  )
}

export default MarksLedgerNumberListView