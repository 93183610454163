import React, { useEffect, useState } from 'react';
import GalleryAddFormView from '../../../components/mobile_app/gallery/GalleryAddFormView';
import { Grid } from '@mui/material';
import GalleryListView from '../../../components/mobile_app/gallery/GalleryListView';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';

const MobileAppGalleryCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null)
  }, [PageTitle]);

  const [onGalleryCateList, setOnGalleryCateList] = useState([]);
  const fetchGalleryCategoryList = async () => {
    try {
      await fetchDataFromApi(AppURL.MobileAppURL + "gallery-category-create-list-view/").then((res) => {
        setOnGalleryCateList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchGalleryCategoryList();
  }, [])
  const [onGalleryList, setOnGalleryList] = useState([]);
  const urls = AppURL.MobileAppURL + "mobile-app-student-gallery-list-view/";
  const fetchGalleryList = async (urls) => {
    try {
      await fetchDataFromApi(urls).then((res) => {
        setOnGalleryList(res);
      });
    } catch (error) {
      console.log(error);
    }
  }
  const FetchData = async ()=>{
    fetchGalleryList(urls);
  }
  useEffect(() => {
    FetchData();
  }, [])


  return (
    <Grid container spacing={2}>
      <Grid item xl={4} md={4} lg={4} xs={12} sm={12}>
        <GalleryAddFormView FetchData={FetchData} onGalleryCateList={onGalleryCateList} />
      </Grid>
      <Grid item xl={8} md={8} lg={8} xs={12} sm={12}>
        <GalleryListView onGalleryList={onGalleryList} />
      </Grid>
    </Grid>
  )
}

export default MobileAppGalleryCreateListView