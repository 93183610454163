import { createSlice } from '@reduxjs/toolkit';

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState: {
        cate_product_list: [],
        purchase_list: []
    },
    reducers: {
        ItemCateWiseProductList: (state, action) => {
            state.cate_product_list = action.payload;
        },
        ItemPurchaseAddList: (state, action)=>{
            state.purchase_list = action.payload;
        },
        ItemPurchaseRptList:(state, action)=>{
            state.purchase_rpt_list = action.payload;
        },
        ItemSalesRptList:(state, action)=>{
            state.sales_rpt_list = action.payload;
        },
        ItemIssueRptList:(state, action)=>{
            state.issue_rpt_list = action.payload;
        },
        ItemStockRptList:(state, action)=>{
            state.stock_rpt_list= action.payload;
        },
        ItemStockType:(state, action)=>{
            state.stock_rpt_type= action.payload;
        }

    }
});

// Action creators are generated for each case reducer function
export const { ItemCateWiseProductList, ItemStockRptList, ItemStockType, ItemIssueRptList, ItemPurchaseRptList, ItemSalesRptList, ItemPurchaseAddList } = inventorySlice.actions
export default inventorySlice.reducer