import React, { Fragment, useEffect, useRef } from 'react';
import ClassRoutineDetailsListView from '../../../../components/academics/report/class_routine/ClassRoutineDetailsListView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import { PdfExportBtn } from '../../../../widgets/AppBtn';
import { useReactToPrint } from 'react-to-print';
import PDFClassWiseRoutineListView from '../../../../components/academics/report/class_routine/PDFClassWiseRoutineListView';

const AcaClassRoutinePageListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(<PdfExportBtn onClick={handlePrintCard} />);
  }, []);

  const componentListRef = useRef();
  const RenderListComponent = React.forwardRef(() => (
    <PDFClassWiseRoutineListView />
  ));

  const handlePrintCard = useReactToPrint({
    content: () => componentListRef.current,
  });

  return (
    <Fragment>
      <ClassRoutineDetailsListView />
      <div className='hidden'>
        <div ref={componentListRef}>
          <RenderListComponent />
        </div>
      </div>

    </Fragment>
  )
}

export default AcaClassRoutinePageListView