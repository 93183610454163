import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NotificationSeenUserListVIew from '../../../components/mobile_app/teacher_noti/widgets/NotificationSeenUserListVIew';
import TeacherNotificationDetails from '../../../components/mobile_app/teacher_noti/widgets/TeacherNotificationDetails';
import RelatedNotificationView from '../../../components/mobile_app/teacher_noti/widgets/RelatedNotificationView';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import { fetchDataFromApi } from '../../../widgets/api';
import AppURL from '../../../widgets/AppURL';
import { useParams } from 'react-router-dom';

const MobileAppTeacherNotificationDetailsListView = ({ PageTitle, setIconsButton }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(pageTitle({ "title": PageTitle }))
        document.title = `Nexapp - ${PageTitle}`;
        setIconsButton(null)
    }, [PageTitle]);
    const { id } = useParams();
    const [onNotificationDetails, setOnNotificationDetails] = useState({});
    const fetchTeacherNotificationDetailsView = async (url) => {
        try {
            await fetchDataFromApi(url).then((res)=>{
                setOnNotificationDetails(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    const fetchDataList = async () => {
        await fetchTeacherNotificationDetailsView(AppURL.MobileAppURL + "teacher-notification-details-list-view/" + id);
    }
    useEffect(() => {
        fetchDataList();
    }, [])
    return (
        <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <TeacherNotificationDetails onNotificationDetails={onNotificationDetails} />
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <NotificationSeenUserListVIew onNotificationDetails={onNotificationDetails} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <RelatedNotificationView onNotificationDetails={onNotificationDetails} />
            </Grid>
        </Grid>

    )
}

export default MobileAppTeacherNotificationDetailsListView