import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../../store/softSlice';
import StdPhotoUploadFormView from '../../../../components/academics/create/photo_upload/StdPhotoUploadFormView';
import { Grid } from '@mui/material';
import AppURL from '../../../../widgets/AppURL';
import { fetchDataFromApi } from '../../../../widgets/api';
import StdPhotoUploadListView from '../../../../components/academics/create/photo_upload/StdPhotoUploadListView';

const StudentPhotoUploadCreateListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }));
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
  const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
  return (
    <Fragment>
      
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <StdPhotoUploadFormView onStudentOptionList={onStudentOptionList} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <StdPhotoUploadListView onStudentOptionList={onStudentOptionList} />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default StudentPhotoUploadCreateListView