import React from 'react';
import AttendanceLeaveTypeCreateListView from '../../views/attendance/setup/AttendanceLeaveTypeCreateListView';
import AttendanceTeacherCreateListView from '../../views/attendance/create/AttendanceTeacherCreateListView';
import MobileAppNotificationCreateListView from '../../views/mobile_app/create/MobileAppNotificationCreateListView';
import MobileAppMessageCreateListView from '../../views/mobile_app/create/MobileAppMessageCreateListView';
import MobileAppNewEventCreateListView from '../../views/mobile_app/create/MobileAppNewEventCreateListView';
import MobileAppHomeWorkCreateListView from '../../views/mobile_app/create/MobileAppHomeWorkCreateListView';
import MobileAppObservationCreateListView from '../../views/mobile_app/create/MobileAppObservationCreateListView';
import MobileAppTeacherNotificationCreateListView from '../../views/mobile_app/create/MobileAppTeacherNotificationCreateListView';
import MobileAppMarksEntryScheduleView from '../../views/mobile_app/create/MobileAppMarksEntryScheduleView';
import MobileAppDigitalContentCreateListView from '../../views/mobile_app/create/MobileAppDigitalContentCreateListView';
import MobileAppAssignmentCreateListView from '../../views/mobile_app/create/MobileAppAssignmentCreateListView';
import GalleryCategoryCreateListView from '../../views/mobile_app/setup/GalleryCategoryCreateListView';
import AcaStudentUpgradeCreateListView from '../../views/academics/create/std_upgrade/AcaStudentUpgradeCreateListView';
import StudentPhotoUploadCreateListView from '../../views/academics/create/std_photo_upload/StudentPhotoUploadCreateListView';
import AcaAppUserDetailsCreateListView from '../../views/academics/create/app_details/AcaAppUserDetailsCreateListView';
import AcaStdQRGenerateCreateListView from '../../views/academics/create/qr/AcaStdQRGenerateCreateListView';
import AcaAdmissionRequestCreateListView from '../../views/academics/create/admission_req/AcaAdmissionRequestCreateListView';
import AcaRoutineCreateListView from '../../views/academics/create/routine/AcaRoutineCreateListView';
import AcaCalenderCreateListView from '../../views/academics/create/calender/AcaCalenderCreateListView';
import AcaGuardianFeedbackReportListView from '../../views/application/report/AcaGuardianFeedbackReportListView';
import AcaVehicleAddViewListView from '../../views/academics/setup/AcaVehicleAddViewListView';
import AcaVehicleRoutineCreateListView from '../../views/academics/create/vehicle_routine/AcaVehicleRoutineCreateListView';
import AcademicsVechileRoutineRptPageView from '../../views/academics/create/vehicle_routine/AcademicsVechileRoutineRptPageView';
import AcaAdmitCardCreateListView from '../../views/academics/create/admit_card/AcaAdmitCardCreateListView';
import AcaClassRoutinePageListView from '../../views/academics/report/cls_routine/AcaClassRoutinePageListView';
import HRStaffDetailsPageView from '../../views/hr/report/HRStaffDetailsPageView';
import EmisHallRoomSetUpCreateListView from '../../views/exam/setup/EmisHallRoomSetUpCreateListView';
import ExamStdHallSeatDetailsListView from '../../views/exam/setup/ExamStdHallSeatDetailsListView';
import ExamClassConfigureExamHallView from '../../views/exam/setup/ExamClassConfigureExamHallView';
import ExamHallRoomReportPageListView from '../../views/exam/reports/exam_seat_plan/ExamHallRoomReportPageListView';
import MarksLedgerCreateFormView from '../../views/exam/reports/marks_ledger/MarksLedgerCreateFormView';
import MarksLedgerPageCreateListView from '../../views/exam/reports/marks_ledger/MarksLedgerPageCreateListView';
import ExamMarksheetFormView from '../../views/exam/reports/marksheet/ExamMarksheetFormView';
import ExamMarksheetStudentListView from '../../views/exam/reports/marksheet/ExamMarksheetStudentListView';
import MobileAppGalleryCreateListView from '../../views/mobile_app/create/MobileAppGalleryCreateListView';
import MobileAppTeacherNotificationDetailsListView from '../../views/mobile_app/create/MobileAppTeacherNotificationDetailsListView';
import AttendanceStudentCreateListView from '../../views/attendance/create/AttendanceStudentCreateListView';
import StdAttendanceReportListViewPage from '../../views/attendance/reports/std_attendance_rpt/StdAttendanceReportListViewPage';
import AcaStudentLeaveRequestListView from '../../views/attendance/create/AcaStudentLeaveRequestListView';
import AtteTeacherLeaveRequestListView from '../../views/attendance/create/AtteTeacherLeaveRequestListView';
import AcaRollNoGenerateCreateListView from '../../views/academics/create/roll_no_gener/AcaRollNoGenerateCreateListView';
import MobileAppDetailsGenerateCreateListView from '../../views/hr/create/MobileAppDetailsGenerateCreateListView';
import LibraryBookKindCreateListView from '../../views/library/setup/LibraryBookKindCreateListView';
import AccountTransactionReportPageListView from '../../views/account/report/tran_rpt/AccountTransactionReportPageListView';
import SubjectHomeworkCreateListView from '../../views/mobile_app/create/SubjectHomeworkCreateListView';
import ExamResultsPublishedCreateListView from '../../views/mobile_app/create/ExamResultsPublishedCreateListView';
import ExamGradeGPACreateListView from '../../views/exam/setup/ExamGradeGPACreateListView';
import HREmpPhotoUploadsCreateListView from '../../views/hr/create/HREmpPhotoUploadsCreateListView';
import AppDepartmentsCreateListView from '../../views/application/setup/AppDepartmentsCreateListView';
import DailyTeacherLogBookPageListView from '../../views/academics/report/teach_daily_log_book/DailyTeacherLogBookPageListView';
import DailyTeacherLogBookCreateListView from '../../views/academics/create/daily_tea_log_book/DailyTeacherLogBookCreateListView';
import WeeklyLessionPlanCreateListView from '../../views/academics/create/weekly_lession_plan/WeeklyLessionPlanCreateListView';
import WeeklyLessionPlanReportPageListView from '../../views/academics/report/weekly_lession_plan/WeeklyLessionPlanReportPageListView';
import MarksLedgerNumberListView from '../../views/exam/reports/marks_ledg_num/MarksLedgerNumberListView';
import MarksEvulationReportPageListView from '../../views/exam/reports/marks_evaluation_rpt/MarksEvulationReportPageListView';
import ExamAssignSubjectReportListView from '../../views/exam/reports/assign_sub_rpt/ExamAssignSubjectReportListView';
import FeeMappingReportPageListView from '../../views/account/report/fee_mapping_rpt/FeeMappingReportPageListView';
import AccountLedgerReportViewView from '../../views/account/report/ac_ledger/AccountLedgerReportViewView';
import StudentAccountDuesReportListView from '../../views/account/report/dues_rpt/StudentAccountDuesReportListView';
const AccountVoucherReportListView = React.lazy(() => import('../../views/account/report/voucher_rpt/AccountVoucherReportListView'));
const AccountTrialReportListView = React.lazy(() => import('../../views/account/report/trial_rpt/AccountTrialReportListView'));
const AccountProfitLossReportPageView = React.lazy(() => import('../../views/account/report/profit_loss_rpt/AccountProfitLossReportPageView'));
const AccountSheetRptPageListView = React.lazy(() => import('../../views/account/report/balance_sheet/AccountSheetRptPageListView'));
const AcaStudentEditFormView = React.lazy(() => import('../../views/academics/create/student/AcaStudentEditFormView'));
const AccountParticularListDetailsView = React.lazy(() => import('../../views/account/setup/particular/AccountParticularListDetailsView'));
const AcaBatchCreateListView = React.lazy(() => import('../../views/academics/setup/AcaBatchCreateListView'));
const AcaEnthinicityCreateListView = React.lazy(() => import('../../views/academics/setup/AcaEnthinicityCreateListView'));
const AcaSubjectCreateListView = React.lazy(() => import('../../views/exam/setup/AcaSubjectCreateListView'));
const ExamSubjectGroupCreateListView = React.lazy(() => import('../../views/exam/setup/ExamSubjectGroupCreateListView'));
const ExamSubjectCategoryCreateListView = React.lazy(() => import('../../views/exam/setup/ExamSubjectCategoryCreateListView'));
const ExamTypeCreateListView = React.lazy(() => import('../../views/exam/setup/ExamTypeCreateListView'));
const ExamSubjectAssignCreateListView = React.lazy(() => import('../../views/exam/create/ExamSubjectAssignCreateListView'));
const ExamMultiMarksEntryCreateView = React.lazy(() => import('../../views/exam/create/ExamMultiMarksEntryCreateView'));
const ExamClassWiseSubjectListViewPage = React.lazy(() => import('../../views/exam/reports/subj_list/ExamClassWiseSubjectListViewPage'));
const ExamMarksReportListView = React.lazy(() => import('../../views/exam/reports/marks_rpt/ExamMarksReportListView'));
const AccountDayBookPageListView = React.lazy(() => import('../../views/account/report/day_book/AccountDayBookPageListView'));
const AcaCasteCreateListView = React.lazy(() => import('../../views/academics/setup/AcaCasteCreateListView'));
const AccountSettingsCreateListView = React.lazy(() => import('../../views/common/AccountSettingsCreateListView'));
const AppAuthorCreateListView = React.lazy(() => import('../../views/application/create/author/AppAuthorCreateListView'));
const InventoryPurchaseItemAddView = React.lazy(() => import('../../views/inventory/create/purchase/InventoryPurchaseItemAddView'));
const InventorySalesProductView = React.lazy(() => import('../../views/inventory/create/sales/InventorySalesProductView'));
const HREmployeeCreateListView = React.lazy(() => import('../../views/hr/create/HREmployeeCreateListView'));
const HRDesignationCreateListView = React.lazy(() => import('../../views/hr/setup/HRDesignationCreateListView'));
const HREmployeeListView = React.lazy(() => import('../../views/hr/report/HREmployeeListView'));
const HRStaffPayrollCreateListView = React.lazy(() => import('../../views/hr/create/HRStaffPayrollCreateListView'));
const InventoryIssueItemView = React.lazy(() => import('../../views/inventory/create/issueItem/InventoryIssueItemView'));
const DefaultDashboardView = React.lazy(() => import('../../views/application/create/dashboard/DefaultDashboardView'));
const InventoryItemListView = React.lazy(() => import('../../views/inventory/create/addItem/InventoryItemListView'));
const InventoryReturnIssueItemView = React.lazy(() => import('../../views/inventory/create/return_issue_item/InventoryReturnIssueItemView'));
const InventorySalesPageReportListView = React.lazy(() => import('../../views/inventory/report/sales_rpt/InventorySalesPageReportListView'));
const PurchaseReportPageView = React.lazy(() => import('../../views/inventory/report/purchase_rpt/PurchaseReportPageView'));
const IssueReportPageView = React.lazy(() => import('../../views/inventory/report/issue_rpt/IssueReportPageView'));
const StockReportPageView = React.lazy(() => import('../../views/inventory/report/stock_rpt/StockReportPageView'));
const LibraryBookAuthorCreateListView = React.lazy(() => import('../../views/library/setup/LibraryBookAuthorCreateListView'));
const LibraryBookPublicationListView = React.lazy(() => import('../../views/library/setup/LibraryBookPublicationListView'));
const LibraryBookAddView = React.lazy(() => import('../../views/library/create/LibraryBookAddView'));
const LibraryBookListView = React.lazy(() => import('../../views/library/report/LibraryBookListView'));
const LibraryBookIssueReturnView = React.lazy(() => import('../../views/library/create/LibraryBookIssueReturnView'));
const LibraryBookIssueReturnReportPageView = React.lazy(() => import('../../views/library/report/issue_rtn_rpt/LibraryBookIssueReturnReportPageView'));
const AcaStudentAddView = React.lazy(() => import('../../views/academics/create/student/AcaStudentAddView'));
const StudentFilterListView = React.lazy(() => import('../../views/academics/create/student/StudentFilterListView'));
const AcaStudentDetailsView = React.lazy(() => import('../../views/academics/create/student/AcaStudentDetailsView'));
const AccountFeeMappingView = React.lazy(() => import('../../views/account/create/AccountFeeMappingView'));
const AccountGeneralLedgerListView = React.lazy(() => import('../../views/account/setup/general_ledger/AccountGeneralLedgerListView'));
const AccountTransactionEntryView = React.lazy(() => import('../../views/account/create/AccountTransactionEntryView'));
const AccountSubLedgerListView = React.lazy(() => import('../../views/account/setup/sub_ledg/AccountSubLedgerListView'));
const AccountsScholarshipCreateListView = React.lazy(() => import('../../views/account/setup/scholarship/AccountsScholarshipCreateListView'));
const AccountScholarshipStyleAddListView = React.lazy(() => import('../../views/account/setup/scholarship_style/AccountScholarshipStyleAddListView'));
const FeeTermsCreateListView = React.lazy(() => import('../../views/account/setup/fee_terms/FeeTermsCreateListView'));
const AccountFeeCreateListView = React.lazy(() => import('../../views/account/setup/fees/AccountFeeCreateListView'));
const AccountFeeBillingCreateView = React.lazy(() => import('../../views/account/create/AccountFeeBillingCreateView'));
const InventoryCategoryMasterAddListView = React.lazy(() => import('../../views/inventory/setup/masterCategory/InventoryCategoryMasterAddListView'));
const InventoryAddItemView = React.lazy(() => import('../../views/inventory/setup/addItem/InventoryAddItemView'));
const InventoryItemCategoryAddListView = React.lazy(() => import('../../views/inventory/setup/itemCategory/InventoryItemCategoryAddListView'));
const InventoryStoreAddListView = React.lazy(() => import('../../views/inventory/setup/itemStore/InventoryStoreAddListView'));
const InventoryItemSuppilerAddListView = React.lazy(() => import('../../views/inventory/setup/itemSuppiler/InventoryItemSuppilerAddListView'));
const InventoryUnitItemAdd = React.lazy(() => import('../../views/inventory/setup/unit/InventoryUnitItemAdd'));
const AccountFiscalYearCreateListView = React.lazy(() => import('../../views/account/setup/fiscal_year/AccountFiscalYearCreateListView'));
const AcaClassAddListView = React.lazy(() => import('../../views/academics/setup/AcaClassAddListView'));
const AcaSectionCreateListView = React.lazy(() => import('../../views/academics/setup/AcaSectionCreateListView'));
const AcaTeamCreateListView = React.lazy(() => import('../../views/academics/setup/AcaTeamCreateListView'));
const AuthorMenuListView = React.lazy(() => import('../../views/application/create/author_role/AuthorMenuListView'));
const AuthorRoleListView = React.lazy(() => import('../../views/application/create/author_role/AuthorRoleListView'));
const MenuDetailsListView = React.lazy(() => import('../../views/application/create/author_role/MenuDetailsListView'));
const MenuCreateListView = React.lazy(() => import('../../views/application/create/menu/MenuCreateListView'));
const SubMenuCreateListView = React.lazy(() => import('../../views/application/create/menu/SubMenuCreateListView'));
const SubSubMenuCreateListView = React.lazy(() => import('../../views/application/create/menu/SubSubMenuCreateListView'));
const OrganizationStruListView = React.lazy(() => import('../../views/application/create/org/OrganizationStruListView'));


export const ImportComponent = (route) => {
    switch (route) {
        case 'MenuCreateListView':
            return MenuCreateListView;
        case 'SubMenuCreateListView':
            return SubMenuCreateListView;
        case 'SubSubMenuCreateListView':
            return SubSubMenuCreateListView;
        case 'AuthorRoleListView':
            return AuthorRoleListView;
        case 'AuthorMenuListView':
            return AuthorMenuListView;
        case 'MenuDetailsListView':
            return MenuDetailsListView;
        case 'OrganizationStruListView':
            return OrganizationStruListView;
        case 'AcaClassAddListView':
            return AcaClassAddListView;
        case 'AcaSectionCreateListView':
            return AcaSectionCreateListView;
        case 'AcaTeamCreateListView':
            return AcaTeamCreateListView;
        case 'AcaStudentAddView':
            return AcaStudentAddView;
        case 'StudentFilterListView':
            return StudentFilterListView;
        case 'AcaStudentDetailsView':
            return AcaStudentDetailsView;
        case 'AcaSubjectCreateListView':
            return AcaSubjectCreateListView;
        case 'AccountFeeMappingView':
            return AccountFeeMappingView;
        case 'AccountGeneralLedgerListView':
            return AccountGeneralLedgerListView;
        case 'AccountTransactionEntryView':
            return AccountTransactionEntryView;
        case 'AccountSubLedgerListView':
            return AccountSubLedgerListView;
        case 'AccountsScholarshipCreateListView':
            return AccountsScholarshipCreateListView;
        case 'AccountScholarshipStyleAddListView':
            return AccountScholarshipStyleAddListView;
        case 'FeeTermsCreateListView':
            return FeeTermsCreateListView;
        case 'AccountFeeCreateListView':
            return AccountFeeCreateListView;
        case 'AccountFeeBillingCreateView':
            return AccountFeeBillingCreateView;
        case 'InventoryCategoryMasterAddListView':
            return InventoryCategoryMasterAddListView;
        case 'InventoryAddItemView':
            return InventoryAddItemView;
        case 'InventoryItemCategoryAddListView':
            return InventoryItemCategoryAddListView;
        case 'InventoryStoreAddListView':
            return InventoryStoreAddListView;
        case 'InventoryItemSuppilerAddListView':
            return InventoryItemSuppilerAddListView;
        case 'InventoryUnitItemAdd':
            return InventoryUnitItemAdd;
        case 'AccountFiscalYearCreateListView':
            return AccountFiscalYearCreateListView;
        case 'AccountSettingsCreateListView':
            return AccountSettingsCreateListView;
        case 'InventoryPurchaseItemAddView':
            return InventoryPurchaseItemAddView;
        case 'InventorySalesProductView':
            return InventorySalesProductView;
        case 'HREmployeeCreateListView':
            return HREmployeeCreateListView;
        case 'HRDesignationCreateListView':
            return HRDesignationCreateListView;
        case 'HREmployeeListView':
            return HREmployeeListView;
        case 'HRStaffPayrollCreateListView':
            return HRStaffPayrollCreateListView;
        case 'InventoryIssueItemView':
            return InventoryIssueItemView;
        case 'DefaultDashboardView':
            return DefaultDashboardView;
        case 'InventoryItemListView':
            return InventoryItemListView;
        case 'InventoryReturnIssueItemView':
            return InventoryReturnIssueItemView;
        case 'InventorySalesPageReportListView':
            return InventorySalesPageReportListView;
        case 'PurchaseReportPageView':
            return PurchaseReportPageView;
        case 'IssueReportPageView':
            return IssueReportPageView;
        case 'StockReportPageView':
            return StockReportPageView;
        case 'LibraryBookAuthorCreateListView':
            return LibraryBookAuthorCreateListView;
        case 'LibraryBookPublicationListView':
            return LibraryBookPublicationListView;
        case 'LibraryBookAddView':
            return LibraryBookAddView;
        case 'LibraryBookListView':
            return LibraryBookListView;
        case 'LibraryBookIssueReturnView':
            return LibraryBookIssueReturnView;
        case 'LibraryBookIssueReturnReportPageView':
            return LibraryBookIssueReturnReportPageView;
        case 'AppAuthorCreateListView':
            return AppAuthorCreateListView;
        case 'AccountVoucherReportListView':
            return AccountVoucherReportListView;
        case 'AccountTrialReportListView':
            return AccountTrialReportListView;
        case 'AccountProfitLossReportPageView':
            return AccountProfitLossReportPageView;
        case 'AccountSheetRptPageListView':
            return AccountSheetRptPageListView;
        case 'AcaStudentEditFormView':
            return AcaStudentEditFormView;
        case 'AccountParticularListDetailsView':
            return AccountParticularListDetailsView;
        case 'AcaBatchCreateListView':
            return AcaBatchCreateListView;
        case 'AcaEnthinicityCreateListView':
            return AcaEnthinicityCreateListView;
        case 'ExamSubjectGroupCreateListView':
            return ExamSubjectGroupCreateListView;
        case 'ExamSubjectCategoryCreateListView':
            return ExamSubjectCategoryCreateListView;
        case 'ExamTypeCreateListView':
            return ExamTypeCreateListView;
        case 'ExamSubjectAssignCreateListView':
            return ExamSubjectAssignCreateListView;
        case 'ExamMultiMarksEntryCreateView':
            return ExamMultiMarksEntryCreateView;
        case 'ExamClassWiseSubjectListViewPage':
            return ExamClassWiseSubjectListViewPage;
        case 'ExamMarksReportListView':
            return ExamMarksReportListView;
        case 'AccountDayBookPageListView':
            return AccountDayBookPageListView;
        case 'AcaCasteCreateListView':
            return AcaCasteCreateListView;
        case 'AttendanceLeaveTypeCreateListView':
            return AttendanceLeaveTypeCreateListView;
        case 'AttendanceTeacherCreateListView':
            return AttendanceTeacherCreateListView;
        case 'MobileAppNotificationCreateListView':
            return MobileAppNotificationCreateListView;
        case 'MobileAppMessageCreateListView':
            return MobileAppMessageCreateListView;
        case 'MobileAppNewEventCreateListView':
            return MobileAppNewEventCreateListView;
        case 'MobileAppHomeWorkCreateListView':
            return MobileAppHomeWorkCreateListView;
        case 'MobileAppObservationCreateListView':
            return MobileAppObservationCreateListView;
        case 'MobileAppTeacherNotificationCreateListView':
            return MobileAppTeacherNotificationCreateListView;
        case 'MobileAppMarksEntryScheduleView':
            return MobileAppMarksEntryScheduleView;
        case 'MobileAppDigitalContentCreateListView':
            return MobileAppDigitalContentCreateListView;
        case 'MobileAppAssignmentCreateListView':
            return MobileAppAssignmentCreateListView;
        case 'GalleryCategoryCreateListView':
            return GalleryCategoryCreateListView;
        case 'AcaStudentUpgradeCreateListView':
            return AcaStudentUpgradeCreateListView;
        case 'StudentPhotoUploadCreateListView':
            return StudentPhotoUploadCreateListView;
        case 'AcaAppUserDetailsCreateListView':
            return AcaAppUserDetailsCreateListView;
        case 'AcaStdQRGenerateCreateListView':
            return AcaStdQRGenerateCreateListView;
        case 'AcaAdmissionRequestCreateListView':
            return AcaAdmissionRequestCreateListView;
        case 'AcaRoutineCreateListView':
            return AcaRoutineCreateListView;
        case 'AcaCalenderCreateListView':
            return AcaCalenderCreateListView;
        case 'AcaGuardianFeedbackReportListView':
            return AcaGuardianFeedbackReportListView;
        case 'AcaVehicleAddViewListView':
            return AcaVehicleAddViewListView;
        case 'AcaVehicleRoutineCreateListView':
            return AcaVehicleRoutineCreateListView;
        case 'AcademicsVechileRoutineRptPageView':
            return AcademicsVechileRoutineRptPageView;
        case 'AcaAdmitCardCreateListView':
            return AcaAdmitCardCreateListView;
        case 'AcaClassRoutinePageListView':
            return AcaClassRoutinePageListView;
        case 'HRStaffDetailsPageView':
            return HRStaffDetailsPageView;
        case 'EmisHallRoomSetUpCreateListView':
            return EmisHallRoomSetUpCreateListView;
        case 'ExamStdHallSeatDetailsListView':
            return ExamStdHallSeatDetailsListView;
        case 'ExamClassConfigureExamHallView':
            return ExamClassConfigureExamHallView;
        case 'ExamHallRoomReportPageListView':
            return ExamHallRoomReportPageListView;
        case 'MarksLedgerCreateFormView':
            return MarksLedgerCreateFormView;
        case 'MarksLedgerPageCreateListView':
            return MarksLedgerPageCreateListView;
        case 'ExamMarksheetFormView':
            return ExamMarksheetFormView;
        case 'ExamMarksheetStudentListView':
            return ExamMarksheetStudentListView;
        case 'MobileAppGalleryCreateListView':
            return MobileAppGalleryCreateListView;
        case 'MobileAppTeacherNotificationDetailsListView':
            return MobileAppTeacherNotificationDetailsListView;
        case 'AttendanceStudentCreateListView':
            return AttendanceStudentCreateListView;
        case 'StdAttendanceReportListViewPage':
            return StdAttendanceReportListViewPage;
        case 'AcaStudentLeaveRequestListView':
            return AcaStudentLeaveRequestListView;
        case 'AtteTeacherLeaveRequestListView':
            return AtteTeacherLeaveRequestListView;
        case 'AcaRollNoGenerateCreateListView':
            return AcaRollNoGenerateCreateListView;
        case 'MobileAppDetailsGenerateCreateListView':
            return MobileAppDetailsGenerateCreateListView;
        case 'LibraryBookKindCreateListView':
            return LibraryBookKindCreateListView;
        case 'AccountTransactionReportPageListView':
            return AccountTransactionReportPageListView;
        case 'SubjectHomeworkCreateListView':
            return SubjectHomeworkCreateListView;
        case 'ExamResultsPublishedCreateListView':
            return ExamResultsPublishedCreateListView;
        case 'ExamGradeGPACreateListView':
            return ExamGradeGPACreateListView;
        case 'HREmpPhotoUploadsCreateListView':
            return HREmpPhotoUploadsCreateListView;
        case 'AppDepartmentsCreateListView':
            return AppDepartmentsCreateListView;
        case 'DailyTeacherLogBookPageListView':
            return DailyTeacherLogBookPageListView;
        case 'DailyTeacherLogBookCreateListView':
            return DailyTeacherLogBookCreateListView;
        case 'WeeklyLessionPlanCreateListView':
            return WeeklyLessionPlanCreateListView;
        case 'WeeklyLessionPlanReportPageListView':
            return WeeklyLessionPlanReportPageListView;
        case 'MarksLedgerNumberListView':
            return MarksLedgerNumberListView;
        case 'MarksEvulationReportPageListView':
            return MarksEvulationReportPageListView;
        case 'ExamAssignSubjectReportListView':
            return ExamAssignSubjectReportListView;
        case 'FeeMappingReportPageListView':
            return FeeMappingReportPageListView;
        case 'AccountLedgerReportViewView':
            return AccountLedgerReportViewView;
        case 'StudentAccountDuesReportListView':
            return StudentAccountDuesReportListView;
        default:
            return null;
    }
};





