import { useFormik } from 'formik';
import React, { Fragment } from 'react'
import { Grid } from "@mui/material";
import InputTextBox from '../../../../widgets/InputTextBox';
import { SaveBtn } from '../../../../widgets/AppBtn';
import ComponentHeading from '../../../../widgets/ComponentHeading';
import { IoMdAddCircle } from "react-icons/io";
import { postDataToTheServer } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ToastSuccess } from '../../../../widgets/ToastMessage';
import { useLanguage } from "../../../../widgets/LangContext";

const GpaGradeCreateFormView = ({ FetchData, onEntincityList }) => {
  const { language } = useLanguage();
  const initialValues = {
    division_name: "",
    perc_from: 0,
    perc_to: 0,
    grade: "",
    gpa: 0,
    grade_des: "",
  };

  const { values, errors, touched, handleReset, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("division_name", values.division_name);
        formData.append("perc_from", values.perc_from);
        formData.append("perc_to", values.perc_to);
        formData.append("grade", values.grade);
        formData.append("gpa", values.gpa);
        formData.append("grade_des", values.grade_des);
        try {
          await postDataToTheServer(AppURL.ExamURL + "exam-gap-grade-division-create-list-view/", formData).then((res) => {
            ToastSuccess(res?.message);
            action.resetForm();
            FetchData();
          });
        } catch (error) {
          console.log(error);
        }
      },
    });
  return (
    <Fragment>
      <form onSubmit={handleSubmit} onReset={handleReset} className="px-4 py-4 dark:bg-slate800 bg-white rounded-md ">
        <ComponentHeading label={language ? "Add GPA/Grade/Division" : "GPA/ग्रेड/डिभिजन थप्नुहोस्"} icon={IoMdAddCircle} />
        <Grid container spacing={1}>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="division_name"
              type="text"
              label={language ? "Division Name" : "डिभिजन नाम"}
              value={values.division_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.division_name}
              errors={errors.division_name}
              required={true}
            />
          </Grid>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="perc_from"
              type="number"
              label={language ? "Percentage From" : "बाट प्रतिशत"}
              value={values.perc_from}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.perc_from}
              errors={errors.perc_from}
              required={true}
            />
          </Grid>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="perc_to"
              type="number"
              label={language ? "Percentage to" : "को प्रतिशत"}
              value={values.perc_to}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.perc_to}
              errors={errors.perc_to}
              required={true}
            />
          </Grid>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="grade"
              type="text"
              label={language ? "Grade" : "ग्रेड"}
              value={values.grade}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.grade}
              errors={errors.grade}
              required={true}
            />
          </Grid>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="gpa"
              type="number"
              label={language ? "GPA" : "GPA"}
              value={values.gpa}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.gpa}
              errors={errors.gpa}
              required={true}
            />
          </Grid>
          <Grid item xl={6} md={6} lg={6} xs={12} sm={12}>
            <InputTextBox
              name="grade_des"
              type="text"
              label={language ? "Grade Des" : "ग्रेड विवरण"}
              value={values.grade_des}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.grade_des}
              errors={errors.grade_des}
              required={true}
            />
          </Grid>
        </Grid>
        <div className="flex justify-end mt-3 gap-2">
          <SaveBtn type="submit" />
        </div>
      </form>
    </Fragment>
  )
}

export default GpaGradeCreateFormView