import React, { Fragment } from 'react'
import MarksLedgerFormView from '../../../../components/exam/report/marks_ledger/MarksLedgerFormView'

const MarksLedgerCreateFormView = () => {
  return (
    <Fragment>
        <MarksLedgerFormView />
    </Fragment>
  )
}

export default MarksLedgerCreateFormView