import React, { Fragment } from 'react'
import { useLanguage } from '../../../../../widgets/LangContext';
import { useSelector } from 'react-redux';
import AppURL from '../../../../../widgets/AppURL';

const PrintStudentUsernamePassword = () => {
    const { language } = useLanguage();
    const { class_wise_app_details_std_list } = useSelector((state) => state?.academics);
    const listItems = class_wise_app_details_std_list?.students;
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    return (
        <Fragment>

            <div className='p-5 bg-white h-screen'>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-gray-900 whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">

                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-gray-900 whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>

                            </tr>
                            <tr >
                                <td rowSpan={3} className="w-[25%] font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={Org_Details?.logo ? AppURL.BaseURL + Org_Details?.logo : "logo"} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>

                                <td className="w-[25%]">
                                </td>

                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-gray-900 font-semibold">
                                    Date: 2080/02/21
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Student Username / Password
                                </td>
                                <td className="w-[25%]">
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>


                <div className="relative mt-3">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                        <thead className="text-[12px] text-gray-800 capitalize bg-gray200">
                            <tr>
                                <th scope="col" align='center' className="px-1 py-1  border-[1px] border-gray-800 w-16">
                                    Roll. No
                                </th>
                                <th scope="col" className="px-1 py-1  border-[1px] border-gray-800">
                                    Name
                                </th>
                                <th scope="col" className="px-1 py-1  border-[1px] border-gray-800">
                                    Username
                                </th>
                                <th scope="col" className="px-1 py-1 border-[1px] border-gray-800">
                                    Password
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-[10px] font-medium text-gray-800">
                            {listItems &&
                                listItems?.map((item, index) => (
                                    <tr key={index} className='font-public-sans'>
                                        <td align='center' className="px-1 py-0.5 border-[1px] border-gray-800 font-medium">
                                            {index + 1}
                                        </td>
                                        <td className="px-1  border-[1px] capitalize py-0.5 border-gray-800 font-medium">
                                            {`${item?.first_name} ${item?.middle_name ? item?.middle_name : ""} ${item?.last_name}`}
                                        </td>
                                        <td className="px-1 border-[1px] py-0.5 border-gray-800 font-medium">
                                            {item?.username}
                                        </td>
                                        <td align='left' className="px-1 py-0.5  border-[1px] border-gray-800 font-medium">
                                            {item?.password}
                                        </td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        </Fragment>
    )
}

export default PrintStudentUsernamePassword