import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { CreatedDateFormat, NepNowDateFormat } from '../../../../widgets/DateFormat';
import AppURL from '../../../../widgets/AppURL';
import { timeAMandPMformat } from '../../../../widgets/NumberFormat';
import './widgets/style.css'

const WeeklyLessionPlanPDFView = () => {
    const { teacher_weekly_lession_plan_list, teacher_daily_day_book_saerch_form } = useSelector((state) => state.academics);
    const { menu_list } = useSelector((state) => state?.software);
    const Org_Details = menu_list?.structure;
    const DateNow = NepNowDateFormat();
    
    return (
        <Fragment>
            <div className='bg-white rounded-md p-4 shadow-sm dark:bg-slate800 print:landscape:block'>
                <style>
                    {`@media print {
                        @page {
                            size: A4 landscape;
                        }
                    }`}
                </style>
                <div className="relative">
                    <table className="w-full text-sm text-left rtl:text-right text-black">
                        <tbody>
                            <tr>
                                <td className="w-[25%] text-[12px] text-black whitespace-nowrap ">
                                    Reg. No: {Org_Details?.org_reg}
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td className="w-[25%]">
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black whitespace-nowrap">
                                    Phone: {Org_Details?.org_phone}
                                </td>
                            </tr>
                            <tr >
                                <td rowSpan={3} className="w-[25%] font-medium text-black whitespace-nowrap dark:text-white">
                                    <img width={60} height={60} src={AppURL.BaseURL + Org_Details?.logo} alt="" />
                                </td>
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    {Org_Details?.org_name}
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-medium text-[12px]">
                                    {Org_Details?.org_add}
                                </td>
                                <td align='right' className="w-[25%] text-[12px] text-black font-semibold">
                                    Print Date: {DateNow}
                                </td>
                            </tr>
                            <tr >
                                <td align='center' colSpan={2} className="w-[25%] text-black font-bold text-[16px]">
                                    Weekly Lession Plan Report
                                </td>
                                <td className="w-[25%]">
                                </td>
                            </tr>
                            <tr >
                            <td align='left' colSpan={1} className="w-[25%] text-black font-normal text-[14px]">
                                    Class: {teacher_daily_day_book_saerch_form?.class_id} / {teacher_daily_day_book_saerch_form?.subjects}
                                </td>
                            
                                <td align='center' colSpan={2} className="w-[25%] text-black font-normal text-[14px]">
                                    Date: {teacher_daily_day_book_saerch_form?.date_from} - {teacher_daily_day_book_saerch_form?.date_to}
                                </td>
                                <td align='right' className="w-[25%]">
                                    Teacher: {teacher_daily_day_book_saerch_form?.teacher}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='bg-white rounded-md p-2 shadow-sm dark:bg-slate-800'>
                    <div className="relative overflow-x-scroll">
                        <table className="text-sm text-left rtl:text-right text-gray-100 whitespace-nowrap scroll-x-auto dark:text-gray-400 rounded w-full">
                            <thead className="text-[12px] text-gray600 capitalize border-b  dark:text-gray-400 font-public-sans">
                                <tr className='bg-gray200'>

                                    <th scope="col" align='center' className="px-2 py-1.5 border border-gray300 dark:border-slate600 w-10">
                                        {"S.N."}
                                    </th>
                                    <th scope="col" className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                        {"Lession To Be Taught"}
                                    </th>
                                    <th scope="col" className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                        {"Objectives to the lession"}
                                    </th>
                                    <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                        {"Teaching Materials"}
                                    </th>
                                    <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                        {"Teaching Method"}
                                    </th>
                                    <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                        {"Assignments Test or Project Works"}
                                    </th>

                                    <th scope="col" align='left' className="px-2 py-1.5 border border-gray300 dark:border-slate600">
                                        {"Created At"}
                                    </th>

                                </tr>
                            </thead>
                            <tbody className='text-slate-800'>
                                {Array.isArray(teacher_weekly_lession_plan_list?.results) && teacher_weekly_lession_plan_list?.results?.length > 0 ? (
                                    teacher_weekly_lession_plan_list?.results?.map((cls, index) => (
                                        <tr key={index} className="odd:bg-white text-[12px] text-slate600 dark:text-gray-400 odd:dark:bg-slate800 even:bg-gray-50 even:dark:bg-slate700 border-b font-public-sans dark:border-gray-700">
                                            <td align='center' className="px-2 border border-gray300 dark:border-slate600  ">
                                                {index + 1}.
                                            </td>
                                            <td className="px-2 py-1 border dark:border-slate600 border-gray300 font-medium">
                                                {cls?.lession_to_be_taught || "-"}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.objective_to_lession || "-"}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.teachining_materials || "-"}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.teachining_methods || "-"}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.assignment_test || "-"}
                                            </td>
                                            <td align='left' className="px-2 py-1 border border-gray300 dark:border-slate600 ">
                                                {cls?.created_at ? CreatedDateFormat(cls?.created_at) : ""} {timeAMandPMformat(cls?.created_at)}
                                            </td>
                                        </tr>
                                    ))
                                ) : (<tr>
                                    <td colSpan="12" className="text-center py-1.5 text-[12px] font-public-sans border border-gray300 text-gray500 dark:text-gray-400">
                                        No Data Found
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default WeeklyLessionPlanPDFView