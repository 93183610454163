import React from 'react'
import { CiCalendar } from "react-icons/ci";
import { FaRegUserCircle } from "react-icons/fa";

const GalleryItem = ({ images, title, gallery_category }) => {
    return (
        <div className="rounded overflow-hidden bg-white">
            <div className="relative w-full p-4 group">
                <div className="w-full h-34 overflow-hidden bg-gray-200 rounded-lg shadow-md">
                    {(images || []).slice(0, 1).map((image, index) => (
                        <img
                            key={index}
                            src={image.image}
                            alt={`Gallery image ${index}`}
                            className="object-cover w-full h-full"
                        />
                    ))}
                </div>
                <div className="absolute top-0 left-0 z-10 flex w-full h-full transition-transform transform group-hover:translate-x-0 overflow-x-scroll">
                    {Array.isArray(images) && images.map((image, index) => (
                        <div key={index} className="flex-shrink-0 w-full h-full">
                            <img
                                src={image.image}
                                alt={`Gallery image ${index}`}
                                className="object-cover w-full h-full"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="py-2 px-2">
                <div className="font-bold text-xl my-0.5 font-public-sans !text-[14px] text-gray-700">{title}</div>
                <div className='flex justify-between flex-row'>
                    <div className='flex flex-row gap-1 font-public-sans text-gray400'>
                        <FaRegUserCircle className='my-auto' />
                        <span className='text-[11px] my-auto'>{gallery_category}</span>
                    </div>
                    <div className='flex flex-row gap-1 font-public-sans text-gray400'>
                        <CiCalendar className='my-auto' />
                        <span className='text-[11px] my-auto'>July 02, 2023</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryItem
