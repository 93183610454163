import React from 'react'

const ComponentHeading = ({ label, icon }) => {
    const Icons = icon ?icon:null;
    return (
        <div className='flex flex-row mb-12 select-none sm:mb-1 sticky'>
            <div className="inline-flex items-start justify-start w-full flex-col">
                <hr className="w-full h-px my-4  bg-gray-200 border-0 dark:bg-slate700 sm:flex hidden" />
                <span className="absolute px-3 mb-3 font-medium text-gray-600 flex sm:translate-x-10 bg-white dark:text-white dark:bg-slate800">
                    <Icons className='my-auto text-lg text-customPrimary cursor-not-allowed' />
                    <span className='font-public-sans text-gray600 dark:text-gray-400 ml-3 font-bold text-[15px] cursor-default'>{label}</span>
                </span>
            </div>
        </div>
    )
}

export default ComponentHeading