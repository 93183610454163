import React, { Fragment, useEffect, useState } from 'react'
import ContentHeading from '../../../../widgets/ContentHeading'
import { Grid } from "@mui/material";
import SelectOptionBox from '../../../../widgets/SelectOptionBox';
import { useLanguage } from '../../../../widgets/LangContext';
import { useFormik } from 'formik';
import { SearchBtn } from '../../../../widgets/AppBtn';
import { FaSearch } from "react-icons/fa";
import { fetchDataFromApi } from '../../../../widgets/api';
import AppURL from '../../../../widgets/AppURL';
import { ExamTypeOptions, MediumOptions, StdClassOptions, StdSectionOptions } from '../../../../widgets/Options';
import { lanugage_medium } from '../../../../widgets/json';
import { useDispatch } from "react-redux";
import InputTextBox from '../../../../widgets/InputTextBox';
import InputNumber from '../../../../widgets/InputNumber';
import { StdAdmitCardDetailsList, Student_List_Search } from '../../../../store/academicsSlice';

const SearchAdmitCardStudentView = ({ setOnExamType }) => {
    const { language } = useLanguage();
    const [onStudentOptionList, setOnStudentOptionList] = useState([]);
    const fetchStudentFilterOptionListView = async () => {
        try {
            await fetchDataFromApi(AppURL.ExamURL + "exam-student-filter-options-list-view/").then((res) => {
                setOnStudentOptionList(res);
            });
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchStudentFilterOptionListView();
    }, []);
    const dispatch = useDispatch();
    const lang = lanugage_medium;
    const StdClassOption = StdClassOptions(onStudentOptionList?.class);
    const StdSectionOpt = StdSectionOptions(onStudentOptionList?.section);
    const MediumOption = MediumOptions(lang);
    const ExamTypeOption = ExamTypeOptions(onStudentOptionList?.exam_type);
    const [selectValues, setOnSelectValues] = useState({
        class_id: "",
        section: "",
        medium: "",
        exam_type: ""
    });
    const optionHandleSelect = (fieldName, data) => {
        handleChange(fieldName)(data.value)
        setOnSelectValues((prevValues) => ({
            ...prevValues,
            [fieldName]: data,
        }));
    };
    const initialValues = {
        class_id: "",
        section: "",
        medium: "",
        exam_type: "",
        exam_center: "",
        exam_time: "",
        exam_date:""
    };

    const { errors,values, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        const formData = new FormData();
        formData.append("class_id", selectValues.class_id.value);
        formData.append("section", selectValues.section?.value);
        formData.append("medium", selectValues.medium?.value);
        try {
          await fetchDataFromApi(AppURL.AcademicsURL + "academics-student-search-filter-list-view/?class_id=" + selectValues?.class_id?.value + "&section=" + selectValues?.section?.value + "&medium=" + selectValues?.medium?.value, formData).then((res) => {
            dispatch(Student_List_Search(res));
            dispatch(StdAdmitCardDetailsList({
                exam_time:values.exam_time,
                exam_center: values.exam_center,
                exam_date: values.exam_date,
            }));
          });

        } catch (error) {
          console.log(error);
        }
      },
    });
    return (
        <Fragment>
            <div className='mb-4 bg-white rounded-md dark:bg-slate800 shadow p-4'>
                <ContentHeading label="Search Student" icon={FaSearch} margin={0} />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Class" : "कक्षा"} placeholder="" value={selectValues.class_id} options={StdClassOption} name="class_id" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.class_id} errors={errors.class_id} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Section" : "सेक्सन"} placeholder="" value={selectValues.section} name="section" options={StdSectionOpt} optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.section} errors={errors.section} required={true} />
                        </Grid>

                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Medium" : "माध्यम"} placeholder="" options={MediumOption} value={selectValues.medium} name="medium" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.medium} errors={errors.medium} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <SelectOptionBox label={language ? "Exam Type" : "परीक्षा प्रकार"} placeholder="" options={ExamTypeOption} value={selectValues.exam_type} name="exam_type" optionSelect={optionHandleSelect} handleBlur={handleBlur}
                                touched={touched.exam_type} errors={errors.exam_type} required={true} />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <InputTextBox
                                name="exam_center"
                                type="text"
                                label={language ? "Exam Center" : "पुस्तक गणना"}
                                value={values.exam_center}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder=""
                                touched={touched.exam_center}
                                errors={errors.exam_center}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <InputTextBox
                                name="exam_time"
                                type="time"
                                label={language ? "Exam Time" : "पुस्तक गणना"}
                                value={values.exam_time}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder=""
                                touched={touched.exam_time}
                                errors={errors.exam_time}
                                required={true}
                            />
                        </Grid>
                        <Grid item xl={2} md={2} lg={2} xs={12} sm={12}>
                            <InputNumber name="exam_date" value={values.exam_date} handleChange={handleChange} mask="9999/99/99" required={true} label={language ? "Exam Date(BS)" : "जन्म मिति (BS)"} />
                        </Grid>
                        <Grid item xl={10} md={10} lg={10} xs={12} sm={12}>
                            <div className='flex justify-end mt-5'>
                                <SearchBtn type="submit" />
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Fragment>
    )
}

export default SearchAdmitCardStudentView