import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../../store/softSlice';
import GuardianFeedbackCard from '../../../components/application/report/guardian_rpt/GuardianFeedbackCard';
import { Grid } from '@mui/material';

const AcaGuardianFeedbackReportListView = ({ PageTitle, setIconsButton }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageTitle({ "title": PageTitle }))
    document.title = `Nexapp - ${PageTitle}`;
    setIconsButton(null);
  }, []);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={3} lg={3} xs={12} sm={12} md={3}>
          <GuardianFeedbackCard />
        </Grid>
        <Grid item xl={3} lg={3} xs={12} sm={12} md={3}>
          <GuardianFeedbackCard />
        </Grid> <Grid item xl={3} lg={3} xs={12} sm={12} md={3}>
          <GuardianFeedbackCard />
        </Grid> <Grid item xl={3} lg={3} xs={12} sm={12} md={3}>
          <GuardianFeedbackCard />
        </Grid> <Grid item xl={3} lg={3} xs={12} sm={12} md={3}>
          <GuardianFeedbackCard />
        </Grid>
      </Grid>
    </div>
  )
}

export default AcaGuardianFeedbackReportListView