import React, { Fragment } from 'react'
import VehicleRoutineFormView from '../../../../components/academics/report/vehicle_routine/VehicleRoutineFormView'

const VehicleRoutineReportFormView = () => {
  return (
    <Fragment>
        <VehicleRoutineFormView />
    </Fragment>
  )
}

export default VehicleRoutineReportFormView