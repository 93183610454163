import React, { useState } from 'react';
import { Dialog, DialogBody, DialogFooter } from "@material-tailwind/react";
import AppURL from '../../../../widgets/AppURL';
import { ClearBtn } from '../../../../widgets/AppBtn';

const TeacherNotificationDetails = ({ onNotificationDetails }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleImageClick = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <div className='bg-white rounded-md p-3'>
            <div className='mb-2 underline text-gray-700'>
                <h1 className='font-public-sans font-bold text-[15px]'>{onNotificationDetails?.data?.title}</h1>
            </div>
            <div className='flex flex-row gap-2 justify-between'>
                <div className='font-public-sans text-[12px] text-normal text-justify'>
                    {onNotificationDetails?.data?.details}
                </div>
                <img
                    className='mt-3 h-[200px] border border-gray-600 transition-all duration-300 rounded-lg blur-sm hover:blur-none cursor-pointer'
                    src={AppURL.URL+onNotificationDetails?.data?.image}
                    alt=""
                    onClick={handleImageClick}
                />
            </div>
            <div className='flex justify-between mt-4'>
                <h6 className='font-public-sans text-[12px]'>Sent By: <span className='text-gray-600'>{`${onNotificationDetails?.data?.first_name || ""} ${onNotificationDetails?.data?.last_name || ""}`}</span></h6>
                <h6 className='font-public-sans text-[12px]'>Date: <span className='text-gray-600'>{`${onNotificationDetails?.data?.date || ""}`}</span></h6>
            </div>

            <Dialog open={isDialogOpen} onClose={handleClose} size="lg">
                <DialogBody>
                    <img
                        src={AppURL.URL+onNotificationDetails?.data?.image}
                        alt="Zoomed"
                        className="w-full h-full object-contain"
                    />
                </DialogBody>
                <DialogFooter>
                    <ClearBtn  onClick={handleClose} />
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default TeacherNotificationDetails;
